import React from 'react'
import { useTheme } from '@mui/material/styles'
import { styled } from '@mui/material/styles'
import { CheckCircle } from '@mui/icons-material'

const MainContainerStyled = styled('div')(({ theme }) => ({
	width: '100%',
	borderRadius: 8,
	padding: 8,
	cursor: 'pointer',
	display: 'flex',
	alignItems: 'center',
	backgroundColor: 'transparent',
	'&:hover': {
		backgroundColor: theme.custom.colors.kozmikGray
	}
}))

const OnboardingListItem = (props) => {
	const theme = useTheme()

	const { text, completed, ...restProps } = props

	return (
		<MainContainerStyled {...restProps}>
			<CheckCircle
				sx={{
					...{ fontSize: '18px', color: '#BBBBBD' },
					...(completed ? { color: theme.custom.colors.kozmikGreen } : null)
				}}
			/>
			<span style={{ fontSize: 14, paddingLeft: 4 }}>{text}</span>
		</MainContainerStyled>
	)
}

export default OnboardingListItem
