import React from 'react'
import { styled } from '@mui/material/styles'
import { Typography } from '@mui/material'

const MenuItemStyled = styled('div')((props) => ({
	...{
		cursor: 'pointer',
		textDecoration: 'none !important',
		display: 'flex',
		alignItems: 'center',
		width: '100%',
		height: 40,
		padding: '0 15px',
		borderRadius: 6,
		justifyContent: 'space-between',
		backgroundColor: 'transparent',
		'&:hover': {
			backgroundColor: props.theme.custom.colors.disabledGray
		}
	},
	...(props?.isActive && {
		backgroundColor: `${props.theme.custom.colors.kozmikLightBlue} !important`,
		'& .text': {
			color: props.theme.custom.colors.kozmikBlue,
			fontWeight: '600'
		}
	})
}))

const TextStyled = styled(Typography)(({ theme }) => ({
	flex: 1,
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
	fontSize: 14,
	color: theme.custom.colors.textPrimary,
	textAlign: 'left'
}))

const KozmikMenuItem = (props) => {
	const { text, isActive, adornment, onClick, ...restProps } = props

	return (
		<div {...restProps}>
			<MenuItemStyled isActive={isActive} onClick={onClick}>
				<TextStyled className='text'>{text}</TextStyled>
				{adornment}
			</MenuItemStyled>
		</div>
	)
}

export default KozmikMenuItem
