import React from 'react'
import { styled } from '@mui/material/styles'

import { ArrowDropUp, ArrowDropDown } from '@mui/icons-material'

const NumericInputStyled = styled('input')((props) => ({
	backgroundColor: props?.bgColor
		? props.bgColor
		: props.theme.custom.colors.kozmikGray,
	borderRadius: 3,
	borderWidth: 0,
	height: 37,
	fontSize: '14px',
	color: props.theme.custom.colors.textPrimary,
	outline: 'none',
	padding: '0 21.5px 0 10px',
	minWidth: 60,
	width: '100%',
	...(props.disabled && {
		color: `${props.theme.custom.colors.disabledPrimary} !important`,
		backgroundColor: `${props.theme.custom.colors.disabledGray} !important`,
		cursor: 'default !important'
	})
}))

const ArrowDropUpStyled = styled(ArrowDropUp)((props) => ({
	borderRadius: 3,
	cursor: 'pointer',
	position: 'absolute',
	zIndex: 1,
	right: 3,
	width: 18.5,
	height: 18.5,
	top: 0,
	...(props.disabled && {
		color: `${props.theme.custom.colors.disabledPrimary} !important`,
		backgroundColor: `${props.theme.custom.colors.disabledGray} !important`,
		cursor: 'default !important'
	})
}))

const ArrowDropDownStyled = styled(ArrowDropDown)((props) => ({
	borderRadius: 3,
	cursor: 'pointer',
	position: 'absolute',
	zIndex: 1,
	right: 3,
	width: 18.5,
	height: 18.5,
	bottom: 0,
	...(props.disabled && {
		color: `${props.theme.custom.colors.disabledPrimary} !important`,
		backgroundColor: `${props.theme.custom.colors.disabledGray} !important`,
		cursor: 'default !important'
	})
}))

const NumericInput = (props) => {
	const { value, onChange, className, disabled, maxVal, minVal, ...restProps } =
		props

	const handleChange = (e) => {
		// const checkedVal = e.target.validity.valid && e.target.value >= 0 ? e.target.value : value
		const checkedVal = !isNaN(e.target.value) ? e.target.value : value

		if (checkedVal != value) {
			const submitVal = checkedVal ? parseInt(checkedVal) : 0
			onChange(submitVal)
		}
	}

	const increase = () => {
		if (!disabled) {
			const convertedVal = value ? parseInt(value) : 0
			const submitVal = convertedVal + 1

			if (maxVal) {
				convertedVal < maxVal && onChange(submitVal)
			} else {
				onChange(submitVal)
			}
		}
	}

	const decrease = () => {
		if (!disabled) {
			const convertedVal = value ? parseInt(value) : 0
			const submitVal = convertedVal - 1

			if (minVal) {
				convertedVal > minVal && onChange(submitVal)
			} else {
				onChange(submitVal)
			}
		}
	}

	return (
		<div style={{ position: 'relative' }}>
			<NumericInputStyled
				disabled={disabled}
				type='text'
				pattern='[0-9]*'
				onInput={handleChange}
				value={value}
				{...restProps}
			/>
			<ArrowDropUpStyled disabled={disabled} onClick={increase} />
			<ArrowDropDownStyled disabled={disabled} onClick={decrease} />
		</div>
	)
}
export default NumericInput
