import React, { useEffect, useState } from 'react'
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd'
import update from 'immutability-helper'
import { useTranslation } from 'react-i18next'
import { AddBox } from '@mui/icons-material'
import { Box, Popover } from '@mui/material'

import TextButton from '../../atoms/button/TextButton'
import AccordionItem from '../../atoms/accordionItem/AccordionItem'
import PopoverItem from '../../molecules/popoverItem/PopoverItem'
import ButtonSelection from '../buttonSelection/ButtonSelection'
import defaultElementSchemas from '../../../defaultElementSchemas'
import AppPageBox from '../../atoms/appPageBox/AppPageBox'
import Accordion from '../../atoms/accordion/Accordion'

import actionTypes from '../../../common/actionTypes'

import styledUI from '../../../styledUI'

const getListStyle = (isDraggingOver) => ({
	background: isDraggingOver ? '#D7DBFB' : 'transparent',
	borderRadius: 6,
	padding: 2,
	width: '100%'
})

const getElementStyle = (isDragging, draggableStyle) => ({
	userSelect: 'none',
	margin: `0 0 10px 0`,
	borderRadius: 6,
	border: isDragging ? '2px dashed gray' : 'none',
	overflow: isDragging ? 'hidden' : 'visible',
	cursor: 'default',

	...draggableStyle
})

const reorder = (list, startIndex, endIndex) => {
	const result = Array.from(list)
	const [removed] = result.splice(startIndex, 1)
	result.splice(endIndex, 0, removed)

	return result
}

const ButtonList = (props) => {
	const { t } = useTranslation()

	const [selectedButtonIndex, setSelectedButtonIndex] = useState(null)
	const [buttonPop, setButtonPop] = useState(null)
	const buttonPopOpen = Boolean(buttonPop)

	const onDragEnd = (result) => {
		const { destination } = result

		if (!destination) {
			return
		}

		const elements = reorder(
			props.buttons,
			result.source.index,
			result.destination.index
		)

		props.updateButtons(elements)
	}

	const addNewButton = () => {
		const modifiedButtons = update(props.buttons, {
			$push: [defaultElementSchemas.button()]
		})

		props.updateButtons(modifiedButtons)
		setSelectedButtonIndex(props.buttons.length)
	}

	const deleteButton = (index) => {
		const modifiedButtons = update(props.buttons, {
			$splice: [[index, 1]]
		})

		props.updateButtons(modifiedButtons)
		setSelectedButtonIndex(null)
	}

	const updateButton = (updatedButton, index) => {
		const modifiedButtons = update(props.buttons, {
			[index]: { $set: updatedButton }
		})
		props.updateButtons(modifiedButtons)
	}

	const openButtonPop = (event, index) => {
		setSelectedButtonIndex(index)
		setButtonPop(event.currentTarget)
	}

	const closeButtonPop = () => {
		setButtonPop(null)
	}

	return (
		<AppPageBox>
			<Accordion
				title={t('screens.elementSettings.buttonSelection.title')}
				expanded={true}
			>
				<DragDropContext onDragEnd={onDragEnd}>
					<Droppable droppableId='droppable'>
						{(provided, snapshot) => (
							<div
								{...provided.droppableProps}
								ref={provided.innerRef}
								style={getListStyle(snapshot.isDraggingOver)}
							>
								{!!props?.buttons?.length &&
									props?.buttons.map((item, index) => (
										<Draggable
											key={`droppable-button-${index}`}
											draggableId={`droppable-button-${index}`}
											index={index}
										>
											{(provided, snapshot) => (
												<div
													ref={provided.innerRef}
													{...provided.draggableProps}
													{...provided.dragHandleProps}
													style={getElementStyle(
														snapshot.isDragging,
														provided.draggableProps.style
													)}
												>
													<styledUI.StyledDiv key={index}>
														<AccordionItem
															title={
																item?.mapping?.text?.default
																	? item.mapping.text.default
																	: actionTypes?.find(
																			(x) => x.value === item.actions?.[0]?.type
																	  )?.label ||
																	  t(
																			'screens.elementSettings.buttonSelection.noAction'
																	  )
															}
															expanded={selectedButtonIndex === index}
															padding={8}
															onChange={() => (event, newExpanded) => {
																setSelectedButtonIndex(
																	selectedButtonIndex === index ? null : index
																)
															}}
															moreClick={(e) => openButtonPop(e, index)}
															bgColor='#F0F0F4'
														>
															<ButtonSelection
																bgColor='#ffffff'
																button={item}
																buttonIndex={index}
																updateButton={updateButton}
																entities={props.entities}
																activeScreen={props.activeScreen}
																formSource={props.formSource}
																isList={!!props?.isList}
																listSource={props?.listSource}
															/>
														</AccordionItem>
													</styledUI.StyledDiv>
												</div>
											)}
										</Draggable>
									))}
								{provided.placeholder}
							</div>
						)}
					</Droppable>
				</DragDropContext>

				<TextButton
					onClick={addNewButton}
					icon={AddBox}
					text={t('screens.elementSettings.buttonSelection.addNewButton')}
				/>

				<Popover
					open={buttonPopOpen}
					anchorEl={buttonPop}
					onClose={() => {
						closeButtonPop()
					}}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'center'
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'center'
					}}
				>
					<Box
						sx={{
							padding: 8
						}}
					>
						<PopoverItem
							text={t('screens.elementSettings.buttonSelection.delete')}
							onClick={() => {
								closeButtonPop()
								deleteButton(selectedButtonIndex)
							}}
							color='#FF2619'
						>
							<styledUI.PopoverDeleteIconStyled />
						</PopoverItem>
					</Box>
				</Popover>
			</Accordion>
		</AppPageBox>
	)
}

export default ButtonList
