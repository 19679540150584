import React from 'react'
import { styled } from '@mui/material/styles'
import { Typography } from '@mui/material'

const CardContainerStyled = styled('div')(({ theme }) => ({
	width: '50%',
	height: 'auto',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	flexDirection: 'column',
	padding: '7px 10px',
	position: 'relative'
}))

const AspectRatioContainerStyled = styled('div')(({ theme }) => ({
	height: 0,
	width: '100%',
	paddingTop: '70.74%', // 147:104
	position: 'relative'
}))

const IconBoxStyled = styled('div')(({ theme }) => ({
	position: 'absolute',
	top: 0,
	left: 0,
	width: '100%',
	height: '100%',
	'&:hover': {
		boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.17)'
	},
	cursor: 'pointer',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	backgroundColor: theme.custom.colors.kozmikGray,
	borderRadius: '4px'
}))

const IconStyled = styled('img')(({ theme }) => ({
	color: theme.custom.colors.textPrimary,
	width: '100%',
	height: '100%'
}))

const SignTextStyled = styled(Typography)(({ theme }) => ({
	textAlign: 'center',
	color: theme.custom.colors.textPrimary,
	fontSize: 12
}))

const BadgeStyled = styled('span')((props) => ({
	fontSize: 8,
	fontWeight: 'bold',
	borderRadius: 7.5,
	padding: '0 4px',
	position: 'absolute',
	top: 12,
	left: 15,
	backgroundColor: props?.badgeColor ? props.badgeColor : '#78FFAA'
}))

const ResponsiveCard = (props) => {
	return (
		<CardContainerStyled>
			<AspectRatioContainerStyled>
				<IconBoxStyled onClick={props?.onClick}>
					<IconStyled src={props.icon} alt='element image' />
					{/* <props.icon className={classes.icon} /> */}
				</IconBoxStyled>
			</AspectRatioContainerStyled>
			{!!props?.badgeText && (
				<BadgeStyled badgeColor={props?.badgeColor}>
					{props.badgeText}
				</BadgeStyled>
			)}
			{!!props?.text && <SignTextStyled>{props.text}</SignTextStyled>}
		</CardContainerStyled>
	)
}
export default ResponsiveCard
