import React from 'react'
import { styled } from '@mui/material/styles'

import { Typography } from '@mui/material'
import Grid from '@mui/material/Grid2'
import { MoreVert } from '@mui/icons-material'

const TableContainerStyled = styled(Grid)((props) => ({
	paddingLeft: 10,
	height: 40,
	marginBottom: 5,
	borderRadius: 6,
	cursor: 'pointer',
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	'&:hover': {
		backgroundColor: props.theme.custom.colors.kozmikGray
	},
	'&:hover .moreIcon': {
		display: 'block'
	},
	...(props.isActive
		? {
				backgroundColor: `${props.theme.custom.colors.kozmikLightBlue} !important`,
				'& .tableBtnTxt': {
					color: props.theme.custom.colors.kozmikBlue
				},
				'& .moreIcon': {
					display: 'block'
				}
		  }
		: null)
}))

const TextContainerStyled = styled('div')(({ theme }) => ({
	overflowX: 'hidden'
}))

const TableBtnTextStyled = styled(Typography)(({ theme }) => ({
	fontSize: 14,
	color: theme.custom.colors.textPrimary,
	fontWeight: '600'
}))

const MoreIconStyled = styled(MoreVert)(({ theme }) => ({
	color: theme.custom.colors.textPrimary,
	display: 'none'
}))

const TableMenuItem = (props) => {
	return (
		<TableContainerStyled
			size={{ xs: 12 }}
			onClick={() => {
				!props.isActive && props.click(props.item)
			}}
		>
			<TextContainerStyled>
				<TableBtnTextStyled className='tableBtnTxt'>
					{props.item.label}
				</TableBtnTextStyled>
			</TextContainerStyled>
			<MoreIconStyled
				className='moreIcon'
				onClick={(e) => {
					props.editClick(props.item, e)
				}}
			/>
		</TableContainerStyled>
	)
}

export default TableMenuItem
