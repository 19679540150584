import React, { useEffect, useState } from 'react'
import { Search } from '@mui/icons-material'
import { useTheme } from '@mui/material/styles'
import InputAdornment from '@mui/material/InputAdornment'
import CancelRoundedIcon from '@mui/icons-material/CancelRounded'
import Grid from '@mui/material/Grid2'
import FormatLineSpacingIcon from '@mui/icons-material/FormatLineSpacing'

import Header from '../../molecules/header/Header'
import BorderlessTextField from '../../atoms/borderlessTextField/BorderlessTextField'
import SelectBox from '../../molecules/selectBox/SelectBox'

const style = {
	input: {
		backgroundColor: '#ffffff',
		borderRadius: 4
	},
	inputSize: {
		fontSize: '14px'
	}
}

const SearchHeader = (props) => {
	const theme = useTheme()
	const [searchVal, setSearchVal] = useState('')
	const [selectVal, setSelectVal] = useState('Created desc')

	const selectChange = (e) => {
		setSelectVal(e.target.value)
		props?.searchAndFilter(searchVal, e.target.value)
		// props?.filter(e.target.value)
	}

	const searchOnKeyPress = (e) => {
		if (e.charCode === 13) {
			props?.searchAndFilter(e.target.value, selectVal)
			// props?.search(e.target.value)
		}
	}

	const clearSearch = () => {
		setSearchVal('')
		props?.searchAndFilter('', selectVal)
	}

	useEffect(() => {}, [])
	return (
		<div
			style={{
				display: 'flex',
				backgroundColor: '#fafafc',
				zIndex: 1
			}}
		>
			<div
				style={{
					width: '100%',
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
					flexWrap: 'wrap'
				}}
			>
				{props?.title && <Header text={props.title}></Header>}

				<div>
					<Grid container spacing={2}>
						<Grid size={{ xs: 6 }}>
							<SelectBox
								sx={{ ...style.input, ...style.inputSize }}
								onChange={selectChange}
								startAdornment={
									<InputAdornment position='start'>
										<FormatLineSpacingIcon
											sx={{
												color: theme.custom.colors.textSecondary,
												opacity: 0.6
											}}
										/>
									</InputAdornment>
								}
								value={selectVal}
								data={props.selectData}
								textKey='text'
								valueKey='value'
								placeholder={
									props?.placeholders?.select
										? props.placeholders.select
										: 'Sort'
								}
							/>
						</Grid>
						<Grid size={{ xs: 6 }}>
							<BorderlessTextField
								sx={style.input}
								size='small'
								id='standard-search'
								placeholder={
									props?.placeholders?.search
										? props.placeholders.search
										: 'Search...'
								}
								variant='outlined'
								fullWidth={true}
								value={searchVal}
								onChange={(e) => setSearchVal(e.target.value)}
								onKeyPress={searchOnKeyPress}
								slotProps={{
									input: {
										style: style.inputSize,
										startAdornment: (
											<InputAdornment position='start'>
												<Search
													sx={{
														color: theme.custom.colors.textSecondary,
														opacity: 0.6
													}}
												/>
											</InputAdornment>
										),

										endAdornment: searchVal && (
											<InputAdornment
												onClick={() => clearSearch()}
												position='end'
												style={{ cursor: 'pointer' }}
											>
												<CancelRoundedIcon
													sx={{
														color: theme.custom.colors.textSecondary,
														opacity: 0.6
													}}
												/>
											</InputAdornment>
										)
									}
								}}
							/>
						</Grid>
					</Grid>
				</div>
			</div>
		</div>
	)
}
export default SearchHeader
