import React, { useState, useEffect } from 'react'
import { useTheme } from '@mui/material/styles'
import Grid from '@mui/material/Grid2'
import update from 'immutability-helper'
import { useTranslation } from 'react-i18next'
import {
	SwapVert,
	BrowserNotSupported,
	FilterAltOutlined,
	PlusOne
} from '@mui/icons-material'

import AppPageBox from '../../../atoms/appPageBox/AppPageBox'
import SelectBox from '../../../molecules/selectBox/SelectBox'
import SquareOptions from '../../../molecules/squareOprions/SquareOptions'
import Accordion from '../../../atoms/accordion/Accordion'
import FilterModalOptions from '../../../molecules/filterModalOptions/FilterModalOptions'
import SearchOptions from '../../../molecules/searchOptions/SearchOptions'
import { default as Label } from '../../../atoms/settingLabel/SettingLabel'
import AccordionItem from '../../../atoms/accordionItem/AccordionItem'
import FilterList from '../../../molecules/filterList/FilterList'
import ActionList from '../../actionList/ActionList'
import DoubleStateMentions from '../../../molecules/doubleStateField/DoubleStateMentions'
import VisibilityOptions from '../../../molecules/visibilityOptions/VisibilityOptions'

import { ReactComponent as Koseli } from '../../../../assets/icons/Settings/Koseli.svg'
import { ReactComponent as Oval } from '../../../../assets/icons/Settings/Oval.svg'

import timeoutDelay from '../../../../methods/timeoutDelay'
import getEntityFieldsByDataTypes from '../../../../methods/getEntityFieldsByDataTypes'
import aggOperators from '../../../../common/aggOperators'

import relationTypes from '../../../../common/enums/relationTypes'

import defaultElementSchemas from '../../../../defaultElementSchemas'

import styledUI from '../../../../styledUI'

const Numbers = (props) => {
	const { t } = useTranslation()
	const theme = useTheme()

	const [elementName, setElementName] = useState(null)

	/****SOURCE***************************/
	const [source, setSource] = useState('')

	const textFields = getEntityFieldsByDataTypes(
		props?.entities,
		props.activeScreen?.data?.source,
		['Text', 'LongText', 'Number', 'Date', 'Email', 'Formula'],
		null,
		true,
		relationTypes.one
	)

	const textOptions = textFields?.length ? textFields : []
	const textOptionsWithEmpty = [{ label: '-', name: '' }, ...textOptions]

	const numericFields = getEntityFieldsByDataTypes(
		props?.entities,
		source,
		['Number', 'Rollup', 'Formula'],
		null,
		true,
		relationTypes.one
	)?.filter(
		(x) =>
			!x.formula || (x.formula && x.formula.formulaFieldDataType === 'Number')
	)

	const sourceChange = (e) => {
		submitSource(source, e.target.value)
		setSource(e.target.value)
	}
	const submitSource = (oldTableName, tableName) => {
		const numericFields = getEntityFieldsByDataTypes(
			props?.entities,
			tableName,
			['Number'],
			null,
			true,
			relationTypes.one
		)

		const numericField = numericFields?.length ? numericFields[0] : null

		let modifiedElementData = update(props.elementData, {
			source: { $set: tableName },
			mapping: {
				value: {
					field: { $set: numericField?.name },
					type: { $set: numericField?.dataType }
				},
				aggregation: { $set: 'count' }
			},
			filters: {
				groupOp: { $set: 'AND' },
				rules: { $set: [] }
			},
			filterModal: {
				$set: defaultElementSchemas.filterModal()
			}
		})

		props.updateSelectedElement(modifiedElementData)
	}

	/****TITLE***************************/
	const [isTitleTextMode, setIsTitleTextMode] = useState(false)
	const [titleField, setTitleField] = useState('')
	const [titleDefault, setTitleDefault] = useState('')
	const titleChange = (data) => {
		if (isTitleTextMode) {
			submitTitle(data)
			setTitleDefault(data)
		} else {
			submitTitle(data?.value)
			setTitleField(data?.value)
		}
	}
	const submitTitle = (x) => {
		const selectedField = textFields?.find((y) => y.name == x)
		const modifiedElementData = update(props.elementData, {
			mapping: {
				title: {
					field: { $set: isTitleTextMode ? null : x },
					default: { $set: isTitleTextMode ? x : null },
					type: { $set: isTitleTextMode ? null : selectedField?.dataType }
				}
			}
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****DESCRIPTION***************************/
	const [isDescriptionTextMode, setIsDescriptionTextMode] = useState(false)
	const [descriptionField, setDescriptionField] = useState('')
	const [descriptionDefault, setDescriptionDefault] = useState('')
	const descriptionChange = (data) => {
		if (isDescriptionTextMode) {
			submitDescription(data)
			setDescriptionDefault(data)
		} else {
			submitDescription(data?.value)
			setDescriptionField(data?.value)
		}
	}
	const submitDescription = (x) => {
		const selectedField = textFields?.find((y) => y.name == x)
		const modifiedElementData = update(props.elementData, {
			mapping: {
				description: {
					field: { $set: isDescriptionTextMode ? null : x },
					default: { $set: isDescriptionTextMode ? x : null },
					type: { $set: isDescriptionTextMode ? null : selectedField?.dataType }
				}
			}
		})
		props.updateSelectedElement(modifiedElementData)
	}
	/****AGGREGATION***************************/
	const [aggregation, setAggregation] = useState('')
	const aggregationChange = (e) => {
		submitAggregation(e.target.value)
		setAggregation(e.target.value)
	}
	const submitAggregation = (x) => {
		const modifiedElementData = update(props.elementData, {
			mapping: {
				aggregation: { $set: x }
			}
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****VALUE***************************/
	const [value, setValue] = useState('')
	const valuceChange = (e) => {
		submitValue(e.target.value)
		setValue(e.target.value)
	}
	const submitValue = (x) => {
		const selectedField = numericFields?.find((y) => y.name == x)
		const modifiedElementData = update(props.elementData, {
			mapping: {
				value: {
					field: { $set: x },
					type: { $set: selectedField?.dataType }
				}
			}
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****ACTIONS***************************/
	const updateActions = (newActions) => {
		const modifiedElementData = update(props.elementData, {
			actions: { $set: newActions }
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****LIMIT***************************/
	const [limit, setLimit] = useState(10)
	const limitChange = (val) => {
		timeoutDelay(submitLimit, val, 1000)
		setLimit(val)
	}
	const submitLimit = (x) => {
		const modifiedElementData = update(props.elementData, {
			limit: { $set: x }
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****BACKGROUND***************************/
	const [background, setBackground] = useState('none')
	const backgroundChange = (val) => {
		submitBackground(val)
		setBackground(val)
	}
	const submitBackground = (x) => {
		const modifiedElementData = update(props.elementData, {
			background: { $set: x }
		})
		props.updateSelectedElement(modifiedElementData)
	}
	const backgroundOptions = [
		{
			text: t('screens.elementSettings.numbers.backgroundOpts.none'),
			value: 'none',
			icon: BrowserNotSupported
		},
		{
			text: t('screens.elementSettings.numbers.backgroundOpts.square'),
			value: 'square',
			icon: Koseli
		},
		{
			text: t('screens.elementSettings.numbers.backgroundOpts.oval'),
			value: 'oval',
			icon: Oval
		}
	]

	/****FILTER_&_SORT***************************/
	const [localElementData, setLocalElementData] = useState(null)

	/****INITIALIZATION***************************/
	const initStates = (elementData) => {
		/****DATA***************************/
		setSource(elementData?.source ?? '')
		setLimit(elementData?.limit ?? 10)
		/****CONTENT***************************/
		/****TEXT***************************/
		elementData?.mapping?.title?.field ||
		elementData?.mapping?.title?.field === ''
			? setIsTitleTextMode(false)
			: setIsTitleTextMode(true)

		setTitleField(elementData?.mapping?.title?.field || '')
		setTitleDefault(elementData?.mapping?.title?.default || '')
		/****DESCRIPTION***************************/
		elementData?.mapping?.description?.field ||
		elementData?.mapping?.description?.field === ''
			? setIsDescriptionTextMode(false)
			: setIsDescriptionTextMode(true)

		setDescriptionField(elementData?.mapping?.description?.field || '')
		setDescriptionDefault(elementData?.mapping?.description?.default || '')
		setValue(elementData?.mapping?.value?.field ?? '')
		setAggregation(elementData.mapping?.aggregation ?? '')
		/****DESIGN***************************/
		setBackground(elementData?.background ?? 'none')

		if (!elementData?.filterModal) {
			const modifiedElementData = update(elementData, {
				filterModal: {
					$set: defaultElementSchemas.filterModal()
				}
			})
			props.updateSelectedElement(modifiedElementData)
		}

		if (!elementData?.searchPlaceholder) {
			const modifiedElementData = update(elementData, {
				searchPlaceholder: {
					$set: {
						default: t('schemas.numbers.searchPlaceholder')
					}
				}
			})

			props.updateSelectedElement(modifiedElementData)
		}

		setElementName(elementData?._uid)
	}

	useEffect(() => {
		if (props?.elementData) {
			initStates(props.elementData)
			setLocalElementData(props.elementData)
		}
	}, [props.elementData])

	return (
		<>
			<AppPageBox>
				<Accordion
					title={t('screens.elementSettings.common.titles.source')}
					expanded={true}
				>
					<styledUI.StyledGrid container alignItems='center'>
						<Grid size={{ xs: 12 }}>
							<SelectBox
								sx={styledUI.Style.selectBox(props, theme)}
								onChange={sourceChange}
								value={source}
								data={props.entities}
								textKey='label'
								valueKey='name'
							/>
						</Grid>
					</styledUI.StyledGrid>

					<styledUI.StyledDiv>
						<AccordionItem
							icon={FilterAltOutlined}
							title={t('screens.elementSettings.numbers.filter')}
							expanded={false}
							padding={8}
						>
							<FilterList
								filter={localElementData?.filters}
								table={props?.entities?.find(
									(y) => y.name == props?.elementData?.source
								)}
								activeScreen={props?.activeScreen}
								entities={props?.entities}
								tableName={props?.elementData?.source}
								screenTable={props?.entities?.find(
									(y) => y.name == props.activeScreen?.data?.source
								)}
								filtering={props.filtering}
								setLocalElementData={setLocalElementData}
							/>
						</AccordionItem>
					</styledUI.StyledDiv>
				</Accordion>
			</AppPageBox>

			<AppPageBox>
				<Accordion
					title={t('screens.elementSettings.common.titles.content')}
					expanded={true}
				>
					<styledUI.StyledGrid container alignItems='center'>
						<Grid size={{ xs: 4 }}>
							<Label>{t('screens.elementSettings.numbers.title')}</Label>
						</Grid>
						<Grid size={{ xs: 8 }}>
							<DoubleStateMentions
								name={`${elementName}-title`}
								multiline={false}
								fullWidth={true}
								isTextMode={isTitleTextMode}
								setIsTextMode={setIsTitleTextMode}
								valChange={titleChange}
								textVal={titleDefault}
								autoCompVal={titleField}
								autoCompOptions={textOptionsWithEmpty}
								valueKey='name'
								labelKey='label'
							/>
						</Grid>
					</styledUI.StyledGrid>
					<styledUI.StyledGrid container alignItems='center'>
						<Grid size={{ xs: 4 }}>
							<Label>{t('screens.elementSettings.numbers.description')}</Label>
						</Grid>
						<Grid size={{ xs: 8 }}>
							<DoubleStateMentions
								name={`${elementName}-description`}
								multiline={false}
								fullWidth={true}
								isTextMode={isDescriptionTextMode}
								setIsTextMode={setIsDescriptionTextMode}
								valChange={descriptionChange}
								textVal={descriptionDefault}
								autoCompVal={descriptionField}
								autoCompOptions={textOptionsWithEmpty}
								valueKey='name'
								labelKey='label'
							/>
						</Grid>
					</styledUI.StyledGrid>
					<styledUI.StyledGrid container alignItems='center'>
						<Grid size={{ xs: 4 }}>
							<Label>{t('screens.elementSettings.numbers.value')}</Label>
						</Grid>
						<Grid size={{ xs: 8 }}>
							<SelectBox
								emptyText={t('screens.elementSettings.numbers.emptyField')}
								sx={styledUI.Style.selectBox(props, theme)}
								onChange={valuceChange}
								value={value}
								data={numericFields}
								textKey='label'
								valueKey='name'
							/>
						</Grid>
					</styledUI.StyledGrid>
					<styledUI.StyledGrid container alignItems='center'>
						<Grid size={{ xs: 4 }}>
							<Label>{t('screens.elementSettings.numbers.aggregation')}</Label>
						</Grid>
						<Grid size={{ xs: 8 }}>
							<SelectBox
								emptyText={t('screens.elementSettings.numbers.emptyField')}
								sx={styledUI.Style.selectBox(props, theme)}
								onChange={aggregationChange}
								value={aggregation}
								data={aggOperators}
								textKey='label'
								valueKey='value'
							/>
						</Grid>
					</styledUI.StyledGrid>
				</Accordion>
			</AppPageBox>

			<AppPageBox>
				<Accordion
					title={t('screens.elementSettings.common.titles.design')}
					expanded={true}
				>
					<styledUI.StyledGrid container alignItems='center'>
						<Grid size={{ xs: 4 }}>
							<Label>{t('screens.elementSettings.numbers.background')}</Label>
						</Grid>
						<Grid size={{ xs: 8 }}>
							<SquareOptions
								compact
								data={backgroundOptions}
								getChange={backgroundChange}
								activeValue={background}
							/>
						</Grid>
					</styledUI.StyledGrid>
				</Accordion>
			</AppPageBox>

			{/* <AppPageBox>
				<Accordion
					title={t('screens.elementSettings.common.titles.search')}
					expanded={true}
				>
					<SearchOptions
						source={source}
						elementData={props.elementData}
						updateSelectedElement={props.updateSelectedElement}
					/>
				</Accordion>
			</AppPageBox> */}

			<AppPageBox>
				<Accordion
					title={t('screens.elementSettings.common.titles.inAppFilter')}
					expanded={false}
				>
					{source && (
						<FilterModalOptions
							source={source}
							activeScreen={props.activeScreen}
							elementData={props.elementData}
							entities={props.entities}
							sorting={props.sorting}
							filtering={props.filtering}
							updateSelectedElement={props.updateSelectedElement}
						/>
					)}
				</Accordion>
			</AppPageBox>

			<AppPageBox>
				<Accordion
					title={t('screens.elementSettings.common.titles.actions')}
					expanded={true}
				>
					{source && (
						<ActionList
							name={elementName}
							entities={props.entities}
							activeScreen={props.activeScreen}
							actions={props.elementData.actions}
							updateActions={updateActions}
							isList={true}
							listSource={source}
						/>
					)}
				</Accordion>
			</AppPageBox>

			<VisibilityOptions
				block={props.elementData}
				activeScreen={props.activeScreen}
				entities={props.entities}
				updateBlock={props.updateSelectedElement}
			/>
		</>
	)
}

export default Numbers
