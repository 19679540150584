import React, { useState } from 'react'
import { styled } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'
import Grid from '@mui/material/Grid2'
import update from 'immutability-helper'
import InputAdornment from '@mui/material/InputAdornment'
import { Search } from '@mui/icons-material'
import { connect } from 'react-redux'

import ResponsiveCard from '../responsiveCard/ResponsiveCard'
import AppPageBox from '../../atoms/appPageBox/AppPageBox'
import Accordion from '../../atoms/accordion/Accordion'
import Label from '../../atoms/label/Label'
import KozmikTextField from '../../atoms/kozmikTextField/KozmikTextField'

import defaultElementSchemas from '../../../defaultElementSchemas'
import fieldDataTypes from '../../../common/fieldDataTypes'
import showToastError from '../../../methods/showToastError'
import elementNames from '../../../common/enums/elementNames'
import postMessageToPreview from '../../../methods/postMessageToPreview'
import systemFieldNames from '../../../common/systemFieldNames/systemFieldNames'

import { default as List } from '../../../assets/icons/Elements/List.svg'
import { default as Table } from '../../../assets/icons/Elements/Table.svg'
import { default as Columns } from '../../../assets/icons/Elements/Columns.svg'
import { default as Tile } from '../../../assets/icons/Elements/Tile.svg'
import { default as Card } from '../../../assets/icons/Elements/Card.svg'
import { default as PhoneButton } from '../../../assets/icons/Elements/PhoneButton.svg'
import { default as EmailButton } from '../../../assets/icons/Elements/EmailButton.svg'
import { default as LinkButton } from '../../../assets/icons/Elements/LinkButton.svg'
import { default as Notebox } from '../../../assets/icons/Elements/Notebox.svg'
import { default as Cover } from '../../../assets/icons/Elements/Cover.svg'
import { default as Form } from '../../../assets/icons/Elements/Form.svg'
import { default as Text } from '../../../assets/icons/Elements/Text.svg'
import { default as Image } from '../../../assets/icons/Elements/Image.svg'
import { default as Button } from '../../../assets/icons/Elements/Button.svg'
import { default as ButtonGroup } from '../../../assets/icons/Elements/ButtonGroup.svg'
import { default as CheckboxIcon } from '../../../assets/icons/Elements/Checkbox.svg'
import { default as Video } from '../../../assets/icons/Elements/Video.svg'
import { default as WebView } from '../../../assets/icons/Elements/WebView.svg'
import { default as Map } from '../../../assets/icons/Elements/Map.svg'
import { default as Logout } from '../../../assets/icons/Elements/Logout.svg'
import { default as SpaceLine } from '../../../assets/icons/Elements/SpaceLine.svg'
import { default as UserInfo } from '../../../assets/icons/Elements/UserInfo.svg'
import { default as Choice } from '../../../assets/icons/Elements/Choice.svg'
import { default as CalendarList } from '../../../assets/icons/Elements/CalendarList.svg'
import { default as NumberEntry } from '../../../assets/icons/Elements/NumberEntry.svg'
import { default as LineChart } from '../../../assets/icons/Elements/LineChart.svg'
import { default as BarChart } from '../../../assets/icons/Elements/BarChart.svg'
import { default as PieChart } from '../../../assets/icons/Elements/PieChart.svg'
import { default as ProgressChart } from '../../../assets/icons/Elements/ProgressChart.svg'
import { default as ProgressBar } from '../../../assets/icons/Elements/ProgressBar.svg'
import { default as Carousel } from '../../../assets/icons/Elements/Carousel.svg'
import { default as Gallery } from '../../../assets/icons/Elements/Gallery.svg'
import { default as Container } from '../../../assets/icons/Elements/Container.svg'
import { default as Barcode } from '../../../assets/icons/Elements/Barcode.svg'
import { default as QRCode } from '../../../assets/icons/Elements/QRCode.svg'
import { default as Numbers } from '../../../assets/icons/Elements/Numbers.svg'
import { default as Chat } from '../../../assets/icons/Elements/Chat.svg'
import { default as Reviews } from '../../../assets/icons/Elements/Reviews.svg'
import { default as MapList } from '../../../assets/icons/Elements/MapList.svg'
import { default as Rating } from '../../../assets/icons/Elements/Rating.svg'

const PaddingBoxStyled = styled('div')(({ theme }) => ({
	width: '100%',
	padding: '15px 15px 0 15px',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between'
}))

const SearchInputStyled = styled(KozmikTextField)(({ theme }) => ({
	backgroundColor: theme.custom.colors.kozmikGray,
	borderRadius: 4,
	height: 38
}))

const InputIconStyled = styled(Search)(({ theme }) => ({
	color: theme.custom.colors.textSecondary,
	opacity: 0.6,
	cursor: 'pointer'
}))

const ElementAddList = (props) => {
	const { t } = useTranslation()

	const localTable = props.activeScreen.data?.source
		? props?.entities?.find((x) => x.name == props.activeScreen.data?.source)
		: null

	const scrollToNewPreviewElement = (id, timeout = 1500) => {
		postMessageToPreview({ source: 'kozmik-screens', scrollToId: id })
	}

	const listClick = async () => {
		const firstTable = getFirstTable()

		if (firstTable) {
			const element = defaultElementSchemas.list(firstTable)

			addElementHasSourceToScreen(element, firstTable)
		} else {
			showToastError(t('screens.elementAddList.textFieldAlert'))
		}
	}

	const tableClick = async () => {
		const firstTable = getFirstTable()

		if (firstTable) {
			const element = defaultElementSchemas.table(firstTable)

			addElementHasSourceToScreen(element, firstTable)
		} else {
			showToastError(t('screens.elementAddList.textFieldAlert'))
		}
	}

	const tileListClick = async () => {
		const firstTable = getFirstTable()

		if (firstTable) {
			const element = defaultElementSchemas.tileList(firstTable)

			addElementHasSourceToScreen(element, firstTable)
		} else {
			showToastError(t('screens.elementAddList.textFieldAlert'))
		}
	}

	const reviewsClick = async () => {
		const table = props?.entities?.find(
			(x) =>
				!!x?.fields?.find(
					(z) =>
						!systemFieldNames.notVisible.includes(z?.name) &&
						z?.relatedEntityName === props.appDetail?.DataSource?.UsersTableName
				)
		)

		if (table) {
			const element = defaultElementSchemas.reviews(
				table,
				props.appDetail?.DataSource?.UsersTableName
			)

			addElementHasSourceToScreen(element, table)
		} else {
			showToastError(t('screens.elementAddList.textFieldAlert'))
		}
	}

	const mapListClick = async () => {
		const firstTable = getFirstTable()

		if (firstTable) {
			const element = defaultElementSchemas.mapList(firstTable)

			addElementHasSourceToScreen(element, firstTable)
		} else {
			showToastError(t('screens.elementAddList.textFieldAlert'))
		}
	}

	const chatClick = async () => {
		const table = props?.entities?.find(
			(x) =>
				!!x?.fields?.find(
					(z) =>
						!systemFieldNames.notVisible.includes(z?.name) &&
						z?.relatedEntityName === props.appDetail?.DataSource?.UsersTableName
				)
		)

		if (table) {
			const element = defaultElementSchemas.chat(
				table,
				props.appDetail?.DataSource?.UsersTableName
			)

			addElementHasSourceToScreen(element, table)
		} else {
			showToastError(t('screens.elementAddList.textFieldAlert'))
		}
	}

	const calendarListClick = async () => {
		const firstTable = getFirstTable()

		if (firstTable) {
			const element = defaultElementSchemas.calendarList(firstTable)

			addElementHasSourceToScreen(element, firstTable)
		} else {
			showToastError(t('screens.elementAddList.dateFieldAlert'))
		}
	}

	const carouselClick = async () => {
		const firstTable = getFirstTable()

		if (firstTable) {
			const element = defaultElementSchemas.carousel(firstTable)

			addElementHasSourceToScreen(element, firstTable)
		} else {
			showToastError(t('screens.elementAddList.textFieldAlert'))
		}
	}

	const galleryClick = async () => {
		const firstTable = getFirstTable()

		if (firstTable) {
			const element = defaultElementSchemas.gallery(firstTable)

			addElementHasSourceToScreen(element, firstTable)
		} else {
			showToastError(t('screens.elementAddList.textFieldAlert'))
		}
	}

	const numbersClick = async () => {
		const firstTable = getFirstTable()

		if (firstTable) {
			const element = defaultElementSchemas.numbers(firstTable)

			addElementHasSourceToScreen(element, firstTable)
		} else {
			showToastError(t('screens.elementAddList.textFieldAlert'))
		}
	}

	const ratingClick = async () => {
		const firstTable = getFirstTable()

		if (firstTable) {
			const element = defaultElementSchemas.rating(firstTable)

			addElementHasSourceToScreen(element, firstTable)
		} else {
			showToastError(t('screens.elementAddList.textFieldAlert'))
		}
	}

	const chartLineClick = async () => {
		const firstTable = getFirstTable()

		if (firstTable) {
			const element = defaultElementSchemas.chartLine(firstTable)

			const modifiedScreens = update(props?.screens, {
				[props.activeScreen.screenGroup]: {
					[props.activeScreen.screenGroupIndex]: {
						body: {
							$push: [element]
						}
					}
				}
			})

			addElementHasSourceToScreen(element, firstTable)
		} else {
			showToastError(t('screens.elementAddList.textFieldAlert'))
		}
	}

	const chartBarClick = async () => {
		const firstTable = getFirstTable()

		if (firstTable) {
			const element = defaultElementSchemas.chartBar(firstTable)

			addElementHasSourceToScreen(element, firstTable)
		} else {
			showToastError(t('screens.elementAddList.textFieldAlert'))
		}
	}

	const chartPieClick = async () => {
		const firstTable = getFirstTable()

		if (firstTable) {
			const element = defaultElementSchemas.chartPie(firstTable)

			addElementHasSourceToScreen(element, firstTable)
		} else {
			showToastError(t('screens.elementAddList.textFieldAlert'))
		}
	}

	const chartProgressClick = async () => {
		const firstTable = getFirstTable()

		if (firstTable) {
			const element = defaultElementSchemas.chartProgress(firstTable)

			addElementHasSourceToScreen(element, firstTable)
		} else {
			showToastError(t('screens.elementAddList.textFieldAlert'))
		}
	}

	const progressBarClick = async () => {
		const firstTable = getFirstTable()

		if (firstTable) {
			const element = defaultElementSchemas.progressBar(firstTable)

			addElementHasSourceToScreen(element, firstTable)
		} else {
			showToastError(t('screens.elementAddList.textFieldAlert'))
		}
	}

	const formClick = async () => {
		const sameSource = !!props.activeScreen?.data?.source
		const formTable = sameSource
			? props?.entities?.find(
					(x) => x?.name === props.activeScreen?.data?.source
			  )
			: props?.entities?.find((x) => !!x?.fields?.length)

		if (formTable) {
			const element = defaultElementSchemas.form(formTable, sameSource)

			addElementHasSourceToScreen(element, formTable)
		} else {
			showToastError(t('screens.elementAddList.noFieldAlert'))
		}
	}

	const phoneButtonClick = () => {
		const element = defaultElementSchemas.phoneButton(localTable)

		addElementToScreen(element)
	}

	const emailButtonClick = () => {
		const element = defaultElementSchemas.emailButton(localTable)

		addElementToScreen(element)
	}

	const linkButtonClick = () => {
		const element = defaultElementSchemas.linkButton(localTable)

		addElementToScreen(element)
	}

	const noteBoxClick = () => {
		const element = defaultElementSchemas.noteBox(localTable)

		addElementToScreen(element)
	}

	const choiceClick = () => {
		const element = defaultElementSchemas.choice(localTable)

		addElementToScreen(element)
	}

	const titleCardClick = () => {
		const element = defaultElementSchemas.titleCard(localTable)

		addElementToScreen(element)
	}

	const settingsButtonClick = () => {
		const element = defaultElementSchemas.settingsButton(localTable)

		addElementToScreen(element)
	}

	// const userInfoClick = () => {
	// 	const element = defaultElementSchemas.userInfo()

	// 	addElementToScreen(element)
	// }

	// const logoutButtonClick = () => {
	// 	const element = defaultElementSchemas.logoutButton()

	// 	addElementToScreen(element)
	// }

	const seperatorClick = () => {
		const element = defaultElementSchemas.seperator()

		addElementToScreen(element)
	}

	const textAreaClick = () => {
		const element = defaultElementSchemas.textArea(localTable)

		addElementToScreen(element)
	}

	const columnsClick = () => {
		const element = defaultElementSchemas.columns(localTable)

		addElementToScreen(element)
	}

	const imageClick = () => {
		const element = defaultElementSchemas.image(localTable)

		addElementToScreen(element)
	}

	const webViewClick = () => {
		const element = defaultElementSchemas.webView(localTable)

		addElementToScreen(element)
	}

	const videoClick = () => {
		const element = defaultElementSchemas.video(localTable)

		addElementToScreen(element)
	}

	const mapClick = () => {
		const element = defaultElementSchemas.map(localTable)

		addElementToScreen(element)
	}

	const buttonClick = () => {
		const element = defaultElementSchemas.button()

		addElementToScreen(element)
	}

	const buttonBarClick = () => {
		const element = defaultElementSchemas.buttonBar(localTable)

		addElementToScreen(element)
	}

	const checkboxClick = () => {
		const element = defaultElementSchemas.checkbox(localTable)

		addElementToScreen(element)
	}

	const numberEntryClick = () => {
		const element = defaultElementSchemas.numberEntry(localTable)

		addElementToScreen(element)
	}

	const containerClick = async () => {
		const element = defaultElementSchemas.container()

		addElementToScreen(element)
	}
	const barcodeClick = () => {
		const element = defaultElementSchemas.barcode(localTable)

		addElementToScreen(element)
	}
	const qrCodeClick = () => {
		const element = defaultElementSchemas.qrCode(localTable)

		addElementToScreen(element)
	}

	const badgeTexts = {
		new: t('screens.elementAddList.badge.new'),
		soon: t('screens.elementAddList.badge.soon')
	}

	const badgeColors = {
		new: '#78FFAA',
		soon: '#F8D169'
	}

	const defaultData = [
		{
			title: t('screens.elementAddList.listTitle'),
			elements: [
				{
					text: elementNames.list,
					icon: List,
					clickMethod: listClick,
					visible: true
				},
				{
					text: elementNames.tileList,
					icon: Tile,
					clickMethod: tileListClick,
					visible: true
				},
				{
					text: elementNames.calendarList,
					icon: CalendarList,
					clickMethod: calendarListClick,
					visible: true
				},
				{
					text: elementNames.table,
					icon: Table,
					clickMethod: tableClick,
					visible: true
				},
				{
					text: elementNames.carousel,
					icon: Carousel,
					clickMethod: carouselClick,
					visible: true
				},
				{
					text: elementNames.mapList,
					icon: MapList,
					clickMethod: mapListClick,
					badge: {
						text: badgeTexts.new,
						color: badgeColors.new
					},
					visible: true
				},
				{
					text: elementNames.chat,
					icon: Chat,
					clickMethod: chatClick,
					badge: {
						text: badgeTexts.new,
						color: badgeColors.new
					},
					visible: true
				},
				{
					text: elementNames.reviews,
					icon: Reviews,
					clickMethod: reviewsClick,
					badge: {
						text: badgeTexts.new,
						color: badgeColors.new
					},
					visible: true
				}
				// {
				// 	text: 'Card',
				// 	icon: Card,
				// 	clickMethod: null,
				// 	badge: {
				// 		text: badgeTexts.soon,
				// 		color: badgeColors.soon
				// 	},
				// 	visible: true
				// }
			]
		},
		{
			title: t('screens.elementAddList.chartTitle'),
			elements: [
				{
					text: elementNames.chartLine,
					icon: LineChart,
					clickMethod: chartLineClick,
					visible: true
				},
				{
					text: elementNames.chartBar,
					icon: BarChart,
					clickMethod: chartBarClick,
					visible: true
				},
				{
					text: elementNames.chartPie,
					icon: PieChart,
					clickMethod: chartPieClick,
					visible: true
				},
				{
					text: elementNames.chartProgress,
					icon: ProgressChart,
					clickMethod: chartProgressClick,
					visible: true
				},
				{
					text: elementNames.numbers,
					icon: Numbers,
					clickMethod: numbersClick,
					visible: true
				},
				{
					text: elementNames.rating,
					icon: Rating,
					clickMethod: ratingClick,
					badge: {
						text: badgeTexts.new,
						color: badgeColors.new
					},
					visible: true
				}
			]
		},
		{
			title: t('screens.elementAddList.buttonTitle'),
			elements: [
				{
					text: elementNames.button,
					icon: Button,
					clickMethod: buttonClick,
					visible: true
				},
				{
					text: elementNames.buttonBar,
					icon: ButtonGroup,
					clickMethod: buttonBarClick,
					visible: true
				},
				{
					text: elementNames.phoneButton,
					icon: PhoneButton,
					clickMethod: phoneButtonClick,
					visible: true
				},
				{
					text: elementNames.emailButton,
					icon: EmailButton,
					clickMethod: emailButtonClick,
					visible: true
				},
				{
					text: elementNames.linkButton,
					icon: LinkButton,
					clickMethod: linkButtonClick,
					visible: true
				},
				{
					text: elementNames.settingsButton,
					icon: UserInfo,
					clickMethod: settingsButtonClick,
					visible: true,
					badge: {
						text: badgeTexts.new,
						color: badgeColors.new
					}
				}
			]
		},
		{
			title: t('screens.elementAddList.formTitle'),
			elements: [
				{
					text: elementNames.form,
					icon: Form,
					clickMethod: formClick,
					visible: true
				},
				{
					text: elementNames.checkbox,
					icon: CheckboxIcon,
					clickMethod: checkboxClick,
					visible: !!props.activeScreen?.data?.source
				},
				{
					text: elementNames.noteBox,
					icon: Notebox,
					clickMethod: noteBoxClick,
					visible: !!props.activeScreen?.data?.source
				},
				{
					text: elementNames.numberEntry,
					icon: NumberEntry,
					clickMethod: numberEntryClick,
					visible: !!props.activeScreen?.data?.source
				}
			]
		},
		{
			title: t('screens.elementAddList.displayTitle'),
			elements: [
				{
					text: elementNames.columns,
					icon: Columns,
					clickMethod: columnsClick,
					visible: !!props.activeScreen?.data?.source
				},
				{
					text: elementNames.titleCard,
					icon: Cover,
					clickMethod: titleCardClick,
					visible: true
				},
				{
					text: elementNames.seperator,
					icon: SpaceLine,
					clickMethod: seperatorClick,
					visible: true
				},
				{
					text: elementNames.textArea,
					icon: Text,
					clickMethod: textAreaClick,
					visible: true
				},
				{
					text: elementNames.image,
					icon: Image,
					clickMethod: imageClick,
					visible: true
				},
				{
					text: elementNames.map,
					icon: Map,
					clickMethod: mapClick,
					visible: true
				},
				{
					text: elementNames.video,
					icon: Video,
					clickMethod: videoClick,
					visible: true
				},
				{
					text: elementNames.webView,
					icon: WebView,
					clickMethod: webViewClick,
					visible: true
				},
				{
					text: elementNames.gallery,
					icon: Gallery,
					clickMethod: galleryClick,
					visible: true
				},
				{
					text: elementNames.progressBar,
					icon: ProgressBar,
					clickMethod: progressBarClick,
					visible: !!props.activeScreen?.data?.source
				},
				{
					text: elementNames.barcode,
					icon: Barcode,
					clickMethod: barcodeClick,
					badge: {
						text: badgeTexts.new,
						color: badgeColors.new
					},
					visible: true
				},
				{
					text: elementNames.qrCode,
					icon: QRCode,
					clickMethod: qrCodeClick,
					badge: {
						text: badgeTexts.new,
						color: badgeColors.new
					},
					visible: true
				},
				{
					text: elementNames.container,
					icon: Container,
					clickMethod: containerClick,
					badge: {
						text: badgeTexts.new,
						color: badgeColors.new
					},
					visible: !props.activeParentElement
				}
			]
		},
		{
			title: t('screens.elementAddList.pickerTitle'),
			elements: [
				{
					text: elementNames.choice,
					icon: Choice,
					clickMethod: choiceClick,
					visible: !!props.activeScreen?.data?.source
				}
			]
		}
		// {
		// 	title: t('screens.elementAddList.profileTitle'),
		// 	elements: [
		// 		{
		// 			text: elementNames.userInfo,
		// 			icon: UserInfo,
		// 			clickMethod: userInfoClick,
		// 			visible: true
		// 		},
		// 		{
		// 			text: elementNames.logoutButton,
		// 			icon: Logout,
		// 			clickMethod: logoutButtonClick,
		// 			visible: true
		// 		}
		// 	]
		// }
	]

	const getFirstTable = () => {
		return props?.entities?.find(
			(x) =>
				!!x?.fields?.find((z) => fieldDataTypes.textBased.includes(z?.dataType))
		)
	}

	const addElementHasSourceToScreen = async (element, table) => {
		if (props.activeParentElement) {
			const index = props.activeScreen.data?.body?.findIndex(
				(x) => x._uid == props.activeParentElement?._uid
			)

			const modifiedParentElement = update(props.activeParentElement, {
				body: {
					$push: [element]
				}
			})

			const modifiedScreens = update(props?.screens, {
				[props.activeScreen.screenGroup]: {
					[props.activeScreen.screenGroupIndex]: {
						body: {
							[index]: {
								$set: modifiedParentElement
							}
						}
					}
				}
			})

			await props.updateBulkScreensWithModifiedData(
				table.name,
				modifiedScreens,
				modifiedParentElement.component !== 'form'
					? modifiedParentElement
					: null
			)

			props.setActiveElement(element)
		} else {
			const modifiedScreens = update(props?.screens, {
				[props.activeScreen.screenGroup]: {
					[props.activeScreen.screenGroupIndex]: {
						body: {
							$push: [element]
						}
					}
				}
			})

			await props.updateBulkScreensWithModifiedData(
				table.name,
				modifiedScreens,
				element.component !== 'form' ? element : null
			)

			props.setActiveElement(element)
			scrollToNewPreviewElement(element._uid)
		}
	}

	const addElementToScreen = (element) => {
		if (props.activeParentElement) {
			const index = props.activeScreen.data?.body?.findIndex(
				(x) => x._uid == props.activeParentElement?._uid
			)

			const modifiedElementData = update(props.activeParentElement, {
				body: {
					$push: [element]
				}
			})

			const modifiedScreenData = update(props.activeScreen.data, {
				body: {
					[index]: {
						$set: modifiedElementData
					}
				}
			})

			props.updateActiveScreen(modifiedScreenData)

			props.setActiveElement(element)
		} else {
			const modifiedScreenData = update(props.activeScreen.data, {
				body: {
					$push: [element]
				}
			})

			props.updateActiveScreen(modifiedScreenData)

			props.setActiveElement(element)
			scrollToNewPreviewElement(element._uid)
		}
	}

	/******SEARCH*******************/
	const [searchTerm, setSearchTerm] = useState('')

	const searchChange = (val = '') => {
		setSearchTerm(val)
		search(val)
	}

	const search = (term = '') => {
		const data = defaultData
			.map((x) => {
				return {
					title: x.title,
					elements: x.elements.filter((y) => y.visible)
				}
			})
			.filter((x) => x.elements && x.elements.length > 0)

		let filteredData = []
		if (data.length) {
			for (let i = 0; i < data.length; i++) {
				const elementGroup = data[i]

				const filteredGroupElements = elementGroup.elements?.filter((x) =>
					x.text.toLowerCase().includes(term.toLowerCase())
				)
				filteredData.push({
					title: elementGroup.title,
					elements: filteredGroupElements
				})
			}
		}

		return filteredData
	}

	return (
		<>
			<PaddingBoxStyled>
				<Label
					text={t('screens.elementAddList.mainTitle')}
					sx={{
						color: '#000000',
						fontSize: 18,
						lineHeight: '22px',
						textAlign: 'left',
						fontWeight: 'bold'
					}}
				/>
			</PaddingBoxStyled>
			<PaddingBoxStyled>
				<SearchInputStyled
					placeholder={t('screens.elementAddList.search')}
					fullWidth
					value={searchTerm}
					onChange={(e) => searchChange(e.target.value)}
					slotProps={{
						input: {
							startAdornment: (
								<InputAdornment position='start'>
									<InputIconStyled />
								</InputAdornment>
							)
						}
					}}
				/>
			</PaddingBoxStyled>
			{search(searchTerm).map(
				(item, i) =>
					!!item.elements?.length && (
						<AppPageBox key={`pageBox_${i}`}>
							<Accordion title={item.title} expanded={true}>
								<Grid container alignItems='start'>
									{item.elements.map((el, j) => (
										<ResponsiveCard
											key={`${i}_${j}`}
											icon={el.icon}
											text={el.text}
											onClick={el.clickMethod}
											badgeText={el?.badge?.text}
											badgeColor={el?.badge?.color}
										/>
									))}
								</Grid>
							</Accordion>
						</AppPageBox>
					)
			)}
		</>
	)
}

const mapStateToProps = (state) => ({
	...state.appData
})

export default connect(mapStateToProps, null)(ElementAddList)
