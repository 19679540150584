import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm, Controller } from 'react-hook-form'

import { connect } from 'react-redux'
import { setOnboarding } from '../../../redux/actions/onboardingActions'

import { useHistory } from 'react-router-dom'
import Cookies from 'universal-cookie'

import authorizationClient from '../../../services/authorizationClient'
import tokenServices from '../../../services/tokenServices'
import { default as appConfig } from '../../../config/app.json'
import showToastResponseError from '../../../methods/showToastResponseError'
import useQuery from '../../../hooks/UseQuery/useQuery'

import KozmikLogoLight from '../../../assets/icons/KozmikLogoLight.png'

import commonRegExes from '../../../common/regExp/commonRegExes'

import styledUI from '../../../styledUI'

const style = {
	buttonContainer: {
		marginTop: 20
	},
	titleBox: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		marginBottom: 40,
		padding: '0px 20px'
	},
	title: {
		textAlign: 'center',
		fontSize: 30,
		marginBottom: 20,
		color: '#ffffff'
	},
	subTitle: {
		textAlign: 'center',
		fontSize: 14,
		color: '#ffffff'
	},
	textBox: {
		marginTop: 20,
		marginLeft: 14,
		color: '#ffffff',
		fontSize: 12
	},
	underline: {
		color: '#ffffff',
		textDecoration: 'underline'
	}
}

const Signup = (props) => {
	const { t } = useTranslation()
	let queryParams = useQuery()

	const [submitting, setSubmitting] = useState(false)

	const cookies = new Cookies()
	const history = useHistory()
	const navigate = (url) => {
		history.push(url)
	}
	const token = cookies.get(appConfig.cookieKeys.MY_SECURE_TOKEN_KEY)

	const {
		control,
		handleSubmit,
		formState: { errors, isValid }
	} = useForm({
		defaultValues: {
			name: '',
			email: '',
			password: ''
		}
	})
	const onSubmit = (data) => {
		setSubmitting(true)
		authorizationClient
			.register(data)
			.then((response) => {
				if (response?.data?.success) {
					tokenServices.add(response.data.data)
					if (queryParams?.plan && queryParams?.type) {
						//TODO: subscription
					} else {
						props.setOnboarding(true)

						history.replace('/onboarding')
					}
				} else {
					showToastResponseError(response)
				}
				setSubmitting(false)
			})
			.catch(() => {
				setSubmitting(false)
			})
	}

	const handleUserKeyPress = (e) => {
		if (e.key === 'Enter' && !e.shiftKey) {
			handleSubmit(onSubmit)()
		}
	}

	useEffect(() => {
		if (token && token !== 'undefined') {
			history.replace('')
		}
	}, [])

	return (
		<styledUI.StyledAuthContainer>
			<styledUI.StyledAuthContentContainer>
				{/* <styledUI.StyledAuthLogoContainer>
					<a href='https://kozmik.io'>
						<styledUI.StyledAuthLogo
							image={KozmikLogoLight}
							component='img'
						/>
					</a>
				</styledUI.StyledAuthLogoContainer> */}
				<div style={style.titleBox}>
					<span style={style.title}>Sign up for free and start building</span>
					<span style={style.subTitle}>No credit card required</span>
				</div>
				<styledUI.StyledAuthInputContainer>
					<Controller
						control={control}
						name='name'
						rules={{
							required: {
								value: true,
								message: t('authScreens.signup.nameInput.requredError')
							}
						}}
						render={({ field: { onChange, value } }) => {
							return (
								<styledUI.StyledAuthInput
									error={!!errors?.name}
									helperText={errors?.name ? errors?.name?.message : ''}
									placeholder={t('authScreens.signup.nameInput.placeholder')}
									fullWidth
									value={value}
									onChange={(e) => onChange(e.target.value)}
									onKeyPress={handleUserKeyPress}
									inputProps={{
										autoComplete: 'off'
									}}
								/>
							)
						}}
					/>
				</styledUI.StyledAuthInputContainer>
				<styledUI.StyledAuthInputContainer>
					<Controller
						control={control}
						name='email'
						rules={{
							required: {
								value: true,
								message: t('authScreens.signup.mailInput.requredError')
							},
							pattern: {
								value:
									/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
								message: t('authScreens.signup.mailInput.patternError')
							}
						}}
						render={({ field: { onChange, value } }) => {
							return (
								<styledUI.StyledAuthInput
									error={!!errors?.email}
									helperText={errors?.email ? errors?.email?.message : ''}
									placeholder={t('authScreens.signup.mailInput.placeholder')}
									fullWidth
									value={value}
									onChange={(e) => onChange(e.target.value)}
									onKeyPress={handleUserKeyPress}
									inputProps={{
										autoComplete: 'off'
									}}
								/>
							)
						}}
					/>
				</styledUI.StyledAuthInputContainer>
				<styledUI.StyledAuthInputContainer>
					<Controller
						control={control}
						name='password'
						rules={{
							required: {
								value: true,
								message: t('authScreens.signup.passwordInput.requredError')
							},
							pattern: {
								value: commonRegExes.password,
								message: t('authScreens.signup.passwordInput.patternError')
							}
						}}
						render={({ field: { onChange, value } }) => {
							return (
								<styledUI.StyledAuthInput
									error={!!errors?.password}
									helperText={errors?.password ? errors?.password?.message : ''}
									placeholder={t(
										'authScreens.signup.passwordInput.placeholder'
									)}
									fullWidth
									value={value}
									type='password'
									onChange={(e) => onChange(e.target.value)}
									onKeyPress={handleUserKeyPress}
									inputProps={{
										autoComplete: 'new-password'
									}}
								/>
							)
						}}
					/>
				</styledUI.StyledAuthInputContainer>

				<div style={style.textBox}>
					<span>{t('authScreens.signup.agreement')}</span>{' '}
					<a
						href='https://kozmik.io/legal/terms'
						target='_blank'
						rel='noreferrer'
						style={style.underline}
					>
						{t('authScreens.signup.terms')}
					</a>{' '}
					&{' '}
					<a
						href='https://kozmik.io/legal/privacy'
						target='_blank'
						rel='noreferrer'
						style={style.underline}
					>
						{t('authScreens.signup.privacyPolicy')}
					</a>
					.
				</div>

				<div style={style.buttonContainer}>
					<styledUI.StyledAuthSubmitButton
						id={'signUpButton'}
						fullWidth
						variant='contained'
						onClick={handleSubmit(onSubmit)}
						disabled={submitting}
						sx={{
							opacity: submitting ? 0.6 : 1
						}}
					>
						<styledUI.StyledAuthSubmitButtonText>
							{t('authScreens.signup.signupButton')}
						</styledUI.StyledAuthSubmitButtonText>
						{submitting && (
							<styledUI.StyledAuthLoader
								id={'signUpButtonProgress'}
								size={20}
							/>
						)}
					</styledUI.StyledAuthSubmitButton>
				</div>

				<styledUI.StyledAuthBottomText style={{ marginTop: 20 }}>
					{t('authScreens.signup.haveAccount')}{' '}
					<styledUI.StyledAuthBottomLink onClick={() => navigate('/login')}>
						{t('authScreens.signup.login')}
					</styledUI.StyledAuthBottomLink>
				</styledUI.StyledAuthBottomText>
			</styledUI.StyledAuthContentContainer>
		</styledUI.StyledAuthContainer>
	)
}

const mapStateToProps = (state) => ({
	...state.onboarding
})

const mapDispatchToProps = (dispatch) => {
	return {
		setOnboarding: (x) => dispatch(setOnboarding(x))
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Signup)
