import React from 'react'
import Label from '../../atoms/label/Label'

const Header = (props) => {
	return (
		<div
			style={{
				display: 'flex',
				zIndex: 1
			}}
		>
			<div
				style={{
					width: '100%',
					display: 'flex',
					flexWrap: 'wrap',
					flexDirection: 'column',
					justifyContent: 'space-between'
				}}
			>
				<div style={{ width: '100%', marginBottom: 10 }}>
					<Label
						text={props.text}
						sx={{
							color: '#000000',
							//fontFamily: "OpenSans",
							fontSize: 16,
							lineHeight: '22px',
							textAlign: 'left',
							display: 'block',
							fontWeight: '600'
						}}
					/>
				</div>
			</div>
		</div>
	)
}
export default Header
