import React from 'react'
import { Link } from 'react-router-dom'

import PopoverButton from '../../atoms/popoverButton/PopoverButton'

const PopoverItem = (props) => {
	return (
		<div>
			{props?.linkTo ? (
				<Link to={props?.linkTo}>
					<PopoverButton text={props?.text} color={props?.color}>
						{props?.children}
					</PopoverButton>
				</Link>
			) : props?.href ? (
				<a href={props.href}>
					<PopoverButton text={props?.text} color={props?.color}>
						{props?.children}
					</PopoverButton>
				</a>
			) : (
				<PopoverButton {...props}>{props?.children}</PopoverButton>
			)}
		</div>
	)
}

export default PopoverItem
