import React, { useState, useEffect, useRef } from 'react'
import { styled } from '@mui/material/styles'
import Grid from '@mui/material/Grid2'
import update from 'immutability-helper'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import { default as Label } from '../../atoms/settingLabel/SettingLabel'
import MentionsInput from '../../atoms/mentionsInput/MentionsInput'
import SquareOptions from '../../molecules/squareOprions/SquareOptions'
import IoniconsSelect from '../../molecules/IoniconsSelect/IoniconsSelect'
import ActionList from '../actionList/ActionList'

import { ReactComponent as ButonDolu } from '../../../assets/icons/Settings/Buton-Filled.svg'
import { ReactComponent as ButonTransparan } from '../../../assets/icons/Settings/Buton-Transparent.svg'
import { ReactComponent as ButonCerceveli } from '../../../assets/icons/Settings/Buton-Framed.svg'
import { ReactComponent as ButonNone } from '../../../assets/icons/Settings/Button-None.svg'
import { ReactComponent as Koseli } from '../../../assets/icons/Settings/Koseli.svg'
import { ReactComponent as Oval } from '../../../assets/icons/Settings/Oval.svg'

import styledUI from '../../../styledUI'

const ActionsTitleStyled = styled('div')(({ theme }) => ({
	fontSize: 14,
	fontWeight: 'bold',
	marginBottom: 6
}))

const ButtonSelection = (props) => {
	const { t } = useTranslation()
	const propsRef = useRef()
	propsRef.current = props

	const [elementName, setElementName] = useState(null)

	const btnTypeOptions = [
		{
			text: t('screens.elementSettings.button.typeOpts.full'),
			value: 'full',
			icon: ButonDolu
		},
		{
			text: t('screens.elementSettings.button.typeOpts.transparent'),
			value: 'transparent',
			icon: ButonTransparan
		},
		{
			text: t('screens.elementSettings.button.typeOpts.border'),
			value: 'border',
			icon: ButonCerceveli
		},
		{
			text: t('screens.elementSettings.button.typeOpts.none'),
			value: 'none',
			icon: ButonNone
		}
	]

	const cornerOptions = [
		{
			text: t('screens.elementSettings.button.cornerOpts.square'),
			value: 'square',
			icon: Koseli
		},
		{
			text: t('screens.elementSettings.button.cornerOpts.oval'),
			value: 'oval',
			icon: Oval
		}
	]

	/****TEXT***************************/
	// const [isTextTextMode, setIsTextTextMode] = useState(false)
	// const [textField, setTextField] = useState('')
	const [textDefault, setTextDefault] = useState('')

	// const textChange = (data) => {
	// 	if (isTextTextMode) {
	// 		submitText(data)
	// 		setTextDefault(data)
	// 	} else {
	// 		submitText(data?.value)
	// 		setTextField(data?.value)
	// 	}
	// }

	// const submitText = (x) => {
	// 	const selectedField = textFieldsWithLookupAndRel?.find((y) => y.name == x)
	// 	const modifiedButton = update(propsRef.current.button, {
	// 		mapping: {
	// 			text: {
	// 				field: { $set: isTextTextMode ? null : x },
	// 				default: { $set: isTextTextMode ? x : null },
	// 				type: { $set: isTextTextMode ? null : selectedField?.dataType }
	// 			}
	// 		}
	// 	})
	// 	propsRef.current.updateButton(modifiedButton, props.buttonIndex)
	// }

	const textChange = (value) => {
		submitText(value)
		setTextDefault(value)
	}

	const submitText = (value) => {
		const modifiedButton = update(propsRef.current.button, {
			mapping: {
				text: {
					default: { $set: value }
				}
			}
		})
		propsRef.current.updateButton(modifiedButton, props.buttonIndex)
	}

	/****ICON***************************/
	const [icon, setIcon] = useState('')

	const iconChange = (val) => {
		submitIcon(val)
		setIcon(val)
	}

	const submitIcon = (x) => {
		const modifiedButton = update(props.button, {
			icon: { $set: x }
		})
		props.updateButton(modifiedButton, props.buttonIndex)
	}

	/****TYPE***************************/
	const [type, setType] = useState(null)

	const typeChange = (val) => {
		submitType(val)
		setType(val)
	}

	const submitType = (x) => {
		const modifiedButton = update(props.button, {
			type: { $set: x }
		})
		props.updateButton(modifiedButton, props.buttonIndex)
	}

	/****CORNER***************************/
	const [corner, setCorner] = useState(null)

	const cornerChange = (val) => {
		submitCorner(val)
		setCorner(val)
	}

	const submitCorner = (x) => {
		const modifiedButton = update(props.button, {
			corner: { $set: x }
		})
		props.updateButton(modifiedButton, props.buttonIndex)
	}

	/****ACTIONS***************************/
	const updateActions = (newActions) => {
		const modifiedButton = update(props.button, {
			actions: { $set: newActions }
		})
		props.updateButton(modifiedButton, props.buttonIndex)
	}

	/****INITIALIZATION***************************/
	const initStates = (button) => {
		/****TEXT***************************/
		// button?.mapping?.text?.field || button?.mapping?.text?.field === ''
		// 	? setIsTextTextMode(false)
		// 	: setIsTextTextMode(true)

		// setTextField(button?.mapping?.text?.field || '')
		setTextDefault(button?.mapping?.text?.default || '')
		/****ICON***************************/
		setIcon(button?.icon || '')
		/****TYPE***************************/
		setType(button?.type)
		/****CORNER***************************/
		setCorner(button?.corner || null)

		setElementName(button?._uid)
	}

	useEffect(() => {
		props.button && initStates(props.button)
	}, [props.button])

	return (
		<>
			<styledUI.StyledGrid container alignItems='center'>
				<Grid size={{ xs: 4 }}>
					<Label>{t('screens.elementSettings.button.textLabel')}</Label>
				</Grid>
				<Grid size={{ xs: 8 }}>
					<MentionsInput
						name={`${elementName}-text`}
						whitelist={[]}
						onChange={textChange}
						value={textDefault}
						multiline={false}
						inputStyle={{
							borderRadius: 4,
							backgroundColor: '#ffffff',
							fontSize: '14px'
						}}
					/>
				</Grid>
			</styledUI.StyledGrid>
			<styledUI.StyledGrid
				container
				alignItems='center'
				justifyContent='space-between'
			>
				<Grid size={{ xs: 4 }}>
					<Label>{t('screens.elementSettings.button.iconLabel')}</Label>
				</Grid>
				<Grid size={{ xs: 4 }}>
					<IoniconsSelect
						bgColor={'#ffffff'}
						onChange={iconChange}
						value={icon}
						emptySelectable
						fullWidth
					/>
				</Grid>
			</styledUI.StyledGrid>
			<styledUI.StyledGrid container alignItems='center'>
				<Grid size={{ xs: 4 }}>
					<Label>{t('screens.elementSettings.button.typeLabel')}</Label>
				</Grid>
				<Grid size={{ xs: 8 }}>
					<SquareOptions
						compact
						data={btnTypeOptions}
						getChange={typeChange}
						activeValue={type}
					/>
				</Grid>
			</styledUI.StyledGrid>

			{type !== 'none' && (
				<styledUI.StyledGrid container alignItems='center'>
					<Grid size={{ xs: 4 }}>
						<Label>{t('screens.elementSettings.button.cornerLabel')}</Label>
					</Grid>
					<Grid size={{ xs: 8 }}>
						<SquareOptions
							compact
							data={cornerOptions}
							getChange={cornerChange}
							activeValue={corner}
						/>
					</Grid>
				</styledUI.StyledGrid>
			)}

			<styledUI.StyledGrid container alignItems='center'>
				<Grid size={{ xs: 12 }}>
					<ActionsTitleStyled>
						{t('screens.elementSettings.button.actionsLabel')}
					</ActionsTitleStyled>
					<ActionList
						name={elementName}
						entities={props.entities}
						activeScreen={props.activeScreen}
						actions={props.button.actions}
						updateActions={updateActions}
						formSource={props.formSource}
						isList={!!props?.isList}
						listSource={props?.listSource}
					/>
				</Grid>
			</styledUI.StyledGrid>
		</>
	)
}

const mapStateToProps = (state) => ({
	...state.appData
})

export default connect(mapStateToProps)(ButtonSelection)
