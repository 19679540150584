import React, { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import { useTheme } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'
import { Typography, Button, CardMedia } from '@mui/material'

import KozmikModal from '../../molecules/kozmikModal/KozmikModal'

const MainContainerStyled = styled('div')(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	padding: '60px 40px',
	height: '100%',
	justifyContent: 'space-between',
	overflowY: 'auto',
	[theme.breakpoints.down('md')]: {
		padding: '40px 20px'
	}
}))

const ContentContainerStyled = styled('div')(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column'
}))

const FooterContainerStyled = styled('div')(({ theme }) => ({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center'
}))

const BtnContainerStyled = styled('div')(({ theme }) => ({
	width: 220,
	display: 'flex',
	justifyContent: 'flex-end'
}))

const BtnStyled = styled(Button)(({ theme }) => ({
	textTransform: 'none',
	backgroundColor: `${theme.custom.colors.kozmikBlue} !important`,
	boxShadow: 'none !important',
	fontSize: 16,
	borderRadius: '4px',
	width: '48%'
}))

const BtnTxtStyled = styled(Typography)(({ theme }) => ({
	color: '#FFFFFF',
	fontSize: 14,
	fontWeight: 'bold',
	textAlign: 'left',
	textTransform: 'none'
}))

const BtnTxtBlueStyled = styled(BtnTxtStyled)(({ theme }) => ({
	color: theme.custom.colors.kozmikBlue
}))

const PagerStyled = styled('span')(({ theme }) => ({
	fontSize: 14,
	fontWeight: 'bold'
}))

const ImageStyled = styled(CardMedia)(({ theme }) => ({
	width: '100%',
	objectFit: 'contain',
	marginBottom: 60,
	[theme.breakpoints.down('md')]: {
		marginBottom: 80
	}
}))

const TitleStyled = styled('span')(({ theme }) => ({
	fontSize: 16,
	fontWeight: '600'
}))

const TextStyled = styled('div')(({ theme }) => ({
	fontSize: 14
}))

const OnboardingModal = (props) => {
	const { t } = useTranslation()
	const theme = useTheme()

	const [pageIndex, setPageIndex] = useState(0)

	const next = () => {
		setPageIndex((current) => current + 1)
	}
	const back = () => {
		setPageIndex((current) => current - 1)
	}

	useEffect(() => {
		setPageIndex(0)
	}, [props.pages])

	return (
		<KozmikModal
			maxHeight={props.builderOnMobile ? 550 : 660}
			visible={props.visible}
			hideModal={props.hideModal}
		>
			<MainContainerStyled>
				<ContentContainerStyled>
					<ImageStyled image={props.pages[pageIndex].image} component='img' />
					<TitleStyled>{props.pages[pageIndex].title}</TitleStyled>
					<TextStyled
						dangerouslySetInnerHTML={{ __html: props.pages[pageIndex].text }}
					/>
				</ContentContainerStyled>

				<FooterContainerStyled>
					<PagerStyled>
						{pageIndex + 1}/{props.pages.length}
					</PagerStyled>
					<BtnContainerStyled>
						{pageIndex > 0 && (
							<Button
								variant='contained'
								onClick={back}
								sx={{
									textTransform: 'none',
									backgroundColor: `#ffffff !important`,
									boxShadow: 'none !important',
									fontSize: 16,
									borderRadius: '4px',
									width: '48%',
									marginRight: '4%'
								}}
							>
								<BtnTxtBlueStyled>{t('onboarding.back')}</BtnTxtBlueStyled>
							</Button>
						)}
						{props.pages.length - 1 !== pageIndex ? (
							<BtnStyled variant='contained' onClick={next}>
								<BtnTxtStyled>{t('onboarding.next')}</BtnTxtStyled>
							</BtnStyled>
						) : (
							<BtnStyled
								variant='contained'
								onClick={() => {
									props.submit()
									props.hideModal()
								}}
							>
								<BtnTxtStyled>{t('onboarding.done')}</BtnTxtStyled>
							</BtnStyled>
						)}
					</BtnContainerStyled>
				</FooterContainerStyled>
			</MainContainerStyled>
		</KozmikModal>
	)
}

export default OnboardingModal
