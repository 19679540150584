import React from 'react'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { Box, Button } from '@mui/material'
import { CheckCircle, RemoveCircle, ContentCopy } from '@mui/icons-material'

const MainContainerStyled = styled(Box)(({ theme }) => ({
	width: '100%',
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	padding: '4px 12px',
	backgroundColor: theme.custom.colors.kozmikGray,
	borderRadius: 8,
	marginBottom: 5
}))

const InforContainerBoxStyled = styled(Box)(({ theme }) => ({
	display: 'flex',
	alignItems: 'center'
}))

const StatusContainerStyled = styled(Box)(({ theme }) => ({
	width: 20,
	display: 'flex',
	alignItems: 'center'
}))

const ButtonContainerStyled = styled(Box)(({ theme }) => ({
	display: 'flex',
	alignItems: 'center'
}))

const CheckGreenStyled = styled(CheckCircle)(({ theme }) => ({
	fontSize: 15,
	color: theme.custom.colors.kozmikMatGreen
}))

const CheckRedStyled = styled(RemoveCircle)(({ theme }) => ({
	fontSize: 15,
	color: theme.custom.colors.lightRed
}))

const ContentCopyStyled = styled(RemoveCircle)(({ theme }) => ({
	color: theme.custom.colors.kozmikBlue,
	fontSize: 16,
	cursor: 'pointer'
}))

const DomainLinkStyled = styled('a')(({ theme }) => ({
	fontSize: 12,
	color: theme.custom.colors.textPrimary,
	width: 60
}))

const ButtonTextStyled = styled(Typography)(({ theme }) => ({
	fontSize: 12,
	fontWeight: '600',
	textAlign: 'left',
	textTransform: 'none'
}))

const VerifyButtonTextStyled = styled(ButtonTextStyled)(({ theme }) => ({
	color: theme.custom.colors.kozmikBlue
}))

const DeleteButtonTextStyled = styled(ButtonTextStyled)(({ theme }) => ({
	color: theme.custom.colors.red
}))

const DomainItem = (props) => {
	const copyToClipboard = (val) => {
		navigator.clipboard.writeText(val)
	}

	return (
		<MainContainerStyled>
			<InforContainerBoxStyled>
				<StatusContainerStyled>
					{props.item.DomainVerified ? (
						<CheckGreenStyled />
					) : (
						<CheckRedStyled />
					)}
				</StatusContainerStyled>
				<DomainLinkStyled
					href={`https://${props.item.Domain}`}
					target={'_blank'}
					rel='noreferrer'
				>
					{props.item.Domain}
				</DomainLinkStyled>
			</InforContainerBoxStyled>
			<ButtonContainerStyled>
				<ContentCopyStyled
					onClick={() => copyToClipboard(`https://${props.item.Domain}`)}
				/>
				{!props.item.DomainVerified && (
					<Button
						size='small'
						variant='contained'
						onClick={() => {
							props?.verifyBtnClick(props.item)
						}}
						sx={{
							textTransform: 'none',
							backgroundColor: `transparent !important`,
							boxShadow: 'none !important',
							fontSize: 14,
							borderRadius: '4px'
						}}
					>
						<VerifyButtonTextStyled>
							{props?.verifyBtnText}
						</VerifyButtonTextStyled>
					</Button>
				)}
				<Button
					size='small'
					variant='contained'
					onClick={() => {
						props?.deleteBtnClick(props.item)
					}}
					sx={{
						textTransform: 'none',
						backgroundColor: `transparent !important`,
						boxShadow: 'none !important',
						fontSize: 14,
						borderRadius: '4px'
					}}
				>
					<DeleteButtonTextStyled>
						{props?.deleteBtnText}
					</DeleteButtonTextStyled>
				</Button>
			</ButtonContainerStyled>
		</MainContainerStyled>
	)
}

export default DomainItem
