import React, { useState } from 'react'
import { useTheme } from '@mui/material/styles'
import { styled } from '@mui/material/styles'
import { Box, Popover } from '@mui/material'
import { connect } from 'react-redux'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import Cookies from 'universal-cookie'

import ProfileCard from '../../molecules/profileCard/ProfileCard'
import PopoverItem from '../../molecules/popoverItem/PopoverItem'

import { setCurrentOrg } from '../../../redux/actions/userInfoActions'
import currentOrgService from '../../../services/custom/currentOrgService'

const BoxStyled = styled(Box)(({ theme }) => ({
	padding: 8,
	display: 'flex',
	flexDirection: 'column'
}))

const NameStyled = styled('div')(({ theme }) => ({
	color: theme.custom.colors.textPrimary,
	fontSize: 12,
	textAlign: 'left',
	fontWeight: 600,
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis'
}))

const style = {
	infoBox: {
		flexGrow: 1,
		paddingLeft: 5,
		overflow: 'hidden'
	},
	mainContainer: {
		display: 'flex',
		alignItems: 'center',
		height: 45,
		borderRadius: 6,
		padding: 5,
		cursor: 'pointer',
		marginBottom: 5
	}
}

const OrgSelection = (props) => {
	const theme = useTheme()
	const cookies = new Cookies()

	const [orgsPop, setOrgsPop] = useState(null)

	const openOrgsPop = (event) => {
		setOrgsPop(event.currentTarget)
	}
	const closeOrgsPop = () => {
		setOrgsPop(null)
	}
	const orgsPopOpen = Boolean(orgsPop)

	const selectOrg = (org) => {
		currentOrgService.set(org)
		closeOrgsPop()
	}

	return (
		<>
			<div style={style.mainContainer} onClick={openOrgsPop}>
				<ProfileCard
					text={props.currentOrg?.name}
					// imgUrl={}
					size={35}
					circle
					bgColor={theme.custom.colors.gray}
				/>
				<div style={style.infoBox}>
					<NameStyled>{props.currentOrg?.name}</NameStyled>
				</div>
				{props.org?.length > 1 && (
					<ArrowDropDownIcon style={{ fontSize: 18 }} />
				)}
			</div>

			{props.org?.length > 1 && (
				<Popover
					open={orgsPopOpen}
					anchorEl={orgsPop}
					onClose={closeOrgsPop}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'right'
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'right'
					}}
				>
					<BoxStyled>
						{props.org
							.filter((x) => x.id != props.currentOrg?.id)
							.map((item, index) => (
								<PopoverItem
									key={index}
									text={item?.name}
									onClick={() => {
										selectOrg(item)
									}}
									color='#000000'
								>
									<ProfileCard
										text={item?.name}
										size={25}
										circle
										bgColor={theme.custom.colors.gray}
									/>
								</PopoverItem>
							))}
					</BoxStyled>
				</Popover>
			)}
		</>
	)
}

const mapStateToProps = (state) => ({
	...state.userInfo
})

const mapDispatchToProps = (dispatch) => {
	return {
		setCurrentOrg: (x) => dispatch(setCurrentOrg(x))
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(OrgSelection)
