import { ApiClient } from './client'

import { default as appConfig } from '../config/app.json'

const apiEndpoint = `${appConfig.endpoints.api}/api/store/setting`

export default {
	async getSetting(appId, headers = {}) {
		headers['AppId'] = appId

		return await new ApiClient(apiEndpoint, headers).get('')
	},
	async updateSetting(appId, data = null, headers = {}) {
		headers['Content-Type'] = 'application/json'
		headers['AppId'] = appId

		return await new ApiClient(apiEndpoint, headers).post('/update', data)
	}
}
