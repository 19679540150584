import React from 'react'
import Grid from '@mui/material/Grid2'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Lock, Info, Brush } from '@mui/icons-material'

import Label from '../../atoms/label/Label'
import AppMenu from '../../molecules/appMenu/AppMenu'

const AppLeftFrame = () => {
	const { t } = useTranslation()
	let { appId } = useParams()

	const menuList = [
		{
			icon: Info,
			name: t('appDetailMenu.menu.info'),
			href: `/app/${appId}/settings/info`,
			isActive: true
		},
		{
			icon: Brush,
			name: t('appDetailMenu.menu.theme'),
			href: `/app/${appId}/settings/theme`,
			isActive: false
		},
		{
			icon: Lock,
			name: t('appDetailMenu.menu.splash'),
			href: `/app/${appId}/settings/splash`,
			isActive: false
		}
	]
	return (
		<Grid container style={{ padding: 15 }}>
			<Grid size={{ xs: 12 }}>
				<Label
					text={t('appDetailMenu.title')}
					sx={{
						color: '#000000',
						lineHeight: '22px',
						textAlign: 'left',
						fontWeight: 'bold'
					}}
				/>
			</Grid>
			<Grid size={{ xs: 12 }}>
				<AppMenu menuItems={menuList} />
			</Grid>
		</Grid>
	)
}

export default AppLeftFrame
