import React, { useState, useEffect } from 'react'
import { styled } from '@mui/material/styles'
import { useTheme } from '@mui/material/styles'
import { Close, Lightbulb } from '@mui/icons-material'
import Cookies from 'universal-cookie'
import { useTranslation } from 'react-i18next'

import KozmikModal from '../../molecules/kozmikModal/KozmikModal'

import KozmikProgressBar from '../../atoms/kozmikProgressBar/KozmikProgressBar'
import OnboardingListItem from '../../atoms/onboardingListItem/OnboardingListItem'
import OnboardingModal from './OnboardingModal'

const MainContainerStyled = styled('div')((props) =>
	props.builderOnMobile
		? {
				padding: '20px 15px',
				backgroundColor: props.theme.custom.colors.gray,
				[props.theme.breakpoints.up('md')]: {
					display: 'none'
				}
		  }
		: {
				width: 300,
				borderRadius: 16,
				backgroundColor: props.theme.custom.colors.gray,
				padding: '21px 14px',
				position: 'absolute',
				right: '23%',
				bottom: 70,
				boxShadow: '0 8px 16px 0 rgba(0, 0, 0, 0.1)',
				zIndex: 11,
				[props.theme.breakpoints.down('md')]: {
					display: 'none'
				}
		  }
)

const ButtonStyled = styled('div')(({ theme }) => ({
	height: 35,
	position: 'absolute',
	right: '26%',
	bottom: 25,
	borderRadius: 8,
	backgroundColor: theme.custom.colors.kozmikDarkBlue,
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	padding: '0 12px',
	cursor: 'pointer',
	[theme.breakpoints.down('md')]: {
		display: 'none'
	}
}))

const ButtonTextStyled = styled('span')(({ theme }) => ({
	fontSize: 14,
	color: '#ffffff',
	paddingLeft: 6
}))

const HeadContainerStyled = styled('div')(({ theme }) => ({
	padding: '0 10px'
}))

const TitleStyled = styled('span')(({ theme }) => ({
	fontSize: 14,
	fontWeight: 'bold',
	display: 'inline-block'
}))

const SubTitleStyled = styled('span')(({ theme }) => ({
	fontSize: 12,
	display: 'inline-block'
}))

const ProgressContainerStyled = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center'
}))

const ProgressStyled = styled(KozmikProgressBar)(({ theme }) => ({
	margin: '15px 0',
	flexGrow: 1
}))

const ProgressTextStyled = styled('span')(({ theme }) => ({
	fontSize: 10,
	fontWeight: 'bold',
	paddingLeft: 11
}))

const IconStyled = styled(Lightbulb)(({ theme }) => ({
	fontSize: '18px',
	color: '#ffffff'
}))

const CloseIconStyled = styled(Close)(({ theme }) => ({
	display: 'block',
	fontSize: '16px',
	color: theme.custom.colors.textPrimary,
	marginLeft: 'auto',
	cursor: 'pointer'
}))

const cookieVersion = '0.0.2' //increment version for data changes

const Onboarding = (props) => {
	const { t } = useTranslation()
	const cookies = new Cookies()
	const theme = useTheme()

	const localizedData = t('onboarding.data', { returnObjects: true })

	const [cookieData, setCookieData] = useState(null)

	const [isModalVisible, setIsModalVisible] = useState(false)
	const [modalCookieIndex, setModalCookieIndex] = useState(0)

	const setOnboardingCookie = (data) => {
		cookies.set('onboard', data, {
			path: '/',
			sameSite: 'none',
			secure: true,
			domain: process.env.REACT_APP_COOKIE_DOMAIN
			// httpOnly: true
		})
		setCookieData(data)
	}

	const getCookie = () => {
		const cData = cookies.get('onboard')
		if (cData?.v === cookieVersion) {
			setCookieData(cData)
			!cData.closed && props.setIsOnboardingVisible(true)
		} else {
			const newcData = {
				v: cookieVersion,
				closed: false,
				progress: Array(localizedData.length).fill(0)
			}

			setOnboardingCookie(newcData)
			props.setIsOnboardingVisible(true)
		}
	}

	const completedCount = cookieData?.progress?.reduce((a, b) => a + b, 0)
	const itemCount = cookieData?.progress?.length

	const openModalWithData = (cookieIndex) => {
		setModalCookieIndex(cookieIndex)
		props.builderOnMobile && props.setIsOnboardingVisible(false)
		setIsModalVisible(true)
	}

	const toggleList = () => {
		if (props.isOnboardingVisible) {
			closeList()
		} else {
			props.setIsOnboardingVisible(true)
		}
	}

	const closeList = () => {
		props.setIsOnboardingVisible(false)

		let newCookieData = { ...cookieData }

		if (!newCookieData.closed) {
			newCookieData.closed = true
			setOnboardingCookie(newCookieData)
		}
	}

	const submitCookie = (index) => {
		let newCookieData = { ...cookieData }
		newCookieData.progress[index] = 1

		setOnboardingCookie(newCookieData)
	}

	const renderOnboarding = () => {
		return (
			<MainContainerStyled>
				{!props.builderOnMobile && <CloseIconStyled onClick={closeList} />}
				<HeadContainerStyled>
					<TitleStyled>{t('onboarding.title')}</TitleStyled>
					<SubTitleStyled>{t('onboarding.subTitle')}</SubTitleStyled>
					<ProgressContainerStyled>
						<ProgressStyled
							barColor={'#ffffff'}
							progressColor={theme.custom.colors.kozmikGreen}
							height={6}
							variant='determinate'
							value={(100 / itemCount) * completedCount}
						/>
						{!!cookieData && (
							<ProgressTextStyled>
								{completedCount}/{itemCount}
							</ProgressTextStyled>
						)}
					</ProgressContainerStyled>
				</HeadContainerStyled>
				{!!cookieData && (
					<div>
						{localizedData?.map((item, index) => (
							<OnboardingListItem
								key={index}
								completed={!!cookieData.progress[index]}
								text={item.title}
								onClick={() => openModalWithData(index)}
							/>
						))}
					</div>
				)}
			</MainContainerStyled>
		)
	}

	useEffect(() => {
		getCookie()
	}, [])

	return (
		<>
			<ButtonStyled onClick={toggleList}>
				<IconStyled />
				<ButtonTextStyled>{t('onboarding.btnText')}</ButtonTextStyled>
			</ButtonStyled>

			{!props.builderOnMobile &&
				props.isOnboardingVisible &&
				renderOnboarding()}

			{
				<KozmikModal
					flexHeight
					visible={props.builderOnMobile && props.isOnboardingVisible}
					hideModal={closeList}
				>
					{renderOnboarding()}
				</KozmikModal>
			}

			<OnboardingModal
				builderOnMobile={props.builderOnMobile}
				visible={isModalVisible}
				hideModal={() => {
					setIsModalVisible(false)
					props.builderOnMobile && props.setIsOnboardingVisible(true)
				}}
				pages={localizedData?.[modalCookieIndex]?.pages}
				submit={() => submitCookie(modalCookieIndex)}
			/>
		</>
	)
}

export default Onboarding
