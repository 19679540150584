import React from 'react'
import Grid from '@mui/material/Grid2'

import DetailMenuItem from '../detailMenuItem/DetailMenuItem'

const DetailMenu = (props) => {
	return (
		<Grid container style={{ justifyContent: 'space-around' }}>
			{props.menuItems.map((element, index) => (
				<DetailMenuItem
					activeMenuEvent={props.activeMenuEvent}
					setActiveMenuEvent={props.setActiveMenuEvent}
					key={index}
					data={element}
				/>
			))}
		</Grid>
	)
}

export default DetailMenu
