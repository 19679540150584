import React, { useEffect, useState } from 'react'
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd'

import ElementListItem from '../elementListItem/ElementListItem'

const getListStyle = (isDraggingOver) => ({
	background: 'transparent',
	borderRadius: 6,
	width: '100%'
})

const getElementStyle = (isDragging, draggableStyle) => ({
	userSelect: 'none',
	margin: `0 0 5px 0`,
	borderRadius: 6,
	boxShadow: isDragging ? '0 4px 8px 0 rgba(0, 0, 0, 0.17)' : 'none',
	overflow: isDragging ? 'hidden' : 'visible',

	...draggableStyle
})

const reorder = (list, startIndex, endIndex) => {
	const result = Array.from(list)
	const [removed] = result.splice(startIndex, 1)
	result.splice(endIndex, 0, removed)

	return result
}

const ScreenElementList = (props) => {
	const [isDragDisabled, setIsDragDisabled] = useState(false)

	const elementList = props.elements

	const onDragEnd = (result) => {
		const { destination } = result

		if (!destination) {
			return
		}

		const elements = reorder(
			elementList,
			result.source.index,
			result.destination.index
		)

		props.setElements(
			props.activeScreen.data,
			props.activeScreen.screenGroup,
			elements
		)
	}

	return (
		<DragDropContext onDragEnd={onDragEnd}>
			<Droppable droppableId='droppable'>
				{(provided, snapshot) => (
					<div
						{...provided.droppableProps}
						ref={provided.innerRef}
						style={getListStyle(snapshot.isDraggingOver)}
					>
						{!!props?.emptyText && !elementList?.length ? (
							<span style={{ fontSize: 12, fontStyle: 'italic' }}>
								{props.emptyText}
							</span>
						) : null}
						{!!elementList?.length &&
							elementList.map((item, index) => (
								<Draggable
									key={item._uid}
									draggableId={item._uid}
									index={index}
									isDragDisabled={isDragDisabled}
								>
									{(provided, snapshot) => (
										<div
											ref={provided.innerRef}
											{...provided.draggableProps}
											{...provided.dragHandleProps}
											style={getElementStyle(
												snapshot.isDragging,
												provided.draggableProps.style
											)}
										>
											<ElementListItem
												setIsDragDisabled={setIsDragDisabled}
												element={item}
												activeScreen={props.activeScreen}
												activeElement={props.activeElement}
												setActiveElement={props.setActiveElement}
												setActiveParentElement={props.setActiveParentElement}
												scrollToPreviewElement={props.scrollToPreviewElement}
												deleteElement={props.deleteElement}
												setRightContentType={props.setRightContentType}
												setElements={props.setElements}
												builderOnMobile={props.builderOnMobile}
												setAddElementModalVisible={
													props.setAddElementModalVisible
												}
											/>
										</div>
									)}
								</Draggable>
							))}
						{provided.placeholder}
					</div>
				)}
			</Droppable>
		</DragDropContext>
	)
}

export default ScreenElementList
