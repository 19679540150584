import React, { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import { Typography } from '@mui/material'
import Grid from '@mui/material/Grid2'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'

import MediaPicker from '../../atoms/mediaPicker/MediaPicker'
import AppPageBoxTitle from '../../atoms/appPageBox/AppPageBoxTitle'
import AppPageBox from '../../atoms/appPageBox/AppPageBox'
import SettingLabel from '../../atoms/settingLabel/SettingLabel'
import SquareOptions from '../../molecules/squareOprions/SquareOptions'
import KozmikCheckBox from '../../atoms/kozmikCheckBox/KozmikCheckBox'

import appClient from '../../../services/appClient'
import { setDetail } from '../../../redux/actions/appDataActions'

import showToastResponseError from '../../../methods/showToastResponseError'

import { ReactComponent as ImageOdakli } from '../../../assets/icons/Settings/Image-Odakli.svg'
import { ReactComponent as ImageOrtali } from '../../../assets/icons/Settings/Image-Ortali.svg'
import postMessageToPreview from '../../../methods/postMessageToPreview'

import styledUI from '../../../styledUI'

const PageTitleStyled = styled(Typography)(({ theme }) => ({
	padding: '10px 15px',
	minHeight: 45,
	color: '#000000',
	fontSize: 14,
	lineHeight: '22px',
	textAlign: 'left',
	fontWeight: 'bold',
	borderBottom: `1px solid ${theme.custom.colors.gray}`
}))

const RightContainerStyled = styled('div')(({ theme }) => ({
	backgroundColor: '#ffffff'
}))

const SplashSettingsTemplate = (props) => {
	const { t } = useTranslation()
	let { appId } = useParams()

	const triggerPropPostMessage = (objectName, propName, value) => {
		const postData = {
			source: 'kozmik-builder',
			updatedProp: {
				objectName: objectName,
				propName: propName,
				value: value
			}
		}

		postMessageToPreview(postData)
	}

	const postData = (
		propName,
		propData,
		configobjectName = null,
		configPropName = null
	) => {
		if (appId) {
			let updateData = {
				Id: appId
			}
			updateData[propName] = propData

			appClient
				.update(updateData)
				.then((response) => {
					if (response.status == 200) {
						updateReduxDetailData(propName, propData)
						if (configobjectName && configPropName) {
							triggerPropPostMessage(configobjectName, configPropName, propData)
						}
					} else {
						showToastResponseError(response)
						initialization()
					}
				})
				.catch((error) => {
					initialization()
				})
		}
	}

	const updateReduxDetailData = (propName, propData) => {
		let tempData = { ...props.appDetail }
		tempData[propName] = propData
		props.setDetail(tempData)
	}

	// SplashResizeMode -> contain, cover
	// SplashUseAccentColor -> true,false

	/****SPLASH_ICON***************************/
	const [image, setImage] = useState(null)

	const postImage = (base64Image) => {
		postData('SplashIcon', base64Image)
	}

	/****SPLASH_RESIZE_MODE***************************/
	const [splashResizeMode, setSplashResizeMode] = useState('')

	const splashResizeModeChange = (val) => {
		postSplashResizeMode(val)
		setSplashResizeMode(val)
	}

	const resizeModeOptions = [
		{
			text: t('splashSettingsTemp.resizeModeOpts.cover'),
			value: 'cover',
			icon: ImageOdakli
		},
		{
			text: t('splashSettingsTemp.resizeModeOpts.contain'),
			value: 'contain',
			icon: ImageOrtali
		}
	]

	const postSplashResizeMode = (val) => {
		postData('SplashResizeMode', val)
	}

	/****SPLASH_USE_ACCENT_COLOR***************************/
	const [splashUseAccentColor, setSplashUseAccentColor] = useState(false)

	const splashUseAccentColorChange = (e) => {
		const val = e.target.checked
		setSplashUseAccentColor(val)
		postSplashUseAccentColor(val)
	}

	const postSplashUseAccentColor = (val) => {
		postData('SplashUseAccentColor', val)
	}

	/****INITIALIZATION***************************/

	const initialization = () => {
		setImage(props?.appDetail?.SplashIcon || null)
		setSplashResizeMode(props?.appDetail?.SplashResizeMode || '')
		setSplashUseAccentColor(!!props?.appDetail?.SplashUseAccentColor)
	}

	useEffect(() => {
		props.appDetail && initialization()
	}, [props.appDetail])

	return (
		<RightContainerStyled>
			<PageTitleStyled>{t('splashSettingsTemp.title')}</PageTitleStyled>
			<AppPageBox>
				<AppPageBoxTitle style={{ marginBottom: 10 }} />
				<styledUI.StyledGrid container justifyContent='center'>
					<MediaPicker
						accept='image/png'
						setImage={setImage}
						image={image}
						postData={postImage}
					/>
				</styledUI.StyledGrid>
				<styledUI.StyledGrid container alignItems='center'>
					<Grid size={{ xs: 4 }}>
						<SettingLabel>{t('splashSettingsTemp.resizeMode')}</SettingLabel>
					</Grid>
					<Grid size={{ xs: 8 }}>
						<SquareOptions
							data={resizeModeOptions}
							getChange={splashResizeModeChange}
							activeValue={splashResizeMode}
						/>
					</Grid>
				</styledUI.StyledGrid>
				<styledUI.StyledItemContainer>
					<div>
						<KozmikCheckBox
							checked={splashUseAccentColor}
							onChange={splashUseAccentColorChange}
						/>
					</div>
					<styledUI.StyledLabel>
						<SettingLabel>
							{t('splashSettingsTemp.useAccentColor')}
						</SettingLabel>
					</styledUI.StyledLabel>
				</styledUI.StyledItemContainer>
			</AppPageBox>
		</RightContainerStyled>
	)
}

const mapStateToProps = (state) => ({
	...state.appData
})

const mapDispatchToProps = (dispatch) => {
	return {
		setDetail: (x) => dispatch(setDetail(x))
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(SplashSettingsTemplate)
