import React, { forwardRef } from 'react'
import { styled } from '@mui/material/styles'
import Checkbox from '@mui/material/Checkbox'

const CheckboxStyled = styled(Checkbox)(({ theme }) => ({
	'& .MuiSvgIcon-root': {
		color: '#E9E9E9'
	},
	'&.Mui-checked .MuiSvgIcon-root': {
		color: theme.custom.colors.kozmikBlue
	}
}))

const KozmikCheckBox = forwardRef((props, ref) => {
	return <CheckboxStyled variant='outlined' ref={ref} {...props} />
})
export default KozmikCheckBox
