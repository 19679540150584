import React, { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import { useTheme } from '@mui/material/styles'
import {
	Typography,
	Box,
	InputAdornment,
	CircularProgress,
	Button
} from '@mui/material'
import Grid from '@mui/material/Grid2'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import update from 'immutability-helper'
import { CheckCircle, Add } from '@mui/icons-material'

import DomainItem from '../../../molecules/domainItem/DomainItem'
import WarningDialog from '../../../molecules/dialog/warningDialog/WarningDialog'
import validateWithRegExp from '../../../../methods/validateWithRegExp'

import timeoutDelay from '../../../../methods/timeoutDelay'
import showToastResponseError from '../../../../methods/showToastResponseError'

import appClient from '../../../../services/appClient'
import domainClient from '../../../../services/domainClient'
import storeSettingClient from '../../../../services/storeSettingClient'

import { setDetail } from '../../../../redux/actions/appDataActions'

import styledUI from '../../../../styledUI'

const style = {
	text: {
		fontSize: 12
	},
	textDNS: {
		fontSize: 14,
		padding: '4px 0'
	}
}

const AdornmentTextStyled = styled(Typography)(({ theme }) => ({
	fontSize: 12,
	backgroundColor: theme.custom.colors.gray,
	padding: '16px 12px',
	color: theme.custom.colors.textPrimary
}))

const CopyBtnTxtStyled = styled(Typography)(({ theme }) => ({
	color: theme.custom.colors.kozmikBlue,
	fontSize: 12,
	fontWeight: '600',
	textAlign: 'left',
	textTransform: 'none'
}))

const CopyBtnIconStyled = styled(Add)(({ theme }) => ({
	color: theme.custom.colors.kozmikBlue,
	fontSize: 12
}))

const CustomDomain = (props) => {
	const { t } = useTranslation()
	const theme = useTheme()
	let { appId } = useParams()

	const customDomainRegExp =
		/^([a-zA-Z0-9][a-zA-Z0-9-_]*\.)*[a-zA-Z0-9]*[a-zA-Z0-9-_]*[[a-zA-Z0-9]+$/

	/****CUSTOMDOMAIN***************************/
	const [deleteConfirmationVisible, setDeleteConfirmationVisible] =
		useState(false)
	const [testing, setTesting] = useState(false)
	const [customDomain, setCustomDomain] = useState('')
	const [customDomainValid, setCustomDomainValid] = useState(true)
	const [focusedDomain, setFocusedDomain] = useState(null)
	const [beingDeletedDomain, setBeingDeletedDomain] = useState(null)
	const [customDomainList, setCustomDomainList] = useState([])

	const getDomainName = (domain) => {
		const domainParts = domain.split('.')

		return domainParts[0]
	}

	const changeCustomDomain = (e) => {
		setCustomDomain(e.target.value)
		validateWithRegExp(e.target.value, customDomainRegExp).then((isValid) => {
			if (isValid) {
				//part count check
				const domainPartCount = e.target.value.split('.')?.length
				if (domainPartCount >= 3 && domainPartCount <= 4) {
					setCustomDomainValid(true)
				} else {
					setCustomDomainValid(false)
				}
			} else {
				setCustomDomainValid(false)
			}
		})
	}
	const getCustomDomainList = () => {
		domainClient.getDomainList(appId).then((response) => {
			if (response?.data?.success) {
				setCustomDomainList(response?.data?.data)
			} else {
				showToastResponseError(response)
			}
		})
	}
	const addCustomDomain = (domain) => {
		validateWithRegExp(domain, customDomainRegExp).then((isValid) => {
			if (isValid) {
				//part count check
				const domainPartCount = domain.split('.')?.length
				if (domainPartCount >= 3 && domainPartCount <= 4) {
					setCustomDomainValid(true)
					domainClient.addDomain(appId, domain).then((response) => {
						if (response?.data?.success) {
							const newCustomDomainList = [...customDomainList]
							newCustomDomainList.push({
								Id: response.data?.unique?.Value,
								Domain: domain,
								DomainVerified: false
							})
							setCustomDomainList(newCustomDomainList)
							setCustomDomain('')
						} else {
							showToastResponseError(response)
						}
					})
				} else {
					setCustomDomainValid(false)
				}
			} else {
				setCustomDomainValid(false)
			}
		})
	}
	const verifyCustomDomain = (domain) => {
		setTesting(true)
		domainClient
			.verifyDomain(appId, domain)
			.then((response) => {
				if (response?.data?.success) {
					setTesting(false)
					const updatedFocusedDomain = update(focusedDomain, {
						DomainVerified: { $set: true }
					})
					setFocusedDomain(updatedFocusedDomain)

					const focusedDomainIndex = customDomainList.findIndex(
						(x) => x.Id == focusedDomain.Id
					)
					const updatedDomainList = update(customDomainList, {
						[focusedDomainIndex]: {
							DomainVerified: { $set: true }
						}
					})
					setCustomDomainList(updatedDomainList)
				} else {
					setTesting(false)
					showToastResponseError(response)
				}
			})
			.catch((error) => {
				setTesting(false)
			})
	}
	const deleteCustomDomain = (domainId) => {
		domainClient.deleteDomain(appId, domainId).then((response) => {
			if (response?.data?.success) {
				setBeingDeletedDomain(null)
				const newCustomDomainList = customDomainList?.filter(
					(x) => x.Id != domainId
				)
				setCustomDomainList(newCustomDomainList)
			} else {
				showToastResponseError(response)
			}
		})
	}

	const deleteBtnClick = (item) => {
		setFocusedDomain(null)
		if (item.DomainVerified) {
			setBeingDeletedDomain(item)
			setDeleteConfirmationVisible(true)
		} else {
			deleteCustomDomain(item.Id)
		}
	}

	const handleDeleteDialogClose = () => {
		setDeleteConfirmationVisible(false)
	}
	const handleDeleteDialogAccept = () => {
		deleteCustomDomain(beingDeletedDomain.Id)
		setDeleteConfirmationVisible(false)
	}

	useEffect(() => {
		getCustomDomainList()
		setCustomDomain('')
		setFocusedDomain(null)
		setBeingDeletedDomain(null)
		setCustomDomainValid(true)
	}, [])

	return (
		<styledUI.StyledIntContainer>
			<styledUI.StyledIntSubTitle>
				{t('publishModule.webModal.customDomainTitle')}
			</styledUI.StyledIntSubTitle>
			{props?.currentOrg?.plan?.includes('free') ? (
				<styledUI.StyledGrayBox style={{ justifyContent: 'space-between' }}>
					<Typography style={style.text}>
						{t('publishModule.webModal.availableOnPaid')}
					</Typography>
					<styledUI.StyledBadge>
						{t('publishModule.webModal.upgrade')}
					</styledUI.StyledBadge>
					{/* <GradientButton
											onClick={() => }
											text={t('publishModule.webModal.upgrade')}
											bgColor={theme.custom.colors.kozmikGray}
											gradient='linear-gradient(90deg, #96f1ce 50%, rgba(27,54,254,0.3) 100%)'
										/> */}
				</styledUI.StyledGrayBox>
			) : (
				<>
					<Grid
						container
						size={{ xs: 12 }}
						direction='row'
						justifyContent='space-between'
						alignItems='center'
						style={{ marginBottom: 10 }}
					>
						<Grid size={{ xs: 9 }}>
							<styledUI.StyledIntInput
								error={!customDomainValid}
								fullWidth
								placeholder={t(
									'publishModule.webModal.customDomainPlaceholder'
								)}
								style={{
									backgroundColor: '#FAFAFC',
									overflow: 'hidden'
								}}
								value={customDomain}
								onChange={changeCustomDomain}
								slotProps={{
									input: {
										startAdornment: (
											<InputAdornment position='start'>
												<AdornmentTextStyled>https://</AdornmentTextStyled>
											</InputAdornment>
										)
									}
								}}
							/>
						</Grid>
						<Grid size={{ xs: 3 }}>
							<Button
								startIcon={<CopyBtnIconStyled />}
								// fullWidth
								size='large'
								variant='contained'
								onClick={() => addCustomDomain(customDomain)}
								sx={{
									textTransform: 'none',
									backgroundColor: `transparent !important`,
									boxShadow: 'none !important',
									fontSize: 14,
									borderRadius: '4px',
									margin: '5px'
								}}
							>
								<CopyBtnTxtStyled>
									{t('publishModule.webModal.addCustomDomain')}
								</CopyBtnTxtStyled>
							</Button>
						</Grid>
					</Grid>
					{!!focusedDomain &&
						(focusedDomain.DomainVerified ? (
							<styledUI.StyledGrayBox
								style={{
									backgroundColor: '#EBFBF6',
									minHeight: 40
								}}
							>
								<styledUI.StyledCheckActive />
								<Typography style={{ ...style.text, ...{ paddingLeft: 10 } }}>
									{t('publishModule.webModal.customDomainSuccess')}
									<b>{t('publishModule.webModal.customDomainSuccessBold')}</b>
								</Typography>
							</styledUI.StyledGrayBox>
						) : (
							<>
								<styledUI.StyledGrayBox style={{ display: 'block' }}>
									<Box
										style={{
											borderBottom: `1px solid ${theme.custom.colors.gray}`
										}}
									>
										<Typography style={style.textDNS}>
											{t('publishModule.webModal.dnsText')}
										</Typography>
									</Box>
									<Box>
										<Typography style={style.textDNS}>
											Name / @ / Host:{' '}
											<b>{getDomainName(focusedDomain.Domain)}</b>
										</Typography>
										<Typography style={style.textDNS}>
											Type: <b>CNAME</b>
										</Typography>
										<Typography style={style.textDNS}>
											Points To/ Domain / Value: <b>domain.kozmik.dev</b>
										</Typography>
									</Box>
								</styledUI.StyledGrayBox>
								<Grid
									container
									size={{ xs: 12 }}
									direction='row'
									justifyContent='space-between'
									alignItems='center'
									flexWrap='nowrap'
									style={{ marginBottom: 10 }}
								>
									{testing ? (
										<>
											<CircularProgress
												size={30}
												sx={{
													color: theme.custom.colors.kozmikBlue
												}}
											/>
											<Typography
												style={{ ...style.textDNS, ...{ paddingLeft: 15 } }}
											>
												{t('publishModule.webModal.dnsWaiting')}
											</Typography>
										</>
									) : (
										<Typography style={style.textDNS}>
											{t('publishModule.webModal.dnsTest')}
										</Typography>
									)}
									<Button
										size='small'
										variant='outlined'
										onClick={() => verifyCustomDomain(focusedDomain.Domain)}
										disabled={testing}
										sx={{
											textTransform: 'none',
											borderColor: testing
												? theme.custom.colors.gray
												: theme.custom.colors.kozmikBlue,
											boxShadow: 'none !important',
											height: '35px',
											borderRadius: '8px'
										}}
									>
										<CopyBtnTxtStyled
											style={{
												color: testing ? theme.custom.colors.gray : null
											}}
										>
											{t('publishModule.webModal.dnsTestBtn')}
										</CopyBtnTxtStyled>
									</Button>
								</Grid>
							</>
						))}

					{customDomainList?.map((item, index) => (
						<DomainItem
							item={item}
							key={index}
							verifyBtnClick={() => setFocusedDomain(item)}
							verifyBtnText={t('publishModule.webModal.verifyCustomDomain')}
							deleteBtnClick={() => deleteBtnClick(item)}
							deleteBtnText={t('publishModule.webModal.deleteCustomDomain')}
						/>
					))}
				</>
			)}
			<WarningDialog
				dialogVisible={deleteConfirmationVisible}
				dialogClose={handleDeleteDialogClose}
				dialogAccept={handleDeleteDialogAccept}
				dialogTitle={t('publishModule.webModal.confirmation.title')}
				title={null}
				text={t('publishModule.webModal.confirmation.text')}
				dialogCancelText={t('publishModule.webModal.confirmation.closeButton')}
				dialogAcceptText={t('publishModule.webModal.confirmation.acceptButton')}
			/>
		</styledUI.StyledIntContainer>
	)
}

const mapStateToProps = (state) => ({
	...state.appData,
	...state.userInfo,
	...state.currentOrg
})

const mapDispatchToProps = (dispatch) => {
	return {
		setDetail: (x) => dispatch(setDetail(x))
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomDomain)
