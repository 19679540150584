import React from 'react'
import { styled } from '@mui/material/styles'
import CardMedia from '@mui/material/CardMedia'

const MainContainerStyled = styled('div')((props) => ({
	backgroundColor: props?.bgColor ? props.bgColor : '#FFFFFF',
	border: `1px solid ${props.theme.custom.colors.gray}`,
	overflow: 'hidden',
	borderRadius: props?.circle ? '50%' : '4px',
	width: props?.size ? props.size : 40,
	height: props?.size ? props.size : 40,
	minWidth: props?.size ? props.size : 40,
	minHeight: props?.size ? props.size : 40,
	fontSize: props?.size ? props.size / 2.86 : '14px',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	color: props.theme.custom.colors.textSecondary,
	fontWeight: 'bold',
	textTransform: 'uppercase'
}))

const ProfileCard = (props) => {
	const textManager = (text) => {
		const letterArray = text.match(/\b(\w)/g)
		const letters = letterArray.join('')

		return letters.slice(0, 3)
	}

	return (
		<MainContainerStyled
			style={
				props?.size && {
					width: props.size,
					height: props.size,
					minWidth: props.size,
					minHeight: props.size,
					fontSize: props.size / 2.86
				}
			}
			{...props}
		>
			{props?.imgUrl ? (
				<CardMedia
					image={props.imgUrl}
					component='img'
					sx={{
						width: '100%',
						height: '100%'
					}}
				/>
			) : (
				props?.text && textManager(props.text)
			)}
		</MainContainerStyled>
	)
}

export default ProfileCard
