import React, { useState } from 'react'
import { useTheme } from '@mui/material/styles'
import { Box, InputAdornment } from '@mui/material'

import SelectBox from '../selectBox/SelectBox'
import Dropdown from '../dropdown/Dropdown'

import styledUI from '../../../styledUI'

const style = {
	input: (props, theme) => {
		return {
			backgroundColor: props?.bgColor ? props.bgColor : '#FAFAFC',
			borderRadius: '3px !important',
			border: 'none',
			'& .MuiInputBase-adornedStart': {
				paddingLeft: 0,
				overflow: 'hidden'
			},
			paddingLeft: '0px !important',
			overflow: 'hidden',
			fontSize: 14,
			color: theme.custom.colors.textPrimary
		}
	}
}

const DoubleStateSelect = (props) => {
	const {
		onOpen,
		secondaryMultiple,
		primaryValueKey,
		secondaryValueKey,
		primaryVal,
		secondaryVal,
		primaryValChange,
		secondaryValChange,
		primaryOptions,
		secondaryOptions,
		primaryLabelKey,
		secondaryLabelKey,
		isSecondaryMode,
		setIsSecondaryMode,
		callback,
		undefinedText,
		bgColor,
		icon,
		...restProps
	} = props

	const theme = useTheme()

	const [openP, setOpenP] = useState(false)
	const closePrimary = () => setOpenP(false)
	const openPrimary = () => setOpenP(true)

	const [openS, setOpenS] = useState(false)
	const closeSecondary = () => setOpenS(false)
	const openSecondary = () => {
		onOpen && onOpen()
		setOpenS(true)
	}

	const getLabelFieldName = (valueFieldName, textfieldName) => {
		if (secondaryOptions.length == 0) return 'Name'

		const keys = Object.keys(secondaryOptions?.[0])

		let fieldName = keys?.find((x) => x == textfieldName)
		if (!fieldName) {
			fieldName = keys?.find((x) => x != valueFieldName)
		}

		return fieldName ?? 'Name'
	}

	const switchButton = (secondaryMode, callbackFunc = null) => {
		setIsSecondaryMode(secondaryMode)

		if (callbackFunc) {
			if (secondaryMode) {
				callbackFunc(secondaryMode, secondaryVal)
			} else {
				callbackFunc(secondaryMode, primaryVal)
			}
		}

		if (secondaryMode) {
			openSecondary()
		} else {
			openPrimary()
		}
	}

	return isSecondaryMode ? (
		<SelectBox
			sx={style.input(props, theme)}
			onChange={secondaryValChange}
			onOpen={openSecondary}
			open={openS}
			onClose={closeSecondary}
			value={secondaryVal}
			data={secondaryOptions}
			valueKey={secondaryValueKey}
			textKey={getLabelFieldName(secondaryValueKey, secondaryLabelKey)}
			multiple={secondaryMultiple}
			startAdornment={
				<InputAdornment position='start'>
					<styledUI.AdornmentActiveStyled
						onClick={() => switchButton(false, callback ? callback : null)}
					>
						{icon ? (
							<styledUI.IconActiveStyled />
						) : (
							<styledUI.InputIconActiveStyled />
						)}
					</styledUI.AdornmentActiveStyled>
				</InputAdornment>
			}
		/>
	) : (
		<Dropdown
			bgColor={bgColor}
			onItemClick={primaryValChange}
			value={primaryVal}
			data={primaryOptions}
			textKey={primaryLabelKey}
			valueKey={primaryValueKey}
			groupKey={primaryLabelKey}
			groupKeySeperator='.'
			startAdornment={
				<styledUI.AdornmentStyled
					onClick={() => switchButton(true, callback ? callback : null)}
				>
					{icon ? <styledUI.IconStyled /> : <styledUI.InputIconStyled />}
				</styledUI.AdornmentStyled>
			}
		/>
	)
}
export default DoubleStateSelect
