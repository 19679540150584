import React from 'react'
import { DragDropContext } from '@hello-pangea/dnd'
import { useTranslation } from 'react-i18next'

import ScreenMenu from '../screenMenu/ScreenMenu'

const reorder = (list, startIndex, endIndex) => {
	const result = Array.from(list)
	const [removed] = result.splice(startIndex, 1)
	result.splice(endIndex, 0, removed)

	return result
}

const ScreenLists = (props) => {
	const { t } = useTranslation()

	const idList = {
		droppable1: 'hiddens',
		droppable2: 'tabs',
		droppable3: 'drawers'
	}

	const getAllowedKeys = (object, disallowedKeys) => {
		const allowedKeys = Object.keys(object).filter((key) => {
			if (!disallowedKeys.includes(key)) {
				return key
			}
		})
		return allowedKeys
	}

	const move = (
		sourceId,
		destinationId,
		droppableSource,
		droppableDestination
	) => {
		const neutralIds = getAllowedKeys(idList, [sourceId, destinationId])

		const sourceList = getList(sourceId)
		const destList = getList(destinationId)
		let neutralLists = {}
		neutralIds.forEach((id) => {
			neutralLists[id] = getList(id)
		})

		const sourceClone = Array.from(sourceList)
		const destClone = Array.from(destList)
		let neutralClones = {}
		neutralIds.forEach((id) => {
			neutralClones[id] = Array.from(neutralLists[id])
		})

		const [removed] = sourceClone.splice(droppableSource.index, 1)

		destClone.splice(droppableDestination.index, 0, removed)

		const result = {}
		result[droppableSource.droppableId] = sourceClone
		result[droppableDestination.droppableId] = destClone
		neutralIds.forEach((id) => {
			result[id] = neutralClones[id]
		})

		return result
	}

	const getList = (id) => props.items[idList[id]]

	const onDragEnd = (result) => {
		const { source, destination } = result

		if (!destination) {
			return
		}

		// Sorting in same list
		if (source.droppableId === destination.droppableId) {
			const items = reorder(
				getList(source.droppableId),
				source.index,
				destination.index
			)

			let state = { items }

			if (source.droppableId === 'droppable1') {
				state = {
					//TODO:immutability-helper kullanır hale gelmeli
					hiddens: items,
					tabs: getList('droppable2'),
					drawers: getList('droppable3')
					// hiddens: getList('droppable1')
				}
			} else if (source.droppableId === 'droppable2') {
				state = {
					hiddens: getList('droppable1'),
					tabs: items,
					drawers: getList('droppable3')
					// hiddens: getList('droppable1')
				}
			} else if (source.droppableId === 'droppable3') {
				state = {
					hiddens: getList('droppable1'),
					tabs: getList('droppable2'),
					drawers: items
					// hiddens: getList('droppable1')
				}
			}

			props.setScreens(state)
		}
		// Interlist movement
		else {
			const result = move(
				source.droppableId,
				destination.droppableId,
				source,
				destination
			)

			props.setScreens({
				hiddens: result.droppable1,
				tabs: result.droppable2,
				drawers: result.droppable3
			})
		}
	}

	const deleteScreen = (droppableId, index) => {
		props?.deleteScreen(idList[droppableId], index)
	}

	const copyItem = (droppableId, item) => {
		props?.copyItem(idList[droppableId], item)
	}
	//TODO:ScreenMenuler map ile dönülecek
	return (
		<div style={{ display: 'flex', flexDirection: 'column' }}>
			<DragDropContext onDragEnd={onDragEnd}>
				<ScreenMenu
					emptyText={t('screens.emptyScreensText')}
					droppableId='droppable1'
					title={t('screens.hiddensTitle')}
					items={props?.items?.hiddens}
					deleteItem={deleteScreen}
					copyItem={copyItem}
					linkingScreens={props?.linkingScreens}
					activePreviewRoute={props?.activePreviewRoute}
					navigatePreview={props.navigatePreview}
					setRightContentType={props.setRightContentType}
				/>
				<ScreenMenu
					accordion
					emptyText={t('screens.emptyTabText')}
					droppableId='droppable2'
					title={t('screens.tabsTitle')}
					items={props?.items?.tabs}
					deleteItem={deleteScreen}
					copyItem={copyItem}
					linkingScreens={props?.linkingScreens}
					activePreviewRoute={props?.activePreviewRoute}
					navigatePreview={props.navigatePreview}
					setRightContentType={props.setRightContentType}
				/>
				<ScreenMenu
					accordion
					emptyText={t('screens.emptyMenuText')}
					droppableId='droppable3'
					title={t('screens.drawersTitle')}
					items={props?.items?.drawers}
					deleteItem={deleteScreen}
					copyItem={copyItem}
					linkingScreens={props?.linkingScreens}
					activePreviewRoute={props?.activePreviewRoute}
					navigatePreview={props.navigatePreview}
					setRightContentType={props.setRightContentType}
				/>
			</DragDropContext>
		</div>
	)
}

export default ScreenLists
