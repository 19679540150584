import React from 'react'
import { Droppable, Draggable } from '@hello-pangea/dnd'

import ScreenMenuItem from '../screenMenuItem/ScreenMenuItem'
import Accordion from '../../atoms/accordion/Accordion'

const getListStyle = (isDraggingOver) => ({
	background: 'transparent', //isDraggingOver ? '#D7DBFB' : 'transparent',
	borderRadius: 6,
	// padding: 5,
	width: '100%',
	minHeight: 30
})

const getItemStyle = (isDragging, draggableStyle) => ({
	userSelect: 'none',
	margin: `0 0 5px 0`,
	borderRadius: 6,
	boxShadow: isDragging ? '0 4px 8px 0 rgba(0, 0, 0, 0.17)' : 'none',
	overflow: isDragging ? 'hidden' : 'visible',

	...draggableStyle
})

const ScreenMenu = (props) => {
	const getRoute = (item) => {
		let detailKey = props?.linkingScreens[item.key]?.initialRouteName
		return {
			key: item.key, //TODO screens eklenince kaldırılacak
			detailKey: detailKey ? detailKey : null
		}
	}

	const isActive = (route) => {
		return props?.activePreviewRoute?.route?.name === route.key
	}

	const isActiveAccordion = (items) => {
		return true

		// if (items?.length) {
		// 	const itemsRoutes = items.map((item) => getRoute(item).key)

		// 	if (itemsRoutes.includes(props?.activePreviewRoute?.route?.name)) {
		// 		return true
		// 	}
		// }
	}

	const content = () => {
		return (
			props?.items?.length > 0 &&
			props.items.map((item, index) => (
				<Draggable key={item.key} draggableId={item.key} index={index}>
					{(provided, snapshot) => {
						const itemRoute = getRoute(item)
						return (
							<div
								ref={provided.innerRef}
								{...provided.draggableProps}
								{...provided.dragHandleProps}
								style={getItemStyle(
									snapshot.isDragging,
									provided.draggableProps.style
								)}
							>
								<ScreenMenuItem
									text={item.name}
									deleteItem={() => props.deleteItem(props.droppableId, index)}
									copyItem={() => props.copyItem(props.droppableId, item)}
									navigatePreview={() => {
										// !isActive(itemRoute) && props.navigatePreview(itemRoute)
										props.navigatePreview(itemRoute)
										props.setRightContentType('screenSettings')
									}}
									isActive={isActive(itemRoute)}
								/>
							</div>
						)
					}}
				</Draggable>
			))
		)
	}

	return props?.droppableId ? (
		<Droppable droppableId={props.droppableId}>
			{(provided, snapshot) => (
				<div
					ref={provided.innerRef}
					style={getListStyle(snapshot.isDraggingOver)}
				>
					{!props?.accordion && !!props?.emptyText && !props?.items?.length ? (
						<span style={{ fontSize: 12, fontStyle: 'italic' }}>
							{props.emptyText}
						</span>
					) : null}
					{props?.accordion ? (
						<Accordion
							color={'#000'}
							title={props.title}
							expanded={isActiveAccordion(props?.items)}
						>
							{!!props?.emptyText && !props?.items?.length ? (
								<span style={{ fontSize: 12, fontStyle: 'italic' }}>
									{props.emptyText}
								</span>
							) : null}
							{content()}
							{provided.placeholder}
						</Accordion>
					) : (
						content()
					)}
				</div>
			)}
		</Droppable>
	) : (
		<div style={getListStyle(false)}>
			<Accordion title={props.title} expanded={isActiveAccordion(props?.items)}>
				{props?.items?.length > 0 &&
					props.items.map((item, index) => {
						const itemRoute = getRoute(item)
						return (
							<div key={index} style={getItemStyle(false)}>
								<ScreenMenuItem
									nonDraggable
									text={item.name}
									deleteItem={() =>
										props.deleteItem(props.screenGroupId, index)
									}
									copyItem={() => props.copyItem(props.screenGroupId, item)}
									navigatePreview={() => {
										!isActive(itemRoute) && props.navigatePreview(itemRoute)
										props.setRightContentType('screenSettings')
									}}
									isActive={isActive(itemRoute)}
								/>
							</div>
						)
					})}
			</Accordion>
		</div>
	)
}

export default ScreenMenu
