import React, { useState } from 'react'
import { styled } from '@mui/material/styles'
import { Box, Popover } from '@mui/material'
import Grid from '@mui/material/Grid2'
import { DeleteOutlined } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'

import PopoverItem from '../popoverItem/PopoverItem'
import WarningDialog from '../dialog/warningDialog/WarningDialog'
import AccordionItem from '../../atoms/accordionItem/AccordionItem'
import { default as Label } from '../../atoms/settingLabel/SettingLabel'
import KozmikTextField from '../../atoms/kozmikTextField/KozmikTextField'
import fieldDataTypes from '../../../common/fieldDataTypes'

import styledUI from '../../../styledUI'

const ColumnListItem = (props) => {
	const { t } = useTranslation()

	const {
		field,
		element,
		deleteColumn,
		activeScreen,
		index,
		columnTitleChange,
		entities,
		...restProps
	} = props

	const [elementPop, setElementPop] = useState(null)

	const openPop = (event) => {
		setElementPop(event.currentTarget)
	}

	const closePop = () => {
		setElementPop(null)
	}

	const elementPopOpen = Boolean(elementPop)

	const titleChange = (e) => {
		columnTitleChange(index, e.target.value)
	}

	return (
		<div {...restProps}>
			<AccordionItem
				title={field?.label || element?.mapping?.field}
				expanded={false}
				moreClick={openPop}
				bgColor='#F0F0F4'
			>
				<styledUI.StyledGrid container alignItems='center'>
					<Grid size={{ xs: 4 }}>
						<Label>{t('screens.elementSettings.table.column.title')}</Label>
					</Grid>
					<Grid size={{ xs: 8 }}>
						<KozmikTextField
							fullWidth
							bgColor='#ffffff'
							value={element?.title?.default ?? ''}
							onChange={titleChange}
						/>
					</Grid>
				</styledUI.StyledGrid>
			</AccordionItem>
			<Popover
				open={elementPopOpen}
				anchorEl={elementPop}
				onClose={() => {
					closePop()
				}}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center'
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center'
				}}
			>
				<styledUI.PopoverBoxStyled>
					<PopoverItem
						text={t('screens.elementSettings.table.column.delete')}
						onClick={() => {
							closePop()
							deleteColumn()
						}}
						color='#FF2619'
					>
						<styledUI.PopoverDeleteIconStyled />
					</PopoverItem>
				</styledUI.PopoverBoxStyled>
			</Popover>
		</div>
	)
}

export default ColumnListItem
