import React, { useState, useEffect } from 'react'
import Grid from '@mui/material/Grid2'
import { useTheme } from '@mui/material/styles'
import update from 'immutability-helper'
import { useTranslation } from 'react-i18next'
import { SwapVert, FilterAltOutlined, PlusOne } from '@mui/icons-material'

import AppPageBox from '../../../atoms/appPageBox/AppPageBox'
import SelectBox from '../../../molecules/selectBox/SelectBox'
import FilterModalOptions from '../../../molecules/filterModalOptions/FilterModalOptions'
import SearchOptions from '../../../molecules/searchOptions/SearchOptions'
import SquareOptions from '../../../molecules/squareOprions/SquareOptions'
import Accordion from '../../../atoms/accordion/Accordion'
import NumericInput from '../../../atoms/numericInput/NumericInput'
import { default as Label } from '../../../atoms/settingLabel/SettingLabel'
import AccordionItem from '../../../atoms/accordionItem/AccordionItem'
import FilterList from '../../../molecules/filterList/FilterList'
import Sorting from '../../../molecules/sorting/Sorting'
import ColumnList from '../../../molecules/columnList/ColumnList'
import ActionList from '../../actionList/ActionList'
import VisibilityOptions from '../../../molecules/visibilityOptions/VisibilityOptions'

import timeoutDelay from '../../../../methods/timeoutDelay'
import getEntityFieldsByDataTypes from '../../../../methods/getEntityFieldsByDataTypes'
import fieldDataTypes from '../../../../common/fieldDataTypes'
import systemFieldNames from '../../../../common/systemFieldNames/systemFieldNames'

import { ReactComponent as Koseli } from '../../../../assets/icons/Settings/Koseli.svg'
import { ReactComponent as Oval } from '../../../../assets/icons/Settings/Oval.svg'
import { ReactComponent as Daire } from '../../../../assets/icons/Settings/KaroTip-Daire.svg'
import { ReactComponent as MetinBoyutuKucuk } from '../../../../assets/icons/Settings/MetinBoyutu-Kucuk.svg'
import { ReactComponent as MetinBoyutuStandart } from '../../../../assets/icons/Settings/MetinBoyutu-Standart.svg'
import { ReactComponent as MetinBoyutuBuyuk } from '../../../../assets/icons/Settings/MetinBoyutu-Buyuk.svg'

import defaultElementSchemas from '../../../../defaultElementSchemas'

import styledUI from '../../../../styledUI'

const Table = (props) => {
	const { t } = useTranslation()
	const theme = useTheme()

	const [elementName, setElementName] = useState(null)

	const fontSizeOptions = [
		{
			text: t('screens.elementSettings.table.fontOpts.sm'),
			value: 'sm',
			icon: MetinBoyutuKucuk
		},
		{
			text: t('screens.elementSettings.table.fontOpts.md'),
			value: 'md',
			icon: MetinBoyutuStandart
		},
		{
			text: t('screens.elementSettings.table.fontOpts.lg'),
			value: 'lg',
			icon: MetinBoyutuBuyuk
		}
	]

	/****SOURCE***************************/
	const [source, setSource] = useState('')

	const textFields = getEntityFieldsByDataTypes(
		props?.entities,
		source,
		fieldDataTypes.textBased,
		null,
		true
		// relationTypes.one
	)

	const sourceChange = (e) => {
		submitSource(source, e.target.value)
		setSource(e.target.value)
	}

	const submitSource = (oldTableName, tableName) => {
		const table = props?.entities?.find((y) => y.name == tableName)

		const acceptedFields = getEntityFieldsByDataTypes(
			null,
			null,
			fieldDataTypes.all,
			table
		)
			?.filter(
				(x) =>
					!systemFieldNames.notVisible.includes(x.name) &&
					x.dataType != 'Lookup' &&
					x.dataType != 'Rollup' &&
					x.dataType != 'Formula'
			)
			?.slice(0, 10)

		let modifiedElementData = update(props.elementData, {
			source: { $set: tableName },
			columns: {
				$set: acceptedFields.map((item) => {
					return {
						title: {
							default: item.label
						},
						field: item.name,
						type: item.dataType
					}
				})
			},
			sorting: {
				field: { $set: 'Created' },
				sort: { $set: 'desc' }
			},
			filters: {
				groupOp: { $set: 'AND' },
				rules: { $set: [] }
			},
			seeAllUrl: {
				$set: props.elementData?.seeAllUrl
			},
			filterModal: {
				$set: defaultElementSchemas.filterModal()
			}
		})

		props.updateBulkScreensWithModifiedData(
			table.name,
			null,
			modifiedElementData
		)
	}

	/****ACTIONS***************************/
	const updateActions = (newActions) => {
		const modifiedElementData = update(props.elementData, {
			actions: { $set: newActions }
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****IMG_CORNER***************************/
	const [imgCorner, setImgCorner] = useState(null)
	const imgCornerChange = (val) => {
		submitImgCorner(val)
		setImgCorner(val)
	}
	const submitImgCorner = (x) => {
		const modifiedElementData = update(props.elementData, {
			corner: { $set: x }
		})
		props.updateSelectedElement(modifiedElementData)
	}
	const imgCornerOptions = [
		{
			text: t('screens.elementSettings.table.imgCornerOpts.square'),
			value: 'square',
			icon: Koseli
		},
		{
			text: t('screens.elementSettings.table.imgCornerOpts.oval'),
			value: 'oval',
			icon: Oval
		},
		{
			text: t('screens.elementSettings.table.imgCornerOpts.circle'),
			value: 'circle',
			icon: Daire
		}
	]

	/****FONT_SIZE***************************/
	const [fontSize, setFontSize] = useState(null)
	const fontSizeChange = (val) => {
		submitFontSize(val)
		setFontSize(val)
	}
	const submitFontSize = (x) => {
		const modifiedElementData = update(props.elementData, {
			fontSize: { $set: x }
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****LIMIT***************************/
	const [limit, setLimit] = useState(25)
	const limitChange = (val) => {
		timeoutDelay(submitLimit, val, 1000)
		setLimit(val)
	}
	const submitLimit = (x) => {
		const modifiedElementData = update(props.elementData, {
			limit: { $set: x }
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****FILTER_&_SORT***************************/
	const [localElementData, setLocalElementData] = useState(null)

	/****INITIALIZATION***************************/
	const initStates = (elementData) => {
		/****SOURCE***************************/
		setSource(elementData?.source ? elementData.source : '')
		/****LIMIT***************************/
		setLimit(elementData?.limit ?? 10)
		/****IMG_CORNER***************************/
		setImgCorner(elementData?.corner ? elementData.corner : null)
		/****FONT_SIZE***************************/
		setFontSize(elementData?.fontSize)

		setElementName(elementData?._uid)
	}

	useEffect(() => {
		if (props?.elementData) {
			initStates(props.elementData)
			setLocalElementData(props.elementData)
		}
	}, [props.elementData])

	return (
		<>
			<AppPageBox>
				<Accordion
					title={t('screens.elementSettings.common.titles.source')}
					expanded={true}
				>
					<styledUI.StyledGrid container alignItems='center'>
						{/* <Grid size={{ xs: 4 }}>
								<Label>{t('screens.elementSettings.table.source')}</Label>
							</Grid> */}
						<Grid size={{ xs: 12 }}>
							<SelectBox
								sx={styledUI.Style.selectBox(props, theme)}
								onChange={sourceChange}
								value={source}
								data={props.entities}
								textKey='label'
								valueKey='name'
							/>
						</Grid>
					</styledUI.StyledGrid>

					<styledUI.StyledDiv>
						<AccordionItem
							icon={FilterAltOutlined}
							title={t('screens.elementSettings.table.filter')}
							expanded={false}
							padding={8}
						>
							<FilterList
								filter={localElementData?.filters}
								table={props?.entities?.find(
									(y) => y.name == props?.elementData?.source
								)}
								activeScreen={props?.activeScreen}
								entities={props?.entities}
								tableName={props?.elementData?.source}
								screenTable={props?.entities?.find(
									(y) => y.name == props.activeScreen?.data?.source
								)}
								filtering={props.filtering}
								setLocalElementData={setLocalElementData}
							/>
						</AccordionItem>
					</styledUI.StyledDiv>

					<styledUI.StyledDiv>
						<AccordionItem
							icon={SwapVert}
							title={t('screens.elementSettings.table.sort')}
							expanded={false}
							padding={8}
						>
							<Sorting
								sortingData={localElementData?.sorting}
								sorting={props.sorting}
								fieldOptions={textFields}
							/>
						</AccordionItem>
					</styledUI.StyledDiv>

					<styledUI.StyledDiv>
						<AccordionItem
							icon={PlusOne}
							title={t('screens.elementSettings.table.limit')}
							expanded={false}
							padding={8}
						>
							<NumericInput
								bgColor={'#ffffff'}
								minVal={0}
								value={limit}
								onChange={limitChange}
							/>
						</AccordionItem>
					</styledUI.StyledDiv>
				</Accordion>
			</AppPageBox>

			<AppPageBox>
				<Accordion
					title={t('screens.elementSettings.common.titles.columns')}
					expanded={true}
				>
					<ColumnList
						source={source}
						activeScreen={props.activeScreen}
						elementData={props.elementData}
						entities={props.entities}
						updateSelectedElement={props.updateSelectedElement}
					/>
				</Accordion>
			</AppPageBox>

			<AppPageBox>
				<Accordion
					title={t('screens.elementSettings.common.titles.design')}
					expanded={true}
				>
					<styledUI.StyledGrid container alignItems='center'>
						<Grid size={{ xs: 4 }}>
							<Label>{t('screens.elementSettings.table.imgCorner')}</Label>
						</Grid>
						<Grid size={{ xs: 8 }}>
							<SquareOptions
								compact
								data={imgCornerOptions}
								getChange={imgCornerChange}
								activeValue={imgCorner}
							/>
						</Grid>
					</styledUI.StyledGrid>
					<styledUI.StyledGrid container alignItems='center'>
						<Grid size={{ xs: 4 }}>
							<Label>{t('screens.elementSettings.table.fontSize')}</Label>
						</Grid>
						<Grid size={{ xs: 8 }}>
							<SquareOptions
								compact
								data={fontSizeOptions}
								getChange={fontSizeChange}
								activeValue={fontSize}
							/>
						</Grid>
					</styledUI.StyledGrid>
				</Accordion>
			</AppPageBox>

			<AppPageBox>
				<Accordion
					title={t('screens.elementSettings.common.titles.search')}
					expanded={true}
				>
					<SearchOptions
						source={source}
						elementData={props.elementData}
						updateSelectedElement={props.updateSelectedElement}
					/>
				</Accordion>
			</AppPageBox>

			<AppPageBox>
				<Accordion
					title={t('screens.elementSettings.common.titles.inAppFilter')}
					expanded={false}
				>
					{source && (
						<FilterModalOptions
							source={source}
							activeScreen={props.activeScreen}
							elementData={props.elementData}
							entities={props.entities}
							sorting={props.sorting}
							filtering={props.filtering}
							updateSelectedElement={props.updateSelectedElement}
						/>
					)}
				</Accordion>
			</AppPageBox>

			<AppPageBox>
				<Accordion
					title={t('screens.elementSettings.common.titles.actions')}
					expanded={true}
				>
					{source && (
						<ActionList
							name={elementName}
							entities={props.entities}
							activeScreen={props.activeScreen}
							actions={props.elementData.actions}
							updateActions={updateActions}
							isList={true}
							listSource={source}
						/>
					)}
				</Accordion>
			</AppPageBox>

			<VisibilityOptions
				block={props.elementData}
				activeScreen={props.activeScreen}
				entities={props.entities}
				updateBlock={props.updateSelectedElement}
			/>
		</>
	)
}

export default Table
