import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm, Controller } from 'react-hook-form'
import { toast } from 'react-toastify'
import Cookies from 'universal-cookie'
import { useHistory } from 'react-router-dom'

import authorizationClient from '../../../services/authorizationClient'
import { default as appConfig } from '../../../config/app.json'
import showToastResponseError from '../../../methods/showToastResponseError'

import KozmikLogoLight from '../../../assets/icons/KozmikLogoLight.png'

import styledUI from '../../../styledUI'

const style = {
	buttonContainer: {
		margin: '10px 0'
	}
}

const ForgotPassword = () => {
	const { t } = useTranslation()

	const [submitting, setSubmitting] = useState(false)

	const cookies = new Cookies()
	const history = useHistory()
	const navigate = (url) => {
		history.push(url)
	} //let navigate = useNavigate()
	const token = cookies.get(appConfig.cookieKeys.MY_SECURE_TOKEN_KEY)

	const {
		control,
		handleSubmit,
		formState: { errors, isValid }
	} = useForm({
		defaultValues: {
			email: ''
		}
	})
	const onSubmit = (data) => {
		setSubmitting(true)
		authorizationClient
			.forgotPassword(data)
			.then((response) => {
				if (response?.data?.success) {
					// toast.success(response?.data?.message)
					navigate('/login')
				} else {
					showToastResponseError(response)
				}
				setSubmitting(false)
			})
			.catch(() => {
				setSubmitting(false)
			})
	}

	const handleUserKeyPress = (e) => {
		if (e.key === 'Enter' && !e.shiftKey) {
			handleSubmit(onSubmit)()
		}
	}

	useEffect(() => {
		if (token && token !== 'undefined') {
			navigate('')
		}
	}, [])

	return (
		<styledUI.StyledAuthContainer>
			<styledUI.StyledAuthContentContainer>
				<div>
					<styledUI.StyledAuthLogoContainer>
						<a href='https://kozmik.io'>
							<styledUI.StyledAuthLogo
								image={KozmikLogoLight}
								component='img'
							/>
						</a>
					</styledUI.StyledAuthLogoContainer>
					<styledUI.StyledAuthSubTitle>
						{t('authScreens.forgotPassword.subTitle')}
					</styledUI.StyledAuthSubTitle>
				</div>
				<styledUI.StyledAuthInputContainer>
					<Controller
						control={control}
						name='email'
						rules={{
							required: {
								value: true,
								message: t('authScreens.forgotPassword.mailInput.requredError')
							},
							pattern: {
								value:
									/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
								message: t('authScreens.forgotPassword.mailInput.patternError')
							}
						}}
						render={({ field: { onChange, value } }) => {
							return (
								<styledUI.StyledAuthInput
									error={!!errors?.email}
									helperText={errors?.email ? errors?.email?.message : ''}
									placeholder={t(
										'authScreens.forgotPassword.mailInput.placeholder'
									)}
									fullWidth
									value={value}
									onChange={(e) => onChange(e.target.value)}
									onKeyPress={handleUserKeyPress}
								/>
							)
						}}
					/>
				</styledUI.StyledAuthInputContainer>

				<div style={style.buttonContainer}>
					<styledUI.StyledAuthSubmitButton
						fullWidth
						variant='contained'
						onClick={handleSubmit(onSubmit)}
						disabled={submitting}
						sx={{
							opacity: submitting ? 0.6 : 1
						}}
					>
						<styledUI.StyledAuthSubmitButtonText>
							{t('authScreens.forgotPassword.acceptButton')}
						</styledUI.StyledAuthSubmitButtonText>
						{submitting && <styledUI.StyledAuthLoader size={20} />}
					</styledUI.StyledAuthSubmitButton>
				</div>
			</styledUI.StyledAuthContentContainer>
		</styledUI.StyledAuthContainer>
	)
}

export default ForgotPassword
