import React, { useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'

import KozmikFilePicker from '../../../atoms/kozmikFilePicker/KozmikFilePicker'

import timeoutDelay from '../../../../methods/timeoutDelay'

import appClient from '../../../../services/appClient'
import storeSettingClient from '../../../../services/storeSettingClient'
import showToastResponseError from '../../../../methods/showToastResponseError'

import { setDetail } from '../../../../redux/actions/appDataActions'

import styledUI from '../../../../styledUI'

const PlayStore = (props) => {
	const { t } = useTranslation()
	let { appId } = useParams()

	const chooseText = t('publishModule.androidModal.chooseFile')

	const [setting, setSetting] = useState(null)

	/****PACKAGENAME***************************/
	const [packageName, setPackageName] = useState('')
	const changePackageName = (e) => {
		setPackageName(e.target.value)

		timeoutDelay(
			updateSetting,
			{
				AndroidPackageName: e.target.value
			},
			1000
		)
	}

	/****SERVICES_KEY_FILE***************************/
	const [servicesFile, setServicesFile] = useState(null)
	const [servicesFileName, setServicesFileName] = useState(chooseText)
	const servicesFileOnChange = (fileObject) => {
		setServicesFileName(fileObject?.name)
		setServicesFile(fileObject?.base64File)

		props.updateSetting({
			FirebaseGoogleServiceFile: fileObject?.base64File
		})
	}

	/****ACCOUNT_KEY_FILE***************************/
	const [accountFile, setAccountFile] = useState(null)
	const [accountFileName, setAccountFileName] = useState(chooseText)
	const accountFileOnChange = (fileObject) => {
		setAccountFileName(fileObject?.name)
		setAccountFile(fileObject?.base64File)

		props.updateSetting({
			AndroidGoogleAccountFile: fileObject?.base64File
		})
	}

	const getSetting = () => {
		storeSettingClient.getSetting(appId).then((response) => {
			if (response?.data?.success) {
				setSetting(response?.data?.data)
			} else {
				showToastResponseError(response)
			}
		})
	}

	const updateSetting = (data) => {
		storeSettingClient
			.updateSetting(appId, data)
			.then((response) => {
				if (response?.data?.success) {
					setSetting({ ...setting, ...data })
				} else {
					setSetting(setting)
					showToastResponseError(response)
				}
			})
			.catch((error) => {
				setSetting(setting)
			})
	}

	const initializationSetting = (setting) => {
		/****FRONTEND_FILE***************************/
		setServicesFile(setting?.FirebaseGoogleServiceFile || null)
		setServicesFileName(
			setting?.FirebaseGoogleServiceFile
				? t('publishModule.androidModal.servicesFile')
				: chooseText
		)
		/****ACCOUNT_KEY_FILE***************************/
		setAccountFile(setting?.AndroidGoogleAccountFile || null)
		setAccountFileName(
			setting?.AndroidGoogleAccountFile
				? t('publishModule.androidModal.accountFile')
				: chooseText
		)
		/****PACKAGE_NAME***************************/
		setPackageName(setting?.AndroidPackageName || '')
	}

	useEffect(() => {
		setting && initializationSetting(setting)
	}, [setting])

	useEffect(() => {
		getSetting()
	}, [])

	return (
		<styledUI.StyledIntContainer>
			<styledUI.StyledIntTitle>
				{t('publishModule.androidModal.credentialsTitle')}
			</styledUI.StyledIntTitle>
			<styledUI.StyledIntDesc>
				{t('publishModule.androidModal.credentialsDesc')}
			</styledUI.StyledIntDesc>

			<Box style={{ marginBottom: 10 }}>
				<styledUI.StyledIntSubTitle>
					{t('publishModule.androidModal.packageName')}
				</styledUI.StyledIntSubTitle>
				<styledUI.StyledIntInput
					fullWidth
					placeholder={t('publishModule.androidModal.packageNamePlaceholder')}
					style={{ backgroundColor: '#FAFAFC', overflow: 'hidden' }}
					value={packageName}
					onChange={changePackageName}
				/>
			</Box>

			<Box style={{ marginBottom: 30 }}>
				<styledUI.StyledIntSubTitle>
					{t('publishModule.androidModal.accountKeyFile')}
				</styledUI.StyledIntSubTitle>
				<KozmikFilePicker
					onChange={accountFileOnChange}
					fileName={accountFileName}
					accept='.json'
				/>
			</Box>

			<Box style={{ marginBottom: 10 }}>
				<styledUI.StyledIntTitle>
					{t('publishModule.androidModal.pushCredentialsTitle')}
				</styledUI.StyledIntTitle>
				<styledUI.StyledIntDesc>
					{t('publishModule.androidModal.pushCredentialsDesc')}
				</styledUI.StyledIntDesc>
			</Box>

			<Box style={{ marginBottom: 10 }}>
				<styledUI.StyledIntSubTitle>
					{t('publishModule.androidModal.servicesKeyFile')}
				</styledUI.StyledIntSubTitle>
				<KozmikFilePicker
					onChange={servicesFileOnChange}
					fileName={servicesFileName}
					accept='.json'
				/>
			</Box>
		</styledUI.StyledIntContainer>
	)
}

const mapStateToProps = (state) => ({
	...state.appData
})

const mapDispatchToProps = (dispatch) => {
	return {
		setDetail: (x) => dispatch(setDetail(x))
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(PlayStore)
