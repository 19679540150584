import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm, Controller } from 'react-hook-form'
import { useHistory } from 'react-router-dom'

import authorizationClient from '../../../services/authorizationClient'
import tokenServices from '../../../services/tokenServices'

import useQuery from '../../../hooks/UseQuery/useQuery'
import showToastResponseError from '../../../methods/showToastResponseError'
import commonRegExes from '../../../common/regExp/commonRegExes'

import KozmikLogoLight from '../../../assets/icons/KozmikLogoLight.png'

import styledUI from '../../../styledUI'

const style = {
	buttonContainer: {
		marginTop: 20,
		marginBottom: 20
	}
}

const InvitationRegister = () => {
	const { t } = useTranslation()

	const [submitting, setSubmitting] = useState(false)

	const history = useHistory()
	const navigate = (url) => {
		history.push(url)
	} //let navigate = useNavigate()
	let queryParams = useQuery()

	const {
		control,
		handleSubmit,
		formState: { errors, isValid }
	} = useForm({
		defaultValues: {
			name: '',
			password: ''
		}
	})
	const onSubmit = (data) => {
		const dataWithToken = {
			token: queryParams.token,
			orgToken: queryParams.orgToken,
			...data
		}
		setSubmitting(true)
		authorizationClient
			.invitation(dataWithToken)
			.then((response) => {
				if (response?.data?.success) {
					tokenServices.add(response.data.data)
					navigate('')
				} else {
					showToastResponseError(response)
				}
				setSubmitting(false)
			})
			.catch(() => {
				setSubmitting(true)
			})
	}

	const handleUserKeyPress = (e) => {
		if (e.key === 'Enter' && !e.shiftKey) {
			handleSubmit(onSubmit)()
		}
	}

	return (
		<styledUI.StyledAuthContainer>
			<styledUI.StyledAuthContentContainer>
				<styledUI.StyledAuthLogoContainer>
					<a href='https://kozmik.io'>
						<styledUI.StyledAuthLogo image={KozmikLogoLight} component='img' />
					</a>
				</styledUI.StyledAuthLogoContainer>
				<styledUI.StyledAuthInputContainer>
					<Controller
						control={control}
						name='name'
						rules={{
							required: {
								value: true,
								message: t(
									'authScreens.invitationRegister.nameInput.requredError'
								)
							}
						}}
						render={({ field: { onChange, value } }) => {
							return (
								<styledUI.StyledAuthInput
									error={!!errors?.name}
									helperText={errors?.name ? errors?.name?.message : ''}
									placeholder={t(
										'authScreens.invitationRegister.nameInput.placeholder'
									)}
									fullWidth
									value={value}
									onChange={(e) => onChange(e.target.value)}
									onKeyPress={handleUserKeyPress}
									inputProps={{
										autoComplete: 'off'
									}}
								/>
							)
						}}
					/>
				</styledUI.StyledAuthInputContainer>

				<styledUI.StyledAuthInputContainer>
					<Controller
						control={control}
						name='password'
						rules={{
							required: {
								value: true,
								message: t(
									'authScreens.invitationRegister.passwordInput.requredError'
								)
							},
							pattern: {
								value: commonRegExes.password,
								message: t(
									'authScreens.invitationRegister.passwordInput.patternError'
								)
							}
						}}
						render={({ field: { onChange, value } }) => {
							return (
								<styledUI.StyledAuthInput
									error={!!errors?.password}
									helperText={errors?.password ? errors?.password?.message : ''}
									placeholder={t(
										'authScreens.invitationRegister.passwordInput.placeholder'
									)}
									fullWidth
									value={value}
									type='password'
									onChange={(e) => onChange(e.target.value)}
									onKeyPress={handleUserKeyPress}
									inputProps={{
										autoComplete: 'new-password'
									}}
								/>
							)
						}}
					/>
				</styledUI.StyledAuthInputContainer>

				<div style={style.buttonContainer}>
					<styledUI.StyledAuthSubmitButton
						fullWidth
						variant='contained'
						onClick={handleSubmit(onSubmit)}
						disabled={submitting}
						sx={{
							opacity: submitting ? 0.6 : 1
						}}
					>
						<styledUI.StyledAuthSubmitButtonText>
							{t('authScreens.invitationRegister.signupButton')}
						</styledUI.StyledAuthSubmitButtonText>
						{submitting && <styledUI.StyledAuthLoader size={20} />}
					</styledUI.StyledAuthSubmitButton>
				</div>
				<styledUI.StyledAuthBottomText>
					{t('authScreens.invitationRegister.haveAccount')}{' '}
					<styledUI.StyledAuthBottomLink onClick={() => navigate('/login')}>
						{t('authScreens.invitationRegister.login')}
					</styledUI.StyledAuthBottomLink>
				</styledUI.StyledAuthBottomText>
			</styledUI.StyledAuthContentContainer>
		</styledUI.StyledAuthContainer>
	)
}

export default InvitationRegister
