import React from 'react'
import { styled } from '@mui/material/styles'
import { LaptopMac, TabletMac, PhoneIphone } from '@mui/icons-material'

const style = {
	mainContainer: {
		display: 'flex',
		alignItems: 'center'
	},
	button: {
		padding: 5,
		margin: '0 20px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		cursor: 'pointer',
		color: '#BBBBBD'
	},
	icon: {
		fontSize: 25
	},
	active: {
		color: '#000000'
	},
	seperator: {
		width: 0,
		height: 25,
		border: '1px solid #BBBBBD'
	}
}

const PreviewButtons = (props) => {
	const buttonClass = (index) =>
		index === props.previewButtonIndex && style.active

	return (
		<div style={style.mainContainer}>
			<div
				style={{ ...style.button, ...buttonClass(0) }}
				onClick={() => props.setPreviewButtonIndex(0)}
			>
				<PhoneIphone style={style.icon} />
			</div>
			<div style={style.seperator}>{/*SEPERATOR*/}</div>
			<div
				style={{ ...style.button, ...buttonClass(1) }}
				onClick={() => props.setPreviewButtonIndex(1)}
			>
				<TabletMac sx={style.icon} />
			</div>
			<div style={style.seperator}>{/*SEPERATOR*/}</div>
			<div
				style={{ ...style.button, ...buttonClass(2) }}
				onClick={() => props.setPreviewButtonIndex(2)}
			>
				<LaptopMac style={{ ...style.icon, ...{ fontSize: 30 } }} />
			</div>
		</div>
	)
}

export default PreviewButtons
