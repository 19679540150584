import React from 'react'
import { styled } from '@mui/material/styles'
import FormControl from '@mui/material/FormControl'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import { Box } from '@mui/material'

const FormControlStyled = styled(FormControl)((props) => ({
	'& .MuiOutlinedInput-input': {
		color: props.theme.custom.colors.textPrimary
	},
	'& .MuiInputLabel-root': {
		color: props.theme.custom.colors.textPrimary
	},
	'& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
		borderColor: 'transparent'
	},
	'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input': {
		backgroundColor: 'transparent',
		color: props.theme.custom.colors.textPrimary
	},
	'& .MuiInputLabel-root.Mui-focused': {
		color: props.theme.custom.colors.textPrimary
	},
	'& .MuiSvgIcon-root.MuiSelect-icon': {
		color: props.theme.custom.colors.textSecondary
	},
	'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
		borderColor: 'transparent'
	}
}))

const MenuItemStyled = styled(MenuItem)((props) => ({
	fontSize: 14
}))

const BoxStyled = styled(Box)((props) => ({
	color: props.theme.custom.colors.kozmikBlue,
	fontSize: 14,
	padding: '14px 16px 6px 16px',
	borderTop: `1px solid ${props.theme.custom.colors.gray}`,
	cursor: 'pointer'
}))

const SelectBox = (props) => {
	const {
		textKey,
		valueKey,
		objectValueKey,
		data,
		emptyText,
		extraButton,
		...restProps
	} = props

	return (
		<FormControlStyled variant='outlined' size='small' fullWidth={true}>
			<Select variant='outlined' displayEmpty {...restProps}>
				{restProps?.placeholder && (
					<MenuItemStyled disabled value={''}>
						{restProps.placeholder}
					</MenuItemStyled>
				)}
				{!!emptyText && <MenuItemStyled value={''}>{emptyText}</MenuItemStyled>}
				{!!data?.length &&
					data.map((item, index) => (
						<MenuItemStyled
							key={index}
							value={
								objectValueKey
									? item?.[valueKey]?.[objectValueKey]
									: valueKey
									? item?.[valueKey]
									: item
							}
						>
							{item?.[textKey]}
						</MenuItemStyled>
					))}
				{extraButton && (
					<BoxStyled onClick={extraButton.onClick}>
						{extraButton.text}
					</BoxStyled>
				)}
			</Select>
		</FormControlStyled>
	)
}
export default SelectBox
