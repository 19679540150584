import React, { useState, useEffect } from 'react'

import { styled } from '@mui/material/styles'
import { MoreVert, ArrowDropDown } from '@mui/icons-material'
import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'

const AccordionBox = styled((props) => (
	<MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
	border: 'none',
	borderRadius: 4,
	backgroundColor: 'transparent',
	'&.Mui-expanded': {
		backgroundColor: theme.custom.colors.kozmikGray,
		boxShadow: 'none'
	},
	'&:before': {
		display: 'none'
	}
}))

const AccordionSummary = styled((props) => {
	const { icon, bgColor, ...restProps } = props

	return (
		<MuiAccordionSummary
			expandIcon={
				icon ? (
					<props.icon style={{ color: '#000000', fontSize: 16 }} />
				) : (
					<ArrowDropDown style={{ color: '#000000', fontSize: 20 }} />
				)
			}
			{...restProps}
		/>
	)
})((props) => ({
	backgroundColor: props?.bgColor ?? props?.theme.custom.colors.kozmikGray,
	minHeight: '37px !important',
	paddingRight: '10px !important',
	borderRadius: 4,
	'&.Mui-expanded': {
		borderRadius: '4px 4px 0 0',
		backgroundColor: `${props?.theme.custom.colors.gray} !important`
	},
	'& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
		transform: 'rotate(180deg)'
	},
	'& .MuiAccordionSummary-content': {
		margin: 0
	}
}))

const AccordionSummaryTitle = styled(Typography)((props) => ({
	fontSize: 14,
	fontWeight: '600',
	paddingLeft: props?.moreClick ? 5 : 15,
	color: props?.theme.custom.colors.textPrimary
}))

const AccordionSummaryIcon = styled(MoreVert)((props) => ({
	cursor: 'pointer',
	color: props?.theme.custom.colors.textPrimary
}))

const AccordionDetails = styled(MuiAccordionDetails)((props) => ({
	backgroundColor: props?.theme.custom.colors.kozmikGray,
	border: `1px solid ${props?.theme.custom.colors.gray}`,
	borderRadius: '0 0 4px 4px',
	padding: props?.padding ? `${props.padding}px !important` : '10px !important'
}))

const AccordionItem = (props) => {
	const [expanded, setExpanded] = useState(false)

	const handleChange = () => (event, newExpanded) => {
		setExpanded(newExpanded ? true : false)
	}

	useEffect(() => {
		if (typeof props?.expanded == 'boolean') {
			setExpanded(props.expanded)
		}
	}, [props.expanded])

	return (
		<AccordionBox
			expanded={expanded}
			onChange={props?.onChange ? props?.onChange() : handleChange()}
		>
			<AccordionSummary icon={props?.icon} bgColor={props?.bgColor}>
				{!!props?.moreClick && (
					<AccordionSummaryIcon
						onClick={(e) => {
							e.stopPropagation()
							props.moreClick(e)
						}}
					/>
				)}
				<AccordionSummaryTitle>{props.title}</AccordionSummaryTitle>
			</AccordionSummary>
			<AccordionDetails>{props.children}</AccordionDetails>
		</AccordionBox>
	)
}

export default AccordionItem
