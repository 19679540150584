import React from 'react'
import { styled } from '@mui/material/styles'
import Menu from '../../molecules/menu/Menu'
import Account from '../../organisms/account/Account'
import OrgSelection from '../../organisms/orgSelection/OrgSelection'

import { ReactComponent as KozmikLogo } from '../../../assets/icons/KozmikLogo.svg'

const LogoStyled = styled(KozmikLogo)(({ theme }) => ({
	width: 100,
	height: 'auto',
	paddingLeft: 10,
	marginBottom: 20,
	[theme.breakpoints.down('md')]: {
		//For MasterLayout hamburger menu
		display: 'none'
	}
}))

const style = {
	mainContainer: {
		borderRadius: 16,
		boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.03)',
		textAlign: 'left',
		backgroundColor: '#FFFFFF',
		padding: '20px 10px',
		justifyContent: 'space-between',
		display: 'flex',
		flexDirection: 'column',
		height: '100%'
	}
}

const LeftFrame = (props) => {
	return (
		<div style={style.mainContainer}>
			<div>
				<LogoStyled />
				<OrgSelection />
				<Menu
					menuItems={props.menuItems}
					collapseHamburger={props?.collapseHamburger}
				/>
			</div>
			<div>
				<Account collapseHamburger={props?.collapseHamburger} />
			</div>
		</div>
	)
}

export default LeftFrame
