import React from 'react'
import { styled } from '@mui/material/styles'
import { useTheme } from '@mui/material/styles'
import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { DeleteOutline } from '@mui/icons-material'

import ProfileCard from '../../molecules/profileCard/ProfileCard'
import SelectBox from '../../molecules/selectBox/SelectBox'

const style = {
	selectBox: (props, theme) => {
		return {
			marginTop: 5,
			marginBottom: 5,
			marginLeft: '-10px',
			backgroundColor: theme.custom.colors.kozmikGray,
			borderRadius: '4px !important',
			fontSize: '14px',
			color: theme.custom.colors.textPrimary,
			width: '75%',
			[theme.breakpoints.down('md')]: {
				width: 100
			},
			'& .MuiSelect-select': {
				padding: '8.5px 10px'
			},
			'&.MuiOutlinedInput-root': {
				'& fieldset': {
					border: 0
				},
				'&:hover fieldset': {
					border: 0
				},
				'&.Mui-focused fieldset': {
					border: 0
				}
			}
		}
	}
}

const TableBoxStyled = styled(Box)(({ theme }) => ({
	width: '100%',
	maxHeight: '100%',
	overflowX: 'auto',
	overflowY: 'hidden',
	flexDirection: 'column',
	paddingBottom: 70
}))

const TableBodyContainerStyled = styled(Box)(({ theme }) => ({
	flexGrow: 1,
	width: 'fit-content',
	minWidth: '100%',
	maxHeight: '100%',
	overflowY: 'auto',
	overflowX: 'hidden'
}))

const TableHeadContainerStyled = styled(Box)(({ theme }) => ({
	width: 'fit-content',
	minWidth: '100%',
	borderBottom: `1px solid ${theme.custom.colors.gray}`,
	marginBottom: 15,
	display: 'flex',
	alignItems: 'center',
	height: 50,
	padding: '0 10px'
}))

const TableColumnTitleStyled = styled(Typography)(({ theme }) => ({
	fontSize: 14,
	color: '#000000',
	fontWeight: '600',
	paddingBottom: '8px !important',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap'
}))

const NameColumnStyled = styled(TableColumnTitleStyled)(({ theme }) => ({
	minWidth: '30%',
	[theme.breakpoints.down('md')]: {
		minWidth: 220,
		width: 220
	},
	padding: '0 10px'
}))

const EmailColumnStyled = styled(TableColumnTitleStyled)(({ theme }) => ({
	minWidth: '30%',
	[theme.breakpoints.down('md')]: {
		minWidth: 220,
		width: 220
	},
	padding: '0 10px'
}))

const RoleColumnStyled = styled(TableColumnTitleStyled)(({ theme }) => ({
	minWidth: '17.5%',
	[theme.breakpoints.down('md')]: {
		minWidth: 140,
		width: 140
	},
	padding: '0 10px'
}))

const StatusColumnStyled = styled(TableColumnTitleStyled)(({ theme }) => ({
	minWidth: '17.5%',
	[theme.breakpoints.down('md')]: {
		minWidth: 100,
		width: 100
	},
	padding: '0 10px'
}))

const DeleteColumnStyled = styled(Box)(({ theme }) => ({
	height: 20,
	minWidth: '5%',
	[theme.breakpoints.down('md')]: {
		minWidth: 50,
		width: 50
	},
	padding: '0 10px',
	display: 'flex',
	justifyContent: 'center'
}))

const DeleteButtonStyled = styled(DeleteOutline)(({ theme }) => ({
	fontSize: 20,
	cursor: 'pointer',
	color: theme.custom.colors.textPrimary,
	display: 'none'
}))

const TableTextStyled = styled(Typography)(({ theme }) => ({
	fontSize: 14,
	color: theme.custom.colors.textPrimary,
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap'
}))

const TableTextBoxStyled = styled(Box)(({ theme }) => ({
	fontSize: 14,
	color: theme.custom.colors.textPrimary,
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap'
}))

const NameTextStyled = styled(Box)(({ theme }) => ({
	minWidth: '30%',
	[theme.breakpoints.down('md')]: {
		minWidth: 220,
		width: 220
	},
	padding: '0 10px'
}))

const EmailTextStyled = styled(TableTextStyled)(({ theme }) => ({
	minWidth: '30%',
	[theme.breakpoints.down('md')]: {
		minWidth: 220,
		width: 220
	},
	padding: '0 10px'
}))

const RoleTextStyled = styled(TableTextStyled)(({ theme }) => ({
	minWidth: '17.5%',
	[theme.breakpoints.down('md')]: {
		minWidth: 140,
		width: 140
	},
	padding: '0 10px'
}))

const RoleTextBoxStyled = styled(TableTextBoxStyled)(({ theme }) => ({
	minWidth: '17.5%',
	[theme.breakpoints.down('md')]: {
		minWidth: 140,
		width: 140
	},
	padding: '0 10px'
}))

const StatusTextStyled = styled(TableTextStyled)(({ theme }) => ({
	minWidth: '17.5%',
	[theme.breakpoints.down('md')]: {
		minWidth: 100,
		width: 100
	},
	padding: '0 10px'
}))

const StatusTextBoxStyled = styled(TableTextBoxStyled)(({ theme }) => ({
	minWidth: '17.5%',
	[theme.breakpoints.down('md')]: {
		minWidth: 100,
		width: 100
	},
	padding: '0 10px'
}))

const TableHorizontalPaddingStyled = styled(Box)(({ theme }) => ({
	padding: '0 10px'
}))

const TableRowStyled = styled(Box)(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	minWidth: '100%',
	height: 50,
	borderRadius: 4,
	'&:hover': {
		backgroundColor: theme.custom.colors.gray
	},
	'&:hover .deleteButton': {
		display: 'block'
	}
}))

const TeamTable = (props) => {
	const { t } = useTranslation()
	const theme = useTheme()

	const currentUserEmail = props?.userInfo?.email

	return (
		<TableBoxStyled>
			<TableHeadContainerStyled>
				<NameColumnStyled>{t('teamTable.name')}</NameColumnStyled>
				<EmailColumnStyled>{t('teamTable.email')}</EmailColumnStyled>
				<RoleColumnStyled>{t('teamTable.role')}</RoleColumnStyled>
				<StatusColumnStyled>{t('teamTable.status')}</StatusColumnStyled>
				<DeleteColumnStyled>{/**/}</DeleteColumnStyled>
			</TableHeadContainerStyled>

			<TableBodyContainerStyled>
				{props?.userData?.map((item, index) => (
					<TableHorizontalPaddingStyled key={index}>
						<TableRowStyled>
							<NameTextStyled style={{ display: 'flex', alignItems: 'center' }}>
								<ProfileCard text={item.Name} />
								<TableTextStyled style={{ marginLeft: 10 }}>
									{item.Name}
								</TableTextStyled>
							</NameTextStyled>
							<EmailTextStyled>{item.Email}</EmailTextStyled>
							{props?.permission && currentUserEmail != item.Email ? (
								<RoleTextBoxStyled>
									<SelectBox
										sx={style.selectBox(props, theme)}
										onChange={(e) => props.userGroupsChange(e, item.Id)}
										value={item.RoleType}
										data={props.roleOpts}
										textKey='label'
										valueKey='value'
									/>
								</RoleTextBoxStyled>
							) : (
								<RoleTextStyled>
									{props.roleOpts?.find((x) => x.value == item.RoleType)?.label}
								</RoleTextStyled>
							)}
							{props?.permission && currentUserEmail != item.Email ? (
								<StatusTextBoxStyled>
									<SelectBox
										sx={style.selectBox(props, theme)}
										onChange={(e) => props.statusChange(e, item.Id)}
										value={item.IsActive}
										data={props.statusOpts}
										textKey='label'
										valueKey='value'
									/>
								</StatusTextBoxStyled>
							) : (
								<StatusTextStyled>
									{
										props.statusOpts?.find((x) => x.value == item.IsActive)
											?.label
									}
								</StatusTextStyled>
							)}
							<DeleteColumnStyled>
								{!!props?.permission && currentUserEmail != item.Email && (
									<DeleteButtonStyled
										className='deleteButton'
										onClick={() => {
											props.setDeleteId(item.Id)
											props.setVisibleModal('delete')
										}}
									/>
								)}
							</DeleteColumnStyled>
						</TableRowStyled>
					</TableHorizontalPaddingStyled>
				))}
			</TableBodyContainerStyled>
		</TableBoxStyled>
	)
}

const mapStateToProps = (state) => ({
	...state.userInfo
})

export default connect(mapStateToProps, null)(TeamTable)
