import React from 'react'

import kozmikbackground from '../../../assets/images/kozmikbackground.png'

const AuthLayout = (props) => {
	return (
		<div
			style={{
				width: '100%',
				height: '100vh',
				flexWrap: 'nowrap',
				overflow: 'hidden',
				backgroundImage: `url("${kozmikbackground}")`,
				backgroundSize: 'cover'
			}}
		>
			{props?.children}
		</div>
	)
}

export default AuthLayout
