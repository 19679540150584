import React from 'react'
import { styled } from '@mui/material/styles'
import { Menu, SubMenu, MenuItem, MenuButton } from '@szhsin/react-menu'
import { ArrowDropDownOutlined, ArrowDropUpOutlined } from '@mui/icons-material'
import { Tooltip } from '@mui/material'

import '@szhsin/react-menu/dist/index.css'
import '@szhsin/react-menu/dist/transitions/slide.css'

const style = {
	menuItem: {
		fontSize: 14
	}
}

const StyledContainer = styled('div')((props) => ({
	display: 'flex',
	flexDirection: 'row',
	width: '100%',
	overflow: 'hidden',
	borderRadius: 3
}))

const StyledMenuButtonContainer = styled('div')((props) => ({
	display: 'flex',
	width: '100%',
	height: 38,
	backgroundColor: props?.bgColor
		? props.bgColor
		: props?.theme.custom.colors.kozmikGray,
	border: 'none',
	alignItems: 'center',
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis'
}))

const StyledMenuButton = styled((props) => {
	const { bgColor, ...restProps } = props
	return <MenuButton {...restProps} />
})((props) => ({
	flexGrow: 1,
	width: '100%',
	height: '100%',
	overflow: 'hidden',
	textAlign: 'left',
	padding: '8.5px 14px',
	border: 0,
	borderRadius: 4,
	backgroundColor: props?.bgColor
		? props.bgColor
		: props?.theme.custom.colors.kozmikGray,
	color: props?.theme.custom.colors.textPrimary,
	fontSize: 14,
	cursor: 'pointer'
}))

const StyledArrowDropUpOutlined = styled(ArrowDropUpOutlined)((props) => ({
	color: props?.theme.custom.colors.textSecondary,
	fontSize: '1.5rem',
	marginRight: 7,
	cursor: 'pointer'
}))

const StyledArrowDropDownOutlined = styled(ArrowDropDownOutlined)((props) => ({
	color: props?.theme.custom.colors.textSecondary,
	fontSize: '1.5rem',
	marginRight: 7,
	cursor: 'pointer'
}))

const Dropdown = (props) => {
	const {
		textKey,
		valueKey,
		groupKey,
		groupKeySeperator,
		objectValueKey,
		data,
		value,
		emptyText,
		startAdornment,
		bgColor,
		...restProps
	} = props

	const forwardIcon =
		'<span style="font-size:12px;color:#DEDEDE;margin:0 5px;">&#62;</span>'

	const groupedData =
		groupKey &&
		data?.reduce((group, item) => {
			const seperatorIndex =
				groupKeySeperator && item[groupKey]?.indexOf(groupKeySeperator)

			const gk =
				groupKeySeperator && seperatorIndex > -1
					? item[groupKey]?.slice(0, seperatorIndex)
					: item[groupKey]
			group[gk] = group[gk] ?? []
			group[gk].push(item)

			return group
		}, {})

	const groupedDataArr =
		groupedData &&
		Object.keys(groupedData).map((key) => [key, groupedData[key]])

	const valueText = data?.find((x) => x[valueKey] === value)?.[textKey] ?? ''

	const beautifyText = (text) => {
		if (!text) return null

		return groupKeySeperator ? text?.split('.').join(forwardIcon) : text
	}

	const getItemText = (text) => {
		return groupKeySeperator
			? beautifyText(
					text?.slice((text?.indexOf(groupKeySeperator) ?? 0) + 1, text?.length)
			  ) ?? ''
			: text
	}

	const renderMenuItem = (item, index) => {
		return item?.disabled && item?.tooltip ? (
			<Tooltip key={`${index}-tooltip`} title={item?.tooltip} followCursor>
				<MenuItem
					key={index}
					value={
						objectValueKey
							? item?.[valueKey]?.[objectValueKey]
							: valueKey
							? item?.[valueKey]
							: item
					}
					disabled={item?.disabled}
					style={style.menuItem}
				>
					<span
						dangerouslySetInnerHTML={{
							__html: getItemText(item[textKey])
						}}
					/>
				</MenuItem>
			</Tooltip>
		) : (
			<MenuItem
				key={index}
				value={
					objectValueKey
						? item?.[valueKey]?.[objectValueKey]
						: valueKey
						? item?.[valueKey]
						: item
				}
				disabled={item?.disabled}
				style={style.menuItem}
			>
				<span
					dangerouslySetInnerHTML={{
						__html: getItemText(item[textKey])
					}}
				/>
			</MenuItem>
		)
	}

	return (
		<StyledContainer>
			{startAdornment}
			<Menu
				menuButton={({ open }) => (
					<StyledMenuButtonContainer bgColor={bgColor}>
						<StyledMenuButton bgColor={bgColor}>
							<span
								dangerouslySetInnerHTML={{
									__html: beautifyText(valueText ?? value) ?? emptyText
								}}
							/>
						</StyledMenuButton>
						{open ? (
							<StyledArrowDropUpOutlined />
						) : (
							<StyledArrowDropDownOutlined />
						)}
					</StyledMenuButtonContainer>
				)}
				transition
				{...restProps}
			>
				{restProps?.placeholder && (
					<MenuItem disabled value={null} style={style.menuItem}>
						{restProps.placeholder}
					</MenuItem>
				)}

				{!!emptyText && (
					<MenuItem value={null} style={style.menuItem}>
						{emptyText}
					</MenuItem>
				)}

				{!!groupedDataArr?.length &&
					groupedDataArr.map((group, groupIndex) => {
						return group[0] === group[1][0][textKey] ? (
							renderMenuItem(group[1][0], groupIndex)
						) : (
							<SubMenu key={groupIndex} label={group[0]} style={style.menuItem}>
								{group[1].map((item, index) =>
									renderMenuItem(item, `${groupIndex}-${index}`)
								)}
							</SubMenu>
						)
					})}
			</Menu>
		</StyledContainer>
	)
}
export default Dropdown
