import React, { useState } from 'react'
import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import Dropdown from '../dropdown/Dropdown'

import localeEN from 'date-fns/locale/en-US'
import localeTR from 'date-fns/locale/tr'

import localeParseDate from '../../../methods/localeParseDate'

import styledUI from '../../../styledUI'

const DateBoxStyled = styled('div')((props) => ({
	overflow: 'hidden',
	borderRadius: '3px !important',
	backgroundColor: props?.bgColor ? props.bgColor : '#FAFAFC',
	display: 'flex'
}))

const DateTextBoxStyled = styled(Box)(({ theme }) => ({
	display: 'flex',
	flex: 1,
	cursor: 'pointer',
	alignItems: 'center',
	padding: '0 10px',
	fontSize: '14px',
	color: theme.custom.colors.textPrimary,
	whiteSpace: 'nowrap',
	textOverflow: 'ellipsis'
}))

const DoubleStateDate = (props) => {
	const {
		includeTime,
		emptyText,
		valChange,
		dateVal,
		autoCompOptions,
		autoCompVal,
		isDateMode,
		setIsDateMode,
		valueKey,
		labelKey,
		callback,
		undefinedText,
		bgColor,
		icon,
		languageCode,
		dataType,
		...restProps
	} = props

	const culture = localStorage.getItem('i18nextLng')

	const [open, setOpen] = useState(false)
	const closeDropdown = () => setOpen(false)
	const openDropdown = () => setOpen(true)

	const switchButton = (textMode, callbackFunc = null) => {
		setIsDateMode(textMode)
		if (callbackFunc) {
			if (textMode) {
				callbackFunc(textMode, dateVal)
			} else {
				callbackFunc(textMode, autoCompVal)
			}
		}
	}

	return isDateMode ? (
		<LocalizationProvider
			dateAdapter={AdapterDateFns}
			adapterLocale={culture === 'tr-TR' ? localeTR : localeEN}
		>
			{!includeTime ? (
				<DatePicker
					sx={styledUI.Style.datePicker(props)}
					value={localeParseDate(dateVal)}
					onChange={(newValue) => {
						valChange(newValue)
					}}
					renderInput={(params) => {
						const dateValue = localeParseDate(dateVal)
						return (
							<DateBoxStyled bgColor={props.bgColor}>
								<styledUI.AdornmentActiveStyled
									onClick={() =>
										switchButton(false, callback ? callback : null)
									}
								>
									{icon ? (
										<styledUI.IconActiveStyled />
									) : (
										<styledUI.InputIconActiveStyled />
									)}
								</styledUI.AdornmentActiveStyled>
								<DateTextBoxStyled
									onClick={
										params?.InputProps?.endAdornment?.props?.children?.props
											?.onClick
									}
									ref={params?.inputRef}
								>
									{dateVal
										? dateValue.toLocaleDateString(languageCode)
										: emptyText}
								</DateTextBoxStyled>
							</DateBoxStyled>
						)
					}}
					{...props}
				/>
			) : (
				<DateTimePicker
					sx={styledUI.Style.datePicker(props)}
					value={localeParseDate(dateVal)}
					onChange={(newValue) => {
						valChange(newValue)
					}}
					renderInput={(params) => {
						const dateValue = localeParseDate(dateVal)
						return (
							<DateBoxStyled bgColor={props.bgColor}>
								<styledUI.AdornmentActiveStyled
									onClick={() =>
										switchButton(false, callback ? callback : null)
									}
								>
									{icon ? (
										<styledUI.IconActiveStyled />
									) : (
										<styledUI.InputIconActiveStyled />
									)}
								</styledUI.AdornmentActiveStyled>
								<DateTextBoxStyled
									onClick={
										params?.InputProps?.endAdornment?.props?.children?.props
											?.onClick
									}
									ref={params?.inputRef}
								>
									{dateVal
										? `${dateValue.toLocaleDateString(
												languageCode
										  )} - ${dateValue.toLocaleTimeString(languageCode)}`
										: emptyText}
								</DateTextBoxStyled>
							</DateBoxStyled>
						)
					}}
					{...props}
				/>
			)}
		</LocalizationProvider>
	) : (
		<Dropdown
			bgColor={bgColor}
			onItemClick={valChange}
			value={autoCompVal}
			data={autoCompOptions}
			textKey={labelKey}
			valueKey={valueKey}
			groupKey={labelKey}
			groupKeySeperator='.'
			startAdornment={
				<styledUI.AdornmentStyled
					onClick={() => switchButton(true, callback ? callback : null)}
				>
					{icon ? <styledUI.IconStyled /> : <styledUI.InputIconStyled />}
				</styledUI.AdornmentStyled>
			}
		/>
	)
}
export default DoubleStateDate
