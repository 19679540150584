import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import update from 'immutability-helper'
import Grid from '@mui/material/Grid2'

import KozmikTextField from '../../atoms/kozmikTextField/KozmikTextField'

import timeoutDelay from '../../../methods/timeoutDelay'
import { default as Label } from '../../atoms/settingLabel/SettingLabel'

const EmptyOptions = (props) => {
	const { t } = useTranslation()

	/****EMPTY_STATE***************************/
	const [emptyStateMessage, setEmptyStateMessage] = useState('')

	const emptyStateMessageChange = (e) => {
		timeoutDelay(submitEmptyStateMessage, e.target.value, 1000)
		setEmptyStateMessage(e.target.value)
	}

	const submitEmptyStateMessage = (x) => {
		const modifiedElementData = update(props.elementData, {
			emptyState: {
				default: { $set: x }
			}
		})

		props.updateSelectedElement(modifiedElementData)
	}

	/****INITIALIZATION***************************/
	const initStates = (elementData) => {
		/****EMPTY_STATE***************************/
		setEmptyStateMessage(
			elementData?.emptyState?.default ? elementData?.emptyState?.default : ''
		)
	}

	useEffect(() => {
		if (props?.elementData) {
			initStates(props.elementData)
		}
	}, [props.elementData])

	return (
		<div>
			<Grid container alignItems='center'>
				<Grid size={{ xs: 4 }}>
					<Label>{t('screens.elementSettings.common.labels.message')}</Label>
				</Grid>
				<Grid size={{ xs: 8 }}>
					<KozmikTextField
						fullWidth
						value={emptyStateMessage}
						onChange={emptyStateMessageChange}
					/>
				</Grid>
			</Grid>
		</div>
	)
}

export default EmptyOptions
