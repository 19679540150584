import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'

import authorizationClient from '../../../services/authorizationClient'
import useQuery from '../../../hooks/UseQuery/useQuery'
import showToastResponseError from '../../../methods/showToastResponseError'

import KozmikLogoLight from '../../../assets/icons/KozmikLogoLight.png'

import styledUI from '../../../styledUI'

const style = {
	buttonContainer: {
		margin: '10px 0'
	}
}

const AcceptInvitation = () => {
	const { t } = useTranslation()

	const [submitting, setSubmitting] = useState(false)

	const history = useHistory()
	const navigate = (url) => {
		history.push(url)
	}
	let queryParams = useQuery()

	const submit = () => {
		const data = {
			token: queryParams.token,
			orgToken: queryParams.orgToken
		}
		setSubmitting(true)
		authorizationClient
			.acceptInvitation(data)
			.then((response) => {
				if (response?.data?.success) {
					// toast.success(response?.data?.message)
					navigate('/login')
				} else {
					showToastResponseError(response)
				}
				setSubmitting(false)
			})
			.catch(() => {
				setSubmitting(false)
			})
	}

	return (
		<styledUI.StyledAuthContainer>
			<styledUI.StyledAuthContentContainer>
				<div>
					<styledUI.StyledAuthLogoContainer>
						<a href='https://kozmik.io'>
							<styledUI.StyledAuthLogo
								image={KozmikLogoLight}
								component='img'
							/>
						</a>
					</styledUI.StyledAuthLogoContainer>
					<styledUI.StyledAuthSubTitle>
						{t('authScreens.acceptInvitation.subTitle')}
					</styledUI.StyledAuthSubTitle>
				</div>

				<div style={style.buttonContainer}>
					<styledUI.StyledAuthSubmitButton
						fullWidth
						variant='contained'
						onClick={submit}
						disabled={submitting}
						sx={{
							opacity: submitting ? 0.6 : 1
						}}
					>
						<styledUI.StyledAuthSubmitButtonText>
							{t('authScreens.acceptInvitation.acceptButton')}
						</styledUI.StyledAuthSubmitButtonText>
						{submitting && <styledUI.StyledAuthLoader size={20} />}
					</styledUI.StyledAuthSubmitButton>
				</div>
			</styledUI.StyledAuthContentContainer>
		</styledUI.StyledAuthContainer>
	)
}

export default AcceptInvitation
