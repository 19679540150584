import React, { useState, useEffect } from 'react'
import { useTheme } from '@mui/material/styles'
import Grid from '@mui/material/Grid2'
import update from 'immutability-helper'
import { useTranslation } from 'react-i18next'

import AppPageBox from '../../../atoms/appPageBox/AppPageBox'
import DoubleStateMentions from '../../../molecules/doubleStateField/DoubleStateMentions'
import ActionList from '../../actionList/ActionList'
import VisibilityOptions from '../../../molecules/visibilityOptions/VisibilityOptions'
import Accordion from '../../../atoms/accordion/Accordion'
import { default as Label } from '../../../atoms/settingLabel/SettingLabel'
import IoniconsSelect from '../../../molecules/IoniconsSelect/IoniconsSelect'
import SquareOptions from '../../../molecules/squareOprions/SquareOptions'
import { default as Slider } from '../../../atoms/kozmikSlider/KozmikSlider'

import getEntityFieldsByDataTypes from '../../../../methods/getEntityFieldsByDataTypes'
import fieldDataTypes from '../../../../common/fieldDataTypes'
import relationTypes from '../../../../common/enums/relationTypes'
import formulas from '../../../../common/formulas'

import { ReactComponent as Koseli } from '../../../../assets/icons/Settings/Koseli.svg'
import { ReactComponent as Oval } from '../../../../assets/icons/Settings/Oval.svg'
import { ReactComponent as Daire } from '../../../../assets/icons/Settings/KaroTip-Daire.svg'

import styledUI from '../../../../styledUI'

const SettingsButton = (props) => {
	const { t } = useTranslation()
	const theme = useTheme()

	const [elementName, setElementName] = useState(null)

	const cornerOptions = [
		{
			text: t('screens.elementSettings.settingsButton.cornerOpts.square'),
			value: 'square',
			icon: Koseli
		},
		{
			text: t('screens.elementSettings.settingsButton.cornerOpts.oval'),
			value: 'oval',
			icon: Oval
		},
		{
			text: t('screens.elementSettings.settingsButton.cornerOpts.circle'),
			value: 'circle',
			icon: Daire
		}
	]

	const textFields = getEntityFieldsByDataTypes(
		props?.entities,
		props.activeScreen.data?.source,
		fieldDataTypes.textBased,
		null,
		true,
		relationTypes.one
	)
	const textOptions = textFields?.length ? textFields : formulas.profile
	const textOptionsWithEmpty = [{ label: '-', name: '' }, ...textOptions]

	/****TITLE***************************/
	const [isTitleTextMode, setIsTitleTextMode] = useState(false)
	const [titleField, setTitleField] = useState('')
	const [titleDefault, setTitleDefault] = useState('')

	const titleChange = (data) => {
		if (isTitleTextMode) {
			submitTitle(data)
			setTitleDefault(data)
		} else {
			submitTitle(data?.value)
			setTitleField(data?.value)
		}
	}

	const submitTitle = (x) => {
		const selectedField = textOptions?.find((y) => y.name == x)
		const modifiedElementData = update(props.elementData, {
			mapping: {
				title: {
					field: { $set: isTitleTextMode ? null : x },
					default: { $set: isTitleTextMode ? x : null },
					type: { $set: isTitleTextMode ? null : selectedField?.dataType }
				}
			}
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****ICON***************************/
	const [icon, setIcon] = useState('')

	const iconChange = (val) => {
		submitIcon(val)
		setIcon(val)
	}

	const submitIcon = (x) => {
		const modifiedElementData = update(props.elementData, {
			icon: { $set: x }
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****CORNER***************************/
	const [corner, setCorner] = useState(null)

	const cornerChange = (val) => {
		submitCorner(val)
		setCorner(val)
	}

	const submitCorner = (x) => {
		const modifiedElementData = update(props.elementData, {
			corner: { $set: x }
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****HEIGHT***************************/
	const [height, setHeight] = useState(null)

	const heightChange = (e, newVal) => {
		if (typeof newVal === 'number') {
			submitHeight(newVal)
			setHeight(newVal)
		}
	}

	const submitHeight = (x) => {
		const modifiedElementData = update(props.elementData, {
			height: { $set: x }
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****ACTIONS***************************/
	const updateActions = (newActions) => {
		const modifiedElementData = update(props.elementData, {
			actions: { $set: newActions }
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****INITIALIZATION***************************/
	const initStates = (elementData) => {
		/****TITLE***************************/
		elementData?.mapping?.title?.field ||
		elementData?.mapping?.title?.field === ''
			? setIsTitleTextMode(false)
			: setIsTitleTextMode(true)

		setTitleField(elementData?.mapping?.title?.field || '')
		setTitleDefault(elementData?.mapping?.title?.default || '')
		/****ICON***************************/
		setIcon(elementData?.icon || '')
		/****CORNER***************************/
		setCorner(elementData?.corner || null)
		/****HEIGHT***************************/
		setHeight(elementData?.height || null)
	}

	useEffect(() => {
		props?.elementData && initStates(props.elementData)
	}, [props.elementData])

	return (
		<>
			<AppPageBox>
				<Accordion
					title={t('screens.elementSettings.common.titles.content')}
					expanded={true}
				>
					<styledUI.StyledGrid container alignItems='center'>
						<Grid size={{ xs: 4 }}>
							<Label>{t('screens.elementSettings.settingsButton.title')}</Label>
						</Grid>
						<Grid size={{ xs: 8 }}>
							<DoubleStateMentions
								name={`${elementName}-title`}
								multiline={false}
								fullWidth={true}
								isTextMode={isTitleTextMode}
								setIsTextMode={setIsTitleTextMode}
								valChange={titleChange}
								textVal={titleDefault}
								autoCompVal={titleField}
								autoCompOptions={textOptionsWithEmpty}
								valueKey='name'
								labelKey='label'
							/>
						</Grid>
					</styledUI.StyledGrid>
				</Accordion>
			</AppPageBox>

			<AppPageBox>
				<Accordion
					title={t('screens.elementSettings.common.titles.design')}
					expanded={true}
				>
					<styledUI.StyledGrid
						container
						alignItems='center'
						justifyContent='space-between'
					>
						<Grid size={{ xs: 4 }}>
							<Label>{t('screens.elementSettings.settingsButton.icon')}</Label>
						</Grid>
						<Grid size={{ xs: 4 }}>
							<IoniconsSelect
								onChange={iconChange}
								value={icon}
								emptySelectable
								fullWidth
							/>
						</Grid>
					</styledUI.StyledGrid>
					{icon && (
						<>
							<styledUI.StyledGrid container alignItems='center'>
								<Grid size={{ xs: 4 }}>
									<Label>
										{t('screens.elementSettings.settingsButton.iconSize')}
									</Label>
								</Grid>
								<Grid
									style={{
										display: 'flex',
										alignItems: 'center'
									}}
									size={{ xs: 12, lg: 8 }}
								>
									<Slider
										value={height}
										min={48}
										step={10}
										max={78}
										onChange={heightChange}
										aria-labelledby='non-linear-slider'
									/>
								</Grid>
							</styledUI.StyledGrid>
							{/* <styledUI.StyledGrid container alignItems='center' >
								<Grid size={{ xs: 4 }}>
									<Label>{t('screens.elementSettings.settingsButton.corners')}</Label>
								</Grid>
								<Grid size={{ xs: 8 }}>
									<SquareOptions
										compact
										data={cornerOptions}
										getChange={cornerChange}
										activeValue={corner}
									/>
								</Grid>
							</styledUI.StyledGrid> */}
						</>
					)}
				</Accordion>
			</AppPageBox>

			<AppPageBox>
				<Accordion
					title={t('screens.elementSettings.common.titles.actions')}
					expanded={true}
				>
					<ActionList
						name={elementName}
						entities={props.entities}
						activeScreen={props.activeScreen}
						actions={props.elementData.actions}
						updateActions={updateActions}
					/>
				</Accordion>
			</AppPageBox>

			<VisibilityOptions
				block={props.elementData}
				activeScreen={props.activeScreen}
				entities={props.entities}
				updateBlock={props.updateSelectedElement}
			/>
		</>
	)
}

export default SettingsButton
