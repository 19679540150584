import React, { useState, useRef } from 'react'
import { styled } from '@mui/material/styles'
import CircularProgress from '@mui/material/CircularProgress'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardContent from '@mui/material/CardContent'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import { Assignment, ColorLensOutlined, MoreHoriz } from '@mui/icons-material'
import { useHistory } from 'react-router-dom'
import CardMedia from '@mui/material/CardMedia'
import { useTranslation } from 'react-i18next'
import { blue } from '@mui/material/colors'

import PopoverItem from '../popoverItem/PopoverItem'

import authorizationClient from '../../../services/authorizationClient'
import appClient from '../../../services/appClient'
import useOutsideClick from '../../../hooks/UseOutsideClick/useOutsideClick'
import WarningDialog from '../dialog/warningDialog/WarningDialog'
import showToastResponseError from '../../../methods/showToastResponseError'

import styledUI from '../../../styledUI'

const AspectRatioContainerStyled = styled('div')(({ theme }) => ({
	height: 0,
	width: '100%',
	paddingTop: '100%',
	position: 'relative'
}))

const CardStyled = styled(Card)(({ theme }) => ({
	position: 'absolute',
	top: 0,
	left: 0,
	width: '100%',
	height: '100%',
	borderRadius: 16,
	'&:hover': {
		boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.17)'
	},
	cursor: 'pointer'
}))

const CardHeaderStyled = styled(CardHeader)(({ theme }) => ({
	padding: '0 10px',
	height: '20%'
}))

const CardContentStyled = styled(CardContent)(({ theme }) => ({
	padding: 0,
	display: 'flex',
	flexWrap: 'wrap',
	height: '100%',
	flexDirection: 'column',
	alignItems: 'center'
}))

const RoundedStyled = styled('div')(({ theme }) => ({
	backgroundColor: blue[500],
	height: '37.5%',
	width: '33%',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	borderRadius: 8
}))

const SpanStyled = styled(Typography)(({ theme }) => ({
	backgroundColor: '#56d87b',
	color: 'white',
	padding: '1px 5px',
	borderRadius: '10px',
	fontSize: 9,
	fontWeight: 'bold'
}))

const TilePopoverStyled = styled('div')(({ theme }) => ({
	position: 'absolute',
	top: '15%',
	left: '5%',
	width: '90%',
	backgroundColor: '#FFFFFF',
	borderRadius: '4px',
	boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.17)',
	padding: '5%',
	zIndex: 1
}))

const ExpandButtonStyled = styled(IconButton)(({ theme }) => ({
	margin: '12px 8px',
	borderRadius: '4px',
	width: '30px',
	height: '10px'
}))

const TextStyled = styled(Typography)(({ theme }) => ({
	width: '100%',
	fontWeight: 600,
	color: theme.custom.colors.textPrimary
}))

const PrimaryTextStyled = styled(TextStyled)(({ theme }) => ({
	marginTop: '11%',
	fontSize: 14
}))

const SecondaryTextStyled = styled(TextStyled)(({ theme }) => ({
	opacity: 0.62,
	fontSize: 12
}))

const style = {
	icon: {
		width: '50%',
		height: 'auto',
		color: '#ffffff'
	},
	icon2: {
		width: '100%',
		height: 'auto',
		borderRadius: 8
	}
}

const Tile = (props) => {
	const { t } = useTranslation()
	const [expanded, setExpanded] = useState(false)
	const [dialogVisible, setDialogVisible] = useState(false)
	const history = useHistory()
	const navigate = (url) => {
		history.push(url)
	}

	const [fetching, setFetching] = useState(false)

	const actionsPopover = useRef(null)
	useOutsideClick(actionsPopover, () => {
		setExpanded(false)
	})

	const copyApp = () => {
		if (props.data?.Id) {
			setFetching(true)

			appClient
				.copy(props.data.Id)
				.then(async (response) => {
					if (response?.data?.success) {
						await authorizationClient.refreshTokenAndUserInfo()

						response?.data?.data?.Id &&
							navigate(
								props.linkUrl.replace(':appId', response?.data?.data?.Id)
							)
					} else {
						showToastResponseError(response)
					}
					setFetching(false)
				})
				.catch((error) => {
					setFetching(false)
					showToastResponseError(error.response)
				})
		}
	}

	const deleteApp = () => {
		if (props.data?.Id) {
			appClient.delete(props.data.Id).then((response) => {
				if (response?.data?.success) {
					handleDialogClose()
					setExpanded(false)
					props?.reload && props.reload()
				} else {
					showToastResponseError(response)
				}
			})
		}
	}

	const handleDialogClose = () => {
		setDialogVisible(false)
	}

	const redirect = (url) => {
		window.location.href = url
	}

	const getDetailLink = () => {
		return props.data?.linkUrl
			? `${props.data.linkUrl}`
			: props.linkUrl.replace(':appId', props.data?.Id)
	}

	const click = () => {
		if (props.data?.click) {
			props.data.click()
		} else {
			props.data?.linkUrl
				? redirect(`${props.data.linkUrl}`)
				: props?.linkUrl &&
				  navigate(props.linkUrl.replace(':appId', props.data?.Id))
		}
	}

	return (
		<>
			<AspectRatioContainerStyled onClick={() => click()}>
				<CardStyled
					elevation={0}
					style={{
						backgroundColor: props.data?.cardColor
							? props.data.cardColor
							: '#ffffff'
					}}
				>
					<CardHeaderStyled
						avatar={
							props.data?.Published ? (
								<SpanStyled>
									{props.data?.publishedText
										? props.data.publishedText
										: t('tile.published')}
								</SpanStyled>
							) : null
						}
						action={
							props?.isInteractive ? (
								<ExpandButtonStyled
									style={{ backgroundColor: expanded && '#F0F0F4' }}
									onClick={(e) => {
										e.stopPropagation()
										setExpanded(!expanded)
									}}
									size='large'
								>
									<MoreHoriz />
								</ExpandButtonStyled>
							) : null
						}
					/>
					<CardContentStyled>
						{props.data?.Icon ? (
							typeof props.data.Icon == 'string' ? (
								<RoundedStyled style={{ backgroundColor: 'unset' }}>
									<CardMedia
										image={props.data.Icon}
										style={style.icon2}
										component='img'
									/>
								</RoundedStyled>
							) : (
								<RoundedStyled
									style={{ backgroundColor: props.data?.AccentColor }}
								>
									<props.data.Icon style={style.icon} />
								</RoundedStyled>
							)
						) : (
							<RoundedStyled
								style={{ backgroundColor: props.data?.AccentColor }}
							>
								<Assignment style={style.icon} />
							</RoundedStyled>
						)}
						{props.data?.Name && (
							<PrimaryTextStyled>{props.data.Name}</PrimaryTextStyled>
						)}

						{!props.data?.IsPublic && (
							<SecondaryTextStyled>{t('tile.private')}</SecondaryTextStyled>
						)}
					</CardContentStyled>
				</CardStyled>
				{expanded && (
					<TilePopoverStyled
						ref={actionsPopover}
						onClick={(e) => {
							e.stopPropagation()
						}}
					>
						<PopoverItem
							text={t('tile.openApp')}
							linkTo={() => getDetailLink()}
							color='#000000'
						>
							<styledUI.PopoverLaunchIconStyled />
						</PopoverItem>
						<PopoverItem
							text={t('tile.copy')}
							onClick={copyApp}
							color='#000000'
						>
							{fetching ? (
								<CircularProgress
									size={14}
									sx={{
										color: '#000000'
									}}
								/>
							) : (
								<styledUI.PopoverFilterIconStyled />
							)}
						</PopoverItem>
						<PopoverItem
							text={t('tile.delete')}
							color='#FF2619'
							onClick={() => setDialogVisible(true)}
						>
							<styledUI.PopoverDeleteIconStyled />
						</PopoverItem>
					</TilePopoverStyled>
				)}
			</AspectRatioContainerStyled>
			<WarningDialog
				dialogVisible={dialogVisible}
				dialogClose={handleDialogClose}
				dialogAccept={deleteApp}
				dialogTitle={t('tile.modal.dialogTitle')}
				title={null}
				text={t('tile.modal.text')}
				dialogCancelText={t('tile.modal.closeButton')}
				dialogAcceptText={t('tile.modal.acceptButton')}
			/>
		</>
	)
}

export default Tile
