import { Typography } from '@mui/material'
import React from 'react'
import { useTheme } from '@mui/material/styles'

const Label = (props) => {
	const theme = useTheme()

	const { sx, ...restProps } = props

	return (
		<Typography
			sx={{
				color: theme.custom.colors.kozmikBlue,
				//fontFamily: "DMSans",
				fontSize: 14,
				//lineHeight: "2.2vw",
				textAlign: 'left',
				fontWeight: 'bolder',
				...(sx ? sx : null)
			}}
			{...restProps}
		>
			{props.text}{' '}
		</Typography>
	)
}

export default Label
