import React from 'react'
import { styled } from '@mui/material/styles'
import { useTheme } from '@mui/material/styles'

import { Typography } from '@mui/material'
import Grid from '@mui/material/Grid2'

const BtnStyled = styled(Grid)((props) => ({
	...{
		padding: '5px 16px',
		marginBottom: 10,
		borderRadius: 4,
		cursor: 'pointer',
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'center',
		'&:hover': {
			backgroundColor: props.theme.custom.colors.kozmikGray
		},
		'&:hover $icon': {
			display: 'block'
		}
	},
	...(props.isActive && {
		backgroundColor: `${props.theme.custom.colors.kozmikLightBlue} !important`,
		'& .btnTxt': {
			color: props.theme.custom.colors.kozmikBlue
		},
		'& .icon': {
			color: props.theme.custom.colors.kozmikBlue
		}
	})
}))

const BtnTxtStyled = styled(Typography)(({ theme }) => ({
	color: theme.custom.colors.textPrimary,
	paddingLeft: 5,
	fontSize: 12,
	fontWeight: '600',
	whiteSpace: 'nowrap',
	textOverflow: 'ellipsis',
	overflow: 'hidden'
}))

const IconStyled = (props) => (
	<props.item.icon
		className='icon'
		sx={{
			color: props.theme.custom.colors.textPrimary,
			fontSize: 20
		}}
	/>
)

const DensityMenuItem = (props) => {
	const theme = useTheme()
	const { item, isActive, ...restProps } = props

	return (
		<BtnStyled size={{ xs: 12 }} isActive={isActive} {...restProps}>
			{!!item?.icon && <IconStyled item={item} theme={theme} />}
			<BtnTxtStyled className='btnTxt'>{item.label}</BtnTxtStyled>
		</BtnStyled>
	)
}

export default DensityMenuItem
