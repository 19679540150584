import React, {
	useState,
	useEffect,
	forwardRef,
	useImperativeHandle
} from 'react'
import Uppy from '@uppy/core'
import XHRUpload from '@uppy/xhr-upload'
import Url from '@uppy/url'
import Webcam from '@uppy/webcam'
import Audio from '@uppy/audio'
import { DashboardModal } from '@uppy/react'
import Cookies from 'universal-cookie'

import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'
import '@uppy/drag-drop/dist/style.css'
import '@uppy/file-input/dist/style.css'
import '@uppy/progress-bar/dist/style.css'
import '@uppy/webcam/dist/style.css'
import '@uppy/audio/dist/style.css'
import '@uppy/url/dist/style.css'

import { default as appConfig } from '../../../config/app.json'

const apiEndpoint = `${appConfig.endpoints.api}/api/cdn`

const UppyPicker = forwardRef((props, ref) => {
	const cookies = new Cookies()
	const token = cookies.get(appConfig.cookieKeys.MY_SECURE_TOKEN_KEY)
	const headers = {
		Authorization: `Bearer ${token}`
	}

	const [modalOpen, setModalOpen] = useState(false)

	useImperativeHandle(ref, () => ({
		open: handleOpen,
		close: handleClose
	}))

	const uppy = new Uppy({
		id: 'uppy',
		autoProceed: true,
		restrictions: {
			maxNumberOfFiles: 1,
			allowedFileTypes: props?.allowedFileTypes || [] //['image/*', 'video/*']
		}
	})
	uppy.use(XHRUpload, {
		endpoint: `${apiEndpoint}/upload`,
		fieldName: 'file',
		headers: headers,
		timeout: 60 * 1000,
		limit: 5
	})
	uppy.use(Url, {
		// target: Dashboard,
		companionUrl: apiEndpoint,
		locale: {}
	})
	uppy.use(Webcam, {
		// target: Dashboard,
		onBeforeSnapshot: () => Promise.resolve(),
		countdown: false,
		modes: ['video-audio', 'video-only', 'audio-only', 'picture'],
		mirror: true,
		showVideoSourceDropdown: false,
		/** @deprecated Use `videoConstraints.facingMode` instead. */
		facingMode: 'user',
		videoConstraints: {
			facingMode: 'user'
		},
		preferredImageMimeType: null,
		preferredVideoMimeType: null,
		showRecordingLength: false,
		// mobileNativeCamera: isMobile({ tablet: true }),
		locale: {}
	})
	uppy.use(Audio, {
		// target: Dashboard,
		locale: {}
	})
	uppy.on('complete', (result) => {
		props.uploadComplete(result)
		setModalOpen(false)
		uppy.close({ reason: 'unmount' })
	})

	useEffect(() => {
		return () => {
			uppy.close({ reason: 'unmount' })
		}
	}, [])

	const handleOpen = () => {
		setModalOpen(true)
	}

	const handleClose = () => {
		setModalOpen(false)
	}

	return (
		<div>
			<div onClick={handleOpen}>{props.children}</div>
			<DashboardModal
				uppy={uppy}
				closeModalOnClickOutside
				open={modalOpen}
				onRequestClose={handleClose}
				plugins={['Webcam']} //{['Url', 'Webcam', 'Audio']}
			/>
		</div>
	)
})

export default UppyPicker
