import React from 'react'
import { useTheme } from '@mui/material/styles'
import Grid from '@mui/material/Grid2'
import { SwapVert, FilterAltOutlined, PlusOne } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'

import AccordionItem from '../../atoms/accordionItem/AccordionItem'
import { default as Label } from '../../atoms/settingLabel/SettingLabel'
import KozmikTextField from '../../atoms/kozmikTextField/KozmikTextField'
import SquareOptions from '../squareOprions/SquareOptions'
import NumericInput from '../../atoms/numericInput/NumericInput'
import { default as Checkbox } from '../../atoms/kozmikCheckBox/KozmikCheckBox'
import SelectBox from '../selectBox/SelectBox'
import Sorting from '../sorting/Sorting'
import FilterList from '../filterList/FilterList'

import getEntityFieldsByDataTypes from '../../../methods/getEntityFieldsByDataTypes'
import fieldDataTypes from '../../../common/fieldDataTypes'
import filterOperators from '../../../common/filterOperators'

import styledUI from '../../../styledUI'

const style = {
	item: {
		width: '100%',
		display: 'flex',
		alignItems: 'center'
	},
	labelBox: {
		flexGrow: 1,
		width: 80,
		minWidth: 80
	}
}

const FilterModalElement = (props) => {
	const { t } = useTranslation()
	const theme = useTheme()

	const {
		field,
		element,
		activeScreen,
		index,
		elementOperatorChange,
		elementTitleChange,
		elementLabelChange,
		elementPlaceholderChange,
		elementPrecisionChange,
		elementLimitChange,
		elementCheckBoxTypeChange,
		elementTypeChange,
		elementScannerChange,
		choiceTypeOptions,
		checkboxTypeOptions,
		entities,
		elementRelationFieldChange,
		sorting,
		filtering,
		...restProps
	} = props

	const operators = filterOperators
		.find(
			(x) =>
				(field?.dataType === 'Relation' &&
					x.dataTypes.includes(field?.dataType)) ||
				(field?.dataType !== 'Relation' &&
					((field?.displayFieldDataType &&
						x.dataTypes.includes(field?.displayFieldDataType)) ||
						(!field?.displayFieldDataType &&
							x.dataTypes.includes(field?.dataType))))
		)
		?.operators?.filter(
			(x) => !(x.value?.endsWith('nu') || x.value?.endsWith('nn'))
		)

	const relatedEntity = entities?.find(
		(x) => x.name === element?.relation?.source
	)

	const relatedTextFields = getEntityFieldsByDataTypes(
		entities,
		relatedEntity?.name,
		fieldDataTypes.textBased
	)

	const operatorChange = (e) => {
		elementOperatorChange(index, e.target.value)
	}

	const titleChange = (e) => {
		elementTitleChange(index, e.target.value)
	}

	const labelChange = (e) => {
		elementLabelChange(index, e.target.value)
	}

	const typeChange = (value) => {
		elementTypeChange(index, value)
	}

	const scannerChange = (e) => {
		elementScannerChange(index, e.target.checked)
	}

	const placeholderChange = (e) => {
		elementPlaceholderChange(index, e.target.value)
	}

	const precisionChange = (value) => {
		elementPrecisionChange(index, value)
	}

	const limitChange = (value) => {
		elementLimitChange(index, value)
	}

	const checkboxTypeChange = (val) => {
		elementCheckBoxTypeChange(index, val)
	}

	const relationFieldChange = (e) => {
		elementRelationFieldChange(index, e.target.value)
	}

	return (
		<div {...restProps}>
			<styledUI.StyledGrid container alignItems='center'>
				<Grid size={{ xs: 4 }}>
					<Label>
						{t('screens.elementSettings.common.filterModal.element.operator')}
					</Label>
				</Grid>
				<Grid size={{ xs: 8 }}>
					<SelectBox
						sx={styledUI.Style.selectBox(props, theme)}
						onChange={operatorChange}
						value={element?.operator ?? ''}
						data={operators}
						textKey='label'
						valueKey='value'
					/>
				</Grid>
			</styledUI.StyledGrid>
			<styledUI.StyledGrid container alignItems='center'>
				<Grid size={{ xs: 4 }}>
					<Label>
						{t('screens.elementSettings.common.filterModal.element.title')}
					</Label>
				</Grid>
				<Grid size={{ xs: 8 }}>
					<KozmikTextField
						fullWidth
						bgColor='#ffffff'
						value={element?.title}
						onChange={titleChange}
					/>
				</Grid>
			</styledUI.StyledGrid>
			{element?.component === 'inputCheckbox' && (
				<>
					<styledUI.StyledGrid container alignItems='center'>
						<Grid size={{ xs: 4 }}>
							<Label>
								{t('screens.elementSettings.common.filterModal.element.label')}
							</Label>
						</Grid>
						<Grid size={{ xs: 8 }}>
							<KozmikTextField
								fullWidth
								bgColor='#ffffff'
								value={element?.label?.default}
								onChange={labelChange}
							/>
						</Grid>
					</styledUI.StyledGrid>
					<styledUI.StyledGrid container alignItems='center'>
						<Grid size={{ xs: 4 }}>
							<Label>
								{t(
									'screens.elementSettings.common.filterModal.element.checkboxType'
								)}
							</Label>
						</Grid>
						<Grid size={{ xs: 8 }}>
							<SquareOptions
								boxColor='#ffffff'
								data={checkboxTypeOptions}
								getChange={checkboxTypeChange}
								activeValue={element?.type}
							/>
						</Grid>
					</styledUI.StyledGrid>
				</>
			)}
			{element?.component != 'inputCheckbox' &&
				element?.component != 'inputChoice' && (
					<>
						<styledUI.StyledGrid container alignItems='center'>
							<Grid size={{ xs: 4 }}>
								<Label>
									{t(
										'screens.elementSettings.common.filterModal.element.placeholder'
									)}
								</Label>
							</Grid>
							<Grid size={{ xs: 8 }}>
								<KozmikTextField
									fullWidth
									bgColor='#ffffff'
									value={element?.placeholder?.default}
									onChange={placeholderChange}
								/>
							</Grid>
						</styledUI.StyledGrid>
					</>
				)}
			{element?.component == 'inputMoney' && (
				<styledUI.StyledGrid container alignItems='center'>
					<Grid size={{ xs: 4 }}>
						<Label>
							{t(
								'screens.elementSettings.common.filterModal.element.precision'
							)}
						</Label>
					</Grid>
					<Grid size={{ xs: 8 }}>
						<NumericInput
							maxVal={6}
							bgColor='#ffffff'
							disabled={false}
							value={element?.precision ?? ''}
							onChange={precisionChange}
						/>
					</Grid>
				</styledUI.StyledGrid>
			)}
			{(element?.component == 'inputMail' ||
				element?.component == 'inputMoney' ||
				element?.component == 'inputNumeric' ||
				element?.component == 'inputPhone' ||
				element?.component == 'inputText') && (
				<styledUI.StyledGrid container alignItems='center'>
					<div style={{ ...style.item }}>
						<div>
							<Checkbox checked={element?.scanner} onChange={scannerChange} />
						</div>
						<div style={{ ...style.labelBox }}>
							<Label>
								{t(
									'screens.elementSettings.common.filterModal.element.scanner'
								)}
							</Label>
						</div>
					</div>
				</styledUI.StyledGrid>
			)}
			{element?.component == 'inputChoice' && (
				<>
					<styledUI.StyledGrid container alignItems='center'>
						<Grid size={{ xs: 4 }}>
							<Label>
								{t(
									'screens.elementSettings.common.filterModal.element.relationField'
								)}
							</Label>
						</Grid>
						<Grid size={{ xs: 8 }}>
							<SelectBox
								sx={styledUI.Style.selectBox(props, theme)}
								onChange={relationFieldChange}
								value={element?.relation?.field}
								data={relatedTextFields}
								textKey='label'
								valueKey='name'
							/>
						</Grid>
					</styledUI.StyledGrid>
					<styledUI.StyledGrid container alignItems='center'>
						<Grid size={{ xs: 4 }}>
							<Label>
								{t(
									'screens.elementSettings.common.filterModal.element.choiceStyle'
								)}
							</Label>
						</Grid>
						<Grid size={{ xs: 8 }}>
							<SquareOptions
								data={choiceTypeOptions}
								getChange={typeChange}
								activeValue={element?.type}
							/>
						</Grid>
					</styledUI.StyledGrid>
					<styledUI.StyledDiv>
						<AccordionItem
							icon={FilterAltOutlined}
							title={t(
								'screens.elementSettings.common.filterModal.element.filter'
							)}
							expanded={false}
							padding={8}
							bgColor='#ffffff'
						>
							<FilterList
								index={index}
								feature={'filterModal'}
								filter={element?.filters}
								table={relatedEntity}
								filtering={filtering}
								//TODO
								activeScreen={activeScreen}
								entities={entities}
								formSource={props.source}
							/>
						</AccordionItem>
					</styledUI.StyledDiv>
					<styledUI.StyledDiv>
						<AccordionItem
							icon={SwapVert}
							title={t(
								'screens.elementSettings.common.filterModal.element.sort'
							)}
							expanded={false}
							padding={8}
							bgColor='#ffffff'
						>
							<Sorting
								index={index}
								feature={'filterModal'}
								sortingData={element?.sorting}
								sorting={sorting}
								fieldOptions={relatedTextFields}
							/>
						</AccordionItem>
					</styledUI.StyledDiv>
					<styledUI.StyledDiv>
						<AccordionItem
							icon={PlusOne}
							title={t(
								'screens.elementSettings.common.filterModal.element.limit'
							)}
							expanded={false}
							padding={8}
							bgColor='#ffffff'
						>
							<NumericInput
								minVal={0}
								value={element?.limit ?? ''}
								onChange={limitChange}
							/>
						</AccordionItem>
					</styledUI.StyledDiv>
				</>
			)}
		</div>
	)
}

export default FilterModalElement
