import React from 'react'
import Grid from '@mui/material/Grid2'
import { useTranslation } from 'react-i18next'

import DoubleStateText from './DoubleStateText'
import DoubleStateDate from './DoubleStateDate'
import DoubleStateSelect from './DoubleStateSelect'

const DoubleStateField = (props) => {
	const { t } = useTranslation()

	const {
		appId,
		languageCode,
		fieldOptionsWithScreenFields,
		dataType,
		entityField,
		fieldValue,
		fieldDataType,
		fieldDataChange,
		isSecondaryMode,
		setIsSecondaryMode,
		callback,
		relationDataOptions,
		dataDefault,
		dataDefaultValid,
		dataErrorText,
		relationTableData,
		refreshRelation,
		...restProps
	} = props

	const boolDataOptions = [
		{
			label: t('screens.elementSettings.filter.listItem.boolOpts.true'),
			value: true
		},
		{
			label: t('screens.elementSettings.filter.listItem.boolOpts.false'),
			value: false
		}
	]

	return (
		<>
			{dataType === 'relation' && (
				<Grid size={{ xs: 12, lg: 12 }}>
					<DoubleStateSelect
						bgColor='#ffffff'
						fullWidth={true}
						primaryOptions={fieldOptionsWithScreenFields}
						primaryLabelKey='label'
						primaryValueKey='name'
						primaryVal={fieldValue}
						primaryValChange={fieldDataChange}
						secondaryOptions={
							relationTableData?.length ? relationTableData : dataDefault
						}
						secondaryLabelKey={entityField?.displayFieldName ?? 'Name'}
						secondaryValueKey='Id'
						secondaryVal={dataDefault?.map((item) => item.Id)}
						secondaryValChange={fieldDataChange}
						isSecondaryMode={isSecondaryMode}
						setIsSecondaryMode={setIsSecondaryMode}
						callback={callback}
						secondaryMultiple={entityField?.relationType === 'Many' ?? true}
						onOpen={() => refreshRelation(entityField)}
						undefinedText={t(
							'screens.elementSettings.filter.listItem.selectScreenField'
						)}
					/>
				</Grid>
			)}

			{(dataType === 'string' || dataType === 'numeric') && (
				<Grid size={{ xs: 12, lg: 12 }}>
					<DoubleStateText
						bgColor='#ffffff'
						fullWidth={true}
						textError={dataDefaultValid}
						errorText={dataErrorText}
						isTextMode={isSecondaryMode}
						setIsTextMode={setIsSecondaryMode}
						valChange={fieldDataChange}
						textVal={dataDefault}
						autoCompVal={fieldValue}
						autoCompOptions={fieldOptionsWithScreenFields}
						valueKey='name'
						labelKey='label'
						callback={callback}
						fieldDataType={fieldDataType}
						undefinedText={t(
							'screens.elementSettings.filter.listItem.selectStringField'
						)}
					/>
				</Grid>
			)}

			{dataType === 'boolean' && (
				<Grid size={{ xs: 12, lg: 12 }}>
					<DoubleStateSelect
						bgColor='#ffffff'
						fullWidth={true}
						primaryOptions={fieldOptionsWithScreenFields}
						primaryLabelKey='label'
						primaryValueKey='name'
						primaryVal={fieldValue}
						primaryValChange={fieldDataChange}
						secondaryOptions={boolDataOptions}
						secondaryLabelKey='label'
						secondaryValueKey='value'
						secondaryVal={dataDefault}
						secondaryValChange={fieldDataChange}
						isSecondaryMode={isSecondaryMode}
						setIsSecondaryMode={setIsSecondaryMode}
						callback={callback}
						secondaryMultiple={false}
						undefinedText={t(
							'screens.elementSettings.filter.listItem.selectBoolField'
						)}
					/>
				</Grid>
			)}

			{dataType === 'datetime' && (
				<Grid size={{ xs: 12, lg: 12 }}>
					<DoubleStateDate
						includeTime={
							entityField?.includeTime === null
								? false
								: entityField?.includeTime
						}
						bgColor='#ffffff'
						fullWidth={true}
						isDateMode={isSecondaryMode}
						setIsDateMode={setIsSecondaryMode}
						valChange={fieldDataChange}
						dateVal={dataDefault}
						autoCompVal={fieldValue}
						autoCompOptions={fieldOptionsWithScreenFields}
						valueKey='name'
						labelKey='label'
						callback={callback}
						undefinedText={t(
							'screens.elementSettings.filter.listItem.selectStringField'
						)}
						emptyText={t('screens.elementSettings.filter.listItem.selectDate')}
						dataType={fieldDataType}
						languageCode={languageCode}
					/>
				</Grid>
			)}
		</>
	)
}
export default DoubleStateField
