import React, { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import { useTheme } from '@mui/material/styles'
import { Typography } from '@mui/material'

const MainContainerStyled = styled('div')(({ theme }) => ({
	display: 'flex',
	flexWrap: 'wrap',
	justifyContent: 'flex-end',
	alignItems: 'start'
}))

const ItemContainerStyled = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	flexDirection: 'column',
	padding: 3
}))

const SelectBoxStyled = styled('div')((props) => ({
	...(props.compact
		? {
				width: 37,
				height: 37,
				cursor: 'pointer',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				backgroundColor: props.theme.custom.colors.kozmikGray
		  }
		: {
				width: props?.width ? props?.width : 42,
				height: props?.height ? props?.height : 42,
				cursor: 'pointer',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				backgroundColor: props.theme.custom.colors.kozmikGray
		  }),
	...(props.selectedIndex === props.index
		? {
				border: `1px solid ${props.theme.custom.colors.kozmikBlue}`,
				borderRadius: '4px',
				textAlign: 'center'
		  }
		: { borderRadius: '4px', textAlign: 'center' })
}))

const SignTextStyled = styled(Typography)(({ theme }) => ({
	textAlign: 'center',
	color: theme.custom.colors.textPrimary,
	marginTop: 2,
	fontSize: '12px'
}))

const IconStyled = (props) => (
	<props.item.icon
		sx={{
			color: props.theme.custom.colors.textPrimary,
			width: 18
		}}
	/>
)

const SquareOptions = (props) => {
	const theme = useTheme()
	const [selectedIndex, setSelectedIndex] = useState()

	const setSelectedIndexByActiveValue = (activeValue) => {
		const activeData = props?.data?.find((x) => x.value == activeValue)
		const activeIndex = props?.data?.indexOf(activeData)

		activeIndex >= 0 && setSelectedIndex(activeIndex)
	}

	useEffect(() => {
		if (props?.activeValue || typeof props.activeValue == 'boolean') {
			setSelectedIndexByActiveValue(props.activeValue)
		}
	}, [props.activeValue])

	return (
		<MainContainerStyled>
			{!!props?.data?.length &&
				props.data.map((item, index) => (
					<ItemContainerStyled key={index}>
						<SelectBoxStyled
							compact={props?.compact}
							width={props?.width}
							height={props?.height}
							selectedIndex={selectedIndex}
							index={index}
							style={
								props?.boxColor ? { backgroundColor: props.boxColor } : null
							}
							onClick={() => {
								setSelectedIndex(index)
								props.getChange(item.value)
							}}
						>
							<IconStyled item={item} theme={theme} />
						</SelectBoxStyled>
						{!props?.compact && <SignTextStyled>{item.text}</SignTextStyled>}
					</ItemContainerStyled>
				))}
		</MainContainerStyled>
	)
}
export default SquareOptions
