import React from 'react'
import { styled } from '@mui/material/styles'
import { useTheme } from '@mui/material/styles'
import Grid from '@mui/material/Grid2'

import Tile from '../../molecules/tile/Tile'
import Label from '../../atoms/label/Label'
// import SearchHeader from '../../organisms/searchHeader/SearchHeader'

const LabelStyled = styled(Label)(({ theme }) => ({
	color: '#000000',
	//fontFamily: 'OpenSans',
	fontSize: '18px',
	lineHeight: '22px',
	textAlign: 'left',
	display: 'block',
	fontWeight: 'bold',
	paddingTop: '3%'
}))

const FixContainerStyled = styled('div')(({ theme }) => ({
	paddingTop: theme.spacing(2),
	paddingLeft: theme.spacing(2),
	textAlign: 'center',
	color: theme.palette.text.secondary,
	width: 217,
	[theme.breakpoints.down('sm')]: {
		width: '50%'
	}
}))

const DataContainerStyled = styled('div')(({ theme }) => ({
	paddingTop: theme.spacing(2),
	paddingLeft: theme.spacing(2),
	textAlign: 'center',
	color: theme.palette.text.secondary,
	width: 217,
	[theme.breakpoints.down('sm')]: {
		width: '50%'
	}
}))

const AppsTemplate = (props) => {
	const theme = useTheme()

	return (
		<>
			{/* <SearchHeader /> */}
			{/* <Grid container>
				<Grid container size={{ md: 10 }} spacing={3} style={{ paddingTop: '3%' }}> TODO*/}

			{props?.title && <LabelStyled text={props.title} />}
			<Grid container spacing={2} style={{ paddingTop: 20 }}>
				{!!props?.fixData?.length &&
					props.fixData.map((item, index) => (
						<FixContainerStyled key={`fix${index}`}>
							<Tile
								data={item}
								linkUrl={props?.linkUrl}
								deleteUrl={props?.deleteUrl}
							/>
						</FixContainerStyled>
					))}
				{!!props?.data?.length &&
					props.data.map((item, index) => (
						<DataContainerStyled key={index}>
							<Tile
								data={item}
								isInteractive={props.isInteractive}
								linkUrl={props?.linkUrl}
								deleteUrl={props?.deleteUrl}
								reload={props?.reload}
							/>
						</DataContainerStyled>
					))}
			</Grid>
		</>
	)
}

export default AppsTemplate
