import React, { useState, useEffect } from 'react'
import { styled } from '@mui/material/styles'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'

const AccordionBox = styled((props) => (
	<MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
	border: 'none',
	borderRadius: 8,
	backgroundColor: 'transparent',
	'&.Mui-expanded': {
		backgroundColor: 'transparent',
		// boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.17)'
		boxShadow: 'none'
	},
	'& .MuiAccordionSummary-root': {
		// padding: '20px 20px 20px 30px',
		padding: 0,
		minHeight: 40,
		[theme.breakpoints.down('sm')]: {
			// padding: '10px 10px 10px 20px'
			padding: 0
		}
	},
	'& .MuiAccordionDetails-root': {
		// padding: '0 30px 30px 30px',
		padding: 0,
		[theme.breakpoints.down('sm')]: {
			// padding: '0 20px 20px 20px'
			padding: 0
		}
	},
	'&:before': {
		display: 'none'
	}
}))

const AccordionSummary = styled((props) => (
	<MuiAccordionSummary
		expandIcon={
			<KeyboardArrowDownIcon style={{ color: '#464646', fontSize: 22 }} />
		}
		{...props}
	/>
))(({ theme }) => ({
	'& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
		transform: 'rotate(180deg)'
	},
	'& .MuiAccordionSummary-content': {
		margin: 0
	}
}))

const AccordionSummaryTitle = styled(Typography)((props) => ({
	fontSize: 11,
	fontWeight: 'bold',
	color: props?.color ?? props?.theme.custom.colors.inverse
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
	padding: theme.spacing(2),
	backgroundColor: 'transparent',
	borderRadius: '0 0 8px 8px'
}))

const Accordion = (props) => {
	const [expanded, setExpanded] = useState(false)

	const handleChange = () => (event, newExpanded) => {
		setExpanded(newExpanded ? true : false)
	}

	useEffect(() => {
		if (typeof props?.expanded == 'boolean') {
			//TODO:expanded prop for parent control
			setExpanded(props.expanded)
		}
	}, [props.expanded])

	return (
		<AccordionBox expanded={expanded} onChange={handleChange()}>
			<AccordionSummary>
				<div
					style={{
						display: 'flex',
						cursor: 'pointer',
						alignItems: 'center'
					}}
				>
					{!!props?.icon && (
						<props.icon
							sx={{
								fontSize: 14,
								marginRight: 6
							}}
						/>
					)}
					<AccordionSummaryTitle>{props.title}</AccordionSummaryTitle>
				</div>
			</AccordionSummary>
			<AccordionDetails>{props.children}</AccordionDetails>
		</AccordionBox>
	)
}

export default Accordion
