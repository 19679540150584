import React, { useState, useEffect, useRef } from 'react'
import { useTheme } from '@mui/material/styles'
import Grid from '@mui/material/Grid2'
import update from 'immutability-helper'
import { useTranslation } from 'react-i18next'

import AppPageBox from '../../../atoms/appPageBox/AppPageBox'
import DoubleStateMentions from '../../../molecules/doubleStateField/DoubleStateMentions'
import Accordion from '../../../atoms/accordion/Accordion'
import { default as Label } from '../../../atoms/settingLabel/SettingLabel'
import ActionList from '../../actionList/ActionList'
import VisibilityOptions from '../../../molecules/visibilityOptions/VisibilityOptions'
import SelectBox from '../../../molecules/selectBox/SelectBox'

import getEntityFieldsByDataTypes from '../../../../methods/getEntityFieldsByDataTypes'
import fieldDataTypes from '../../../../common/fieldDataTypes'
import relationTypes from '../../../../common/enums/relationTypes'

import styledUI from '../../../../styledUI'

const Barcode = (props) => {
	const { t } = useTranslation()
	const theme = useTheme()
	const propsRef = useRef()
	propsRef.current = props

	const [elementName, setElementName] = useState(null)

	const textFieldsWithLookupAndRel = getEntityFieldsByDataTypes(
		props?.entities,
		props.activeScreen.data?.source,
		fieldDataTypes.textBased,
		null,
		true,
		relationTypes.one
	)
	const textOptions = textFieldsWithLookupAndRel?.length
		? textFieldsWithLookupAndRel
		: []
	const textOptionsWithEmpty = [{ label: '-', name: '' }, ...textOptions]

	/****TEXT***************************/
	const [isTextTextMode, setIsTextTextMode] = useState(false)
	const [textField, setTextField] = useState('')
	const [textDefault, setTextDefault] = useState('')

	const textChange = (data) => {
		if (isTextTextMode) {
			submitText(data)
			setTextDefault(data)
		} else {
			submitText(data?.value)
			setTextField(data?.value)
		}
	}

	const submitText = (x) => {
		const selectedField = textFieldsWithLookupAndRel?.find((y) => y.name == x)
		const modifiedElementData = update(propsRef.current.elementData, {
			mapping: {
				text: {
					field: { $set: isTextTextMode ? null : x },
					default: { $set: isTextTextMode ? x : null },
					type: { $set: isTextTextMode ? null : selectedField?.dataType }
				}
			}
		})
		propsRef.current.updateSelectedElement(modifiedElementData)
	}

	/****BARCODE_TYPE***************************/
	const barcodeTypeOptions = [
		{
			label: 'Code 39',
			value: 'CODE39'
		},
		{
			label: 'Code 128',
			value: 'CODE128'
		},
		{
			label: 'Code 128 A',
			value: 'CODE128A'
		},
		{
			label: 'Code 128 B',
			value: 'CODE128B'
		},
		{
			label: 'Code 128 C',
			value: 'CODE128C'
		},
		{
			label: 'EAN 13',
			value: 'EAN13'
		},
		{
			label: 'EAN 8',
			value: 'EAN8'
		},
		{
			label: 'UPC',
			value: 'UPC'
		},
		{
			label: 'ITF 14',
			value: 'ITF14'
		},
		{
			label: 'Codabar',
			value: 'codabar'
		}
	]

	const [barcodeType, setBarcodeType] = useState('')

	const barcodeTypeChange = (e) => {
		setBarcodeType(e.target.value)
		submitBarcodeType(e.target.value)
	}

	const submitBarcodeType = (x) => {
		const modifiedElementData = update(props.elementData, {
			type: { $set: x }
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****ACTIONS***************************/
	const updateActions = (newActions) => {
		const modifiedElementData = update(props.elementData, {
			actions: { $set: newActions }
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****INITIALIZATION***************************/
	const initStates = (elementData) => {
		/****TEXT***************************/
		elementData?.mapping?.text?.field ||
		elementData?.mapping?.text?.field === ''
			? setIsTextTextMode(false)
			: setIsTextTextMode(true)

		setTextField(elementData?.mapping?.text?.field || '')
		setTextDefault(elementData?.mapping?.text?.default || '')
		setBarcodeType(elementData?.type)

		setElementName(elementData?._uid)
	}

	useEffect(() => {
		props?.elementData && initStates(props.elementData)
	}, [props.elementData])

	return (
		<>
			<AppPageBox>
				<Accordion
					title={t('screens.elementSettings.common.titles.settings')}
					expanded={true}
				>
					<styledUI.StyledGrid container alignItems='center'>
						<Grid size={{ xs: 4 }}>
							<Label>{t('screens.elementSettings.common.labels.text')}</Label>
						</Grid>
						<Grid size={{ xs: 8 }}>
							<DoubleStateMentions
								name={`${elementName}`}
								multiline={false}
								fullWidth={true}
								isTextMode={isTextTextMode}
								setIsTextMode={setIsTextTextMode}
								valChange={textChange}
								textVal={textDefault}
								autoCompVal={textField}
								autoCompOptions={textOptionsWithEmpty}
								valueKey='name'
								labelKey='label'
							/>
						</Grid>
					</styledUI.StyledGrid>
					<styledUI.StyledGrid container alignItems='center'>
						<Grid size={{ xs: 4 }}>
							<Label>{t('screens.elementSettings.common.labels.type')}</Label>
						</Grid>
						<Grid size={{ xs: 8 }}>
							<SelectBox
								sx={styledUI.Style.selectBox(props, theme)}
								onChange={barcodeTypeChange}
								value={barcodeType}
								data={barcodeTypeOptions}
								textKey='label'
								valueKey='value'
							/>
						</Grid>
					</styledUI.StyledGrid>
				</Accordion>
			</AppPageBox>
			<AppPageBox>
				<Accordion
					title={t('screens.elementSettings.common.titles.actions')}
					expanded={true}
				>
					<ActionList
						name={elementName}
						entities={props.entities}
						activeScreen={props.activeScreen}
						actions={props.elementData.actions}
						updateActions={updateActions}
					/>
				</Accordion>
			</AppPageBox>

			<VisibilityOptions
				block={props.elementData}
				activeScreen={props.activeScreen}
				entities={props.entities}
				updateBlock={props.updateSelectedElement}
			/>
		</>
	)
}

export default Barcode
