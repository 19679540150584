import React from 'react'
import { useTheme } from '@mui/material/styles'
import Grid from '@mui/material/Grid2'
import { SwapVert, FilterAltOutlined, PlusOne } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'

import AccordionItem from '../../atoms/accordionItem/AccordionItem'
import { default as Label } from '../../atoms/settingLabel/SettingLabel'
import KozmikTextField from '../../atoms/kozmikTextField/KozmikTextField'
import SquareOptions from '../squareOprions/SquareOptions'
import NumericInput from '../../atoms/numericInput/NumericInput'
import { default as Checkbox } from '../../atoms/kozmikCheckBox/KozmikCheckBox'
import SelectBox from '../selectBox/SelectBox'
import Sorting from '../sorting/Sorting'
import FilterList from '../filterList/FilterList'

import getEntityFieldsByDataTypes from '../../../methods/getEntityFieldsByDataTypes'
import fieldDataTypes from '../../../common/fieldDataTypes'

import styledUI from '../../../styledUI'

const style = {
	item: {
		width: '100%',
		display: 'flex',
		alignItems: 'center'
	},
	labelBox: {
		flexGrow: 1,
		width: 80,
		minWidth: 80
	}
}

const FormElementListItem = (props) => {
	const { t } = useTranslation()
	const theme = useTheme()

	const {
		field,
		element,
		activeScreen,
		index,
		elementTitleChange,
		elementLabelChange,
		elementPlaceholderChange,
		elementRequiredChange,
		elementPrecisionChange,
		elementMaxValChange,
		elementMinValChange,
		elementLimitChange,
		elementCheckBoxTypeChange,
		elementScannerChange,
		elementEnableRecurrenceChange,
		elementAllowMultipleChange,
		elementEnableRepeatChange,
		elementTypeChange,
		choiceTypeOptions,
		checkboxTypeOptions,
		languageCode,
		entities,
		elementRelationFieldChange,
		sorting,
		filtering,
		...restProps
	} = props

	const relatedEntity = entities?.find(
		(x) => x.name === element?.relation?.source
	)

	const relatedTextFields = getEntityFieldsByDataTypes(
		entities,
		relatedEntity?.name,
		fieldDataTypes.textBased
	)

	const titleChange = (e) => {
		elementTitleChange(index, e.target.value)
	}

	const labelChange = (e) => {
		elementLabelChange(index, e.target.value)
	}

	const typeChange = (value) => {
		elementTypeChange(index, value)
	}

	const placeholderChange = (e) => {
		elementPlaceholderChange(index, e.target.value)
	}

	const requiredChange = (e) => {
		elementRequiredChange(index, e.target.checked)
	}

	const precisionChange = (value) => {
		elementPrecisionChange(index, value)
	}

	const scannerChange = (e) => {
		elementScannerChange(index, e.target.checked)
	}

	const enableRecurrenceChange = (e) => {
		elementEnableRecurrenceChange(index, e.target.checked)
	}

	const allowMultipleChange = (e) => {
		elementAllowMultipleChange(index, e.target.checked)
	}

	const enableRepeatChange = (e) => {
		elementEnableRepeatChange(index, e.target.checked)
	}

	const maxValChange = (value) => {
		elementMaxValChange(index, value)
	}

	const minValChange = (value) => {
		elementMinValChange(index, value)
	}

	const limitChange = (value) => {
		elementLimitChange(index, value)
	}

	const checkboxTypeChange = (val) => {
		elementCheckBoxTypeChange(index, val)
	}

	const relationFieldChange = (e) => {
		elementRelationFieldChange(index, e.target.value)
	}

	return (
		<>
			<styledUI.StyledGrid container alignItems='center'>
				<Grid size={{ xs: 4 }}>
					<Label>{t('screens.elementSettings.form.element.title')}</Label>
				</Grid>
				<Grid size={{ xs: 8 }}>
					<KozmikTextField
						fullWidth
						bgColor='#ffffff'
						value={element?.title}
						onChange={titleChange}
					/>
				</Grid>
			</styledUI.StyledGrid>
			{element?.component === 'inputCheckbox' && (
				<>
					<styledUI.StyledGrid container alignItems='center'>
						<Grid size={{ xs: 4 }}>
							<Label>{t('screens.elementSettings.form.element.label')}</Label>
						</Grid>
						<Grid size={{ xs: 8 }}>
							<KozmikTextField
								fullWidth
								bgColor='#ffffff'
								value={element?.label?.default}
								onChange={labelChange}
							/>
						</Grid>
					</styledUI.StyledGrid>
					<styledUI.StyledGrid container alignItems='center'>
						<Grid size={{ xs: 4 }}>
							<Label>
								{t('screens.elementSettings.form.element.checkboxType')}
							</Label>
						</Grid>
						<Grid size={{ xs: 8 }}>
							<SquareOptions
								compact
								boxColor='#ffffff'
								data={checkboxTypeOptions}
								getChange={checkboxTypeChange}
								activeValue={element?.type}
							/>
						</Grid>
					</styledUI.StyledGrid>
				</>
			)}
			{element?.component != 'inputCheckbox' && (
				<styledUI.StyledGrid container alignItems='center'>
					<Grid size={{ xs: 4 }}>
						<Label>
							{t('screens.elementSettings.form.element.placeholder')}
						</Label>
					</Grid>
					<Grid size={{ xs: 8 }}>
						<KozmikTextField
							fullWidth
							bgColor='#ffffff'
							value={element?.placeholder?.default}
							onChange={placeholderChange}
						/>
					</Grid>
				</styledUI.StyledGrid>
			)}
			{element?.component == 'inputMoney' && (
				<styledUI.StyledGrid container alignItems='center'>
					<Grid size={{ xs: 4 }}>
						<Label>{t('screens.elementSettings.form.element.precision')}</Label>
					</Grid>
					<Grid size={{ xs: 8 }}>
						<NumericInput
							maxVal={6}
							bgColor='#ffffff'
							disabled={false}
							value={element?.precision ?? ''}
							onChange={precisionChange}
						/>
					</Grid>
				</styledUI.StyledGrid>
			)}
			{(element?.component == 'inputMoney' ||
				element?.component == 'inputNumeric') && (
				<>
					<styledUI.StyledGrid container alignItems='center'>
						<Grid size={{ xs: 4 }}>
							<Label>{t('screens.elementSettings.form.element.maxVal')}</Label>
						</Grid>
						<Grid size={{ xs: 8 }}>
							<NumericInput
								bgColor='#ffffff'
								disabled={false}
								value={element?.maxVal ?? ''}
								onChange={maxValChange}
							/>
						</Grid>
					</styledUI.StyledGrid>
					<styledUI.StyledGrid container alignItems='center'>
						<Grid size={{ xs: 4 }}>
							<Label>{t('screens.elementSettings.form.element.minVal')}</Label>
						</Grid>
						<Grid size={{ xs: 8 }}>
							<NumericInput
								bgColor='#ffffff'
								disabled={false}
								value={element?.minVal ?? ''}
								onChange={minValChange}
							/>
						</Grid>
					</styledUI.StyledGrid>
				</>
			)}
			{element?.component == 'inputChoice' && (
				<>
					<styledUI.StyledGrid container alignItems='center'>
						<Grid size={{ xs: 4 }}>
							<Label>
								{t('screens.elementSettings.form.element.relationField')}
							</Label>
						</Grid>
						<Grid size={{ xs: 8 }}>
							<SelectBox
								sx={styledUI.Style.selectBox(props, theme)}
								onChange={relationFieldChange}
								value={element?.relation?.field}
								data={relatedTextFields}
								textKey='label'
								valueKey='name'
							/>
						</Grid>
					</styledUI.StyledGrid>
					<styledUI.StyledGrid container alignItems='center'>
						<Grid size={{ xs: 4 }}>
							<Label>
								{t('screens.elementSettings.form.element.choiceStyle')}
							</Label>
						</Grid>
						<Grid size={{ xs: 8 }}>
							<SquareOptions
								compact
								data={choiceTypeOptions}
								getChange={typeChange}
								activeValue={element?.type}
							/>
						</Grid>
					</styledUI.StyledGrid>
					{element?.relation?.type === 'Many' && (
						<>
							<styledUI.StyledGrid container alignItems='center'>
								<div style={{ ...style.item }}>
									<div>
										<Checkbox
											checked={element?.allowMultiple ?? true}
											onChange={allowMultipleChange}
										/>
									</div>
									<div style={{ ...style.labelBox }}>
										<Label>
											{t(
												'screens.elementSettings.form.element.allowMultipleSelection'
											)}
										</Label>
									</div>
								</div>
							</styledUI.StyledGrid>
							<styledUI.StyledGrid container alignItems='center'>
								<div style={{ ...style.item }}>
									<div>
										<Checkbox
											checked={element?.enableRepeat ?? false}
											onChange={enableRepeatChange}
										/>
									</div>
									<div style={{ ...style.labelBox }}>
										<Label>
											{t('screens.elementSettings.form.element.enableRepeat')}
										</Label>
									</div>
								</div>
							</styledUI.StyledGrid>
						</>
					)}
				</>
			)}

			{element?.component != 'inputCheckbox' && (
				<styledUI.StyledGrid container alignItems='center'>
					<div style={{ ...style.item }}>
						<div>
							<Checkbox
								checked={element?.required?.status}
								onChange={requiredChange}
							/>
						</div>
						<div style={{ ...style.labelBox }}>
							<Label>
								{t('screens.elementSettings.form.element.required')}
							</Label>
						</div>
					</div>
				</styledUI.StyledGrid>
			)}
			{(element?.component == 'inputMail' ||
				element?.component == 'inputMoney' ||
				element?.component == 'inputNumeric' ||
				element?.component == 'inputPhone' ||
				element?.component == 'inputText') && (
				<styledUI.StyledGrid container alignItems='center'>
					<div style={{ ...style.item }}>
						<div>
							<Checkbox checked={element?.scanner} onChange={scannerChange} />
						</div>
						<div style={{ ...style.labelBox }}>
							<Label>{t('screens.elementSettings.form.element.scanner')}</Label>
						</div>
					</div>
				</styledUI.StyledGrid>
			)}
			{element?.component == 'datePicker' && (
				<styledUI.StyledGrid container alignItems='center'>
					<div style={{ ...style.item }}>
						<div>
							<Checkbox
								checked={element?.enableRecurrence}
								onChange={enableRecurrenceChange}
							/>
						</div>
						<div style={{ ...style.labelBox }}>
							<Label>
								{t('screens.elementSettings.form.element.enableRecurrence')}
							</Label>
						</div>
					</div>
				</styledUI.StyledGrid>
			)}
			{element?.component == 'inputChoice' && (
				<>
					<styledUI.StyledDiv>
						<AccordionItem
							icon={FilterAltOutlined}
							title={t('screens.elementSettings.form.element.filter')}
							expanded={false}
							padding={8}
							bgColor='#ffffff'
						>
							<FilterList
								index={index}
								filter={element?.filters}
								table={relatedEntity}
								filtering={filtering}
								//TODO
								activeScreen={activeScreen}
								entities={entities}
								formSource={props.source}
							/>
						</AccordionItem>
					</styledUI.StyledDiv>
					<styledUI.StyledDiv>
						<AccordionItem
							icon={SwapVert}
							title={t('screens.elementSettings.form.element.sort')}
							expanded={false}
							padding={8}
							bgColor='#ffffff'
						>
							<Sorting
								index={index}
								sortingData={element?.sorting}
								sorting={sorting}
								fieldOptions={relatedTextFields}
							/>
						</AccordionItem>
					</styledUI.StyledDiv>
					<styledUI.StyledDiv>
						<AccordionItem
							icon={PlusOne}
							title={t('screens.elementSettings.form.element.limit')}
							expanded={false}
							padding={8}
							bgColor='#ffffff'
						>
							<NumericInput
								minVal={0}
								value={element?.limit ?? ''}
								onChange={limitChange}
							/>
						</AccordionItem>
					</styledUI.StyledDiv>
				</>
			)}
		</>
	)
}

export default FormElementListItem
