import React from 'react'
import { styled } from '@mui/material/styles'
import Radio from '@mui/material/Radio'

const RadioStyled = styled((props) => (
	<Radio
		checked={props?.checked}
		onClick={props?.onClick}
		disableRipple
		color='default'
		checkedIcon={<CheckedIconStyled {...props} />}
		icon={<IconStyled {...props} />}
		sx={{
			padding: '4px !important'
		}}
	/>
))(({ theme }) => ({
	'&:hover': {
		backgroundColor: 'transparent'
	}
}))

const CustomIconStyled = styled((props) => {
	return <props.icon {...props} />
})((props) => ({}))
const CustomSpanStyled = styled('span')((props) => ({}))

const IconStyled = styled((props) => {
	return props?.icon ? (
		<CustomIconStyled {...props} />
	) : (
		<CustomSpanStyled {...props} />
	)
})((props) => ({
	borderRadius: 6,
	width: 26,
	height: 26,
	display: 'inherit',
	backgroundColor: props?.radioColor ? props.radioColor : '#ffffff',
	backgroundImage: props?.imgUrl ? `url("${props.imgUrl}")` : null,
	// boxShadow: '0 0 2px gray inset',
	backgroundSize: 'cover',

	'$root.Mui-focusVisible &': {
		outline: '2px auto rgba(19,124,189,.6)',
		outlineOffset: 2
	},
	'input:hover ~ &': {
		//backgroundColor: '#ebf1f5'
	},
	'input:disabled ~ &': {
		boxShadow: 'none',
		background: 'rgba(206,217,224,.5)'
	}
}))

const CheckedIconStyled = styled(IconStyled)(({ theme }) => ({
	border: `2px solid ${theme.custom.colors.kozmikBlue}`
}))

const RadioButton = (props) => {
	return <RadioStyled {...props} />
}

export default RadioButton
