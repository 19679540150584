import { ApiClient } from './client'

import { default as appConfig } from '../config/app.json'

const apiEndpoint = `${appConfig.endpoints.api}/api/data`

export default {
	async searchData(appId, entityName, params = null) {
		let url = `/${entityName}`
		let headers = {}
		let data = params ? { params: params } : null
		headers['AppId'] = appId

		return await new ApiClient(apiEndpoint, headers).get(url, data)
	},
	async detailData(appId, entityName, rowId) {
		let url = `/${entityName}/${rowId}`
		let headers = {}
		headers['AppId'] = appId

		return await new ApiClient(apiEndpoint, headers).get(url)
	},
	async postSearchData(appId, entityName, data = null) {
		let url = `/${entityName}/search`
		let headers = {}
		headers['AppId'] = appId

		return await new ApiClient(apiEndpoint, headers).post(url, data)
	},
	async insertData(appId, entityName, data = null, headers = {}) {
		let url = `/${entityName}`
		headers['Content-Type'] = 'application/json'
		headers['AppId'] = appId

		return await new ApiClient(apiEndpoint, headers).post(url, data)
	},
	async updateData(appId, entityName, data = null, headers = {}) {
		let url = `/${entityName}`
		headers['Content-Type'] = 'application/json'
		headers['AppId'] = appId

		return await new ApiClient(apiEndpoint, headers).patch(url, data)
	},
	async bulkDeleteData(appId, entityName, data = null, headers = {}) {
		let url = `/${entityName}/bulk`
		headers['Content-Type'] = 'application/json'
		headers['AppId'] = appId

		var params = new URLSearchParams()
		data?.map((item, index) => {
			params.append('records', item)
		})

		return await new ApiClient(apiEndpoint, headers).delete(url, {
			params: params
		})
	}
}
