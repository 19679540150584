import React, { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'
import update from 'immutability-helper'
import Grid from '@mui/material/Grid2'

import KozmikTextField from '../../atoms/kozmikTextField/KozmikTextField'

import timeoutDelay from '../../../methods/timeoutDelay'
import { default as Checkbox } from '../../atoms/kozmikCheckBox/KozmikCheckBox'
import { default as Label } from '../../atoms/settingLabel/SettingLabel'

import styledUI from '../../../styledUI'

const SearchOptions = (props) => {
	const { t } = useTranslation()

	/****SEARCHEABLE***************************/
	const [searcheable, setSearcheable] = useState(false)

	const searcheableChange = (e) => {
		setSearcheable(e.target.checked)
		submitSearcheable(e.target.checked)
	}

	const submitSearcheable = (x) => {
		const modifiedElementData = update(props.elementData, {
			searcheable: { $set: x }
		})

		props.updateSelectedElement(modifiedElementData)
		// props.updateElementSearcheable(modifiedElementData)
	}

	/****SEARCH_PLACEHOLDER***************************/
	const [searchPlaceholder, setSearchPlaceholder] = useState('')

	const searchPlaceholderChange = (e) => {
		timeoutDelay(submitSearchPlaceholder, e.target.value, 1000)
		setSearchPlaceholder(e.target.value)
	}

	const submitSearchPlaceholder = (x) => {
		const modifiedElementData = update(props.elementData, {
			searchPlaceholder: {
				default: { $set: x }
			}
		})

		props.updateSelectedElement(modifiedElementData)
	}

	/****INITIALIZATION***************************/
	const initStates = (elementData) => {
		/****SEARCHEABLE***************************/
		setSearcheable(!!props?.elementData?.searcheable)
		/****SEARCH_PLACEHOLDER***************************/
		setSearchPlaceholder(
			props?.elementData?.searchPlaceholder?.default
				? props?.elementData?.searchPlaceholder?.default
				: ''
		)
	}

	useEffect(() => {
		if (props?.elementData) {
			initStates(props.elementData)
		}
	}, [props.elementData])

	return (
		<div>
			<styledUI.StyledGrid container alignItems='center'>
				<styledUI.StyledItemContainer>
					<div>
						<Checkbox checked={searcheable} onChange={searcheableChange} />
					</div>
					<styledUI.StyledLabel>
						<Label>
							{t('screens.elementSettings.common.search.searcheable')}
						</Label>
					</styledUI.StyledLabel>
				</styledUI.StyledItemContainer>
			</styledUI.StyledGrid>
			<styledUI.StyledGrid container alignItems='center'>
				<Grid size={{ xs: 4 }}>
					<Label>
						{t('screens.elementSettings.common.search.placeholder')}
					</Label>
				</Grid>
				<Grid size={{ xs: 8 }}>
					<KozmikTextField
						fullWidth
						placeholder={t(
							'screens.elementSettings.common.search.placeholderEx'
						)}
						value={searchPlaceholder}
						onChange={searchPlaceholderChange}
					/>
				</Grid>
			</styledUI.StyledGrid>
		</div>
	)
}

export default SearchOptions
