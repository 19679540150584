import { ApiClient } from './client'

import { default as appConfig } from '../config/app.json'

const apiEndpoint = `${appConfig.endpoints.api}/api/description`

export default {
	async getAPIDocument(appId, tableName) {
		let url = `/${tableName}`
		let headers = {}
		headers['AppId'] = appId

		return await new ApiClient(apiEndpoint, headers).get(url)
	}
}
