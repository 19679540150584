import React, { useState, useEffect } from 'react'
import { useTheme } from '@mui/material/styles'
import { Delete } from '@mui/icons-material'
import Grid from '@mui/material/Grid2'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import SelectBox from '../selectBox/SelectBox'
import TextButton from '../../atoms/button/TextButton'
import ButtonOptions from '../buttonOptions/ButtonOptions'
import DoubleStateField from '../doubleStateField/DoubleStateField'

import getFilterOpByDataType from '../../../methods/getFilterOpByDataType'
import validateWithRegExp from '../../../methods/validateWithRegExp'
import timeoutDelay from '../../../methods/timeoutDelay'
import getRegExpByDataType from '../../../methods/getRegExpByDataType'
import getCurrentTableField from '../../../methods/getCurrentTableField'
import getFieldDataType from '../../../methods/getFieldDataType'
import showToastResponseError from '../../../methods/showToastResponseError'

import formulas from '../../../common/formulas'
import systemFieldNames from '../../../common/systemFieldNames/systemFieldNames'

import dataClient from '../../../services/dataClient'

const style = {
	input: (props, theme) => {
		return {
			borderRadius: '3px !important',
			backgroundColor: '#ffffff',
			fontSize: 14,
			color: theme.custom.colors.textPrimary,
			'&.MuiOutlinedInput-root': {
				'& fieldset': {
					border: 0
				},
				'&:hover fieldset': {
					border: 0
				},
				'&.Mui-focused fieldset': {
					border: 0
				}
			}
		}
	},
	seperator: (props, theme) => {
		return {
			marginTop: 10,
			marginBottom: 10,
			height: 1,
			backgroundColor: `${theme.custom.colors.gray}`
		}
	},
	mb15: {
		marginBottom: 15
	},
	mb8: {
		marginBottom: 8
	}
}

const FilterListItem = (props) => {
	const { t } = useTranslation()
	const theme = useTheme()
	let { appId } = useParams()

	// const culture = localStorage.getItem('i18nextLng')
	// const languageCode = culture === 'tr-TR' ? 'tr' : 'en'
	const languageCode = 'en'

	const {
		currentRule,
		ruleIndex,
		isLast,
		filter,
		table,
		filtering,
		setLocalElementData,
		ruleGroupOpOpts,
		index,
		feature,
		activeScreen,
		formSource,
		...restProps
	} = props

	const getTableFieldByName = (name, table) => {
		return table.fields.find((x) => x.name == name)
	}

	const ruleFields =
		table.fields
			?.map((f) => f)
			?.sort(function (a, b) {
				return a.label.localeCompare(b.label)
			}) ?? []
	const ruleData = table.fields.find((x) => x.name == currentRule?.field)

	const ruleGroupOpChange = (val) => {
		let updatedElement
		if (index === undefined) {
			updatedElement = filtering.updateGroupOp(val)
		} else {
			updatedElement = filtering.updateGroupOp(
				val,
				index,
				feature === undefined ? null : feature
			)
		}

		setLocalElementData && setLocalElementData(updatedElement)
	}

	/****RULE_DATA***************************/
	const [filterRuleDataType, setFilterRuleDataType] = useState(null)
	const [isRuleDataSecondaryMode, setIsRuleDataSecondaryMode] = useState(false)
	const [ruleDataField, setRuleDataField] = useState('')
	const [ruleDataDefault, setRuleDataDefault] = useState('')
	const [ruleDataDefaultValid, setRuleDataDefaultValid] = useState(true)
	const [ruleDataRegExp, setRuleDataRegExp] = useState(null)
	const [ruleDataErrorText, setRuleDataErrorText] = useState('')

	const [relationTableData, setRelationTableData] = useState([])
	const [ruleDataRelation, setRuleDataRelation] = useState([])

	const unconditionalRuleOps = ['nu', 'nn', 'rnu', 'rnn']
	const conditional = !unconditionalRuleOps.includes(currentRule?.op)

	const getFormulaFields = (ruleData) => {
		let filteredFormulaFields = []
		let filterOps = null

		if (ruleData?.dataType === 'Lookup' || ruleData?.dataType === 'Rollup') {
			filterOps = getFilterOpByDataType(ruleData?.displayFieldDataType)
		} else if (ruleData?.dataType === 'Formula') {
			filterOps = getFilterOpByDataType(ruleData?.formula?.formulaFieldDataType)
		} else {
			filterOps = getFilterOpByDataType(ruleData?.dataType)
		}

		if (filterOps?.type) {
			if (filterOps?.type === 'datetime') {
				filteredFormulaFields = formulas.datetime
			} else if (filterOps?.type === 'string') {
				filteredFormulaFields = [...formulas.string, ...formulas.profile]
			} else if (filterOps?.type === 'relation') {
				filteredFormulaFields = formulas.onlyProfile
			}
		}

		return filteredFormulaFields
	}

	const filterTableFieldsByRuleField = (
		ruleData,
		tableFields,
		addFormulaFields = true
	) => {
		if (ruleData?.dataType) {
			let filteredFields = []
			let filteredFormulaFields = getFormulaFields(ruleData)

			if (ruleData?.dataType === 'Relation') {
				filteredFields = tableFields?.filter(
					(x) =>
						x.dataType === 'RowId' ||
						(x.dataType === ruleData?.dataType &&
							// x.relationType === ruleData?.relationType &&
							x.relatedEntityName === ruleData?.relatedEntityName)
				)
			} else if (
				ruleData?.dataType === 'Lookup' ||
				ruleData?.dataType === 'Rollup'
			) {
				const allowedDataTypes = getFilterOpByDataType(
					ruleData?.displayFieldDataType
				)?.dataTypes

				filteredFields = tableFields?.filter((x) =>
					allowedDataTypes?.includes(x.dataType)
				)
			} else if (ruleData?.dataType === 'Formula') {
				const allowedDataTypes = getFilterOpByDataType(
					ruleData?.formula?.formulaFieldDataType
				)?.dataTypes

				filteredFields = tableFields?.filter((x) =>
					allowedDataTypes?.includes(x.dataType)
				)
			} else {
				const allowedDataTypes = getFilterOpByDataType(
					ruleData?.dataType
				)?.dataTypes

				filteredFields = tableFields?.filter((x) =>
					allowedDataTypes?.includes(x.dataType)
				)
			}

			const sortedFields = filteredFields?.sort(function (a, b) {
				return a.label.localeCompare(b.label)
			})

			if (addFormulaFields) {
				const sortedFormulaFields = filteredFormulaFields?.sort(function (
					a,
					b
				) {
					return a.label.localeCompare(b.label)
				})

				return sortedFields?.concat(sortedFormulaFields)
			}

			return sortedFields
		} else {
			return []
		}
	}

	const getScreenFieldsOptions = (screenSource) => {
		if (screenSource) {
			const screenFields = props?.entities?.find(
				(x) => x.name == screenSource
			)?.fields

			const filteredScreenFields = filterTableFieldsByRuleField(
				ruleData,
				screenFields,
				false
			)
			const formattedScreenFields =
				filteredScreenFields?.map((x) => ({
					label: `Screen.${x.label}`,
					name: `Screen.${x.name}`
				})) ?? []

			return formattedScreenFields?.sort(function (a, b) {
				return a.label.localeCompare(b.label)
			})
		} else {
			return []
		}
	}

	const getFormFieldsOptions = (formSource) => {
		if (formSource) {
			const formFields = props?.entities?.find(
				(x) => x.name == formSource
			)?.fields

			const filteredFormFields = filterTableFieldsByRuleField(
				ruleData,
				formFields,
				false
			)

			const formattedFormFields =
				filteredFormFields
					?.filter((x) => !systemFieldNames.notVisible.includes(x.name))
					?.map((x) => ({
						label: `Form.${x.label}`,
						name: `Form.${x.name}`
					})) ?? []

			return formattedFormFields?.sort(function (a, b) {
				return a.label.localeCompare(b.label)
			})
		} else {
			return []
		}
	}

	const fieldOptions = filterTableFieldsByRuleField(ruleData, table.fields)

	const screenFieldsOptions = getScreenFieldsOptions(activeScreen?.data?.source)

	const formFieldsOptions = getFormFieldsOptions(formSource)

	const fieldOptionsWithScreenFields = [
		...fieldOptions,
		...screenFieldsOptions,
		...formFieldsOptions
	]

	const getRelationTableData = async (field) => {
		if (!field) return

		setRelationTableData([])

		const parameters = {
			sort: 'Created desc',
			fields: 'Id,' + (field?.displayFieldName ?? 'Name')
		}

		return dataClient
			.searchData(appId, field.relatedEntityName, parameters)
			.then((response) => {
				if (response?.data) {
					setRelationTableData(response.data.records)
				} else {
					showToastResponseError(response)
				}
			})
	}

	const getFormattedRelationRuleDataById = (field, idArray) => {
		const filteredData = relationTableData.filter((x) => idArray.includes(x.Id))

		return filteredData?.length
			? filteredData.map((item) => ({
					Id: item?.Id,
					Name: item?.[field.displayFieldName ?? 'Name']
			  }))
			: []
	}

	const ruleDataChange = (e) => {
		if (filterRuleDataType === 'string' || filterRuleDataType === 'numeric') {
			if (isRuleDataSecondaryMode) {
				validateWithRegExp(e.target.value, ruleDataRegExp).then((isValid) => {
					setRuleDataDefaultValid(isValid)
					if (isValid) {
						timeoutDelay(submitRuleData, e.target.value, 1000)
					}
				})

				setRuleDataDefault(e.target.value)
				setRuleDataField('')
			} else {
				submitRuleData(e.value)
				setRuleDataField(e.value)
				setRuleDataDefault('')
			}
			setRuleDataRelation([])
		} else if (filterRuleDataType === 'boolean') {
			if (isRuleDataSecondaryMode) {
				const vl = typeof e.target.value === 'boolean' ? e.target.value : null

				submitRuleData(vl)
				setRuleDataDefault('')
				setRuleDataField('')
			} else {
				submitRuleData(e.value)
				setRuleDataField(e.value)
				setRuleDataDefault('')
			}
			setRuleDataRelation([])
		} else if (filterRuleDataType === 'datetime') {
			if (isRuleDataSecondaryMode) {
				submitRuleData(e)
				setRuleDataDefault(e)
				setRuleDataField('')
			} else {
				submitRuleData(e.value)
				setRuleDataField(e.value)
				setRuleDataDefault('')
			}
			setRuleDataRelation([])
		} else if (filterRuleDataType === 'relation') {
			if (isRuleDataSecondaryMode) {
				const idArray =
					typeof e.target.value === 'string'
						? e.target.value.split(',')
						: e.target.value
				const dataForSubmit = getFormattedRelationRuleDataById(
					ruleDataField,
					idArray
				)

				submitRuleData(dataForSubmit, true)
				setRuleDataRelation(dataForSubmit?.length ? dataForSubmit : [])
				// setRuleDataField('')
				setRuleDataField(currentRule?.field)
				setRuleDataDefault('')
			} else {
				submitRuleData(e.value)
				setRuleDataField(e.value)
				setRuleDataDefault('')
				setRuleDataRelation([])
			}
		}
	}

	const submitRuleData = (data, isRelation = false) => {
		if (index === undefined) {
			filtering.updateRuleTypeAndData(
				ruleIndex,
				data,
				isRelation ? 'Relation' : isRuleDataSecondaryMode ? 'Data' : 'auto'
			)
		} else {
			filtering.updateRuleTypeAndData(
				ruleIndex,
				data,
				isRelation ? 'Relation' : isRuleDataSecondaryMode ? 'Data' : 'auto',
				index,
				feature === undefined ? null : feature
			)
		}
	}

	const initRuleDataRegExp = (dataType, ruleOp = null) => {
		const proceedWithCondition = (condition) => {
			const dataTypeRegExObj = getRegExpByDataType(dataType, languageCode)
			if (condition) {
				if (dataTypeRegExObj) {
					setRuleDataRegExp(dataTypeRegExObj.re)
					setRuleDataErrorText(dataTypeRegExObj.msg)
					return dataTypeRegExObj.re
				} else {
					setRuleDataRegExp(null)
					setRuleDataErrorText('')
					return null
				}
			} else {
				setRuleDataRegExp(null) //accept all
				return null
			}
		}

		switch (dataType) {
			// case 'AutoNumber':
			case 'Number':
				return proceedWithCondition(true)
			case 'Checkbox':
			case 'Date':
				return proceedWithCondition(false)
			default:
				return proceedWithCondition(
					ruleOp === null || ruleOp == 'eq' || ruleOp == 'ne'
				)
		}
	}

	const changeRuleDataMode = (isSecondaryMode, value) => {
		if (isSecondaryMode) {
			validateWithRegExp(value, ruleDataRegExp).then((isValid) => {
				setRuleDataDefaultValid(isValid)
			})
		}
	}

	const getSelectedFieldDataType = (field) => {
		const fieldDataType =
			field?.dataType === 'Relation'
				? field?.dataType
				: field?.formula?.formulaFieldDataType ??
				  field?.displayFieldDataType ??
				  field?.dataType

		return fieldDataType
	}

	/****RULE_FIELD***************************/

	const filterFieldChange = (e) => {
		const selectedField = getTableFieldByName(e.target.value, table)
		const selectedFieldDataType = getSelectedFieldDataType(selectedField)

		initRuleDataRegExp(selectedFieldDataType)

		const filterOp = getFilterOpByDataType(selectedFieldDataType)

		const fieldPrimitiveType = filterOp?.type

		setFilterRuleDataType(fieldPrimitiveType)

		const updatedElement = filtering.updateRuleField(
			ruleIndex,
			selectedFieldDataType,
			e.target.value,
			filterOp?.searchOp,
			index === undefined ? null : index,
			feature === undefined ? null : feature,
			undefined,
			null
		)

		setLocalElementData && setLocalElementData(updatedElement)
	}

	/****RULE_OP***************************/
	const filterOpChange = (e) => {
		const selectedField = getTableFieldByName(currentRule?.field, table)
		const selectedFieldDataType = getSelectedFieldDataType(selectedField)

		const regExp = initRuleDataRegExp(selectedFieldDataType, e.target.value)

		validateWithRegExp(currentRule?.data, regExp).then((isValid) => {
			setRuleDataDefaultValid(isValid)
		})

		let updatedElement
		if (index === undefined) {
			updatedElement = filtering.updateRuleOp(ruleIndex, e.target.value)
		} else {
			updatedElement = filtering.updateRuleOp(
				ruleIndex,
				e.target.value,
				index,
				feature === undefined ? null : feature
			)
		}
		setLocalElementData && setLocalElementData(updatedElement)
	}

	/****DELETE_RULE***************************/
	const deleteRule = () => {
		if (index === undefined) {
			filtering.deleteRule(ruleIndex)
		} else {
			filtering.deleteRule(
				ruleIndex,
				index,
				feature === undefined ? null : feature
			)
		}
	}

	/****INITIALIZATION***************************/
	const initStates = (currentRule) => {
		const selectedField = getTableFieldByName(currentRule?.field, table)
		/****RULE_DATA***************************/
		const fieldPrimitiveType = getFieldDataType(
			currentRule?.field,
			table.fields
		)

		setFilterRuleDataType(fieldPrimitiveType)

		const isSecondaryMode = !(
			currentRule?.type == 'Screen' ||
			currentRule?.type == 'Form' ||
			currentRule?.type == 'Field' ||
			currentRule?.type == 'Variables'
		)
		const ruleDataText =
			typeof currentRule?.data == 'boolean'
				? currentRule.data
				: currentRule?.data || ''

		setIsRuleDataSecondaryMode(isSecondaryMode)

		const regExp = initRuleDataRegExp(selectedField?.dataType, currentRule?.op)
		if (fieldPrimitiveType === 'string' || fieldPrimitiveType === 'numeric') {
			if (isSecondaryMode) {
				setRuleDataDefault(ruleDataText)
				setRuleDataField('')
				validateWithRegExp(ruleDataText, regExp).then((isValid) => {
					setRuleDataDefaultValid(isValid)
				})
			} else {
				setRuleDataField(currentRule?.data || '')
				setRuleDataDefault('')
			}
			setRuleDataRelation([])
		} else if (fieldPrimitiveType === 'boolean') {
			if (isSecondaryMode) {
				setRuleDataDefault(ruleDataText)
				setRuleDataField('')
			} else {
				setRuleDataField(currentRule?.data || '')
				setRuleDataDefault('')
			}
			setRuleDataRelation([])
		} else if (fieldPrimitiveType === 'datetime') {
			if (isSecondaryMode) {
				setRuleDataDefault(ruleDataText)
				setRuleDataField('')
			} else {
				setRuleDataField(currentRule?.data || '')
				setRuleDataDefault('')
			}
			setRuleDataRelation([])
		} else if (fieldPrimitiveType === 'relation') {
			if (isSecondaryMode) {
				setRuleDataRelation(currentRule?.data?.length ? currentRule.data : [])
				setRuleDataField(currentRule?.field)
				setRuleDataDefault('')
			} else {
				setRuleDataRelation([])
				setRuleDataField(currentRule?.data || '')
				setRuleDataDefault('')
			}
		}
	}

	useEffect(() => {
		if (currentRule) {
			initStates(currentRule)
		}
	}, [currentRule])

	return (
		<div {...restProps}>
			<Grid
				container
				alignItems='center'
				justifyContent='flex-end'
				style={{ ...style.mb8 }}
				spacing={1}
			>
				<Grid size={{ xs: conditional ? 7 : 12, lg: conditional ? 7 : 5 }}>
					<SelectBox
						sx={{ ...style.input(props, theme) }}
						onChange={filterFieldChange}
						value={currentRule?.field}
						data={ruleFields}
						textKey='label'
						valueKey='name'
					/>
				</Grid>
				<Grid size={{ xs: conditional ? 5 : 10, lg: conditional ? 5 : 5 }}>
					<SelectBox
						sx={{ ...style.input(props, theme) }}
						onChange={filterOpChange}
						value={currentRule?.op}
						data={
							getFilterOpByDataType(
								ruleData?.dataType === 'Lookup' && ruleData?.relationType
									? ruleData?.relationType === 'One'
										? ruleData?.displayFieldDataType
											? ruleData?.displayFieldDataType
											: ruleData?.dataType
										: ruleData?.dataType
									: ruleData?.dataType === 'Relation'
									? ruleData?.dataType
									: ruleData?.formula?.formulaFieldDataType ??
									  ruleData?.displayFieldDataType ??
									  ruleData?.dataType
							)?.operators
						}
						textKey='label'
						valueKey='value'
					/>
				</Grid>
				{!conditional && (
					<Grid size={{ xs: 2, lg: 2 }}>
						<TextButton onClick={deleteRule} icon={Delete} iconSize={20} />
					</Grid>
				)}
			</Grid>

			{conditional && (
				<Grid
					container
					alignItems='center'
					style={{ ...style.mb8 }}
					spacing={1}
				>
					<Grid size={{ xs: 10, lg: 10 }}>
						<DoubleStateField
							appId={appId}
							languageCode={languageCode}
							fieldOptionsWithScreenFields={fieldOptionsWithScreenFields}
							dataType={filterRuleDataType}
							fieldValue={ruleDataField}
							fieldDataType={() => {
								const currentField = getCurrentTableField(
									currentRule?.field,
									table.fields
								)

								return getSelectedFieldDataType(currentField)
							}}
							fieldDataChange={ruleDataChange}
							entityField={getCurrentTableField(
								currentRule?.field,
								table.fields
							)}
							isSecondaryMode={isRuleDataSecondaryMode}
							setIsSecondaryMode={setIsRuleDataSecondaryMode}
							callback={changeRuleDataMode}
							dataDefault={
								filterRuleDataType === 'relation'
									? ruleDataRelation
									: ruleDataDefault
							}
							dataDefaultValid={!ruleDataDefaultValid}
							dataErrorText={ruleDataErrorText}
							relationTableData={relationTableData}
							refreshRelation={getRelationTableData}
						></DoubleStateField>
					</Grid>
					<Grid size={{ xs: 2, lg: 2 }}>
						<TextButton onClick={deleteRule} icon={Delete} iconSize={20} />
					</Grid>
				</Grid>
			)}

			{ruleIndex == 0 && !isLast ? (
				<Grid container alignItems='center' style={{ ...style.mb8 }}>
					<ButtonOptions
						boxColor='#ffffff'
						padding={8}
						data={ruleGroupOpOpts}
						getChange={ruleGroupOpChange}
						activeValue={filter?.groupOp}
					/>
				</Grid>
			) : (
				!isLast && <div style={{ ...style.seperator(props, theme) }}></div>
			)}
		</div>
	)
}

export default FilterListItem
