import React from 'react'
import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'

const BoxStyled = styled(Box)(({ theme }) => ({
	backgroundColor: '#ffffff',
	borderRadius: 8,
	display: 'flex',
	boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.03)'
}))

const PageBox = (props) => {
	return <BoxStyled {...props}></BoxStyled>
}

export default PageBox
