import React from 'react'
import { useTheme } from '@mui/material/styles'
import { Link, useLocation } from 'react-router-dom'
import { Box, Typography } from '@mui/material'

const style = {
	toolbar: {
		width: '100px',
		textAlign: 'left'
	},
	justifyContent: {
		justifyContent: 'space-between'
	},
	count: {
		fontSize: '22px',
		lineHeight: '17px',
		textAlign: 'right'
	},
	flex: {
		display: 'flex'
	},
	marginTop: {
		marginTop: '8px'
	},
	root: (props, theme) => {
		return {
			'& > span': {
				margin: theme.spacing(2)
			}
		}
	},
	active: (props, theme) => {
		return {
			color: theme.custom.colors.kozmikBlue,
			backgroundColor: theme.custom.colors.kozmikLightBlue,
			textDecoration: 'none !important',
			fontWeight: 'bolder',
			borderRadius: 4
		}
	},
	passive: (props, theme) => {
		return {
			color: theme.custom.colors.textPrimary,
			textDecoration: 'none !important'
		}
	},
	text: {
		paddingLeft: '7px',
		fontSize: '14px',
		fontWeight: 600,
		lineHeight: '24px',
		width: '122px',
		textAlign: 'left'
	}
}

const MenuItem = (props) => {
	const theme = useTheme()
	let currentPage = useLocation().pathname

	return (
		<Link to={props.element.Href} onClick={props?.collapseHamburger}>
			<div
				style={{
					...style.root(props, theme),
					...style.marginTop,
					...(props.element.Href == currentPage
						? style.active(props, theme)
						: style.passive(props, theme))
				}}
			>
				<Box
					display='flex'
					alignItems='center'
					justifyContent='space-between'
					height='40px'
					style={{ padding: '0 10px', overflow: 'hidden' }}
				>
					<Box display='flex' alignItems='center'>
						{props.element?.icon && (
							<props.element.icon style={{ fontSize: 18 }} />
						)}

						<Typography sx={{ ...style.toolbar, ...style.text }}>
							{props.element.Name}
						</Typography>

						{props.element.SubText ? (
							<Typography>{props.element.SubText}</Typography>
						) : null}
					</Box>
					{props.element.Href == currentPage ? (
						<Typography style={{ fontWeight: 600, fontSize: 14 }}>
							{props.element.Count}
						</Typography>
					) : null}
				</Box>
			</div>
		</Link>
	)
}

export default MenuItem
