import React, { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import Grid from '@mui/material/Grid2'
import { Typography } from '@mui/material'

import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'

import MediaPicker from '../../atoms/mediaPicker/MediaPicker'
import AppPageBoxTitle from '../../atoms/appPageBox/AppPageBoxTitle'
import AppPageBox from '../../atoms/appPageBox/AppPageBox'
import KozmikTextField from '../../atoms/kozmikTextField/KozmikTextField'

import appClient from '../../../services/appClient'
import { setDetail } from '../../../redux/actions/appDataActions'

import timeoutDelay from '../../../methods/timeoutDelay'
import showToastResponseError from '../../../methods/showToastResponseError'
import postMessageToPreview from '../../../methods/postMessageToPreview'

const PageTitleStyled = styled(Typography)(({ theme }) => ({
	padding: '10px 15px',
	minHeight: 45,
	color: '#000000',
	fontSize: 14,
	lineHeight: '22px',
	textAlign: 'left',
	fontWeight: 'bold',
	borderBottom: `1px solid ${theme.custom.colors.gray}`
}))

const ImageTextStyled = styled(Typography)(({ theme }) => ({
	paddingTop: 10,
	fontSize: 12,
	color: '#000000'
}))

const RightContainerStyled = styled('div')(({ theme }) => ({
	backgroundColor: '#ffffff'
}))

const AppInformationTemplate = (props) => {
	const { t } = useTranslation()
	let { appId } = useParams()

	const [appName, setAppName] = useState('')
	const [icon, setIcon] = useState(null)
	const [logo, setLogo] = useState(null)

	const initialization = () => {
		if (props.appDetail?.Icon) {
			setIcon(props.appDetail.Icon)
		}
		if (props.appDetail?.Logo) {
			setLogo(props.appDetail.Logo)
		}
		if (props.appDetail?.Name) {
			setAppName(props.appDetail.Name)
		}
	}

	const triggerPropPostMessage = (objectName, propName, value) => {
		const postData = {
			source: 'kozmik-builder',
			updatedProp: {
				objectName: objectName,
				propName: propName,
				value: value
			}
		}

		postMessageToPreview(postData)
	}

	const postData = (
		propName,
		propData,
		configobjectName = null,
		configPropName = null
	) => {
		if (appId) {
			let updateData = {
				Id: appId
			}
			updateData[propName] = propData

			appClient
				.update(updateData)
				.then((response) => {
					if (response.status == 200) {
						updateReduxDetailData(propName, propData)
						if (configobjectName && configPropName) {
							triggerPropPostMessage(configobjectName, configPropName, propData)
						}
					} else {
						showToastResponseError(response)
						initialization()
					}
				})
				.catch((error) => {
					initialization()
				})
		}
	}

	const updateReduxDetailData = (propName, propData) => {
		let tempData = { ...props.appDetail }
		tempData[propName] = propData
		props.setDetail(tempData)
	}

	/****ICON***************************/
	const postIcon = (base64Image) => {
		postData('Icon', base64Image)
	}

	/****LOGO***************************/
	const postLogo = (base64Image) => {
		postData('Logo', base64Image)
	}

	/****APP_NAME***************************/
	const changeAppName = (e) => {
		timeoutDelay(submitAppName, e.target.value, 1000)
		setAppName(e.target.value)
	}

	const submitAppName = (name) => {
		postData('Name', name)
	}

	/****INITIALIZATION***************************/
	useEffect(() => {
		props.appDetail && initialization()
	}, [props.appDetail])

	return (
		<RightContainerStyled>
			<PageTitleStyled>{t('appInfoTemp.title')}</PageTitleStyled>
			<AppPageBox>
				<AppPageBoxTitle
					text={t('appInfoTemp.appName')}
					style={{ fontWeight: 600, marginBottom: 10 }}
				/>
				<Grid container>
					<KozmikTextField
						fullWidth
						placeholder={t('appInfoTemp.appNamePlaceholder')}
						value={appName}
						onChange={changeAppName}
					/>
				</Grid>
			</AppPageBox>
			<AppPageBox>
				<AppPageBoxTitle
					text={t('appInfoTemp.icon')}
					style={{ fontWeight: 600, marginBottom: 10 }}
				/>
				<Grid
					container
					flexDirection={'column'}
					alignItems={'center'}
					justifyContent={'center'}
				>
					<MediaPicker
						setImage={setIcon}
						image={icon}
						postData={postIcon}
						accept={'image/png'}
						checkDimensions={{ width: 1024, height: 1024 }}
					/>
					<ImageTextStyled>1024 x 1024 pixels</ImageTextStyled>
				</Grid>
			</AppPageBox>
			<AppPageBox>
				<AppPageBoxTitle
					text={t('appInfoTemp.logo')}
					style={{ fontWeight: 600, marginBottom: 10 }}
				/>
				<Grid
					container
					flexDirection={'column'}
					alignItems={'center'}
					justifyContent={'center'}
				>
					<MediaPicker
						setImage={setLogo}
						image={logo}
						postData={postLogo}
						accept={'image/png'}
					/>
				</Grid>
			</AppPageBox>
		</RightContainerStyled>
	)
}

const mapStateToProps = (state) => ({
	...state.appData
})

const mapDispatchToProps = (dispatch) => {
	return {
		setDetail: (x) => dispatch(setDetail(x))
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AppInformationTemplate)
