import React, { useState, useEffect, useRef } from 'react'
import { styled } from '@mui/material/styles'
import { useTheme } from '@mui/material/styles'
import Grid from '@mui/material/Grid2'
import update from 'immutability-helper'
import { useTranslation } from 'react-i18next'
import { ToggleOnOutlined, CheckBoxOutlined } from '@mui/icons-material'

import AppPageBox from '../../../atoms/appPageBox/AppPageBox'
import DoubleStateMentions from '../../../molecules/doubleStateField/DoubleStateMentions'
import Accordion from '../../../atoms/accordion/Accordion'
import { default as Label } from '../../../atoms/settingLabel/SettingLabel'
import SquareOptions from '../../../molecules/squareOprions/SquareOptions'
import SelectBox from '../../../molecules/selectBox/SelectBox'
import VisibilityOptions from '../../../molecules/visibilityOptions/VisibilityOptions'

import getEntityFieldsByDataTypes from '../../../../methods/getEntityFieldsByDataTypes'
import fieldDataTypes from '../../../../common/fieldDataTypes'
import relationTypes from '../../../../common/enums/relationTypes'

import styledUI from '../../../../styledUI'

const Checkbox = (props) => {
	const { t } = useTranslation()
	const theme = useTheme()
	const propsRef = useRef()
	propsRef.current = props

	const [elementName, setElementName] = useState(null)

	const boolFields = getEntityFieldsByDataTypes(
		props?.entities,
		props.activeScreen.data?.source,
		['Checkbox']
	)

	const textFields = getEntityFieldsByDataTypes(
		props?.entities,
		props.activeScreen.data?.source,
		fieldDataTypes.textBased,
		null,
		true,
		relationTypes.one
	)
	const textOptions = textFields?.length ? textFields : []
	const textOptionsWithEmpty = [{ label: '-', name: '' }, ...textOptions]

	const typeOptions = [
		{
			text: t('screens.elementSettings.checkbox.typeOpts.check'),
			value: 'checkbox',
			icon: CheckBoxOutlined
		},
		{
			text: t('screens.elementSettings.checkbox.typeOpts.switch'),
			value: 'switch',
			icon: ToggleOnOutlined
		}
	]

	/****FIELD***************************/
	const [field, setField] = useState('')

	const fieldChange = (e) => {
		submitField(e.target.value)
		setField(e.target.value)
	}

	const submitField = (x) => {
		const selectedField = boolFields?.find((y) => y.name == x)

		const modifiedElementData = update(props.elementData, {
			mapping: {
				field: {
					field: { $set: x },
					type: { $set: selectedField?.dataType }
				}
			}
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****TITLE***************************/
	const [isTitleTextMode, setIsTitleTextMode] = useState(false)
	const [titleField, setTitleField] = useState('')
	const [titleDefault, setTitleDefault] = useState('')

	const titleChange = (data) => {
		if (isTitleTextMode) {
			submitTitle(data)
			setTitleDefault(data)
		} else {
			submitTitle(data?.value)
			setTitleField(data?.value)
		}
	}
	const submitTitle = (x) => {
		const selectedField = textFields?.find((y) => y.name == x)
		const modifiedElementData = update(propsRef.current.elementData, {
			mapping: {
				title: {
					field: { $set: isTitleTextMode ? null : x },
					default: { $set: isTitleTextMode ? x : null },
					type: { $set: isTitleTextMode ? null : selectedField?.dataType }
				}
			}
		})
		propsRef.current.updateSelectedElement(modifiedElementData)
	}

	/****LABEL***************************/
	const [isLabelTextMode, setIsLabelTextMode] = useState(false)
	const [labelField, setLabelField] = useState('')
	const [labelDefault, setLabelDefault] = useState('')

	const labelChange = (data) => {
		if (isLabelTextMode) {
			submitLabel(data)
			setLabelDefault(data)
		} else {
			submitLabel(data?.value)
			setLabelField(data?.value)
		}
	}
	const submitLabel = (x) => {
		const selectedField = textFields?.find((y) => y.name == x)
		const modifiedElementData = update(propsRef.current.elementData, {
			mapping: {
				label: {
					field: { $set: isLabelTextMode ? null : x },
					default: { $set: isLabelTextMode ? x : null },
					type: { $set: isLabelTextMode ? null : selectedField?.dataType }
				}
			}
		})
		propsRef.current.updateSelectedElement(modifiedElementData)
	}

	/****TYPE***************************/
	const [type, setType] = useState(null)

	const typeChange = (val) => {
		submitType(val)
		setType(val)
	}

	const submitType = (x) => {
		const modifiedElementData = update(props.elementData, {
			type: { $set: x }
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****INITIALIZATION***************************/
	const initStates = (elementData) => {
		/****FIELD***************************/
		setField(elementData?.mapping?.field?.field || '')
		/****TITLE***************************/
		elementData?.mapping?.title?.field ||
		elementData?.mapping?.title?.field === ''
			? setIsTitleTextMode(false)
			: setIsTitleTextMode(true)

		setTitleField(elementData?.mapping?.title?.field || '')
		setTitleDefault(elementData?.mapping?.title?.default || '')
		/****LABEL***************************/
		elementData?.mapping?.label?.field ||
		elementData?.mapping?.label?.field === ''
			? setIsLabelTextMode(false)
			: setIsLabelTextMode(true)

		setLabelField(elementData?.mapping?.label?.field || '')
		setLabelDefault(elementData?.mapping?.label?.default || '')
		/****TYPE***************************/
		setType(elementData?.type || null)

		setElementName(elementData?._uid)
	}

	useEffect(() => {
		props?.elementData && initStates(props.elementData)
	}, [props.elementData])

	return (
		<>
			<AppPageBox>
				<Accordion
					title={t('screens.elementSettings.common.titles.content')}
					expanded={true}
				>
					<styledUI.StyledGrid container alignItems='center'>
						<Grid size={{ xs: 4 }}>
							<Label>{t('screens.elementSettings.checkbox.field')}</Label>
						</Grid>
						<Grid size={{ xs: 8 }}>
							<SelectBox
								sx={styledUI.Style.selectBox(props, theme)}
								emptyText={t('screens.elementSettings.checkbox.emptyField')}
								onChange={fieldChange}
								value={field}
								data={boolFields}
								textKey='label'
								valueKey='name'
							/>
						</Grid>
					</styledUI.StyledGrid>
				</Accordion>
			</AppPageBox>
			<AppPageBox>
				<Accordion
					title={t('screens.elementSettings.common.titles.design')}
					expanded={true}
				>
					<styledUI.StyledGrid container alignItems='center'>
						<Grid size={{ xs: 4 }}>
							<Label>{t('screens.elementSettings.checkbox.title')}</Label>
						</Grid>
						<Grid size={{ xs: 8 }}>
							<DoubleStateMentions
								name={`${elementName}-title`}
								fullWidth={true}
								isTextMode={isTitleTextMode}
								setIsTextMode={setIsTitleTextMode}
								valChange={titleChange}
								multiline={false}
								textVal={titleDefault}
								autoCompVal={titleField}
								autoCompOptions={textOptionsWithEmpty}
								valueKey='name'
								labelKey='label'
							/>
						</Grid>
					</styledUI.StyledGrid>
					<styledUI.StyledGrid container alignItems='center'>
						<Grid size={{ xs: 4 }}>
							<Label>{t('screens.elementSettings.checkbox.label')}</Label>
						</Grid>
						<Grid size={{ xs: 8 }}>
							<DoubleStateMentions
								name={`${elementName}-label`}
								fullWidth={true}
								isTextMode={isLabelTextMode}
								setIsTextMode={setIsLabelTextMode}
								valChange={labelChange}
								multiline={false}
								textVal={labelDefault}
								autoCompVal={labelField}
								autoCompOptions={textOptionsWithEmpty}
								valueKey='name'
								labelKey='label'
							/>
						</Grid>
					</styledUI.StyledGrid>
					<styledUI.StyledGrid container alignItems='center'>
						<Grid size={{ xs: 4 }}>
							<Label>{t('screens.elementSettings.checkbox.type')}</Label>
						</Grid>
						<Grid size={{ xs: 8 }}>
							<SquareOptions
								compact
								data={typeOptions}
								getChange={typeChange}
								activeValue={type}
							/>
						</Grid>
					</styledUI.StyledGrid>
				</Accordion>
			</AppPageBox>

			<VisibilityOptions
				block={props.elementData}
				activeScreen={props.activeScreen}
				entities={props.entities}
				updateBlock={props.updateSelectedElement}
			/>
		</>
	)
}

export default Checkbox
