import React from 'react'
import { styled } from '@mui/material/styles'
import { Box, Typography } from '@mui/material'

const PopoverButtonStyled = styled(Box)(({ theme }) => ({
	cursor: 'pointer',
	borderRadius: 4,
	height: 30,
	'&:hover': {
		backgroundColor: theme.custom.colors.gray
	},
	padding: '0 5px'
}))

const PopoverButton = (props) => {
	return (
		<PopoverButtonStyled
			display='flex'
			alignItems='center'
			justifyContent='start'
			{...props}
		>
			{props?.children}
			<Typography
				style={{
					paddingLeft: 5,
					fontSize: '12px',
					fontWeight: 600,
					color: props?.color ?? '#000000'
				}}
			>
				{props?.text}{' '}
			</Typography>
		</PopoverButtonStyled>
	)
}

export default PopoverButton
