import { styled } from '@mui/material/styles'
import React, { useEffect, useState, useRef } from 'react'
import { CardMedia, Typography } from '@mui/material'
import Grid from '@mui/material/Grid2'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import IconButton from '@mui/material/IconButton'
import { Close } from '@mui/icons-material'

import IntegrationSettings from '../../organisms/integrationSettings/IntegrationSettings'

import appClient from '../../../services/appClient'
import { setDetail } from '../../../redux/actions/appDataActions'

import { default as KozmikTablesAPI } from '../../../assets/icons/Integrations/KozmikTablesAPI.svg'
import { default as GoogleMaps } from '../../../assets/icons/Integrations/GoogleMaps.svg'
import { default as CallToAPI } from '../../../assets/icons/Integrations/CallToAPI.svg'
import { default as OpenAI } from '../../../assets/icons/Integrations/OpenAI.svg'
import { default as MSSQL } from '../../../assets/icons/Integrations/MSSQL.svg'
import { default as MySQL } from '../../../assets/icons/Integrations/MySQL.svg'
import { default as PostgreSQL } from '../../../assets/icons/Integrations/PostgreSQL.svg'
import { default as PushNotifications } from '../../../assets/icons/Integrations/PushNotifications.svg'
import { default as PlayStore } from '../../../assets/icons/Integrations/PlayStore.svg'
import { default as AppStore } from '../../../assets/icons/Integrations/AppStore.svg'
import { default as CustomDomain } from '../../../assets/icons/Integrations/CustomDomain.svg'

import styledUI from '../../../styledUI'

const MainContainerStyled = styled('div')(({ theme }) => ({
	width: '100%',
	padding: '20px 40px 20px 20px',
	[theme.breakpoints.down('md')]: {
		padding: 20
	},
	[theme.breakpoints.up('md')]: {
		height: '100vh',
		overflow: 'auto'
	}
}))

const GroupsStyled = styled(Grid)(({ theme }) => ({
	marginTop: 10,
	marginBottom: 30
}))

const BoxContainerStyled = styled(Grid)(({ theme }) => ({
	paddingLeft: 10,
	paddingRight: 10,
	paddingBottom: 20
}))

const GroupStyled = styled(Typography)((props) => ({
	...{
		marginLeft: 10,
		padding: '7px 20px',
		fontSize: 14,
		lineHeight: '22px',
		fontWeight: '600',
		textAlign: 'center',
		cursor: 'pointer',
		color: props.theme.custom.colors.darkGray,
		border: `1px solid transparent`,
		[props.theme.breakpoints.down('md')]: {
			padding: '7px 10px'
		}
	},
	...(props.group === props.name && {
		color: '#000000',
		backgroundColor: '#FFFFFF',
		border: `1px solid ${props.theme.custom.colors.gray}`,
		borderRadius: '4px'
	})
}))

const BoxStyled = styled(Grid)(({ theme }) => ({
	position: 'relative',
	backgroundColor: '#FFFFFF',
	borderRadius: '16px',
	padding: 25,
	'&:hover': {
		boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.17)'
	},
	cursor: 'pointer'
}))

const BoxCloseStyled = styled(IconButton)(({ theme }) => ({
	position: 'absolute',
	backgroundColor: '#9A9CAD',
	color: '#FFFFFF',
	borderRadius: '8px',
	fontSize: 28,
	top: 25,
	right: 25,
	'&:hover': {
		backgroundColor: '#9A9CAD'
	}
}))

const BoxIconStyled = styled(CardMedia)(({ theme }) => ({
	width: 'auto',
	height: 50
}))

const BoxTitleStyled = styled(Typography)(({ theme }) => ({
	height: 20,
	fontSize: 16,
	fontWeight: 600
}))

const BoxSubTitleStyled = styled(Typography)(({ theme }) => ({
	height: 60,
	fontSize: 14,
	overflow: 'hidden'
}))

const style = {
	badge: {
		fontSize: 9,
		fontWeight: 'bold',
		borderRadius: 7.5,
		padding: '2px 8px',
		position: 'absolute',
		top: 25,
		right: 25
	},
	activeBadgePosition: {
		top: 25,
		left: 100,
		right: 'unset'
	},
	backgroundGradient: {
		backgroundImage:
			'linear-gradient(90deg, #96f1ce 50%, rgba(27,54,254,0.3) 100%)'
	}
}

const AppIntegrationsTemplate = (props) => {
	const { t } = useTranslation()
	let { appId } = useParams()

	const containerRef = useRef(null)

	const integrations = [
		{
			name: 'kozmikTablesAPI',
			icon: KozmikTablesAPI,
			title: t('appIntegrationsTemp.integrations.kozmikTablesAPI.title'),
			description: t(
				'appIntegrationsTemp.integrations.kozmikTablesAPI.description'
			),
			badge: null,
			groups: ['all', 'data'],
			plans: ['business']
		},
		{
			name: 'googleMaps',
			icon: GoogleMaps,
			title: t('appIntegrationsTemp.integrations.googleMaps.title'),
			description: t('appIntegrationsTemp.integrations.googleMaps.description'),
			badge: null,
			groups: ['all', 'communication'],
			plans: ['pro', 'business']
		},
		{
			name: 'appStore',
			icon: AppStore,
			title: t('appIntegrationsTemp.integrations.appStore.title'),
			description: t('appIntegrationsTemp.integrations.appStore.description'),
			badge: null,
			groups: ['all', 'store'],
			plans: ['pro', 'business']
		},
		{
			name: 'playStore',
			icon: PlayStore,
			title: t('appIntegrationsTemp.integrations.playStore.title'),
			description: t('appIntegrationsTemp.integrations.playStore.description'),
			badge: null,
			groups: ['all', 'store'],
			plans: ['pro', 'business']
		},
		{
			name: 'webPush',
			icon: PushNotifications,
			title: t('appIntegrationsTemp.integrations.webPush.title'),
			description: t('appIntegrationsTemp.integrations.webPush.description'),
			badge: null,
			groups: ['all'],
			plans: ['pro', 'business']
		},
		{
			name: 'customDomain',
			icon: CustomDomain,
			title: t('appIntegrationsTemp.integrations.customDomain.title'),
			description: t(
				'appIntegrationsTemp.integrations.customDomain.description'
			),
			badge: null,
			groups: ['all'],
			plans: ['starter', 'pro', 'business']
		},
		{
			name: 'callToAPI',
			icon: CallToAPI,
			title: t('appIntegrationsTemp.integrations.callToAPI.title'),
			description: t('appIntegrationsTemp.integrations.callToAPI.description'),
			badge: {
				// 	background: '#78FFAA',
				background: '#F8D169',
				color: '#000000',
				text: t('appIntegrationsTemp.badges.soon')
			},
			groups: ['all', 'data', 'communication', 'flow'],
			plans: ['business'],
			disabled: true
		},
		{
			name: 'openAI',
			icon: OpenAI,
			title: t('appIntegrationsTemp.integrations.openAI.title'),
			description: t('appIntegrationsTemp.integrations.openAI.description'),
			badge: {
				background: '#F8D169',
				color: '#000000',
				text: t('appIntegrationsTemp.badges.soon')
			},
			groups: ['all', 'data', 'communication'],
			plans: ['business'],
			disabled: true
		}
	]

	const [integration, setIntegration] = useState(null)

	const integrationDetail = integrations.find((x) => x.name === integration)

	const groups = [
		{
			name: 'all',
			title: t('appIntegrationsTemp.groups.all')
		},
		{
			name: 'data',
			title: t('appIntegrationsTemp.groups.data')
		},
		{
			name: 'communication',
			title: t('appIntegrationsTemp.groups.communication')
		},
		{
			name: 'flow',
			title: t('appIntegrationsTemp.groups.flow')
		},
		{
			name: 'store',
			title: t('appIntegrationsTemp.groups.store')
		}
	]

	const [group, setGroup] = useState('all')

	const filteredIntegrations = group
		? integrations.filter((x) => x.groups.includes(group))
		: integrations

	const initialization = () => {}

	/****INITIALIZATION***************************/
	useEffect(() => {
		props.appDetail && initialization()
	}, [props.appDetail])

	return (
		<MainContainerStyled>
			<Grid container ref={containerRef}>
				<GroupsStyled container size={{ xs: 12 }}>
					{groups.map((i, index) => (
						<GroupStyled
							group={group}
							name={i.name}
							key={index}
							onClick={() => {
								setGroup(i.name)
								setIntegration(null)
							}}
						>
							{i.title}
						</GroupStyled>
					))}
				</GroupsStyled>

				<Grid container size={{ xs: 12 }}>
					{integrationDetail && (
						<BoxContainerStyled container size={{ xs: 12, lg: 12 }}>
							<BoxStyled container size={{ xs: 12 }}>
								<Grid container size={{ xs: 12, lg: 12 }}>
									<Grid size={{ xs: 12 }} style={{ marginBottom: 20 }}>
										<BoxIconStyled
											image={integrationDetail.icon}
											component='img'
										/>
										<BoxCloseStyled onClick={() => setIntegration(null)}>
											<Close />
										</BoxCloseStyled>

										{integrationDetail.badge && (
											<span
												style={{
													...style.badge,
													...style.activeBadgePosition,
													...{
														backgroundColor:
															integrationDetail.badge?.background,
														color: integrationDetail.badge?.color
													}
												}}
											>
												{integrationDetail.badge?.text}
											</span>
										)}
									</Grid>
									<styledUI.StyledGrid size={{ xs: 12 }}>
										<BoxTitleStyled>{integrationDetail.title}</BoxTitleStyled>
									</styledUI.StyledGrid>
									<Grid size={{ xs: 12 }}>
										<BoxSubTitleStyled>
											{integrationDetail.description}
										</BoxSubTitleStyled>
									</Grid>
								</Grid>
								<Grid size={{ xs: 12, lg: 6 }}>
									<IntegrationSettings integration={integration} />
								</Grid>
							</BoxStyled>
						</BoxContainerStyled>
					)}

					{filteredIntegrations.map((integration, index) => {
						const authority = integration.plans.some((x) =>
							props?.currentOrg?.plan?.includes(x)
						)

						return (
							<BoxContainerStyled
								key={index}
								container
								size={{ xs: 12, sm: 6, md: 4, lg: 3 }}
							>
								<BoxStyled
									container
									onClick={() => {
										if (integration.disabled) return

										if (authority) {
											setIntegration(integration.name)
											containerRef.current &&
												containerRef.current.scrollIntoView({
													behavior: 'smooth'
												})
										} else {
											window.location.href = '/subscription?modal=true'
										}
									}}
								>
									<Grid size={{ xs: 12 }} style={{ marginBottom: 20 }}>
										<BoxIconStyled image={integration.icon} component='img' />

										{!authority ? (
											<span
												style={{
													...style.badge,
													...style.backgroundGradient
												}}
											>
												{t('appIntegrationsTemp.upgrade')}
											</span>
										) : (
											integration.badge && (
												<span
													style={{
														...style.badge,
														...{
															backgroundColor: integration.badge?.background,
															color: integration.badge?.color
														}
													}}
												>
													{integration.badge?.text}
												</span>
											)
										)}
									</Grid>
									<styledUI.StyledGrid size={{ xs: 12 }}>
										<BoxTitleStyled>{integration.title}</BoxTitleStyled>
									</styledUI.StyledGrid>
									<Grid size={{ xs: 12 }}>
										<BoxSubTitleStyled>
											{integration.description}
										</BoxSubTitleStyled>
									</Grid>
								</BoxStyled>
							</BoxContainerStyled>
						)
					})}
				</Grid>
			</Grid>
		</MainContainerStyled>
	)
}

const mapStateToProps = (state) => ({
	...state.appData,
	...state.userInfo,
	...state.currentOrg
})

const mapDispatchToProps = (dispatch) => {
	return {
		setDetail: (x) => dispatch(setDetail(x))
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AppIntegrationsTemplate)
