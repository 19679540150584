import React from 'react'
import { styled } from '@mui/material/styles'

import { Link, useLocation } from 'react-router-dom'
import { Typography } from '@mui/material'

const TextStyled = styled(Typography)(({ theme }) => ({
	fontSize: 14,
	color: theme.custom.colors.textPrimary,
	textAlign: 'left'
}))

const AppMenuItemStyled = styled((props) => {
	const { isActive, ...restProps } = props

	return <Link {...restProps} />
})((props) => ({
	...{
		textDecoration: 'none !important',
		display: 'flex',
		alignItems: 'center',
		width: '100%',
		height: 40,
		marginBottom: 5,
		paddingLeft: 10,
		borderRadius: 6,
		'&:hover': {
			backgroundColor: '#FAFAFC'
		}
	},
	...(props.isActive && {
		backgroundColor: `${props.theme.custom.colors.kozmikLightBlue} !important`,
		'& .text': {
			color: props.theme.custom.colors.kozmikBlue,
			fontWeight: '600'
		}
	})
}))

const AppMenuItem = (props) => {
	let currentPage = useLocation().pathname

	return (
		<AppMenuItemStyled
			isActive={props.data.href.indexOf(currentPage) >= 0}
			to={props.data.href}
		>
			<TextStyled className='text'>{props.data.name}</TextStyled>
		</AppMenuItemStyled>
	)
}

export default AppMenuItem
