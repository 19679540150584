import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm, Controller } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import Cookies from 'universal-cookie'

import ColoredButton from '../../atoms/button/ColoredButton'

import authorizationClient from '../../../services/authorizationClient'
import tokenServices from '../../../services/tokenServices'
import { default as appConfig } from '../../../config/app.json'

import useQuery from '../../../hooks/UseQuery/useQuery'
import showToastResponseError from '../../../methods/showToastResponseError'
import commonRegExes from '../../../common/regExp/commonRegExes'

import KozmikLogoLight from '../../../assets/icons/KozmikLogoLight.png'

import styledUI from '../../../styledUI'

const style = {
	buttonContainer: {
		margin: '10px 0'
	}
}

const ResetPassword = () => {
	const { t } = useTranslation()

	const [submitting, setSubmitting] = useState(false)

	const cookies = new Cookies()
	const history = useHistory()
	const navigate = (url) => {
		history.push(url)
	} //let navigate = useNavigate()
	let queryParams = useQuery()
	const token = cookies.get(appConfig.cookieKeys.MY_SECURE_TOKEN_KEY)

	const {
		control,
		handleSubmit,
		formState: { errors, isValid },
		getValues
	} = useForm({
		defaultValues: {
			password: '',
			confirmPassword: ''
		}
	})
	const onSubmit = (data) => {
		const dataWithToken = {
			token: queryParams.token,
			...data
		}
		setSubmitting(true)

		authorizationClient
			.resetpassword(dataWithToken)
			.then((response) => {
				if (response?.data?.success) {
					tokenServices.add(response.data.data)
					navigate('')
				} else {
					showToastResponseError(response)
				}
				setSubmitting(false)
			})
			.catch(() => {
				setSubmitting(false)
			})
	}

	const handleUserKeyPress = (e) => {
		if (e.key === 'Enter' && !e.shiftKey) {
			handleSubmit(onSubmit)()
		}
	}

	useEffect(() => {
		if (token && token !== 'undefined') {
			navigate('')
		}
	}, [])

	return (
		<styledUI.StyledAuthContainer>
			<styledUI.StyledAuthContentContainer>
				<div style={{ marginBottom: 60 }}>
					<div>
						<styledUI.StyledAuthLogoContainer>
							<a href='https://kozmik.io'>
								<styledUI.StyledAuthLogo
									image={KozmikLogoLight}
									component='img'
								/>
							</a>
						</styledUI.StyledAuthLogoContainer>
						<styledUI.StyledAuthSubTitle>
							{t('authScreens.resetPassword.subTitle')}
						</styledUI.StyledAuthSubTitle>
					</div>
					<styledUI.StyledAuthInputContainer>
						<Controller
							control={control}
							name='password'
							rules={{
								required: {
									value: true,
									message: t(
										'authScreens.resetPassword.passwordInput.requredError'
									)
								},
								pattern: {
									value: commonRegExes.password,
									message: t(
										'authScreens.resetPassword.passwordInput.patternError'
									)
								}
							}}
							render={({ field: { onChange, value } }) => {
								return (
									<styledUI.StyledAuthInput
										error={!!errors?.password}
										helperText={errors?.password?.message || ''}
										placeholder={t(
											'authScreens.resetPassword.passwordInput.placeholder'
										)}
										fullWidth
										value={value}
										type='password'
										onChange={(e) => onChange(e.target.value)}
										onKeyPress={handleUserKeyPress}
									/>
								)
							}}
						/>
					</styledUI.StyledAuthInputContainer>
					<styledUI.StyledAuthInputContainer>
						<Controller
							control={control}
							name='confirmPassword'
							rules={{
								required: {
									value: true,
									message: t(
										'authScreens.resetPassword.confirmPassInput.requredError'
									)
								},
								pattern: {
									value: commonRegExes.password,
									message: t(
										'authScreens.resetPassword.confirmPassInput.patternError'
									)
								},
								validate: (value) =>
									value === getValues('password') ||
									t(
										'authScreens.resetPassword.confirmPassInput.validationError'
									)
							}}
							render={({ field: { onChange, value } }) => {
								return (
									<styledUI.StyledAuthInput
										error={!!errors?.confirmPassword}
										helperText={errors?.confirmPassword?.message || ''}
										placeholder={t(
											'authScreens.resetPassword.confirmPassInput.placeholder'
										)}
										fullWidth
										value={value}
										type='password'
										onChange={(e) => onChange(e.target.value)}
										onKeyPress={handleUserKeyPress}
									/>
								)
							}}
						/>
					</styledUI.StyledAuthInputContainer>
				</div>

				<div style={style.buttonContainer}>
					<styledUI.StyledAuthSubmitButton
						fullWidth
						variant='contained'
						onClick={handleSubmit(onSubmit)}
						disabled={submitting}
						sx={{
							opacity: submitting ? 0.6 : 1
						}}
					>
						<styledUI.StyledAuthSubmitButtonText>
							{t('authScreens.resetPassword.acceptButton')}
						</styledUI.StyledAuthSubmitButtonText>
						{submitting && <styledUI.StyledAuthLoader size={20} />}
					</styledUI.StyledAuthSubmitButton>
				</div>
				<div style={style.buttonContainer}>
					<ColoredButton
						onClick={() => navigate('')}
						variant='text'
						textColor={'#00EF91'}
						bgColor='transparent'
						size='large'
						fullWidth
						style={{
							textTransform: 'unset',
							fontSize: 16,
							fontWeight: 600
						}}
					>
						{t('authScreens.resetPassword.cancelButton')}
					</ColoredButton>
				</div>
			</styledUI.StyledAuthContentContainer>
		</styledUI.StyledAuthContainer>
	)
}

export default ResetPassword
