import { ApiClient } from './client'

import { default as appConfig } from '../config/app.json'

const apiEndpoint = `${appConfig.endpoints.api}/api/domain`

export default {
	async getDomainList(appId) {
		let url = '/list'
		let headers = {}
		headers['AppId'] = appId

		return await new ApiClient(apiEndpoint, headers).get(url)
	},
	async addDomain(appId, domain) {
		let url = `/add?domain=${domain}`
		let headers = {}
		headers['AppId'] = appId

		return await new ApiClient(apiEndpoint, headers).post(url)
	},
	async verifyDomain(appId, domain) {
		let url = `/verify?domain=${domain}`
		let headers = {}
		headers['AppId'] = appId

		return await new ApiClient(apiEndpoint, headers).post(url)
	},
	async deleteDomain(appId, domainId) {
		let url = `/delete/${domainId}`
		let headers = {}
		headers['AppId'] = appId

		return await new ApiClient(apiEndpoint, headers).delete(url)
	}
}
