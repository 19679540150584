import React from 'react'
import { styled } from '@mui/material/styles'
import { Typography } from '@mui/material'

const LabelStyled = styled(Typography)(({ theme }) => ({
	fontSize: 14,
	color: theme.custom.colors.textPrimary
}))

const SettingLabel = (props) => {
	return <LabelStyled {...props}>{props.children}</LabelStyled>
}

export default SettingLabel
