import React, { useState, useEffect } from 'react'
import { useTheme } from '@mui/material/styles'
import Grid from '@mui/material/Grid2'
import update from 'immutability-helper'
import { useTranslation } from 'react-i18next'
import {
	SwapVert,
	BrowserNotSupported,
	FilterAltOutlined,
	PlusOne,
	DonutLargeOutlined,
	HorizontalRuleOutlined
} from '@mui/icons-material'

import AppPageBox from '../../../atoms/appPageBox/AppPageBox'
import DoubleStateNumber from '../../../molecules/doubleStateField/DoubleStateNumber'
import SelectBox from '../../../molecules/selectBox/SelectBox'
import SquareOptions from '../../../molecules/squareOprions/SquareOptions'
import Accordion from '../../../atoms/accordion/Accordion'
import FilterModalOptions from '../../../molecules/filterModalOptions/FilterModalOptions'
import SearchOptions from '../../../molecules/searchOptions/SearchOptions'
import NumericInput from '../../../atoms/numericInput/NumericInput'
import { default as Label } from '../../../atoms/settingLabel/SettingLabel'
import AccordionItem from '../../../atoms/accordionItem/AccordionItem'
import FilterList from '../../../molecules/filterList/FilterList'
import Sorting from '../../../molecules/sorting/Sorting'
import ActionList from '../../actionList/ActionList'
import VisibilityOptions from '../../../molecules/visibilityOptions/VisibilityOptions'

import { ReactComponent as Koseli } from '../../../../assets/icons/Settings/Koseli.svg'
import { ReactComponent as Oval } from '../../../../assets/icons/Settings/Oval.svg'

import timeoutDelay from '../../../../methods/timeoutDelay'
import getEntityFieldsByDataTypes from '../../../../methods/getEntityFieldsByDataTypes'
import aggOperators from '../../../../common/aggOperators'

import relationTypes from '../../../../common/enums/relationTypes'

import defaultElementSchemas from '../../../../defaultElementSchemas'

import styledUI from '../../../../styledUI'

const ChartProgress = (props) => {
	const { t } = useTranslation()
	const theme = useTheme()

	const [elementName, setElementName] = useState(null)

	const getScreenFieldsOptions = (screenSource) => {
		if (screenSource) {
			const screenFields = props?.entities?.find(
				(x) => x.name == screenSource
			)?.fields

			const filteredScreenFields = screenFields.filter(
				(x) =>
					x.dataType === 'Formula' ||
					x.dataType === 'Number' ||
					x.dataType === 'Rollup' ||
					(x.displayFieldDataType &&
						(x.displayFieldDataType === 'Formula' ||
							x.displayFieldDataType === 'Number' ||
							x.displayFieldDataType === 'Rollup'))
			)

			const formattedScreenFields = filteredScreenFields?.map((x) => ({
				label: `Screen.${x.label}`,
				name: `Screen.${x.name}`
			}))

			return formattedScreenFields?.sort(function (a, b) {
				return a.label.localeCompare(b.label)
			})
		} else {
			return []
		}
	}

	const screenFieldsOptions = getScreenFieldsOptions(
		props?.activeScreen?.data?.source
	)

	/****SOURCE***************************/
	const [source, setSource] = useState('')

	const textFields = getEntityFieldsByDataTypes(
		props?.entities,
		source,
		['Text', 'LongText', 'Number', 'Date', 'Email', 'Formula'],
		null,
		true,
		relationTypes.one
	)
	const numericFields = getEntityFieldsByDataTypes(
		props?.entities,
		source,
		['Number', 'Rollup', 'Formula'],
		null,
		true,
		relationTypes.one
	)?.filter(
		(x) =>
			!x.formula || (x.formula && x.formula.formulaFieldDataType === 'Number')
	)

	const sourceChange = (e) => {
		submitSource(source, e.target.value)
		setSource(e.target.value)
	}
	const submitSource = (oldTableName, tableName) => {
		const table = props?.entities?.find((y) => y.name == tableName)

		const txtFields = getEntityFieldsByDataTypes(
			props?.entities,
			tableName,
			['Text', 'LongText', 'Number', 'Date', 'Email'],
			null,
			true,
			relationTypes.one
		)
		const numericFields = getEntityFieldsByDataTypes(
			props?.entities,
			tableName,
			['Number'],
			null,
			true,
			relationTypes.one
		)

		const labelField =
			txtFields?.find((x) => x.name === 'Name') ??
			txtFields?.find((x) => !numericFields?.find((y) => x.name === y.name)) ??
			txtFields?.[0]
		const numericField = numericFields?.length ? numericFields[0] : null

		let modifiedElementData = update(props.elementData, {
			source: { $set: tableName },
			mapping: {
				aggregation: { $set: 'count' },
				label: {
					field: { $set: labelField?.name },
					type: { $set: labelField?.dataType }
				},
				value: {
					field: { $set: numericField?.name },
					type: { $set: numericField?.dataType }
				}
			},
			sorting: {
				field: { $set: numericField?.name },
				sort: { $set: 'asc' }
			},
			filters: {
				groupOp: { $set: 'AND' },
				rules: { $set: [] }
			},
			filterModal: {
				$set: defaultElementSchemas.filterModal()
			}
		})

		// props.updateBulkScreensWithModifiedData(
		// 	table.name,
		// 	null,
		// 	modifiedElementData
		// )

		props.updateSelectedElement(modifiedElementData)
	}

	/****LABEL***************************/
	const [label, setLabel] = useState('')
	const labelChange = (e) => {
		submitLabel(e.target.value)
		setLabel(e.target.value)
	}
	const submitLabel = (x) => {
		const selectedField = textFields?.find((y) => y.name == x)
		const modifiedElementData = update(props.elementData, {
			mapping: {
				label: {
					field: { $set: x },
					type: { $set: selectedField?.dataType }
				}
			}
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****AGGREGATION***************************/
	const [aggregation, setAggregation] = useState('')
	const aggregationChange = (e) => {
		submitAggregation(e.target.value)
		setAggregation(e.target.value)
	}
	const submitAggregation = (x) => {
		const modifiedElementData = update(props.elementData, {
			mapping: {
				aggregation: { $set: x }
			}
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****VALUE***************************/
	const [value, setValue] = useState('')
	const valuceChange = (e) => {
		submitValue(e.target.value)
		setValue(e.target.value)
	}
	const submitValue = (x) => {
		const selectedField = numericFields?.find((y) => y.name == x)
		const modifiedElementData = update(props.elementData, {
			mapping: {
				value: {
					field: { $set: x },
					type: { $set: selectedField?.dataType }
				}
			}
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****MAX_VALUE***************************/
	const [isMaxTextMode, setIsMaxTextMode] = useState(false)
	const [maxField, setMaxField] = useState('')
	const [maxDefault, setMaxDefault] = useState(0)

	const maxChange = (data) => {
		if (isMaxTextMode) {
			timeoutDelay(submitMax, data.target.value, 1000)
			setMaxDefault(data.target.value)
		} else {
			submitMax(data?.value)
			setMaxField(data?.value)
		}
	}
	const submitMax = (x) => {
		// const selectedField = numberFields?.find((y) => y.name == x)
		const modifiedElementData = update(props.elementData, {
			maxValue: {
				field: { $set: isMaxTextMode ? null : x },
				default: { $set: isMaxTextMode ? x : null }
				// type: { $set: isMaxTextMode ? null : selectedField?.dataType }
			}
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****ACTIONS***************************/
	const updateActions = (newActions) => {
		const modifiedElementData = update(props.elementData, {
			actions: { $set: newActions }
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****LIMIT***************************/
	const [limit, setLimit] = useState(3)
	const limitChange = (val) => {
		timeoutDelay(submitLimit, val, 1000)
		setLimit(val)
	}
	const submitLimit = (x) => {
		const modifiedElementData = update(props.elementData, {
			limit: { $set: x }
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****BACKGROUND***************************/
	const [background, setBackground] = useState('none')
	const backgroundChange = (val) => {
		submitBackground(val)
		setBackground(val)
	}
	const submitBackground = (x) => {
		const modifiedElementData = update(props.elementData, {
			background: { $set: x }
		})
		props.updateSelectedElement(modifiedElementData)
	}
	const backgroundOptions = [
		{
			text: t('screens.elementSettings.chart.backgroundOpts.none'),
			value: 'none',
			icon: BrowserNotSupported
		},
		{
			text: t('screens.elementSettings.chart.backgroundOpts.square'),
			value: 'square',
			icon: Koseli
		},
		{
			text: t('screens.elementSettings.chart.backgroundOpts.oval'),
			value: 'oval',
			icon: Oval
		}
	]

	/****STYLE***************************/
	const [style, setStyle] = useState('circle')
	const styleChange = (val) => {
		submitStyle(val)
		setStyle(val)
	}
	const submitStyle = (x) => {
		const modifiedElementData = update(props.elementData, {
			style: { $set: x }
		})
		props.updateSelectedElement(modifiedElementData)
	}
	const styleOptions = [
		{
			text: t('screens.elementSettings.chart.styleOpts.none'),
			value: 'circle',
			icon: DonutLargeOutlined
		},
		{
			text: t('screens.elementSettings.chart.styleOpts.square'),
			value: 'line',
			icon: HorizontalRuleOutlined
		}
	]

	/****FILTER_&_SORT***************************/
	const [localElementData, setLocalElementData] = useState(null)

	/****INITIALIZATION***************************/
	const initStates = (elementData) => {
		/****DATA***************************/
		setSource(elementData?.source ?? '')
		setLimit(elementData?.limit ?? 10)
		/****CONTENT***************************/
		setLabel(elementData?.mapping?.label?.field ?? '')
		setAggregation(elementData.mapping?.aggregation ?? '')
		setValue(elementData?.mapping?.value?.field ?? '')
		/****DESIGN***************************/
		setStyle(elementData?.style ?? 'circle')
		setBackground(elementData?.background ?? 'none')

		/****MAX_VALUE***************************/
		elementData?.maxValue?.field || elementData?.maxValue?.field === ''
			? setIsMaxTextMode(false)
			: setIsMaxTextMode(true)

		setMaxField(elementData?.maxValue?.field || '')
		setMaxDefault(elementData?.maxValue?.default || '')

		if (!elementData?.filterModal) {
			const modifiedElementData = update(elementData, {
				filterModal: {
					$set: defaultElementSchemas.filterModal()
				}
			})
			props.updateSelectedElement(modifiedElementData)
		}

		if (!elementData?.searchPlaceholder) {
			const modifiedElementData = update(elementData, {
				searchPlaceholder: {
					$set: {
						default: t('schemas.numbers.searchPlaceholder')
					}
				}
			})

			props.updateSelectedElement(modifiedElementData)
		}

		setElementName(elementData?._uid)
	}

	useEffect(() => {
		if (props?.elementData) {
			initStates(props.elementData)
			setLocalElementData(props.elementData)
		}
	}, [props.elementData])

	return (
		<>
			<AppPageBox>
				<Accordion
					title={t('screens.elementSettings.common.titles.source')}
					expanded={true}
				>
					<styledUI.StyledGrid container alignItems='center'>
						<Grid size={{ xs: 12 }}>
							<SelectBox
								sx={styledUI.Style.selectBox(props, theme)}
								onChange={sourceChange}
								value={source}
								data={props.entities}
								textKey='label'
								valueKey='name'
							/>
						</Grid>
					</styledUI.StyledGrid>

					<styledUI.StyledDiv>
						<AccordionItem
							icon={FilterAltOutlined}
							title={t('screens.elementSettings.chart.filter')}
							expanded={false}
							padding={8}
						>
							<FilterList
								filter={localElementData?.filters}
								table={props?.entities?.find(
									(y) => y.name == props?.elementData?.source
								)}
								activeScreen={props?.activeScreen}
								entities={props?.entities}
								tableName={props?.elementData?.source}
								screenTable={props?.entities?.find(
									(y) => y.name == props.activeScreen?.data?.source
								)}
								filtering={props.filtering}
								setLocalElementData={setLocalElementData}
							/>
						</AccordionItem>
					</styledUI.StyledDiv>

					<styledUI.StyledDiv>
						<AccordionItem
							icon={SwapVert}
							title={t('screens.elementSettings.chart.sort')}
							expanded={false}
							padding={8}
						>
							<Sorting
								sortingData={localElementData?.sorting}
								sorting={props.sorting}
								fieldOptions={textFields}
							/>
						</AccordionItem>
					</styledUI.StyledDiv>

					<styledUI.StyledDiv>
						<AccordionItem
							icon={PlusOne}
							title={t('screens.elementSettings.chart.limit')}
							expanded={false}
							padding={8}
						>
							<NumericInput
								bgColor={'#ffffff'}
								minVal={0}
								value={limit}
								onChange={limitChange}
							/>
						</AccordionItem>
					</styledUI.StyledDiv>
				</Accordion>
			</AppPageBox>

			<AppPageBox>
				<Accordion
					title={t('screens.elementSettings.common.titles.content')}
					expanded={true}
				>
					<styledUI.StyledGrid container alignItems='center'>
						<Grid size={{ xs: 4 }}>
							<Label>{t('screens.elementSettings.chart.label')}</Label>
						</Grid>
						<Grid size={{ xs: 8 }}>
							<SelectBox
								emptyText={t('screens.elementSettings.chart.emptyField')}
								sx={styledUI.Style.selectBox(props, theme)}
								onChange={labelChange}
								value={label}
								data={textFields}
								textKey='label'
								valueKey='name'
							/>
						</Grid>
					</styledUI.StyledGrid>
					<styledUI.StyledGrid container alignItems='center'>
						<Grid size={{ xs: 4 }}>
							<Label>{t('screens.elementSettings.chart.value')}</Label>
						</Grid>
						<Grid size={{ xs: 8 }}>
							<SelectBox
								emptyText={t('screens.elementSettings.chart.emptyField')}
								sx={styledUI.Style.selectBox(props, theme)}
								onChange={valuceChange}
								value={value}
								data={numericFields}
								textKey='label'
								valueKey='name'
							/>
						</Grid>
					</styledUI.StyledGrid>
					<styledUI.StyledGrid container alignItems='center'>
						<Grid size={{ xs: 4 }}>
							<Label>{t('screens.elementSettings.chart.maxValue')}</Label>
						</Grid>
						<Grid size={{ xs: 8 }}>
							<DoubleStateNumber
								fullWidth={true}
								isTextMode={isMaxTextMode}
								setIsTextMode={setIsMaxTextMode}
								valChange={maxChange}
								textVal={maxDefault?.toString()}
								autoCompVal={maxField}
								autoCompOptions={screenFieldsOptions}
								autoCompEmptyText={t(
									'screens.elementSettings.chart.emptyField'
								)}
								valueKey='name'
								labelKey='label'
							/>
						</Grid>
					</styledUI.StyledGrid>
					<styledUI.StyledGrid container alignItems='center'>
						<Grid size={{ xs: 4 }}>
							<Label>{t('screens.elementSettings.chart.aggregation')}</Label>
						</Grid>
						<Grid size={{ xs: 8 }}>
							<SelectBox
								emptyText={t('screens.elementSettings.chart.emptyField')}
								sx={styledUI.Style.selectBox(props, theme)}
								onChange={aggregationChange}
								value={aggregation}
								data={aggOperators}
								textKey='label'
								valueKey='value'
							/>
						</Grid>
					</styledUI.StyledGrid>
				</Accordion>
			</AppPageBox>

			<AppPageBox>
				<Accordion
					title={t('screens.elementSettings.common.titles.design')}
					expanded={true}
				>
					<styledUI.StyledGrid container alignItems='center'>
						<Grid size={{ xs: 4 }}>
							<Label>{t('screens.elementSettings.chart.style')}</Label>
						</Grid>
						<Grid size={{ xs: 8 }}>
							<SquareOptions
								compact
								data={styleOptions}
								getChange={styleChange}
								activeValue={style}
							/>
						</Grid>
					</styledUI.StyledGrid>
					<styledUI.StyledGrid container alignItems='center'>
						<Grid size={{ xs: 4 }}>
							<Label>{t('screens.elementSettings.chart.background')}</Label>
						</Grid>
						<Grid size={{ xs: 8 }}>
							<SquareOptions
								compact
								data={backgroundOptions}
								getChange={backgroundChange}
								activeValue={background}
							/>
						</Grid>
					</styledUI.StyledGrid>
				</Accordion>
			</AppPageBox>

			{/* <AppPageBox>
				<Accordion
					title={t('screens.elementSettings.common.titles.search')}
					expanded={true}
				>
					<SearchOptions
						source={source}
						elementData={props.elementData}
						updateSelectedElement={props.updateSelectedElement}
					/>
				</Accordion>
			</AppPageBox> */}

			<AppPageBox>
				<Accordion
					title={t('screens.elementSettings.common.titles.inAppFilter')}
					expanded={false}
				>
					{source && (
						<FilterModalOptions
							source={source}
							activeScreen={props.activeScreen}
							elementData={props.elementData}
							entities={props.entities}
							sorting={props.sorting}
							filtering={props.filtering}
							updateSelectedElement={props.updateSelectedElement}
						/>
					)}
				</Accordion>
			</AppPageBox>

			<AppPageBox>
				<Accordion
					title={t('screens.elementSettings.common.titles.actions')}
					expanded={true}
				>
					{source && (
						<ActionList
							name={elementName}
							entities={props.entities}
							activeScreen={props.activeScreen}
							actions={props.elementData.actions}
							updateActions={updateActions}
							isList={true}
							listSource={source}
						/>
					)}
				</Accordion>
			</AppPageBox>

			<VisibilityOptions
				block={props.elementData}
				activeScreen={props.activeScreen}
				entities={props.entities}
				updateBlock={props.updateSelectedElement}
			/>
		</>
	)
}

export default ChartProgress
