import React, { useState, useEffect, useRef } from 'react'
import Grid from '@mui/material/Grid2'
import update from 'immutability-helper'
import { useTranslation } from 'react-i18next'

import AppPageBox from '../../../atoms/appPageBox/AppPageBox'
import DoubleStateMentions from '../../../molecules/doubleStateField/DoubleStateMentions'
import Accordion from '../../../atoms/accordion/Accordion'
import { default as Label } from '../../../atoms/settingLabel/SettingLabel'
import SquareOptions from '../../../molecules/squareOprions/SquareOptions'
import IoniconsSelect from '../../../molecules/IoniconsSelect/IoniconsSelect'
import ActionList from '../../actionList/ActionList'
import VisibilityOptions from '../../../molecules/visibilityOptions/VisibilityOptions'

import getEntityFieldsByDataTypes from '../../../../methods/getEntityFieldsByDataTypes'
import fieldDataTypes from '../../../../common/fieldDataTypes'
import relationTypes from '../../../../common/enums/relationTypes'

import { ReactComponent as ButonDolu } from '../../../../assets/icons/Settings/Buton-Filled.svg'
import { ReactComponent as ButonTransparan } from '../../../../assets/icons/Settings/Buton-Transparent.svg'
import { ReactComponent as ButonCerceveli } from '../../../../assets/icons/Settings/Buton-Framed.svg'
import { ReactComponent as ButonNone } from '../../../../assets/icons/Settings/MetinBoyutu-Kucuk.svg'
import { ReactComponent as Koseli } from '../../../../assets/icons/Settings/Koseli.svg'
import { ReactComponent as Oval } from '../../../../assets/icons/Settings/Oval.svg'

import styledUI from '../../../../styledUI'

const Button = (props) => {
	const { t } = useTranslation()
	const propsRef = useRef()
	propsRef.current = props

	const [elementName, setElementName] = useState(null)

	const textFieldsWithLookupAndRel = getEntityFieldsByDataTypes(
		props?.entities,
		props.activeScreen.data?.source,
		fieldDataTypes.textBased,
		null,
		true,
		relationTypes.one
	)
	const textOptions = textFieldsWithLookupAndRel?.length
		? textFieldsWithLookupAndRel
		: []
	const textOptionsWithEmpty = [{ label: '-', name: '' }, ...textOptions]

	const btnTypeOptions = [
		{
			text: t('screens.elementSettings.button.typeOpts.full'),
			value: 'full',
			icon: ButonDolu
		},
		{
			text: t('screens.elementSettings.button.typeOpts.transparent'),
			value: 'transparent',
			icon: ButonTransparan
		},
		{
			text: t('screens.elementSettings.button.typeOpts.border'),
			value: 'border',
			icon: ButonCerceveli
		},
		{
			text: t('screens.elementSettings.button.typeOpts.none'),
			value: 'none',
			icon: ButonNone
		}
	]

	const cornerOptions = [
		{
			text: t('screens.elementSettings.button.cornerOpts.square'),
			value: 'square',
			icon: Koseli
		},
		{
			text: t('screens.elementSettings.button.cornerOpts.oval'),
			value: 'oval',
			icon: Oval
		}
	]

	/****TEXT***************************/
	const [isTextTextMode, setIsTextTextMode] = useState(false)
	const [textField, setTextField] = useState('')
	const [textDefault, setTextDefault] = useState('')

	const textChange = (data) => {
		if (isTextTextMode) {
			submitText(data)
			setTextDefault(data)
		} else {
			submitText(data?.value)
			setTextField(data?.value)
		}
	}
	const submitText = (x) => {
		const selectedField = textFieldsWithLookupAndRel?.find((y) => y.name == x)
		const modifiedElementData = update(propsRef.current.elementData, {
			mapping: {
				text: {
					field: { $set: isTextTextMode ? null : x },
					default: { $set: isTextTextMode ? x : null },
					type: { $set: isTextTextMode ? null : selectedField?.dataType }
				}
			}
		})
		propsRef.current.updateSelectedElement(modifiedElementData)
	}

	/****ICON***************************/
	const [icon, setIcon] = useState('')

	const iconChange = (val) => {
		submitIcon(val)
		setIcon(val)
	}

	const submitIcon = (x) => {
		const modifiedElementData = update(props.elementData, {
			icon: { $set: x }
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****TYPE***************************/
	const [type, setType] = useState(null)

	const typeChange = (val) => {
		submitType(val)
		setType(val)
	}

	const submitType = (x) => {
		const modifiedElementData = update(props.elementData, {
			type: { $set: x }
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****SIZE***************************/
	const [size, setSize] = useState(null)

	const sizeChange = (val) => {
		submitSize(val)
		setSize(val)
	}

	const submitSize = (x) => {
		const modifiedElementData = update(props.elementData, {
			size: { $set: x }
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****CORNER***************************/
	const [corner, setCorner] = useState(null)

	const cornerChange = (val) => {
		submitCorner(val)
		setCorner(val)
	}

	const submitCorner = (x) => {
		const modifiedElementData = update(props.elementData, {
			corner: { $set: x }
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****ACTIONS***************************/
	const updateActions = (newActions) => {
		const modifiedElementData = update(props.elementData, {
			actions: { $set: newActions }
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****INITIALIZATION***************************/
	const initStates = (elementData) => {
		/****TEXT***************************/
		elementData?.mapping?.text?.field ||
		elementData?.mapping?.text?.field === ''
			? setIsTextTextMode(false)
			: setIsTextTextMode(true)

		setTextField(elementData?.mapping?.text?.field || '')
		setTextDefault(elementData?.mapping?.text?.default || '')
		/****ICON***************************/
		setIcon(elementData?.icon || '')
		/****TYPE***************************/
		setType(elementData?.type)
		/****SIZE***************************/
		setSize(elementData?.size)
		/****CORNER***************************/
		setCorner(elementData?.corner || null)

		setElementName(elementData?._uid)
	}

	useEffect(() => {
		props?.elementData && initStates(props.elementData)
	}, [props.elementData])

	return (
		<>
			<AppPageBox>
				<Accordion
					title={t('screens.elementSettings.common.titles.settings')}
					expanded={true}
				>
					<styledUI.StyledGrid container alignItems='center'>
						<Grid size={{ xs: 4 }}>
							<Label>{t('screens.elementSettings.button.textLabel')}</Label>
						</Grid>
						<Grid size={{ xs: 8 }}>
							<DoubleStateMentions
								name={`${elementName}`}
								multiline={false}
								fullWidth={true}
								isTextMode={isTextTextMode}
								setIsTextMode={setIsTextTextMode}
								valChange={textChange}
								textVal={textDefault}
								autoCompVal={textField}
								autoCompOptions={textOptionsWithEmpty}
								valueKey='name'
								labelKey='label'
							/>
						</Grid>
					</styledUI.StyledGrid>
					<styledUI.StyledGrid
						container
						alignItems='center'
						justifyContent='space-between'
					>
						<Grid size={{ xs: 4 }}>
							<Label>{t('screens.elementSettings.button.iconLabel')}</Label>
						</Grid>
						<Grid size={{ xs: 4 }}>
							<IoniconsSelect
								onChange={iconChange}
								value={icon}
								emptySelectable
								fullWidth
							/>
						</Grid>
					</styledUI.StyledGrid>
					<styledUI.StyledGrid container alignItems='center'>
						<Grid size={{ xs: 4 }}>
							<Label>{t('screens.elementSettings.button.typeLabel')}</Label>
						</Grid>
						<Grid size={{ xs: 8 }}>
							<SquareOptions
								compact
								data={btnTypeOptions}
								getChange={typeChange}
								activeValue={type}
							/>
						</Grid>
					</styledUI.StyledGrid>
					{type !== 'none' && (
						<styledUI.StyledGrid container alignItems='center'>
							<Grid size={{ xs: 4 }}>
								<Label>{t('screens.elementSettings.button.cornerLabel')}</Label>
							</Grid>
							<Grid size={{ xs: 8 }}>
								<SquareOptions
									compact
									data={cornerOptions}
									getChange={cornerChange}
									activeValue={corner}
								/>
							</Grid>
						</styledUI.StyledGrid>
					)}
				</Accordion>
			</AppPageBox>
			<AppPageBox>
				<Accordion
					title={t('screens.elementSettings.common.titles.actions')}
					expanded={true}
				>
					<ActionList
						name={elementName}
						entities={props.entities}
						activeScreen={props.activeScreen}
						actions={props.elementData.actions}
						updateActions={updateActions}
					/>
				</Accordion>
			</AppPageBox>

			<VisibilityOptions
				block={props.elementData}
				activeScreen={props.activeScreen}
				entities={props.entities}
				updateBlock={props.updateSelectedElement}
			/>
		</>
	)
}

export default Button
