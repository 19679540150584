import React, { useState, useEffect } from 'react'
import { useTheme } from '@mui/material/styles'
import Grid from '@mui/material/Grid2'
import update from 'immutability-helper'
import { useTranslation } from 'react-i18next'

import AppPageBox from '../../../atoms/appPageBox/AppPageBox'
import SelectBox from '../../../molecules/selectBox/SelectBox'
import Accordion from '../../../atoms/accordion/Accordion'
import { default as Label } from '../../../atoms/settingLabel/SettingLabel'
import { default as Checkbox } from '../../../atoms/kozmikCheckBox/KozmikCheckBox'
import { default as Slider } from '../../../atoms/kozmikSlider/KozmikSlider'
import VisibilityOptions from '../../../molecules/visibilityOptions/VisibilityOptions'

import styledUI from '../../../../styledUI'

const Seperator = (props) => {
	const { t } = useTranslation()
	const theme = useTheme()

	/****LINE_POSITION***************************/
	const [linePosition, setLinePosition] = useState('')

	const linePositionOtions = [
		{
			label: t('screens.elementSettings.seperator.lineOpts.start'),
			value: 'flex-start'
		},
		{
			label: t('screens.elementSettings.seperator.lineOpts.center'),
			value: 'center'
		},
		{
			label: t('screens.elementSettings.seperator.lineOpts.end'),
			value: 'flex-end'
		}
	]

	const linePositionChange = (e) => {
		submitLinePosition(e.target.value)
		setLinePosition(e.target.value)
	}

	const submitLinePosition = (x) => {
		const modifiedElementData = update(props.elementData, {
			linePosition: { $set: x }
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****SIZE***************************/
	const [size, setSize] = useState(null)

	const sizeChange = (e, newVal) => {
		if (typeof newVal === 'number') {
			submitSize(newVal)
			setSize(newVal)
		}
	}

	const submitSize = (x) => {
		const modifiedElementData = update(props.elementData, {
			size: { $set: x }
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****LINE_VISIBLE***************************/
	const [lineVisible, setLineVisible] = useState(false)

	const lineVisibleChange = (e) => {
		setLineVisible(e.target.checked)
		submitLineVisible(e.target.checked)
	}

	const submitLineVisible = (x) => {
		const modifiedElementData = update(props.elementData, {
			lineVisible: { $set: x }
		})

		props.updateSelectedElement(modifiedElementData)
	}

	/****INITIALIZATION***************************/
	const initStates = (elementData) => {
		/****LINE_POSITION***************************/
		setLinePosition(elementData?.linePosition || '')
		/****SIZE***************************/
		setSize(elementData?.size || null)
		/****LINE_VISIBLE***************************/
		setLineVisible(!!elementData?.lineVisible)
	}

	useEffect(() => {
		if (props?.elementData) {
			initStates(props.elementData)
		}
	}, [props.elementData])

	return (
		<>
			<AppPageBox>
				<Accordion
					title={t('screens.elementSettings.common.titles.design')}
					expanded={true}
				>
					<styledUI.StyledGrid container alignItems='center'>
						<Grid size={{ xs: 4 }}>
							<Label>{t('screens.elementSettings.seperator.position')}</Label>
						</Grid>
						<Grid size={{ xs: 8 }}>
							<SelectBox
								// emptyText='Empty Field'
								sx={styledUI.Style.selectBox(props, theme)}
								onChange={linePositionChange}
								value={linePosition}
								data={linePositionOtions}
								textKey='label'
								valueKey='value'
							/>
						</Grid>
					</styledUI.StyledGrid>
					<styledUI.StyledGrid container alignItems='center'>
						<Grid size={{ xs: 4 }}>
							<Label>{t('screens.elementSettings.seperator.size')}</Label>
						</Grid>
						<Grid
							style={{
								display: 'flex',
								alignItems: 'center'
							}}
							size={{ xs: 12, lg: 8 }}
						>
							<Slider
								value={size}
								min={10}
								step={15}
								max={70}
								onChange={sizeChange}
								aria-labelledby='non-linear-slider'
							/>
						</Grid>
					</styledUI.StyledGrid>
					<Grid size={{ xs: 8, lg: 9 }}>
						<styledUI.StyledItemContainer>
							<div>
								<Checkbox checked={lineVisible} onChange={lineVisibleChange} />
							</div>
							<styledUI.StyledLabel>
								<Label>{t('screens.elementSettings.seperator.showLine')}</Label>
							</styledUI.StyledLabel>
						</styledUI.StyledItemContainer>
					</Grid>
				</Accordion>
			</AppPageBox>

			<VisibilityOptions
				block={props.elementData}
				activeScreen={props.activeScreen}
				entities={props.entities}
				updateBlock={props.updateSelectedElement}
			/>
		</>
	)
}

export default Seperator
