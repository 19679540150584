import React, { useState } from 'react'
import { styled } from '@mui/material/styles'
import { Popover, Typography } from '@mui/material'
import { MoreVert, DragIndicator } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'

import PopoverItem from '../popoverItem/PopoverItem'
import WarningDialog from '../dialog/warningDialog/WarningDialog'

import styledUI from '../../../styledUI'

const MenuItemStyled = styled('div')((props) => ({
	...{
		cursor: 'move',
		textDecoration: 'none !important',
		display: 'flex',
		alignItems: 'center',
		width: '100%',
		height: 30,
		// paddingLeft: 10,
		borderRadius: 6,
		justifyContent: 'space-between',
		backgroundColor: '#ffffff',
		'&:hover': {
			backgroundColor: '#FAFAFC',
			'& .moreIcon': {
				visibility: 'visible'
			}
		}
	},
	...(props.isActive && {
		backgroundColor: `${props.theme.custom.colors.kozmikLightBlue} !important`,
		'& .text': {
			color: props.theme.custom.colors.kozmikBlue,
			fontWeight: '600'
		},
		'& .moreIcon': {
			visibility: 'visible'
		}
	})
}))

const TextStyled = styled(Typography)(({ theme }) => ({
	flex: 1,
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
	fontSize: 14,
	color: theme.custom.colors.textPrimary,
	textAlign: 'left'
}))

const DragIconStyled = styled(DragIndicator)(({ theme }) => ({
	marginLeft: 5,
	marginRight: 5,
	fontSize: 16,
	color: theme.custom.colors.disabledPrimary
}))

const MoreIconStyled = styled(MoreVert)(({ theme }) => ({
	color: theme.custom.colors.textPrimary,
	display: 'block',
	visibility: 'hidden',
	cursor: 'pointer'
}))

const ScreenMenuItem = (props) => {
	const { t } = useTranslation()

	const {
		text,
		deleteItem,
		isActive,
		navigatePreview,
		copyItem,
		nonDraggable,
		...restProps
	} = props

	const [screenPop, setScreenPop] = useState(null)
	const [deleteConfirmationVisible, setDeleteConfirmationVisible] =
		useState(false)

	const openScreenPop = (e) => {
		e.stopPropagation()
		setScreenPop(e.currentTarget)
	}

	const closeScreenPop = () => {
		setScreenPop(null)
	}

	const screenPopOpen = Boolean(screenPop)

	const handleDeleteDialogClose = () => {
		setDeleteConfirmationVisible(false)
	}

	const handleDeleteDialogAccept = () => {
		deleteItem()
		setDeleteConfirmationVisible(false)
	}

	return (
		<div {...restProps}>
			<MenuItemStyled
				isActive={isActive}
				style={nonDraggable ? { cursor: 'pointer' } : null}
				onClick={() => {
					navigatePreview()
				}}
			>
				<DragIconStyled className='dragIcon' />
				<TextStyled className='text'>{text}</TextStyled>
				<MoreIconStyled className='moreIcon' onClick={openScreenPop} />
			</MenuItemStyled>
			<Popover
				open={screenPopOpen}
				anchorEl={screenPop}
				onClose={() => {
					closeScreenPop()
				}}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center'
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center'
				}}
			>
				<styledUI.PopoverBoxStyled>
					<PopoverItem
						text={t('screens.menuItem.copy')}
						onClick={() => {
							closeScreenPop()
							copyItem()
						}}
						color='#000000'
					>
						<styledUI.PopoverCopyIconStyled />
					</PopoverItem>
					<PopoverItem
						text={t('screens.menuItem.delete')}
						onClick={() => {
							closeScreenPop()
							setDeleteConfirmationVisible(true)
						}}
						color='#FF2619'
					>
						<styledUI.PopoverDeleteIconStyled />
					</PopoverItem>
				</styledUI.PopoverBoxStyled>
			</Popover>
			<WarningDialog
				dialogVisible={deleteConfirmationVisible}
				dialogClose={handleDeleteDialogClose}
				dialogAccept={handleDeleteDialogAccept}
				dialogTitle={t('screens.menuItem.deleteModal.dialogTitle')}
				title={null}
				text={t('screens.menuItem.deleteModal.text')}
				dialogCancelText={t('screens.menuItem.deleteModal.closeButton')}
				dialogAcceptText={t('screens.menuItem.deleteModal.acceptButton')}
			/>
		</div>
	)
}

export default ScreenMenuItem
