import React from 'react'
import Grid from '@mui/material/Grid2'

import AppMenuItem from '../appMenuItem/AppMenuItem'

const AppMenu = (props) => {
	return (
		<Grid container style={{ paddingTop: 20 }}>
			{props.menuItems.map((element, index) => (
				<AppMenuItem key={index} data={element} />
			))}
		</Grid>
	)
}

export default AppMenu
