import React from 'react'
import { useTheme } from '@mui/material/styles'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import CloseIcon from '@mui/icons-material/Close'

const ModalStyled = styled(Modal)(({ theme }) => ({
	'&:focus-visible': {
		outline: 'none !important'
	}
}))

const overlayStyle = {
	bgcolor: 'rgba(14, 16, 40, 0.6)',
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'center',
	alignItems: 'center',
	padding: '20px'
}

const buttonStyle = {
	zIndex: 1,
	width: '45px',
	height: '45px',
	borderRadius: '8px',
	color: '#ffffff',
	position: 'absolute',
	right: '-15px',
	top: '-15px',
	cursor: 'pointer',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center'
}

const KozmikModal = (props) => {
	const theme = useTheme()
	const accentColor = props?.accentColor || theme.custom.colors.kozmikBlue

	const height = props?.flexHeight ? 'auto' : '100%'
	const maxHeight = props?.maxHeight
		? props.maxHeight
		: props.flexHeight
		? 'auto'
		: props.lg
		? 730
		: props.sm
		? 485
		: 500

	const mainContainer = {
		position: 'relative',
		width: '100%',
		maxWidth: props.lg ? 1280 : props.sm ? 494 : 730,
		height: height,
		maxHeight: maxHeight,
		p: 0
	}

	const contentContainer = {
		position: 'relative',
		overflow: 'hidden',
		bgcolor: '#ffffff',
		borderRadius: '24px',
		width: '100%',
		height: height,
		maxHeight: maxHeight
	}

	const childContentContainer = {
		position: 'relative',
		overflow: 'scroll',
		width: '100%',
		height: height,
		maxHeight: maxHeight
	}

	return (
		<ModalStyled
			open={props.visible}
			onClose={props.hideModal}
			sx={overlayStyle}
		>
			<Box sx={mainContainer}>
				<Box
					sx={{ ...buttonStyle, bgcolor: accentColor }}
					onClick={props.hideModal}
				>
					<CloseIcon style={{ color: '#ffffff', fontSize: 22 }} />
				</Box>
				<Box sx={contentContainer}>
					<Box sx={childContentContainer}>{props.children}</Box>
				</Box>
			</Box>
		</ModalStyled>
	)
}

export default KozmikModal
