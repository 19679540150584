import React, { useState, useEffect } from 'react'
import { useTheme } from '@mui/material/styles'
import Grid from '@mui/material/Grid2'
import update from 'immutability-helper'
import { useTranslation } from 'react-i18next'

import ActionList from '../../actionList/ActionList'
import AppPageBox from '../../../atoms/appPageBox/AppPageBox'
import Accordion from '../../../atoms/accordion/Accordion'
import SelectBox from '../../../molecules/selectBox/SelectBox'
import { default as Label } from '../../../atoms/settingLabel/SettingLabel'
import FormElementList from '../../../molecules/formElementList/FormElementList'
import Deprecated_FormHiddenFieldList from '../../../molecules/deprecated_FormHiddenFieldList/Deprecated_FormHiddenFieldList'
import AccordionItem from '../../../atoms/accordionItem/AccordionItem'
import IoniconsSelect from '../../../molecules/IoniconsSelect/IoniconsSelect'
import SquareOptions from '../../../molecules/squareOprions/SquareOptions'
// import RadioOptions from '../../../molecules/radioOptions/RadioOptions'
import KozmikTextField from '../../../atoms/kozmikTextField/KozmikTextField'
import { default as Checkbox } from '../../../atoms/kozmikCheckBox/KozmikCheckBox'
import VisibilityOptions from '../../../molecules/visibilityOptions/VisibilityOptions'

import timeoutDelay from '../../../../methods/timeoutDelay'
import defaultElementSchemas from '../../../../defaultElementSchemas'
import systemFieldNames from '../../../../common/systemFieldNames/systemFieldNames'

import { ReactComponent as ButonDolu } from '../../../../assets/icons/Settings/Buton-Filled.svg'
import { ReactComponent as ButonTransparan } from '../../../../assets/icons/Settings/Buton-Transparent.svg'
import { ReactComponent as ButonCerceveli } from '../../../../assets/icons/Settings/Buton-Framed.svg'
import { ReactComponent as Koseli } from '../../../../assets/icons/Settings/Koseli.svg'
import { ReactComponent as Oval } from '../../../../assets/icons/Settings/Oval.svg'
import ButtonList from '../../buttonList/ButtonList'

import styledUI from '../../../../styledUI'

const Form = (props) => {
	const { t } = useTranslation()
	const theme = useTheme()
	const culture = localStorage.getItem('i18nextLng')
	// const languageCode = culture === 'tr-TR' ? 'tr' : 'en'
	const languageCode = 'en'

	const [elementName, setElementName] = useState(null)
	const [localElementData, setLocalElementData] = useState(null)

	const localTable = props?.entities?.find(
		(y) => y.name == props.elementData?.source
	)

	const btnTypeOptions = [
		{
			text: t('screens.elementSettings.form.typeOpts.full'),
			value: 'full',
			icon: ButonDolu
		},
		{
			text: t('screens.elementSettings.form.typeOpts.transparent'),
			value: 'transparent',
			icon: ButonTransparan
		},
		{
			text: t('screens.elementSettings.form.typeOpts.border'),
			value: 'border',
			icon: ButonCerceveli
		}
	]

	const cornerOptions = [
		{
			text: t('screens.elementSettings.form.cornerOpts.square'),
			value: 'square',
			icon: Koseli
		},
		{
			text: t('screens.elementSettings.form.cornerOpts.oval'),
			value: 'oval',
			icon: Oval
		}
	]

	/****SOURCE***************************/
	const [source, setSource] = useState('')

	const sourceChange = (e) => {
		setSource(e.target.value)
		submitSource(source, e.target.value)
	}
	const submitSource = (oldTableName, tableName) => {
		const table = props?.entities?.find((y) => y.name == tableName)

		const generatedElement = defaultElementSchemas.form(
			table,
			props.activeScreen?.data?.source == tableName
		)

		const modifiedElementData = update(generatedElement, {
			_uid: { $set: props.elementData._uid }
		})

		props.updateBulkScreensWithModifiedData(
			table.name,
			null,
			modifiedElementData
		)
	}
	const updateFormBody = (elements) => {
		const modifiedElementData = update(props.elementData, {
			body: { $set: elements }
		})

		props.updateSelectedElement(modifiedElementData)
	}

	/****ADD_ELEMENT***************************/
	const [unusedFields, setUnusedFields] = useState(null)

	const updateUnusedFields = (data) => {
		const usedInputFields = data.body?.length
			? data.body.map((x) => x?.mapping?.field)
			: []
		const usedHiddenFields = data?.hiddenFields?.length
			? data.hiddenFields.map((x) => x?.field)
			: []

		const usedFields = usedInputFields.concat(usedHiddenFields)

		const unuseds = localTable.fields.filter(
			(x) =>
				!systemFieldNames.notVisible.includes(x.name) &&
				x.dataType !== 'Lookup' &&
				x.dataType !== 'Formula' &&
				x.dataType !== 'Rollup' &&
				!usedFields?.includes(x.name)
		)

		setUnusedFields(unuseds)
	}

	/****SUBMIT_BUTTON***************************/
	const [submitVisible, setSubmitVisible] = useState(true)
	const [submitText, setSubmitText] = useState('')
	const [submitIcon, setSubmitIcon] = useState('')
	const [submitType, setSubmitType] = useState(null)
	const [submitCorner, setSubmitCorner] = useState(null)

	const submitVisibleChange = (e) => {
		setSubmitVisible(e.target.checked)
		submitSubmitVisible(e.target.checked)
	}
	const submitSubmitVisible = (x) => {
		const modifiedElementData = update(props.elementData, {
			submit: {
				visible: { $set: x }
			}
		})

		props.updateSelectedElement(modifiedElementData)
	}

	const submitTextChange = (e) => {
		timeoutDelay(submitSubmitText, e.target.value, 1000)
		setSubmitText(e.target.value)
	}
	const submitSubmitText = (x) => {
		const modifiedElementData = update(props.elementData, {
			submit: {
				text: {
					default: { $set: x }
				}
			}
		})
		props.updateSelectedElement(modifiedElementData)
	}

	const submitIconChange = (val) => {
		submitSubmitIcon(val)
		setSubmitIcon(val)
	}
	const submitSubmitIcon = (x) => {
		const modifiedElementData = update(props.elementData, {
			submit: {
				icon: { $set: x }
			}
		})
		props.updateSelectedElement(modifiedElementData)
	}

	const submitTypeChange = (val) => {
		submitSubmitType(val)
		setSubmitType(val)
	}
	const submitSubmitType = (x) => {
		const modifiedElementData = update(props.elementData, {
			submit: {
				type: { $set: x }
			}
		})
		props.updateSelectedElement(modifiedElementData)
	}

	const submitCornerChange = (val) => {
		submitSubmitCorner(val)
		setSubmitCorner(val)
	}
	const submitSubmitCorner = (x) => {
		const modifiedElementData = update(props.elementData, {
			submit: {
				corner: { $set: x }
			}
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****DELETE_BUTTON***************************/
	const [deleteVisible, setDeleteVisible] = useState(false)
	const [deleteText, setDeleteText] = useState('')
	const [deleteIcon, setDeleteIcon] = useState('')
	const [deleteType, setDeleteType] = useState(null)
	const [deleteCorner, setDeleteCorner] = useState(null)

	const deleteVisibleChange = (e) => {
		setDeleteVisible(e.target.checked)
		submitDeleteVisible(e.target.checked)
	}
	const submitDeleteVisible = (x) => {
		const modifiedElementData = update(props.elementData, {
			delete: {
				visible: { $set: x }
			}
		})

		props.updateSelectedElement(modifiedElementData)
	}

	const deleteTextChange = (e) => {
		timeoutDelay(submitDeleteText, e.target.value, 1000)
		setDeleteText(e.target.value)
	}
	const submitDeleteText = (x) => {
		const modifiedElementData = update(props.elementData, {
			delete: {
				text: {
					default: { $set: x }
				}
			}
		})
		props.updateSelectedElement(modifiedElementData)
	}

	const deleteIconChange = (val) => {
		submitDeleteIcon(val)
		setDeleteIcon(val)
	}
	const submitDeleteIcon = (x) => {
		const modifiedElementData = update(props.elementData, {
			delete: {
				icon: { $set: x }
			}
		})
		props.updateSelectedElement(modifiedElementData)
	}

	const deleteTypeChange = (val) => {
		submitDeleteType(val)
		setDeleteType(val)
	}
	const submitDeleteType = (x) => {
		const modifiedElementData = update(props.elementData, {
			delete: {
				type: { $set: x }
			}
		})
		props.updateSelectedElement(modifiedElementData)
	}

	const deleteCornerChange = (val) => {
		submitDeleteCorner(val)
		setDeleteCorner(val)
	}
	const submitDeleteCorner = (x) => {
		const modifiedElementData = update(props.elementData, {
			delete: {
				corner: { $set: x }
			}
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****ACTIONS***************************/
	const updateSubmitActions = (newActions) => {
		const modifiedElementData = update(props.elementData, {
			submit: {
				actions: { $set: newActions }
			}
		})
		props.updateSelectedElement(modifiedElementData)
	}
	const updateDeleteActions = (newActions) => {
		const modifiedElementData = update(props.elementData, {
			delete: {
				actions: { $set: newActions }
			}
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****BUTTONS***************************/
	const updateButtons = (newButtons) => {
		const modifiedElementData = update(props.elementData, {
			buttons: { $set: newButtons }
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****INITIALIZATION***************************/
	const initStates = (elementData) => {
		/****SOURCE***************************/
		setSource(elementData?.source ? elementData.source : '')
		/****SUBMIT_BUTTON***************************/
		setSubmitVisible(elementData?.submit?.visible)
		setSubmitText(elementData?.submit?.text?.default || '')
		setSubmitIcon(elementData?.submit?.icon || '')
		setSubmitType(elementData?.submit?.type || null)
		setSubmitCorner(elementData?.submit?.corner || null)
		/****DELETE_BUTTON***************************/
		setDeleteVisible(elementData?.delete?.visible)
		setDeleteText(elementData?.delete?.text?.default || '')
		setDeleteIcon(elementData?.delete?.icon || '')
		setDeleteType(elementData?.delete?.type || null)
		setDeleteCorner(elementData?.delete?.corner || null)

		setElementName(elementData?._uid)
	}

	useEffect(() => {
		if (props?.elementData) {
			initStates(props.elementData)
			setLocalElementData(props.elementData)
			updateUnusedFields(props.elementData)
		}
	}, [props.elementData])

	return (
		<>
			<AppPageBox>
				<Accordion
					title={t('screens.elementSettings.common.titles.source')}
					expanded={true}
				>
					<styledUI.StyledGrid container alignItems='center'>
						{/* <Grid size={{ xs: 4 }}>
								<Label>{t('screens.elementSettings.form.source')}</Label>
							</Grid> */}
						<Grid size={{ xs: 12 }}>
							<SelectBox
								sx={styledUI.Style.selectBox(props, theme)}
								onChange={sourceChange}
								value={source}
								data={props.entities}
								textKey='label'
								valueKey='name'
							/>
						</Grid>
					</styledUI.StyledGrid>
				</Accordion>
			</AppPageBox>

			<FormElementList
				source={source}
				activeScreen={props.activeScreen}
				languageCode={languageCode}
				elementData={props.elementData}
				localElementData={localElementData}
				setLocalElementData={setLocalElementData}
				setElements={updateFormBody}
				entities={props.entities}
				updateSelectedElement={props.updateSelectedElement}
				sorting={props.sorting}
				filtering={props.filtering}
				unusedFields={unusedFields}
			/>

			<Deprecated_FormHiddenFieldList
				source={source}
				languageCode={languageCode}
				elementData={props.elementData}
				localElementData={localElementData}
				setLocalElementData={setLocalElementData}
				activeScreen={props.activeScreen}
				entities={props.entities}
				filtering={props.filtering}
				unusedFields={unusedFields}
				updateSelectedElement={props.updateSelectedElement}
			/>

			{props.elementData.submit ? (
				<>
					<AppPageBox>
						<Accordion
							title={t('screens.elementSettings.form.submitBtnTitle')}
							expanded={true}
						>
							<styledUI.StyledGrid size={{ xs: 8, lg: 9 }}>
								<styledUI.StyledItemContainer>
									<div>
										<Checkbox
											checked={submitVisible}
											onChange={submitVisibleChange}
										/>
									</div>
									<styledUI.StyledLabel>
										<Label>
											{t('screens.elementSettings.form.btn.showSubmit')}
										</Label>
									</styledUI.StyledLabel>
								</styledUI.StyledItemContainer>
							</styledUI.StyledGrid>
							{submitVisible && (
								<styledUI.StyledDiv>
									<styledUI.StyledGrid container alignItems='center'>
										<Grid size={{ xs: 4 }}>
											<Label>
												{t('screens.elementSettings.form.btn.title')}
											</Label>
										</Grid>
										<Grid size={{ xs: 8 }}>
											<Grid container>
												<Grid
													size={{ xs: 9, lg: 9 }}
													style={{ paddingRight: 10 }}
												>
													<KozmikTextField
														fullWidth
														value={submitText}
														onChange={submitTextChange}
													/>
												</Grid>
												<Grid size={{ xs: 3, lg: 3 }}>
													<IoniconsSelect
														onChange={submitIconChange}
														value={submitIcon}
														emptySelectable
														fullWidth
													/>
												</Grid>
											</Grid>
										</Grid>
									</styledUI.StyledGrid>
									<styledUI.StyledGrid container alignItems='center'>
										<Grid size={{ xs: 4 }}>
											<Label>
												{t('screens.elementSettings.form.btn.style')}
											</Label>
										</Grid>
										<Grid size={{ xs: 8 }}>
											<SquareOptions
												boxColor='#ffffff'
												compact
												data={btnTypeOptions}
												getChange={submitTypeChange}
												activeValue={submitType}
											/>
										</Grid>
									</styledUI.StyledGrid>
									<styledUI.StyledGrid container alignItems='center'>
										<Grid size={{ xs: 4 }}>
											<Label>
												{t('screens.elementSettings.form.btn.corners')}
											</Label>
										</Grid>
										<Grid size={{ xs: 8 }}>
											<SquareOptions
												boxColor='#ffffff'
												compact
												data={cornerOptions}
												getChange={submitCornerChange}
												activeValue={submitCorner}
											/>
										</Grid>
									</styledUI.StyledGrid>
									<styledUI.StyledGrid container alignItems='center'>
										<AppPageBox
											style={{
												flex: 1,
												border: 0,
												paddingLeft: 0,
												paddingRight: 0
											}}
										>
											<AccordionItem
												title={t('screens.elementSettings.form.actionsTitle')}
												expanded={true}
											>
												{source && (
													<ActionList
														formSource={source}
														name={elementName}
														entities={props.entities}
														activeScreen={props.activeScreen}
														actions={props.elementData.submit.actions}
														updateActions={updateSubmitActions}
													/>
												)}
											</AccordionItem>
										</AppPageBox>
									</styledUI.StyledGrid>
								</styledUI.StyledDiv>
							)}
						</Accordion>
					</AppPageBox>
					{props.activeScreen?.data?.source == source && (
						<AppPageBox>
							<Accordion
								title={t('screens.elementSettings.form.deleteBtnTitle')}
								expanded={true}
							>
								<styledUI.StyledGrid size={{ xs: 8, lg: 9 }}>
									<styledUI.StyledItemContainer>
										<div>
											<Checkbox
												checked={deleteVisible}
												onChange={deleteVisibleChange}
											/>
										</div>
										<styledUI.StyledLabel>
											<Label>
												{t('screens.elementSettings.form.btn.showDelete')}
											</Label>
										</styledUI.StyledLabel>
									</styledUI.StyledItemContainer>
								</styledUI.StyledGrid>
								{deleteVisible && (
									<styledUI.StyledDiv>
										<styledUI.StyledGrid container alignItems='center'>
											<Grid size={{ xs: 4 }}>
												<Label>
													{t('screens.elementSettings.form.btn.title')}
												</Label>
											</Grid>

											<Grid size={{ xs: 8 }}>
												<Grid container>
													<Grid
														size={{ xs: 9, lg: 9 }}
														style={{ paddingRight: 10 }}
													>
														<KozmikTextField
															fullWidth
															value={deleteText}
															onChange={deleteTextChange}
														/>
													</Grid>
													<Grid size={{ xs: 3, lg: 3 }}>
														<IoniconsSelect
															onChange={deleteIconChange}
															value={deleteIcon}
															emptySelectable
															fullWidth
														/>
													</Grid>
												</Grid>
											</Grid>
										</styledUI.StyledGrid>
										<styledUI.StyledGrid container alignItems='center'>
											<Grid size={{ xs: 4 }}>
												<Label>
													{t('screens.elementSettings.form.btn.style')}
												</Label>
											</Grid>
											<Grid size={{ xs: 8 }}>
												<SquareOptions
													boxColor='#ffffff'
													compact
													data={btnTypeOptions}
													getChange={deleteTypeChange}
													activeValue={deleteType}
												/>
											</Grid>
										</styledUI.StyledGrid>
										<styledUI.StyledGrid container alignItems='center'>
											<Grid size={{ xs: 4 }}>
												<Label>
													{t('screens.elementSettings.form.btn.corners')}
												</Label>
											</Grid>
											<Grid size={{ xs: 8 }}>
												<SquareOptions
													boxColor='#ffffff'
													compact
													data={cornerOptions}
													getChange={deleteCornerChange}
													activeValue={deleteCorner}
												/>
											</Grid>
										</styledUI.StyledGrid>

										<styledUI.StyledGrid container alignItems='center'>
											<AppPageBox
												style={{
													flex: 1,
													border: 0,
													paddingLeft: 0,
													paddingRight: 0
												}}
											>
												<AccordionItem
													title={t('screens.elementSettings.form.actionsTitle')}
													expanded={true}
												>
													{source && (
														<ActionList
															formSource={source}
															name={elementName}
															entities={props.entities}
															activeScreen={props.activeScreen}
															actions={props.elementData.delete.actions}
															updateActions={updateDeleteActions}
														/>
													)}
												</AccordionItem>
											</AppPageBox>
										</styledUI.StyledGrid>
									</styledUI.StyledDiv>
								)}
							</Accordion>
						</AppPageBox>
					)}
				</>
			) : (
				source && (
					<ButtonList
						name={elementName}
						entities={props.entities}
						activeScreen={props.activeScreen}
						buttons={props.elementData.buttons ?? []}
						updateButtons={updateButtons}
						formSource={source}
					/>
				)
			)}

			<VisibilityOptions
				block={props.elementData}
				activeScreen={props.activeScreen}
				entities={props.entities}
				updateBlock={props.updateSelectedElement}
			/>
		</>
	)
}

export default Form
