import React, { useEffect } from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'
import Cookies from 'universal-cookie'
import { useHistory } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { CardMedia } from '@mui/material'

import appClient from '../../../services/appClient'
import authorizationClient from '../../../services/authorizationClient'

import { default as appConfig } from '../../../config/app.json'
import KozmikLogo from '../../../assets/icons/KozmikLogo.png'

import showToastResponseError from '../../../methods/showToastResponseError'

const CopyApp = () => {
	const { t } = useTranslation()
	const history = useHistory()
	const navigate = (url) => {
		history.push(url)
	} //let navigate = useNavigate()
	const cookies = new Cookies()
	const token = cookies.get(appConfig.cookieKeys.MY_SECURE_TOKEN_KEY)
	let { shortName } = useParams()

	useEffect(() => {
		if (shortName) {
			if (token && token !== 'undefined') {
				appClient
					.import(shortName)
					.then((response) => {
						if (response?.data?.success) {
							authorizationClient.refreshTokenAndUserInfo().then(() => {
								navigate(`/app/${response?.data?.unique?.Value}/screens`)
							})
						} else {
							showToastResponseError(response)
							navigate('/error?type=copyError')
						}
					})
					.catch((error) => {
						if (error.response.status === 429) {
							navigate('/error?type=rateLimiting')
						} else {
							navigate('/error?type=tryLater')
						}
					})
			} else {
				navigate(`/login?import=${shortName}`)
			}
		} else {
			navigate('')
		}
	}, [])

	return (
		<div
			style={{
				display: 'flex',
				height: '100vh',
				flexWrap: 'wrap',
				justifyContent: 'center',
				alignItems: 'center'
			}}
		>
			<div style={{ maxWidth: 800 }}>
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						marginBottom: 50
					}}
				>
					<a href='/'>
						<CardMedia
							image={KozmikLogo}
							component='img'
							style={{ width: 75, height: 'auto' }}
						/>
					</a>
				</div>
				<h2
					style={{
						margin: 20,
						fontSize: 20,
						textAlign: 'center',
						marginBottom: 20
					}}
				>
					{t('copyApp.creating')}
				</h2>
				<div
					style={{
						display: 'flex',
						justifyContent: 'center'
					}}
				>
					<CircularProgress />
				</div>
			</div>
		</div>
	)
}

export default CopyApp
