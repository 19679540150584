import React, { useState } from 'react'
import { styled } from '@mui/material/styles'
import { Typography } from '@mui/material'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import MenuIcon from '@mui/icons-material/Menu'
import { Apps, People, Work } from '@mui/icons-material'
import { Link } from 'react-router-dom'

import LeftFrame from '../../templates/leftFrame/LeftFrame'

// import OnboardingMobile from '../../organisms/onboarding/OnboardingMobile'

import { ReactComponent as KozmikLogo } from '../../../assets/icons/KozmikLogo.svg'

const MainContainerStyled = styled('div')(({ theme }) => ({
	width: '100%',
	height: '100vh',
	backgroundColor: theme.custom.colors.kozmikGray,
	display: 'flex',
	flexWrap: 'nowrap',
	overflow: 'hidden',
	[theme.breakpoints.down('md')]: {
		flexDirection: 'column'
	}
}))

const LeftFrameContainerStyled = styled('div')(({ theme }) => ({
	padding: 20,
	width: '256px',
	minWidth: '256px',
	[theme.breakpoints.down('md')]: {
		display: 'none'
	}
}))

const ContentContainerStyled = styled('div')((props) => ({
	flexGrow: 1,
	backgroundColor: props?.bgColor ? props.bgColor : 'transparent',
	overflowY: 'auto',
	padding: '30px 20px 30px 0',
	[props.theme.breakpoints.down('md')]: {
		padding: '30px 20px'
	}
}))

const OverlayMenuStyled = styled('div')(({ theme }) => ({
	height: '0%',
	width: '100%',
	position: 'fixed',
	zIndex: 10,
	top: 0,
	left: 0,
	backgroundColor: '#ffffff',
	overflowY: 'hidden',
	transition: '0.5s',
	display: 'none',
	flexDirection: 'column',
	[theme.breakpoints.down('md')]: {
		display: 'flex'
	}
}))

const HeaderContainerStyled = styled('div')(({ theme }) => ({
	width: '100%',
	justifyContent: 'space-between',
	alignItems: 'center',
	padding: '10px 20px',
	borderBottom: `1px solid ${theme.custom.colors.gray}`,
	backgroundColor: '#ffffff',
	display: 'none',
	[theme.breakpoints.down('md')]: {
		display: 'flex'
	}
}))

const MenuIconStyled = styled(MenuIcon)(({ theme }) => ({
	cursor: 'pointer',
	width: 29,
	height: 'auto',
	color: theme.custom.colors.kozmikBlue
}))

const UpgradeButtonStyled = styled(Link)(({ theme }) => ({
	textTransform: 'none',
	backgroundColor: theme.custom.colors.kozmikGreen,
	boxShadow: 'none !important',
	borderRadius: '5px',
	height: '25px',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	padding: '5px 10px',
	marginRight: 10
}))

const UpgradeTextStyled = styled(Typography)(({ theme }) => ({
	color: theme.custom.colors.textPrimary,
	fontSize: 12,
	fontWeight: '600'
}))

const style = {
	overlayContent: {
		flexGrow: 1
	},
	logo: {
		width: 100,
		height: 'auto'
	},
	rightFrameContainer: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center'
	}
}

const MasterLayout = (props) => {
	const { t } = useTranslation()

	const menuList = [
		{
			icon: Apps,
			Name: t('masterNav.apps'),
			Count: props?.apps?.length || 0,
			Href: '/',
			isActive: true
		},
		{
			icon: People,
			Name: t('masterNav.team'),
			Count: '0',
			Href: '/team',
			isActive: false
		}
	]

	const [isMenuVisible, setIsMenuVisible] = useState(false)

	return (
		<MainContainerStyled>
			{props.auth && (
				<>
					<LeftFrameContainerStyled>
						<LeftFrame menuItems={menuList} />
					</LeftFrameContainerStyled>
					<HeaderContainerStyled>
						<Link to={'/'}>
							<KozmikLogo style={style.logo} />
						</Link>
						<div style={style.rightFrameContainer}>
							{!!props?.usage && (
								<UpgradeButtonStyled to={'/subscription?modal=true'}>
									<UpgradeTextStyled>
										{t('masterAccount.upgrade')}
									</UpgradeTextStyled>
								</UpgradeButtonStyled>
							)}
							<MenuIconStyled onClick={() => setIsMenuVisible(true)} />
						</div>
					</HeaderContainerStyled>
					<OverlayMenuStyled
						style={{
							height: isMenuVisible ? '100%' : '0%'
						}}
					>
						<HeaderContainerStyled>
							<KozmikLogo style={style.logo} />
							<MenuIconStyled onClick={() => setIsMenuVisible(false)} />
						</HeaderContainerStyled>
						<div style={style.overlayContent}>
							<LeftFrame
								menuItems={menuList}
								collapseHamburger={() => setIsMenuVisible(false)}
							/>
						</div>
					</OverlayMenuStyled>
				</>
			)}

			{/* {(props?.apps?.length || 0) === 0 && (
				<OnboardingMobile
					builderOnMobile={props.builderOnMobile}
					justSayHello={true}
				/>
			)} */}

			<ContentContainerStyled {...props}>
				{props?.children}
			</ContentContainerStyled>
		</MainContainerStyled>
	)
}

const mapStateToProps = (state) => ({
	...state.auth,
	...state.usage,
	apps: state.applications
})

export default connect(mapStateToProps, null)(MasterLayout)
