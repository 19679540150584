import React, { useEffect, useState, useRef } from 'react'
import { styled } from '@mui/material/styles'
import { useTheme } from '@mui/material/styles'
import { Typography } from '@mui/material'
import Grid from '@mui/material/Grid2'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import { ChromePicker } from 'react-color'
import { BrowserNotSupported } from '@mui/icons-material'

import AppPageBox from '../../atoms/appPageBox/AppPageBox'
import AppPageBoxTitle from '../../atoms/appPageBox/AppPageBoxTitle'
import colorpicker from '../../../assets/images/colorpicker.png'
import RadioButton from '../../atoms/radioButton/RadioButton'
import useOutsideClick from '../../../hooks/UseOutsideClick/useOutsideClick'
import appClient from '../../../services/appClient'
import { setDetail } from '../../../redux/actions/appDataActions'
import SelectBox from '../../molecules/selectBox/SelectBox'

import showToastResponseError from '../../../methods/showToastResponseError'
import postMessageToPreview from '../../../methods/postMessageToPreview'

const PickerPopoverStyled = styled('div')(({ theme }) => ({
	position: 'absolute',
	top: '60px',
	right: '10px',
	//width: '100%',
	backgroundColor: '#FFFFFF',
	borderRadius: '4px',
	boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.17)',
	//padding: '10px',
	zIndex: 2
}))

const RightContainerStyled = styled('div')(({ theme }) => ({
	backgroundColor: '#ffffff'
}))

const TypographyFieldStyled = styled(Typography)(({ theme }) => ({
	padding: '8px',
	fontSize: '14px',
	color: '#61616E',
	backgroundColor: '#F7F7F7',
	border: '1px solid #E3E3E6',
	borderRadius: '4px',
	width: '100%'
	//height: '108px'
}))

const SignTextStyled = styled(Typography)(({ theme }) => ({
	fontSize: '12px'
}))

const PageTitleStyled = styled(Typography)(({ theme }) => ({
	padding: '10px 15px',
	minHeight: 45,
	color: '#000000',
	fontSize: 14,
	lineHeight: '22px',
	textAlign: 'left',
	fontWeight: 'bold',
	borderBottom: `1px solid ${theme.custom.colors.gray}`
}))

const SelectBoxStyled = styled('div')((props) => ({
	...{
		width: '44px',
		cursor: 'pointer',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	...(props.themeIndex === props.index && {
		border: `2px solid ${props.theme.custom.colors.kozmikBlue}`,
		borderRadius: '4px',
		textAlign: 'center'
	})
}))

const style = {
	input: (props, theme) => {
		return {
			backgroundColor: theme.custom.colors.gray,
			borderRadius: '4px !important',
			fontSize: '14px',
			'&.MuiOutlinedInput-root': {
				'& fieldset': {
					border: 0
				},
				'&:hover fieldset': {
					border: 0
				},
				'&.Mui-focused fieldset': {
					border: 0
				}
			}
		}
	}
}

const AppThemeTemplate = (props) => {
	const { t } = useTranslation()
	const theme = useTheme()
	let { appId } = useParams()

	// const [positionIndex, setPositionIndex] = useState(0)
	const [themeIndex, setThemeIndex] = useState(0)
	const [colorIndex, setColorIndex] = useState(0)
	const [color, setColor] = useState('')
	const [customColor, setCustomColor] = useState('#BB0101')
	const [accentColorIndex, setAccentColorIndex] = useState(0)
	const [accentColor, setAccentColor] = useState('')
	const [customAccentColor, setCustomAccentColor] = useState('#BB0101')
	const [colorPickerVisible, setColorPickerVisible] = useState(false)
	const [accentColorPickerVisible, setAccentColorPickerVisible] =
		useState(false)
	const [fontVal, setFontVal] = useState('')

	const colorPickerRef = useRef(null)
	const accentColorPickerRef = useRef(null)
	useOutsideClick(colorPickerRef, () => {
		setColorPickerVisible(false)
	})
	useOutsideClick(accentColorPickerRef, () => {
		setAccentColorPickerVisible(false)
	})

	const setInitialStates = () => {
		if (props.data?.themeData) {
			setThemeIndex(
				props.data.themeData.indexOf(
					props.data.themeData.filter(
						(x) => x.value == props.appDetail?.Theme
					)[0]
				)
			)
		}

		if (props.data?.colorList) {
			const tempColorIndex = props.data.colorList.indexOf(
				props.data.colorList.filter(
					(x) => x.color == props.appDetail?.AccentColor
				)[0]
			)
			if (tempColorIndex > -1) {
				setColorIndex(tempColorIndex)
				setColor(props.data.colorList[tempColorIndex].color)
			} else {
				if (props.appDetail?.AccentColor) {
					setColorIndex('custom')
					setColor(props.appDetail.AccentColor)
					setCustomColor(props.appDetail.AccentColor)
				} else {
					setColorIndex('none')
					setColor(null)
				}
			}
		}

		if (props.data?.accentColorList) {
			const tempAccentColorIndex = props.data.accentColorList.indexOf(
				props.data.accentColorList.filter(
					(x) => x.color == props.appDetail?.AccentContrast
				)[0]
			)
			if (tempAccentColorIndex > -1) {
				setAccentColorIndex(tempAccentColorIndex)
				setAccentColor(props.data.accentColorList[tempAccentColorIndex].color)
			} else {
				if (props.appDetail?.AccentContrast) {
					setAccentColorIndex('custom')
					setAccentColor(props.appDetail.AccentContrast)
					setCustomAccentColor(props.appDetail.AccentContrast)
				} else {
					setAccentColorIndex('none')
					setAccentColor(null)
				}
			}
		}

		if (props.data?.fontData) {
			const tempfontIndex = props.data.fontData.indexOf(
				props.data.fontData.filter(
					(x) => x.value == props.appDetail?.FontFamily
				)[0]
			)
			if (tempfontIndex > -1) {
				setFontVal(props.data.fontData[tempfontIndex].value)
			} else {
				setFontVal('')
			}
		}
	}

	const postData = (propName, propData) => {
		if (appId) {
			let updateData = {
				Id: appId
			}
			updateData[propName] = propData

			appClient.update(updateData).then((response) => {
				if (response?.data?.success) {
					updateReduxDetailData(propName, propData)
					triggerPropPostMessage(propName, propData)
				} else {
					showToastResponseError(response)
				}
			})
		}
	}

	const updateReduxDetailData = (propName, propData) => {
		let tempData = { ...props.appDetail }
		tempData[propName] = propData
		props.setDetail(tempData)
	}

	const triggerPropPostMessage = (propName, value) => {
		const postData = {
			source: 'kozmik-builder',
			updatedProp: {
				objectName: 'appearence',
				propName: propName,
				value: value
			}
		}

		postMessageToPreview(postData)
	}

	useEffect(() => {
		props.appDetail && setInitialStates()
	}, [props.appDetail])

	return (
		<RightContainerStyled>
			<PageTitleStyled>{t('appThemeTemp.theme')}</PageTitleStyled>
			<AppPageBox>
				{props.data?.themeData && (
					<Grid
						container
						direction='row'
						alignItems='flex-end'
						justifyContent='flex-end'
					>
						{props.data.themeData.map((item, index) => (
							<div
								key={index}
								style={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									flexDirection: 'column',
									padding: '0 8px'
								}}
							>
								<SelectBoxStyled
									index={index}
									themeIndex={themeIndex}
									style={{ height: '91px' }}
									onClick={() => {
										setThemeIndex(index)
										postData('Theme', item.value)
									}}
								>
									<img
										src={item.src}
										style={{ width: '40px', height: '87px' }}
									/>
								</SelectBoxStyled>
								<SignTextStyled>{item.name}</SignTextStyled>
							</div>
						))}
					</Grid>
				)}
				{props.data?.colorList && (
					<Grid
						container
						size={{ xs: 12, md: 12 }}
						display={'flex'}
						style={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between',
							alignItems: 'center',
							paddingTop: '15px'
						}}
					>
						<Grid size={{ xs: 12 }}>
							<AppPageBoxTitle
								text={t('appThemeTemp.accentColor')}
								style={{ fontWeight: 600, marginBottom: 10 }}
							/>
						</Grid>
						<Grid
							container
							size={{ xs: 12 }}
							display='flex'
							alignItems='center'
							justifyContent={'flex-end'}
							style={{ position: 'relative' }}
						>
							<RadioButton
								checked={'none' == colorIndex}
								onClick={() => {
									setColorIndex('none')
									postData('AccentColor', null)
								}}
								icon={BrowserNotSupported}
							/>
							{props.data.colorList.map((item, index) => (
								<RadioButton
									checked={index == colorIndex}
									key={index}
									onClick={() => {
										setColor(item.color)
										postData('AccentColor', item.color)
										setColorIndex(index)
									}}
									radioColor={item?.color}
									imgUrl={item?.imgUrl}
								/>
							))}
							<RadioButton
								checked={'custom' == colorIndex}
								onClick={() => {
									setAccentColorPickerVisible(false)
									setColorPickerVisible(true)
								}}
								imgUrl={colorpicker}
							/>
							{colorPickerVisible && (
								<PickerPopoverStyled ref={colorPickerRef}>
									<ChromePicker
										disableAlpha
										color={color ? color : '#000000'}
										onChangeComplete={(color) => {
											setColor(color.hex)
											postData('AccentColor', color.hex)
											setColorIndex('custom')
											setCustomColor(color.hex)
										}}
									/>
								</PickerPopoverStyled>
							)}
						</Grid>
					</Grid>
				)}
			</AppPageBox>

			<AppPageBox>
				<AppPageBoxTitle style={{ marginTop: 10, marginBottom: 10 }} />

				{props.data?.fontData && (
					<Grid
						container
						size={{ xs: 12, md: 12 }}
						display='flex'
						alignItems='center'
						justifyContent={'flex-end'}
						style={{ position: 'relative' }}
					>
						<Grid size={{ xs: 4 }}>
							<AppPageBoxTitle
								text={t('appTypoTemp.font')}
								style={{ fontSize: '14px', fontWeight: 600, marginBottom: 10 }}
							/>
						</Grid>
						<Grid size={{ xs: 8 }}>
							<SelectBox
								sx={style.input(props, theme)}
								onChange={(e) => {
									setFontVal(e.target.value)
									postData('FontFamily', e.target.value)
								}}
								value={fontVal}
								data={props.data.fontData}
								textKey='text'
								valueKey='value'
							/>
						</Grid>
					</Grid>
				)}
				<Grid size={{ xs: 12 }} style={{ paddingTop: '15px' }}>
					<TypographyFieldStyled style={{ fontFamily: fontVal }}>
						{fontVal} <br />
						Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
						eiusmod tempor incididunt ut labore et dolore magna aliqua
					</TypographyFieldStyled>
				</Grid>
			</AppPageBox>
		</RightContainerStyled>
	)
}

const mapStateToProps = (state) => ({
	...state.appData
})

const mapDispatchToProps = (dispatch) => {
	return {
		setDetail: (x) => dispatch(setDetail(x))
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AppThemeTemplate)
