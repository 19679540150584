import React, { useState } from 'react'
import { styled } from '@mui/material/styles'
import Ionicons from 'react-native-vector-icons/dist/Ionicons'
import glyphMap from 'react-native-vector-icons/dist/glyphmaps/Ionicons.json'
import {
	Box,
	Popover,
	CircularProgress,
	InputAdornment,
	Typography
} from '@mui/material'
import { Search, ArrowDropDown } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroller'

import KozmikTextField from '../../atoms/kozmikTextField/KozmikTextField'

const SelectButtonStyled = styled((props) => {
	const { fullWidth, bgColor, ...restProps } = props

	return <Box {...restProps} />
})((props) => ({
	width: props?.fullWidth ? '100%' : 80,
	padding: '0 8px',
	height: 38,
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	cursor: 'pointer',
	borderRadius: 3,
	backgroundColor: props?.bgColor
		? props.bgColor
		: props.theme.custom.colors.kozmikGray
}))

const EmptyTextStyled = styled(Typography)(({ theme }) => ({
	fontSize: 14,
	color: theme.custom.colors.textSecondary
}))

const PopoverBoxStyled = styled(Box)(({ theme }) => ({
	padding: 8,
	display: 'flex',
	flexDirection: 'column'
}))

const InputBoxStyled = styled(Box)(({ theme }) => ({ width: 233 }))

const ScrollBoxStyled = styled(Box)(({ theme }) => ({
	width: 233,
	maxHeight: 360,
	overflow: 'auto'
}))

const IconBoxStyled = styled((props) => {
	const { isActive, ...restProps } = props

	return <Box {...restProps} />
})((props) => ({
	...{
		width: '20%',
		height: 45,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		cursor: 'pointer',
		borderRadius: 8,
		'&:hover': {
			backgroundColor: props.theme.custom.colors.kozmikGray
		}
	},
	...(props?.isActive
		? {
				backgroundColor: `${props.theme.custom.colors.kozmikLightBlue} !important`
		  }
		: null)
}))

const InputWithAdornmentStyled = styled(KozmikTextField)(({ theme }) => ({
	backgroundColor: theme.custom.colors.kozmikGray,
	borderRadius: '3px',
	border: 'none',
	marginBottom: 4,
	'& .MuiInputBase-adornedStart': {
		paddingLeft: 0,
		overflow: 'hidden'
	}
}))

const AdornmentStyled = styled(Box)(({ theme }) => ({
	backgroundColor: theme.custom.colors.kozmikGray,
	padding: '6px 10px',
	color: theme.custom.colors.textPrimary,
	cursor: 'pointer',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center'
}))

const DropDownIconStyled = styled(ArrowDropDown)(({ theme }) => ({
	color: theme.custom.colors.textSecondary,
	opacity: 0.6,
	cursor: 'pointer'
}))

const SearchIconStyled = styled(Search)(({ theme }) => ({
	color: theme.custom.colors.textSecondary,
	opacity: 0.6,
	cursor: 'pointer'
}))

const IoniconsSelect = (props) => {
	const { t } = useTranslation()

	const iconNames = Object.keys(glyphMap).filter((x) => !x.endsWith('-sharp'))

	const [iconPop, setIconPop] = useState(null)
	const iconPopOpen = Boolean(iconPop)
	const openIconPop = (event) => {
		setIconPop(event.currentTarget)
		setIcons(iconNames)
		setVisibleIcons([])
		setSearchTerm('')
	}

	const [searchTerm, setSearchTerm] = useState('')
	const [icons, setIcons] = useState([])
	const [visibleIcons, setVisibleIcons] = useState([])

	const loadFunc = (pageNumber) => {
		const visibles = icons.slice(0, pageNumber * 40)
		setVisibleIcons(visibles)
	}

	const searchTermOnchange = (e) => {
		setSearchTerm(e.target.value)
		const filteredIcons = iconNames?.filter((x) =>
			x?.toLowerCase()?.includes(e.target.value?.toLowerCase())
		)
		setIcons(filteredIcons)
		setVisibleIcons([])
	}

	const onChangeHandler = (newVal, currentVal) => {
		if (newVal != currentVal) {
			props?.onChange(newVal)
		}
		setIconPop(null)
	}

	return (
		<>
			<SelectButtonStyled
				fullWidth={props?.fullWidth}
				bgColor={props?.bgColor}
				onClick={openIconPop}
			>
				{props?.value ? (
					<Ionicons name={props?.value} size={17} color='#000000' />
				) : (
					<EmptyTextStyled>{t('ionIconsSelect.select')}</EmptyTextStyled>
				)}

				<DropDownIconStyled />
			</SelectButtonStyled>
			<Popover
				id={'icon-popover'}
				open={iconPopOpen}
				anchorEl={iconPop}
				onClose={() => setIconPop(null)}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center'
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center'
				}}
			>
				<PopoverBoxStyled>
					<InputBoxStyled>
						<InputWithAdornmentStyled
							fullWidth
							placeholder={t('ionIconsSelect.search')}
							value={searchTerm}
							onChange={searchTermOnchange}
							slotProps={{
								input: {
									startAdornment: (
										<InputAdornment position='start'>
											<AdornmentStyled>
												<SearchIconStyled />
											</AdornmentStyled>
										</InputAdornment>
									)
								}
							}}
						/>
					</InputBoxStyled>
					<ScrollBoxStyled>
						<InfiniteScroll
							pageStart={0}
							loadMore={loadFunc}
							hasMore={icons?.length != visibleIcons?.length}
							loader={
								<CircularProgress key={0} size={16} sx={{ margin: '10px' }} />
							}
							useWindow={false}
							style={{ display: 'flex', flexWrap: 'wrap' }}
						>
							{!!props?.emptySelectable && (
								<IconBoxStyled
									value={props?.value}
									onClick={() => onChangeHandler('', props?.value)}
								/>
							)}
							{visibleIcons.map((item, index) => (
								<IconBoxStyled
									isActive={item == props?.value}
									key={index}
									onClick={() => onChangeHandler(item, props?.value)}
								>
									<Ionicons name={item} size={25} color='#000000' />
								</IconBoxStyled>
							))}
						</InfiniteScroll>
					</ScrollBoxStyled>
				</PopoverBoxStyled>
			</Popover>
		</>
	)
}
export default IoniconsSelect
