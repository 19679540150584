import React from 'react'
import { styled } from '@mui/material/styles'

const BoxStyled = styled('div')(({ theme }) => ({
	width: '100%',
	backgroundColor: 'white',
	padding: '10px 15px',
	borderBottom: '3px solid #fbfbfc'
}))

const AppPageBox = (props) => {
	return <BoxStyled {...props}></BoxStyled>
}

export default AppPageBox
