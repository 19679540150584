import { ApiClient } from './client'
import qs from 'qs'
import Cookies from 'universal-cookie'

import { default as appConfig } from '../config/app.json'
import store from '../redux/store/store'
import {
	setUserInfo,
	setOrg,
	setCurrentOrg
} from '../redux/actions/userInfoActions'
import { setUsage } from '../redux/actions/usageActions'
import tokenServices from './tokenServices'
import currentOrgService from './custom/currentOrgService'

const apiEndpoint = appConfig.endpoints.api

const cookies = new Cookies()

const orgDataExtractor = (userInfo) => {
	let organizations = []

	const createOrgObject = (array) => {
		return {
			id: array.length >= 1 ? array[0] : null,
			code: array.length >= 2 ? array[1] : null,
			name: array.length >= 3 ? array[2] : null,
			roleType: array.length >= 4 ? array[3] : null
		}
	}

	if (typeof userInfo?.orgs == 'object') {
		userInfo.orgs.map((item) => {
			const array = item.split('|')
			organizations.push(createOrgObject(array))
		})
	} else if (typeof userInfo?.orgs == 'string') {
		const array = userInfo.orgs.split('|')
		organizations.push(createOrgObject(array))
	} else {
		return []
	}
	return organizations
}

export default {
	async register(data = {}, headers = {}) {
		headers['content-type'] = 'application/json'

		return await new ApiClient(apiEndpoint, headers).post(
			'/api/b/register',
			data
		)
	},
	async invitation(data = {}, headers = {}) {
		headers['content-type'] = 'application/json'

		return await new ApiClient(apiEndpoint, headers).post(
			'/api/b/invitation',
			data
		)
	},
	async acceptInvitation(data = {}, headers = {}) {
		headers['content-type'] = 'application/json'

		return await new ApiClient(apiEndpoint, headers).post(
			'/api/b/acceptinvitation',
			data
		)
	},
	async login(data = {}, headers = {}) {
		headers['content-type'] = 'application/json'

		return await new ApiClient(apiEndpoint, headers).post('/api/b/login', data)
	},
	async getUserInfo() {
		return await new ApiClient(apiEndpoint, {})
			.get('/api/b/me')
			.then((response) => {
				store.dispatch(setUserInfo(response.data))
				store.dispatch(setOrg(response.data.orgs))

				const orgId = cookies.get('orgId')

				if (orgId && orgId != 'null') {
					const currentOrg = response.data.orgs.find((x) => x.id === orgId)

					if (currentOrg) {
						currentOrgService.set(currentOrg)
					} else {
						currentOrgService.set(response.data.orgs[0])
					}
				} else {
					currentOrgService.set(response.data.orgs[0])
				}
			})
			.catch((error) => {
				console.log('ERROR: ', error)
			})
	},
	async refreshTokenAndUserInfo() {
		const refreshToken = cookies.get(
			appConfig.cookieKeys.MY_SECURE_REFRESH_TOKEN_KEY
		)
		if (refreshToken && refreshToken !== 'undefined') {
			return this.refresh(refreshToken).then(async (res) => {
				tokenServices.add(res.data.data)

				await this.getUserInfo()
			})
		}
	},
	async logout() {
		return await new ApiClient(apiEndpoint, {})
			.post('/api/b/logout')
			.then(async (response) => {
				if (response?.data?.success) {
					tokenServices.remove()
					store.dispatch(setUserInfo(null))
					store.dispatch(setOrg([]))

					// currentOrgService.remove()
				} else {
					console.log('ERROR', response)
				}
			})
			.then(() => {
				window.location.href = '/login'
			})
			.catch((error) => {})
	},
	async forgotPassword(data = {}, headers = {}) {
		headers['content-type'] = 'application/json'

		return await new ApiClient(apiEndpoint, headers).post(
			'/api/b/forgotpassword',
			data
		)
	},
	async resetpassword(data = {}, headers = {}) {
		headers['content-type'] = 'application/json'

		return await new ApiClient(apiEndpoint, headers).post(
			'/api/b/resetpassword',
			data
		)
	},
	async changePassword(data, headers = {}) {
		headers['content-type'] = 'application/json'

		return await new ApiClient(apiEndpoint, headers).post(
			'/api/b/changepassword',
			data
		)
	},
	async changeProfile(data, headers = {}) {
		headers['content-type'] = 'application/json'

		return await new ApiClient(apiEndpoint, headers).post(
			'/api/b/changeprofile',
			data
		)
	},
	async accountDeactivate() {
		return await new ApiClient(apiEndpoint, {}).post('/api/b/deactivate')
	},
	async refresh(refreshToken, headers = {}) {
		let data = {
			refreshToken: refreshToken
		}
		headers['content-type'] = 'application/json'

		return await new ApiClient(apiEndpoint, headers).post(
			'/api/b/refresh',
			data
		)
	},
	async getUsage() {
		return await new ApiClient(apiEndpoint, {})
			.get('/api/org/usage')
			.then((response) => {
				if (response?.data?.success) {
					store.dispatch(setUsage(response.data.data))
				}
			})
			.catch((error) => {
				console.log('ERROR: ', error)
			})
	}
}
