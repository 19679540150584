import { ApiClient } from './client'

import { default as appConfig } from '../config/app.json'

const apiEndpoint = `${appConfig.endpoints.api}/api/datasource`

export default {
	async testConnection(data = null) {
		let url = `/test`
		let headers = {}

		return await new ApiClient(apiEndpoint, headers).post(url, data)
	},
	async getEntitySummaries(data = null) {
		let url = `/getEntitySummaries`
		let headers = {}

		return await new ApiClient(apiEndpoint, headers).post(url, data)
	}
}
