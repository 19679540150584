import React from 'react'
import { styled } from '@mui/material/styles'

import BorderlessTextField from '../borderlessTextField/BorderlessTextField'

const KozmikTextFieldStyled = styled((props) => {
	const { slotProps, bgColor, ...restProps } = props

	return (
		<BorderlessTextField
			size='small'
			variant='outlined'
			slotProps={{
				...slotProps
			}}
			{...restProps}
		/>
	)
})((props) => ({
	'& .MuiOutlinedInput-root': {
		fontSize: 14
	},
	input: {
		borderRadius: 3,
		backgroundColor: props?.bgColor
			? props.bgColor
			: props.theme.custom.colors.kozmikGray,
		color: props.theme.custom.colors.textPrimary
	}
}))

const KozmikTextField = (props) => {
	return <KozmikTextFieldStyled {...props} />
}
export default KozmikTextField
