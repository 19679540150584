import React, { useEffect, useState } from 'react'
import { useTheme } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'
import Grid from '@mui/material/Grid2'
import update from 'immutability-helper'
import {
	PersonOutlineOutlined,
	PhoneIphoneOutlined,
	RuleOutlined
} from '@mui/icons-material'
import { connect } from 'react-redux'

import SelectBox from '../selectBox/SelectBox'
import AppPageBox from '../../atoms/appPageBox/AppPageBox'
import Accordion from '../../atoms/accordion/Accordion'
import AccordionItem from '../../atoms/accordionItem/AccordionItem'
import FilterList from '../filterList/FilterList'

import filterOperators from '../../../common/filterOperators'
import getValueType from '../../../methods/getValueType'
import ButtonOptions from '../buttonOptions/ButtonOptions'

import styledUI from '../../../styledUI'

const style = {
	groupOpContainer: {
		display: 'flex',
		alignItems: 'center',
		marginTop: 10,
		marginBottom: 10
	}
}

const VisibilityOptions = (props) => {
	const { t } = useTranslation()
	const theme = useTheme()

	/****VISIBILITY***************************/
	const visibilityOptions = [
		{
			label: t('screens.visibilityOptions.everyone'),
			value: 'everyone'
		},
		{
			label: t('screens.visibilityOptions.loggedInUser'),
			value: 'loggedInUser'
		}
	]

	const groupOpOpts = [
		{
			text: t('screens.elementSettings.filter.list.opOpts.and'),
			value: 'AND'
		},
		{
			text: t('screens.elementSettings.filter.list.opOpts.or'),
			value: 'OR'
		}
	]

	const getDefaultVisibility = (
		visible,
		profileGroupOp = null,
		profileGroupRules = null,
		screenGroupOp = null,
		screenGroupRules
	) => {
		return {
			visible: visible,
			conditions: {
				groupOp: 'AND',
				groups: [
					{
						groupOp: profileGroupOp || 'AND',
						rules: profileGroupRules || []
					},
					{
						groupOp: screenGroupOp || 'AND',
						rules: screenGroupRules || []
					}
				]
			}
		}
	}

	const [visible, setVisible] = useState('everyone')

	const visibleOnChange = (e) => {
		submitVisible(e.target.value)
		setVisible(e.target.value)
	}

	const submitVisible = (value) => {
		let modifiedBlockData
		if (props.block?.visibility) {
			modifiedBlockData = update(props.block, {
				visibility: {
					$set: getDefaultVisibility(value)
				}
			})
		} else {
			modifiedBlockData = {
				...props.block,
				...{
					visibility: getDefaultVisibility(value)
				}
			}
		}
		props.updateBlock(modifiedBlockData)
	}

	/****FILTERING_START***************************/
	const [conditions, setConditions] = useState(null)

	const groupOpChange = (val) => {
		let modifiedBlockData

		if (props.block?.visibility) {
			modifiedBlockData = update(props.block, {
				visibility: {
					conditions: {
						groupOp: { $set: val }
					}
				}
			})
		} else {
			modifiedBlockData = {
				...props.block,
				...{
					visibility: {
						conditions: {
							groupOp: val
						}
					}
				}
			}
		}
		props.updateBlock(modifiedBlockData)

		setConditions(modifiedBlockData.visibility.conditions)
	}

	const addNewCondition = (table, index = null, feature = null) => {
		if (table?.fields?.length) {
			const firstField = table.fields.find((x) => x.name != 'Id')
			const filterOp = filterOperators.find((x) =>
				x.dataTypes.includes(firstField.dataType)
			)

			const newRule = {
				type: 'Field',
				dataType: firstField?.dataType,
				field: firstField?.name,
				op: filterOp?.searchOp ?? 'eq',
				data: firstField?.name
			}

			let modifiedBlockData

			if (!props.block?.visibility?.conditions) {
				modifiedBlockData = update(props.block, {
					visibility: {
						$set: getDefaultVisibility(
							visible,
							'AND',
							feature === 'profile' ? [newRule] : [],
							'AND',
							feature === 'screen' ? [newRule] : []
						)
					}
				})

				props.updateBlock(modifiedBlockData)
			} else {
				modifiedBlockData = update(props.block, {
					visibility: {
						conditions: {
							groups: {
								[index]: {
									rules: { $push: [newRule] }
								}
							}
						}
					}
				})

				props.updateBlock(modifiedBlockData)
			}

			setConditions(modifiedBlockData.visibility.conditions)
		} else {
			console.log('Table has no fields')
		}
	}

	const updateConditionField = (
		ruleIndex,
		dataType,
		value,
		filterOp,
		index = null,
		feature = null,
		ruleType = 'Field',
		dataValue = null
	) => {
		const modifiedBlockData = update(props.block, {
			visibility: {
				conditions: {
					groups: {
						[index]: {
							rules: {
								[ruleIndex]: {
									type: { $set: ruleType },
									dataType: { $set: dataType },
									field: { $set: value },
									op: { $set: filterOp },
									data: { $set: dataValue !== null ? dataValue : value }
								}
							}
						}
					}
				}
			}
		})
		props.updateBlock(modifiedBlockData)

		setConditions(modifiedBlockData.visibility.conditions)
	}

	const updateConditionOp = (
		ruleIndex,
		value,
		index = null,
		feature = null
	) => {
		const modifiedBlockData = update(props.block, {
			visibility: {
				conditions: {
					groups: {
						[index]: {
							rules: {
								[ruleIndex]: {
									op: { $set: value }
								}
							}
						}
					}
				}
			}
		})
		props.updateBlock(modifiedBlockData)

		setConditions(modifiedBlockData.visibility.conditions)
	}

	const updateConditionTypeAndData = (
		ruleIndex,
		data,
		ruleType,
		index = null,
		feature = null
	) => {
		const modifiedBlockData = update(props.block, {
			visibility: {
				conditions: {
					groups: {
						[index]: {
							rules: {
								[ruleIndex]: {
									type: { $set: getValueType(data, ruleType) },
									data: { $set: data }
								}
							}
						}
					}
				}
			}
		})
		props.updateBlock(modifiedBlockData)

		setConditions(modifiedBlockData.visibility.conditions)
	}

	const updateConditionGroupOp = (value, index = null, feature = null) => {
		const modifiedBlockData = update(props.block, {
			visibility: {
				conditions: {
					groups: {
						[index]: {
							groupOp: { $set: value }
						}
					}
				}
			}
		})
		props.updateBlock(modifiedBlockData)

		setConditions(modifiedBlockData.visibility.conditions)
	}

	const deleteCondition = (ruleIndex, index = null, feature = null) => {
		const modifiedBlockData = update(props.block, {
			visibility: {
				conditions: {
					groups: {
						[index]: {
							rules: {
								$splice: [[ruleIndex, 1]]
							}
						}
					}
				}
			}
		})
		props.updateBlock(modifiedBlockData)

		setConditions(modifiedBlockData.visibility.conditions)
	}

	const filtering = {
		addNewRule: addNewCondition,
		updateRuleField: updateConditionField,
		updateRuleOp: updateConditionOp,
		updateRuleTypeAndData: updateConditionTypeAndData,
		updateGroupOp: updateConditionGroupOp,
		deleteRule: deleteCondition
	}
	/****FILTERING_END***************************/

	/****INITIALIZATION***************************/
	const initOptions = () => {
		if (props.block?.visibility?.filters) {
			const modifiedBlockData = update(props.block, {
				visibility: {
					$set: getDefaultVisibility(
						props?.block?.visibility?.screen || 'everyone',
						props?.block?.visibility?.filters?.groupOp,
						props?.block?.visibility?.filters?.rules
					)
				}
			})

			props.updateBlock(modifiedBlockData)

			setVisible(props?.block?.visibility?.screen || 'everyone')
			setConditions(modifiedBlockData.visibility.conditions)
		} else {
			setVisible(props?.block?.visibility?.visible || 'everyone')
			setConditions(props?.block?.visibility?.conditions)
		}
	}

	useEffect(() => {
		props.block && initOptions()
	}, [props.block])

	return (
		<AppPageBox>
			<Accordion
				title={t('screens.visibilityOptions.visibilityTitle')}
				expanded={true}
			>
				<styledUI.StyledGrid container alignItems='center'>
					<Grid size={{ xs: 12 }}>
						<SelectBox
							sx={styledUI.Style.selectBox(props, theme)}
							onChange={visibleOnChange}
							value={visible || ''}
							data={visibilityOptions}
							textKey='label'
							valueKey='value'
						/>
					</Grid>
				</styledUI.StyledGrid>

				{props.block?.component ? (
					visible === 'loggedInUser' && props.activeScreen?.data?.source ? (
						<AccordionItem
							icon={RuleOutlined}
							title={t('screens.visibilityOptions.conditions')}
							expanded={false}
							padding={8}
						>
							<AccordionItem
								bgColor='#F0F0F4'
								icon={PersonOutlineOutlined}
								title={t('screens.visibilityOptions.conditionsByProfile')}
								expanded={true}
								padding={8}
							>
								<FilterList
									addNewText={t('screens.visibilityOptions.addCondition')}
									filter={conditions?.groups?.[0] || []}
									index={0}
									feature={'profile'}
									activeScreen={props.activeScreen}
									entities={props.entities}
									filtering={filtering}
									table={props.entities?.find(
										(y) => y.name == props.appDetail?.DataSource?.UsersTableName
									)}
									tableName={props.appDetail?.DataSource?.UsersTableName}
								/>
							</AccordionItem>
							<div style={style.groupOpContainer}>
								<ButtonOptions
									boxColor='#ffffff'
									padding={8}
									data={groupOpOpts}
									getChange={groupOpChange}
									activeValue={conditions?.groupOp}
								/>
							</div>
							<AccordionItem
								bgColor='#F0F0F4'
								icon={PhoneIphoneOutlined}
								title={t('screens.visibilityOptions.conditionsByScreen')}
								expanded={true}
								padding={8}
							>
								<FilterList
									addNewText={t('screens.visibilityOptions.addCondition')}
									filter={conditions?.groups?.[1] || []}
									index={1}
									feature={'screen'}
									activeScreen={props.activeScreen}
									entities={props.entities}
									filtering={filtering}
									table={props.entities?.find(
										(y) => y.name == props.activeScreen?.data?.source
									)}
									tableName={props.activeScreen?.data?.source}
								/>
							</AccordionItem>
						</AccordionItem>
					) : visible === 'loggedInUser' ? (
						<AccordionItem
							icon={RuleOutlined}
							title={t('screens.visibilityOptions.conditionsByProfile')}
							expanded={false}
							padding={8}
						>
							<FilterList
								addNewText={t('screens.visibilityOptions.addCondition')}
								filter={conditions?.groups?.[0] || []}
								index={0}
								feature={'profile'}
								activeScreen={props.activeScreen}
								entities={props.entities}
								filtering={filtering}
								table={props.entities?.find(
									(y) => y.name == props.appDetail?.DataSource?.UsersTableName
								)}
								tableName={props.appDetail?.DataSource?.UsersTableName}
							/>
						</AccordionItem>
					) : props.activeScreen?.data?.source ? (
						<AccordionItem
							icon={RuleOutlined}
							title={t('screens.visibilityOptions.conditionsByScreen')}
							expanded={false}
							padding={8}
						>
							<FilterList
								addNewText={t('screens.visibilityOptions.addCondition')}
								filter={conditions?.groups?.[1] || []}
								index={1}
								feature={'screen'}
								activeScreen={props.activeScreen}
								entities={props.entities}
								filtering={filtering}
								table={props.entities?.find(
									(y) => y.name == props.activeScreen?.data?.source
								)}
								tableName={props.activeScreen?.data?.source}
							/>
						</AccordionItem>
					) : null
				) : (
					visible === 'loggedInUser' && (
						<AccordionItem
							icon={RuleOutlined}
							title={t('screens.visibilityOptions.conditionsByProfile')}
							expanded={false}
							padding={8}
						>
							<FilterList
								addNewText={t('screens.visibilityOptions.addCondition')}
								filter={conditions?.groups?.[0] || []}
								index={0}
								feature={'profile'}
								activeScreen={props.activeScreen}
								entities={props.entities}
								filtering={filtering}
								table={props.entities?.find(
									(y) => y.name == props.appDetail?.DataSource?.UsersTableName
								)}
								tableName={props.appDetail?.DataSource?.UsersTableName}
							/>
						</AccordionItem>
					)
				)}
			</Accordion>
		</AppPageBox>
	)
}

const mapStateToProps = (state) => ({
	...state.appData
})

export default connect(mapStateToProps, null)(VisibilityOptions)
