import React, { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import Grid from '@mui/material/Grid2'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'

import AppHeader from '../../templates/appHeader/AppHeader'
import { setDetail } from '../../../redux/actions/appDataActions'
import DetailLeftFrame from '../../templates/detailLeftFrame/DetailLeftFrame'

import appClient from '../../../services/appClient'
import storeSettingClient from '../../../services/storeSettingClient'

import showToastResponseError from '../../../methods/showToastResponseError'

const MainContainerStyled = styled('div')(({ theme }) => ({
	borderRadius: 3,
	backgroundColor: '#FAFAFC',
	fontSize: '14px',
	color: theme.custom.colors.textPrimary
}))

const ContentContainerStyled = styled('div')(({ theme }) => ({
	height: '100%',
	backgroundColor: '#FAFAFC',
	flex: 1,
	overflow: 'hidden',
	[theme.breakpoints.down('md')]: {
		height: 'auto',
		paddingBottom: 90
	},
	[theme.breakpoints.down('sm')]: {
		paddingBottom: 10
	}
}))

const style = {
	pageContainer: {
		height: 'calc(100vh - 64px)'
	}
}

const AppDetailLayout = (props) => {
	let { appId } = useParams()

	const [setting, setSetting] = useState(null)

	const getDetailData = () => {
		if (appId) {
			appClient.detail(appId).then((response) => {
				if (response.status == 200) {
					props.setDetail(response.data)
				} else {
					showToastResponseError(response)
				}
			})
		}
	}

	const getSetting = () => {
		storeSettingClient.getSetting(appId).then((response) => {
			if (response?.data?.success) {
				setSetting(response?.data?.data)
			} else {
				showToastResponseError(response)
			}
		})
	}

	useEffect(() => {
		getDetailData()
		getSetting()

		return () => {
			props.setDetail(null)
		}
	}, [])

	return (
		<MainContainerStyled>
			{props.auth && (
				<AppHeader
					previewButtonsVisible={props.previewButtonsVisible}
					previewButtonIndex={props.previewButtonIndex}
					setPreviewButtonIndex={props.setPreviewButtonIndex}
					setting={setting}
				/>
			)}
			<Grid container style={style.pageContainer}>
				{props.auth && (
					<DetailLeftFrame
						activeMenuEvent={props.activeMenuEvent}
						setActiveMenuEvent={props.setActiveMenuEvent}
						isFullPagePreview={props.isFullPagePreview}
					/>
				)}
				<ContentContainerStyled>{props?.children}</ContentContainerStyled>
			</Grid>
		</MainContainerStyled>
	)
}

const mapStateToProps = (state) => ({
	...state.auth
})

const mapDispatchToProps = (dispatch) => {
	return {
		setDetail: (x) => dispatch(setDetail(x))
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(AppDetailLayout)
