import React, { useState, useEffect } from 'react'
import { styled } from '@mui/material/styles'
import { useTheme } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'
import { Box, Popover } from '@mui/material'
import { connect } from 'react-redux'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'

import ProfileCard from '../../molecules/profileCard/ProfileCard'
import PopoverItem from '../../molecules/popoverItem/PopoverItem'

import { setGhostUser } from '../../../redux/actions/userInfoActions'

const StyledMainContainer = styled('div')((props) => ({
	...(props.mobile
		? {
				display: 'flex',
				alignItems: 'center',
				maxWidth: 200,
				borderRadius: 6,
				padding: '2px 5px',
				cursor: 'pointer',
				backgroundColor: props.theme.custom.colors.kozmikLightBlue2,
				[props.theme.breakpoints.up('md')]: {
					display: 'none'
				}
		  }
		: props.inline
		? {
				display: 'flex',
				alignItems: 'center',
				maxWidth: 200,
				borderRadius: 6,
				padding: 5,
				cursor: 'pointer',
				[props.theme.breakpoints.down('md')]: {
					display: 'none'
				}
		  }
		: {
				display: 'flex',
				alignItems: 'center',
				maxWidth: 200,
				height: 40,
				borderRadius: 6,
				padding: 5,
				cursor: 'pointer',
				position: 'absolute',
				left: '18%',
				top: 10,
				boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.03)',
				[props.theme.breakpoints.down('md')]: {
					display: 'none'
				}
		  })
}))

const StyledInfoBox = styled('div')(({ theme }) => ({
	flexGrow: 1,
	paddingLeft: 10,
	paddingRight: 10,
	overflow: 'hidden'
}))

const StyledNameText = styled('div')(({ theme }) => ({
	color: theme.custom.colors.textPrimary,
	fontSize: 11,
	textAlign: 'left',
	fontWeight: 600,
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis'
}))

const StyledPopoverBox = styled(Box)(({ theme }) => ({
	padding: 8,
	display: 'flex',
	flexDirection: 'column'
}))

const StyledViewAsIcon = styled(ArrowDropDownIcon)(({ theme }) => ({
	fontSize: 18,
	color: theme.custom.colors.textPrimary
}))

const ViewAs = (props) => {
	const theme = useTheme()
	const { t } = useTranslation()

	const [currenUser, setCurrenUser] = useState(null)
	const [viewAsPop, setViewAsPop] = useState(null)

	const openViewAsPop = (event) => {
		setViewAsPop(event.currentTarget)
	}
	const closeViewAsPop = () => {
		setViewAsPop(null)
	}
	const viewAsPopOpen = Boolean(viewAsPop)

	const selectViewAsUser = (user) => {
		setCurrenUser(user)

		props.setGhostUser(user)

		closeViewAsPop()
	}

	useEffect(() => {
		props.ghostUser &&
			props.ghostUser != currenUser &&
			setCurrenUser(props.ghostUser)
	}, [props.ghostUser])

	return (
		<>
			<StyledMainContainer
				mobile={props.mobile}
				inline={props.inline}
				onClick={openViewAsPop}
			>
				<ProfileCard
					imgUrl={currenUser?.Photo}
					text={currenUser?.Name ?? t('viewAs.anyone')}
					size={25}
					circle
					bgColor={theme.custom.colors.gray}
				/>
				<StyledInfoBox>
					<StyledNameText>
						{t('viewAs.viewAs')}
						{currenUser?.Name ?? t('viewAs.anyone')}
					</StyledNameText>
				</StyledInfoBox>
				{props.users?.length > 0 && <StyledViewAsIcon />}
			</StyledMainContainer>

			{props.users?.length > 0 && (
				<Popover
					style={{ maxHeight: 500 }}
					open={viewAsPopOpen}
					anchorEl={viewAsPop}
					onClose={closeViewAsPop}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'left'
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'left'
					}}
				>
					<StyledPopoverBox>
						<PopoverItem
							key={-1}
							text={t('viewAs.anyone')}
							onClick={() => {
								selectViewAsUser(null)
							}}
							style={{ marginTop: 3, marginBottom: 3 }}
							color='#000000'
						>
							<ProfileCard
								text={t('viewAs.anyone')}
								size={25}
								circle
								bgColor={theme.custom.colors.gray}
							/>
						</PopoverItem>
						{props.users
							?.filter(
								(x) => (currenUser && x.Id != currenUser?.Id) || !currenUser
							)
							.map((item, index) => (
								<PopoverItem
									key={index}
									text={item?.Name}
									onClick={() => {
										selectViewAsUser(item)
									}}
									style={{ marginTop: 3, marginBottom: 3 }}
									color='#000000'
								>
									<ProfileCard
										imgUrl={item?.Photo}
										text={item?.Name}
										size={25}
										circle
										bgColor={theme.custom.colors.gray}
									/>
								</PopoverItem>
							))}
					</StyledPopoverBox>
				</Popover>
			)}
		</>
	)
}

const mapStateToProps = (state) => ({
	...state.appData,
	...state.userInfo,
	...state.ghostUser
})

const mapDispatchToProps = (dispatch) => {
	return {
		setGhostUser: (x) => dispatch(setGhostUser(x))
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewAs)
