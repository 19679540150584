import React from 'react'
import { styled } from '@mui/material/styles'
import Grid from '@mui/material/Grid2'
import {
	Box,
	Button,
	CardMedia,
	CircularProgress,
	Typography,
	TextField
} from '@mui/material'
import {
	SwapCalls,
	Download,
	DeleteOutlined,
	ContentCopy,
	CheckCircle,
	TipsAndUpdatesOutlined
} from '@mui/icons-material'
import FilterNoneIcon from '@mui/icons-material/FilterNone'
import LaunchIcon from '@mui/icons-material/Launch'
import KozmikTextField from '../atomic/atoms/kozmikTextField/KozmikTextField'

const Style = {
	input: (props, theme) => {
		return {
			borderRadius: '3px !important',
			backgroundColor: props?.bgColor ? props.bgColor : '#FAFAFC',
			fontSize: '14px',
			color: theme.custom.colors.textPrimary
		}
	},
	selectBox: (props, theme) => {
		return {
			...Style.input(props, theme),
			'&.MuiOutlinedInput-root': {
				'& fieldset': {
					border: 0
				},
				'&:hover fieldset': {
					border: 0
				},
				'&.Mui-focused fieldset': {
					border: 0
				}
			}
		}
	},
	datePicker: (props) => {
		return {
			'& .MuiOutlinedInput-input': {
				fontSize: 14,
				padding: '10px !important',
				backgroundColor: props?.bgColor ? props.bgColor : '#FAFAFC'
			}
		}
	}
}

const StyledGrid = styled(Grid)(({ theme }) => ({
	marginBottom: 10
}))

const StyledDiv = styled('div')(({ theme }) => ({
	marginBottom: 10
}))

const StyledLabel = styled('div')(({ theme }) => ({
	flexGrow: 1,
	width: 80,
	minWidth: 80
}))

const StyledItemContainer = styled('div')(({ theme }) => ({
	width: '100%',
	display: 'flex',
	alignItems: 'center'
}))

const StyledAuthContainer = styled('div')(({ theme }) => ({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	height: '100%',
	width: '100%',
	padding: 27
}))

const StyledAuthContentContainer = styled('div')(({ theme }) => ({
	marginTop: '20vh',
	maxWidth: 374,
	width: 374,
	height: '100%',
	display: 'flex',
	flexDirection: 'column',
	[theme.breakpoints.down('md')]: {
		marginTop: '5vh'
	}
}))

const StyledAuthSubTitle = styled('h2')(({ theme }) => ({
	marginBottom: 30,
	textAlign: 'center',
	fontSize: 14,
	fontWeight: '600',
	color: '#ffffff'
}))

const StyledAuthInputContainer = styled('div')(({ theme }) => ({
	marginBottom: 20
}))

const StyledAuthLogoContainer = styled('div')(({ theme }) => ({
	display: 'flex',
	justifyContent: 'center',
	marginBottom: 80
}))

const StyledAuthLogo = styled(CardMedia)(({ theme }) => ({
	width: 163,
	height: 'auto'
}))

const StyledAuthInput = styled(TextField)(({ theme }) => ({
	'& .MuiOutlinedInput-root': {
		overflow: 'hidden',
		height: 50,
		backgroundColor: 'rgba(255, 255, 255, 0.6)',
		borderRadius: 8,
		fontSize: 14,
		fontWeight: 600
	}
}))

const StyledAuthLoader = styled(CircularProgress)(({ theme }) => ({
	margin: '20px',
	color: theme.custom.colors.kozmikDarkBlue
}))

const StyledAuthSubmitButton = styled(Button)(({ theme }) => ({
	borderRadius: 8,
	boxShadow: 'none !important',
	textTransform: 'unset',
	height: 50,
	backgroundColor: `${theme.custom.colors.kozmikGreen} !important`
}))

const StyledAuthSubmitButtonText = styled(Typography)(({ theme }) => ({
	fontSize: 16,
	fontWeight: 600,
	color: theme.custom.colors.kozmikDarkBlue
}))

const StyledAuthBottomText = styled('div')(({ theme }) => ({
	textAlign: 'center',
	fontSize: 14,
	alignContent: 'center',
	color: '#ffffff'
}))

const StyledAuthBottomLink = styled('span')(({ theme }) => ({
	color: theme.custom.colors.kozmikGreen,
	cursor: 'pointer',
	fontWeight: 'bold'
}))

const StyledAuthBottomForgotLink = styled('span')(({ theme }) => ({
	fontWeight: '600',
	fontSize: 14,
	cursor: 'pointer',
	color: theme.custom.colors.kozmikGreen,
	paddingLeft: 10
}))

const StyledIntContainer = styled('div')(({ theme }) => ({
	width: '100%',
	paddingTop: 20,
	paddingBottom: 20
}))

const StyledIntTitle = styled(Typography)(({ theme }) => ({
	marginBottom: 10,
	fontSize: 16,
	fontWeight: '600'
}))

const StyledIntDesc = styled(Typography)(({ theme }) => ({
	marginBottom: 15,
	fontSize: 14
}))

const StyledIntSubTitle = styled(Typography)(({ theme }) => ({
	fontSize: 12,
	fontWeight: '600'
}))

const StyledIntInput = styled(KozmikTextField)(({ theme }) => ({
	backgroundColor: theme.custom.colors.kozmikGray,
	borderRadius: 6,
	border: 'none',
	'& .MuiOutlinedInput-root': {
		fontSize: 14,
		padding: 0
	},
	'& .MuiOutlinedInput-adornedStart': {
		paddingLeft: 0
	},
	'& .MuiOutlinedInput-adornedEnd': {
		paddingRight: 0
	}
}))

const StyledGrayBox = styled(Box)(({ theme }) => ({
	borderRadius: 8,
	minHeight: 50,
	width: '100%',
	marginBottom: 10,
	padding: '5px 25px',
	display: 'flex',
	alignItems: 'center',
	backgroundColor: theme.custom.colors.kozmikGray
}))

const StyledBadge = styled(Typography)(({ theme }) => ({
	marginTop: 10,
	fontSize: 9,
	fontWeight: 'bold',
	borderRadius: 7.5,
	padding: '4px 8px',
	backgroundImage:
		'linear-gradient(90deg, #96f1ce 50%, rgba(27,54,254,0.3) 100%)'
}))

const StyledCheckActive = styled(CheckCircle)(({ theme }) => ({
	fontSize: 15,
	color: theme.custom.colors.kozmikMatGreen
}))

const PopoverBoxStyled = styled(Box)(({ theme }) => ({
	padding: 8,
	width: 200
}))

const PopoverDeleteIconStyled = styled(DeleteOutlined)(({ theme }) => ({
	width: '14px',
	color: '#FF2619',
	opacity: 1
}))

const PopoverSwapIconStyled = styled(SwapCalls)(({ theme }) => ({
	width: '14px',
	color: '#FF2619',
	opacity: 1
}))

const PopoverDownloadIconStyled = styled(Download)(({ theme }) => ({
	width: '14px',
	color: '#FF2619',
	opacity: 1
}))

const PopoverCopyIconStyled = styled(ContentCopy)(({ theme }) => ({
	width: '14px',
	color: '#FF2619',
	opacity: 1
}))

const PopoverLaunchIconStyled = styled(LaunchIcon)(({ theme }) => ({
	width: '14px',
	color: '#FF2619',
	opacity: 1
}))

const PopoverFilterIconStyled = styled(FilterNoneIcon)(({ theme }) => ({
	width: '14px',
	color: '#FF2619',
	opacity: 1
}))

const AdornmentStyled = styled(Box)(({ theme }) => ({
	height: 38,
	backgroundColor: '#F0F0F4',
	padding: '6px 10px',
	color: theme.custom.colors.textPrimary,
	cursor: 'pointer',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center'
}))

const AdornmentActiveStyled = styled(Box)(({ theme }) => ({
	height: 38,
	backgroundColor: theme.custom.colors.kozmikLightBlue,
	padding: '6px 10px',
	color: theme.custom.colors.kozmikBlue,
	cursor: 'pointer',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center'
}))

const IconStyled = (props) => (
	<props.icon
		sx={{
			color: 'gray',
			fontSize: 16
		}}
	/>
)

const IconActiveStyled = (props) => (
	<props.icon
		sx={{
			color: (theme) => theme.custom.colors.kozmikBlue,
			fontSize: 16
		}}
	/>
)

const InputIconStyled = styled(TipsAndUpdatesOutlined)(({ theme }) => ({
	color: 'gray',
	fontSize: 16
}))

const InputIconActiveStyled = styled(TipsAndUpdatesOutlined)(({ theme }) => ({
	color: theme.custom.colors.kozmikBlue,
	fontSize: 16
}))

export default {
	Style,
	StyledGrid,
	StyledDiv,
	StyledLabel,
	StyledGrayBox,
	StyledBadge,
	StyledCheckActive,
	StyledItemContainer,
	StyledAuthContainer,
	StyledAuthContentContainer,
	StyledAuthSubTitle,
	StyledAuthInputContainer,
	StyledAuthLogoContainer,
	StyledAuthLogo,
	StyledAuthInput,
	StyledAuthLoader,
	StyledAuthSubmitButton,
	StyledAuthSubmitButtonText,
	StyledAuthBottomText,
	StyledAuthBottomLink,
	StyledAuthBottomForgotLink,
	StyledIntContainer,
	StyledIntTitle,
	StyledIntDesc,
	StyledIntSubTitle,
	StyledIntInput,
	PopoverBoxStyled,
	PopoverDeleteIconStyled,
	PopoverSwapIconStyled,
	PopoverDownloadIconStyled,
	PopoverCopyIconStyled,
	PopoverLaunchIconStyled,
	PopoverFilterIconStyled,
	IconStyled,
	IconActiveStyled,
	InputIconStyled,
	InputIconActiveStyled,
	AdornmentStyled,
	AdornmentActiveStyled
}
