import React from 'react'
import { styled } from '@mui/material/styles'
import TextField from '@mui/material/TextField'

const BorderlessInput = styled(TextField)(({ theme }) => ({
	justifyContent: 'center',
	'& label.Mui-focused': {
		color: 'transparent'
	},
	'& .MuiInput-underline:after': {
		borderBottomColor: 'transparent'
	},
	'& .MuiOutlinedInput-root': {
		'& fieldset': {
			borderColor: 'transparent'
		},
		'&:hover fieldset': {
			borderColor: 'transparent'
		},
		'&.Mui-focused fieldset': {
			borderColor: 'transparent'
		}
	},
	'& .MuiOutlinedInput-root.Mui-error': {
		'& fieldset': {
			borderColor: 'red'
		},
		'&:hover fieldset': {
			borderColor: 'red'
		},
		'&.Mui-focused fieldset': {
			borderColor: 'red'
		}
	}
}))

const BorderlessTextField = (props) => {
	return <BorderlessInput variant='outlined' {...props} />
}
export default BorderlessTextField
