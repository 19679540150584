import React from 'react'
import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import Grid from '@mui/material/Grid2'

const TitleBoxStyled = styled(Grid)(({ theme }) => ({
	// paddingBottom: '10px',
	fontWeight: '600',
	fontSize: '30px'
}))

const TitleStyled = styled(Typography)(({ theme }) => ({
	fontSize: 14,
	color: theme.custom.colors.textPrimary,
	fontWeight: 'bold'
}))

const AppPageBoxTitle = (props) => {
	return (
		<TitleBoxStyled size={{ xs: 12 }}>
			<TitleStyled {...props}>{props.text}</TitleStyled>
		</TitleBoxStyled>
	)
}

export default AppPageBoxTitle
