import React from 'react'
import { styled } from '@mui/material/styles'
import { Typography } from '@mui/material'

const BadgeStyled = styled(Typography)(({ theme }) => ({
	fontSize: 8,
	fontWeight: 'bold',
	borderRadius: 7.5,
	minWidth: 15,
	height: 15,
	padding: 4,
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	backgroundColor: theme.custom.colors.kozmikBlue,
	color: '#ffffff'
}))

const Badge = (props) => {
	return (
		<BadgeStyled
			style={{
				backgroundColor: props?.bgColor || null,
				color: props?.textColor || null
			}}
		>
			{props?.text}
		</BadgeStyled>
	)
}

export default Badge
