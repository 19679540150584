import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm, Controller } from 'react-hook-form'
import Cookies from 'universal-cookie'
import { useHistory } from 'react-router-dom'

import authorizationClient from '../../../services/authorizationClient'
import tokenServices from '../../../services/tokenServices'
import { default as appConfig } from '../../../config/app.json'

import useQuery from '../../../hooks/UseQuery/useQuery'
import showToastResponseError from '../../../methods/showToastResponseError'
import KozmikLogoLight from '../../../assets/icons/KozmikLogoLight.png'

import styledUI from '../../../styledUI'

const style = {
	buttonContainer: {
		marginTop: 40,
		marginBottom: 20
	},
	forgotContainer: {
		textAlign: 'right'
	}
}

const Login = () => {
	const { t } = useTranslation()
	let queryParams = useQuery()

	const [submitting, setSubmitting] = useState(false)

	const cookies = new Cookies()
	const history = useHistory()
	const navigate = (url) => {
		history.push(url)
	} //let navigate = useNavigate()
	const token = cookies.get(appConfig.cookieKeys.MY_SECURE_TOKEN_KEY)

	const {
		control,
		handleSubmit,
		formState: { errors, isValid }
	} = useForm({
		defaultValues: {
			email: '',
			password: ''
		}
	})
	const onSubmit = (data) => {
		setSubmitting(true)
		authorizationClient
			.login(data)
			.then((response) => {
				if (response?.data?.success) {
					tokenServices.add(response.data.data)
					if (queryParams?.import) {
						navigate(`/import/${queryParams.import}`)
					} else {
						navigate('')
					}
				} else {
					showToastResponseError(response)
				}
				setSubmitting(false)
			})
			.catch(() => {
				setSubmitting(false)
			})
	}

	const handleUserKeyPress = (e) => {
		if (e.key === 'Enter' && !e.shiftKey) {
			handleSubmit(onSubmit)()
		}
	}

	useEffect(() => {
		if (token && token !== 'undefined') {
			if (queryParams?.import) {
				navigate(`/import/${queryParams.import}`)
			} else {
				navigate('')
			}
		}
	}, [])

	return (
		<styledUI.StyledAuthContainer>
			<styledUI.StyledAuthContentContainer>
				<styledUI.StyledAuthLogoContainer>
					<a href='https://kozmik.io'>
						<styledUI.StyledAuthLogo image={KozmikLogoLight} component='img' />
					</a>
				</styledUI.StyledAuthLogoContainer>
				<styledUI.StyledAuthInputContainer>
					<Controller
						control={control}
						name='email'
						rules={{
							required: {
								value: true,
								message: t('authScreens.login.mailInput.requredError')
							},
							pattern: {
								value:
									/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
								message: t('authScreens.login.mailInput.patternError')
							}
						}}
						render={({ field: { onChange, value } }) => {
							return (
								<styledUI.StyledAuthInput
									error={!!errors?.email}
									helperText={errors?.email ? errors?.email?.message : ''}
									placeholder={t('authScreens.login.mailInput.placeholder')}
									fullWidth
									value={value}
									onChange={(e) => onChange(e.target.value)}
									onKeyPress={handleUserKeyPress}
								/>
							)
						}}
					/>
				</styledUI.StyledAuthInputContainer>
				<styledUI.StyledAuthInputContainer>
					<Controller
						control={control}
						name='password'
						rules={{
							required: {
								value: true,
								message: t('authScreens.login.passwordInput.requredError')
							}
						}}
						render={({ field: { onChange, value } }) => {
							return (
								<styledUI.StyledAuthInput
									error={!!errors?.password}
									helperText={errors?.password ? errors?.password?.message : ''}
									placeholder={t('authScreens.login.passwordInput.placeholder')}
									fullWidth
									value={value}
									type='password'
									onChange={(e) => onChange(e.target.value)}
									onKeyPress={handleUserKeyPress}
								/>
							)
						}}
					/>
				</styledUI.StyledAuthInputContainer>
				<div style={style.forgotContainer}>
					<styledUI.StyledAuthBottomForgotLink
						onClick={() => navigate('/forgotpassword')}
					>
						{t('authScreens.login.forgotPassword')}
					</styledUI.StyledAuthBottomForgotLink>
				</div>

				<div style={style.buttonContainer}>
					<styledUI.StyledAuthSubmitButton
						fullWidth
						variant='contained'
						onClick={handleSubmit(onSubmit)}
						disabled={submitting}
						sx={{
							opacity: submitting ? 0.6 : 1
						}}
					>
						<styledUI.StyledAuthSubmitButtonText>
							{t('authScreens.login.loginButton')}
						</styledUI.StyledAuthSubmitButtonText>
						{submitting && <styledUI.StyledAuthLoader size={20} />}
					</styledUI.StyledAuthSubmitButton>
				</div>
				<styledUI.StyledAuthBottomText>
					{t('authScreens.login.noAccount')}{' '}
					<styledUI.StyledAuthBottomLink onClick={() => navigate('/signup')}>
						{t('authScreens.login.register')}
					</styledUI.StyledAuthBottomLink>
				</styledUI.StyledAuthBottomText>
			</styledUI.StyledAuthContentContainer>
		</styledUI.StyledAuthContainer>
	)
}

export default Login
