import React, { useState, useEffect } from 'react'
import { styled } from '@mui/material/styles'
import { Download, KeyboardArrowDown } from '@mui/icons-material'
import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import { Box, Button } from '@mui/material'
import { useTranslation } from 'react-i18next'

const AccordionBox = styled((props) => (
	<MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
	border: 'none',
	borderRadius: 8,
	marginBottom: 5,
	backgroundColor: theme.custom.colors.kozmikGray,
	'&.Mui-expanded': {
		//backgroundColor: 'transparent',
		boxShadow: 'none'
	},
	'& .MuiAccordionSummary-root': {
		padding: '0 10px',
		minHeight: 40
		// [theme.breakpoints.down('sm')]: {
		// 	padding: 0
		// }
	},
	'& .MuiAccordionDetails-root': {
		// padding: '0 30px 30px 30px',
		padding: '0 10px'
		// [theme.breakpoints.down('sm')]: {
		// 	padding: 0
		// }
	},
	'&:before': {
		display: 'none'
	}
}))

const AccordionSummary = styled((props) => (
	<MuiAccordionSummary
		expandIcon={
			<KeyboardArrowDown style={{ color: '#464646', fontSize: 22 }} />
		}
		{...props}
	/>
))((/*{theme}*/) => ({
	'& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
		transform: 'rotate(180deg)'
	},
	'& .MuiAccordionSummary-content': {
		margin: 0
	}
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
	padding: theme.spacing(2),
	backgroundColor: 'transparent',
	borderRadius: '0 0 8px 8px'
}))

const TitleBoxStyled = styled(Box)(({ theme }) => ({
	width: '100%',
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center'
}))

const StatusStyled = styled(Box)(({ theme }) => ({
	display: 'flex',
	alignItems: 'center'
}))

const StatusDotStyled = styled(Box)(({ theme }) => ({
	width: 8,
	height: 8,
	borderRadius: '50%',
	marginRight: 5
}))

const DetailBoxStyled = styled(Box)(({ theme }) => ({
	//cursor: 'pointer'
	padding: '10px 0',
	borderTop: '1px solid #F0F0F4'
}))

const TextStyled = styled(Typography)(({ theme }) => ({
	fontSize: 12,
	color: theme.custom.colors.textPrimary
}))

const BtnTxtStyled = styled(Typography)(({ theme }) => ({
	color: theme.custom.colors.kozmikBlue,
	fontSize: 12,
	fontWeight: '600',
	textAlign: 'left',
	textTransform: 'none'
}))

const BtnIconStyled = styled(Download)(({ theme }) => ({
	color: theme.custom.colors.kozmikBlue,
	fontSize: 12
}))

const HistoryItem = (props) => {
	const { t } = useTranslation()

	const [expanded, setExpanded] = useState(false)

	const handleChange = () => (event, newExpanded) => {
		setExpanded(newExpanded ? true : false)
	}

	useEffect(() => {
		if (typeof props?.expanded == 'boolean') {
			setExpanded(props.expanded)
		}
	}, [props.expanded])

	const culture = localStorage.getItem('i18nextLng')
	// const languageCode = culture === 'tr-TR' ? 'tr' : 'en'
	const languageCode = 'en'

	const start = props?.item?.Created ? new Date(props.item.Created) : null

	const getStatusByCode = (code) => {
		let color = 'transparent'
		let text = ''
		let message = ''

		switch (
			code //TODO:CODES
		) {
			case 'InProgress':
				color = '#FFAC5E'
				text = t('historyItem.progress')
				message = getStatusMessage('InProgress', props.platform)
				break
			case 'BuildFinished':
				color = '#33D39D'
				text = t('historyItem.finished')
				message = getStatusMessage('BuildFinished', props.platform)
				break
			case 'Published':
				color = '#33D39D'
				text = t('historyItem.success')
				message = getStatusMessage('Published', props.platform)
				break
			case 'Failed':
				color = '#FF2619'
				text = t('historyItem.error')
				message = getStatusMessage('Failed', props.platform)
				break
			case 'InReview':
				color = '#FFAC5E'
				text = t('historyItem.inReview')
				message = getStatusMessage('InReview', props.platform)
				break
			case 'Approved':
				color = '#33D39D'
				text = t('historyItem.approved')
				message = getStatusMessage('Approved', props.platform)
				break
			case 'Rejected':
				color = '#FF2619'
				text = t('historyItem.rejected')
				message = getStatusMessage('Rejected', props.platform)
				break
		}

		return {
			color,
			text,
			message
		}
	}

	const getStatusMessage = (code, platform) => {
		switch (platform) {
			case 'web':
				switch (code) {
					case 'InProgress':
						return t('historyItem.web.progress')
					case 'BuildFinished':
						return t('historyItem.web.finished')
					case 'Published':
						return t('historyItem.web.success')
					case 'Failed':
						return t('historyItem.web.error')
				}
				break
			case 'android':
				switch (code) {
					case 'InProgress':
						return t('historyItem.android.progress')
					case 'BuildFinished':
						return t('historyItem.android.finished')
					case 'Published':
						return t('historyItem.android.success')
					case 'Failed':
						return t('historyItem.android.error')
				}
				break
			case 'ios':
				switch (code) {
					case 'InProgress':
						return t('historyItem.ios.progress')
					case 'BuildFinished':
						return t('historyItem.ios.finished')
					case 'Published':
						return t('historyItem.ios.success')
					case 'Failed':
						return t('historyItem.ios.error')
				}
				break

			case 'template':
				switch (code) {
					case 'Approved':
						return t('historyItem.template.approved')
					case 'InReview':
						return t('historyItem.template.inReview')
					case 'Rejected':
						return t('historyItem.template.rejected')
				}
				break
		}
	}

	const status = getStatusByCode(props?.item?.Status)

	const dateString = start
		? `${start.toLocaleDateString(languageCode)} - ${start.toLocaleTimeString(
				languageCode
		  )}`
		: ''

	return (
		<AccordionBox expanded={expanded} onChange={handleChange()}>
			<AccordionSummary>
				<TitleBoxStyled>
					<TextStyled>{props.item.Platform?.toUpperCase()}</TextStyled>
					<TextStyled>{props.item.Version}</TextStyled>
					<TextStyled>{dateString}</TextStyled>
					<StatusStyled>
						<StatusDotStyled style={{ backgroundColor: status.color }} />
						<TextStyled>{status.text}</TextStyled>
					</StatusStyled>
				</TitleBoxStyled>
			</AccordionSummary>
			<AccordionDetails>
				<DetailBoxStyled>
					<TextStyled>{props.item?.ErrorMessage || status.message}</TextStyled>
					<Box>
						<TextStyled>{dateString}</TextStyled>
					</Box>
					{!!props.item?.Artifact && (
						<Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
							<Button
								startIcon={<BtnIconStyled />}
								size='small'
								variant='contained'
								onClick={() => {
									let a = document.createElement('a')
									a.href = props.item.Artifact
									a.target = '_blank'
									a.click()
								}}
								sx={{
									textTransform: 'none',
									backgroundColor: `transparent !important`,
									boxShadow: 'none !important',
									fontSize: 14,
									borderRadius: '4px'
								}}
							>
								<BtnTxtStyled>{t('historyItem.download')}</BtnTxtStyled>
							</Button>
						</Box>
					)}
				</DetailBoxStyled>
			</AccordionDetails>
		</AccordionBox>
	)
}

export default HistoryItem
