import React from 'react'
import { styled } from '@mui/material/styles'
import { Box, Typography } from '@mui/material'

import { useTranslation } from 'react-i18next'

const FooterContainerStyled = styled(Box)(({ theme }) => ({
	display: 'flex',
	justifyContent: 'flex-end',
	padding: 10,
	borderTop: `1px solid ${theme.custom.colors.gray}`
}))

const TotalRowsTextStyled = styled(Typography)(({ theme }) => ({
	fontSize: '14px'
}))

function DataTableFooter(props) {
	const { t } = useTranslation()

	const { totalRecords } = props

	return (
		<FooterContainerStyled>
			<TotalRowsTextStyled>
				{t('appData.totalRows')} {totalRecords}
			</TotalRowsTextStyled>
		</FooterContainerStyled>
	)
}

export default DataTableFooter
