import React, { useState, useEffect } from 'react'
import { useTheme } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Popover, Typography, Box } from '@mui/material'
import Grid from '@mui/material/Grid2'
import { useParams } from 'react-router-dom'

import PopoverItem from '../../molecules/popoverItem/PopoverItem'

import TemplateSubmit from './modals/TemplateSubmit'

import { setDetail } from '../../../redux/actions/appDataActions'

const style = {
	popoverBox: {
		width: 225
	},
	box: {
		padding: '8px 16px',
		width: '100%'
	},
	textSm: {
		fontSize: 12
	}
}

const PublishModule = (props) => {
	const { t } = useTranslation()
	const theme = useTheme()
	let { appId } = useParams()

	const [visibleModalNo, setVisibleModalNo] = useState(null)

	const handleClickOpen = (modalNo) => {
		props.onClose()
		setVisibleModalNo(modalNo)
	}
	const handleClose = () => {
		setVisibleModalNo(null)
	}

	return (
		<>
			<Popover
				id={'publish-popover'}
				open={props.open}
				anchorEl={props.anchorEl}
				onClose={props.onClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center'
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center'
				}}
			>
				<Grid container style={style.popoverBox}>
					<Grid
						size={{ xs: 12 }}
						style={{
							borderTop: `2px solid ${theme.custom.colors.gray}`,
							...style.box
						}}
					>
						<PopoverItem onClick={() => handleClickOpen(4)}>
							<Typography sx={style.textSm}>
								{t('publishModule.submitTemplate')}
							</Typography>
						</PopoverItem>
					</Grid>

					{/* {(props?.userInfo?.email?.includes('@kozmik.io') ||
						props?.userInfo?.email?.includes('@logicalbond.com')) && (
						<Grid
							 size={{ xs: 12 }}
							style={{ borderTop: `2px solid ${theme.custom.colors.gray}`, ...style.box }}
						>
							<PopoverItem onClick={() => handleClickOpen(4)}>
								<Typography sx={style.textSm}>
									{t('publishModule.submitTemplate')}
								</Typography>
							</PopoverItem>
						</Grid>
					)} */}
				</Grid>
			</Popover>

			<TemplateSubmit visible={visibleModalNo === 4} hideModal={handleClose} />
		</>
	)
}

const mapStateToProps = (state) => ({
	...state.appData,
	...state.userInfo,
	...state.currentOrg
})

const mapDispatchToProps = (dispatch) => {
	return {
		setDetail: (x) => dispatch(setDetail(x))
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(PublishModule)
