import React from 'react'
import { styled } from '@mui/material/styles'

const SwitchContainerStyled = styled('div')((props) => ({
	width: 320,
	height: 59,
	backgroundColor: '#ffffff',
	borderRadius: 29.5,
	position: 'relative',
	padding: 8,
	cursor: 'pointer',
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	...(props.isOn
		? {
				'& .switch': {
					left: 'calc(100% - 158px)'
				}
		  }
		: {
				'& .switch': {
					left: 8
				}
		  })
}))
const SwitchStyled = styled('div')(({ theme }) => ({
	width: 'calc(50% - 10px)',
	backgroundColor: theme.custom.colors.kozmikDarkBlue,
	borderRadius: 21.5,
	position: 'absolute',
	top: 8,
	bottom: 8,
	transition: '0.2s',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	overflow: 'hidden'
}))
const SwitchTextStyled = styled('span')(({ theme }) => ({
	fontSize: 16,
	color: '#ffffff'
}))
const TextBoxStyled = styled('div')(({ theme }) => ({
	width: 'calc(50% - 10px)',
	fontSize: 16,
	color: theme.custom.colors.kozmikDarkBlue,
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	overflow: 'hidden'
}))

const TextSwitch = ({ isOn, handleToggle, onText, offText }) => {
	return (
		<SwitchContainerStyled isOn={isOn} onClick={handleToggle}>
			<SwitchStyled className='switch'>
				<SwitchTextStyled>{isOn ? onText : offText}</SwitchTextStyled>
			</SwitchStyled>

			<TextBoxStyled>{offText}</TextBoxStyled>
			<TextBoxStyled>{onText}</TextBoxStyled>
		</SwitchContainerStyled>
	)
}

export default TextSwitch
