import React, { useState, useEffect, useRef } from 'react'
import { Typography, Popover } from '@mui/material'
import { Create, Publish } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'

import PopoverItem from '../../molecules/popoverItem/PopoverItem'
import logoPicker1x1 from '../../../assets/images/logoPicker1x1.png'

import useOutsideClick from '../../../hooks/UseOutsideClick/useOutsideClick'
import convertBase64 from '../../../methods/convertBase64'
import checkBase64 from '../../../methods/checkBase64'
import showToastError from '../../../methods/showToastError'

import styledUI from '../../../styledUI'

const MediaPicker = (props) => {
	const { t } = useTranslation()

	const picker = useRef(null)

	const editPopover = useRef(null)
	useOutsideClick(editPopover, () => {
		closeOptionsPop()
	})

	const [imgSrc, setImageSrc] = useState(logoPicker1x1)
	const [imgName, setImgName] = useState('default')
	const [image, setImage] = useState(null)
	const [uploadVisible, setUploadVisible] = useState(true)
	const [optionsPop, setOptionsPop] = useState(null)

	useEffect(() => {
		if (props?.image) {
			setImageSrc(props.image)
			setUploadVisible(false)
		} else {
			setUploadVisible(true)
			setImageSrc(logoPicker1x1)
		}
	}, [props.image])
	useEffect(() => {
		props?.setImage && image && props.setImage(image)
	}, [image])

	const pickImage = async (e) => {
		if (e.target.files[0]) {
			await convertBase64(e.target.files[0]).then((result) => {
				if (props.checkDimensions) {
					var dimensions = new Image()
					dimensions.src = result.data
					dimensions.onload = () => {
						if (
							props.checkDimensions.width != dimensions.width ||
							props.checkDimensions.height != dimensions.height
						) {
							showToastError(t('fileUpload.resolutionNotValid'))
							return
						}

						setImgName(result.name)
						setImage(result.data)
						props.postData && props.postData(result.data)

						const imageUrl = URL.createObjectURL(e.target.files[0])
						setImageSrc(imageUrl)
						setUploadVisible(false)
					}
				} else {
					setImgName(result.name)
					setImage(result.data)
					props.postData && props.postData(result.data)

					const imageUrl = URL.createObjectURL(e.target.files[0])
					setImageSrc(imageUrl)
					setUploadVisible(false)
				}
			})
		} else {
			setUploadVisible(true)
			setImageSrc(logoPicker1x1)
		}
	}

	const downloadImage = (imageSrc, imageName) => {
		checkBase64(imageSrc).then(async (isBase64) => {
			let a = document.createElement('a')
			if (isBase64) {
				a.href = imageSrc
				a.download = imageName
			} else {
				a.href = imageSrc
				a.target = '_blank'
			}
			// let newTab = window.open()
			// newTab.document.body.innerHTML = `<img src="${imageSrc}">`
			a.click()
		})
	}

	const pickerClickHandler = () => {
		picker.current.click()
	}

	const pickerClear = (clearPickerPrewiew = false) => {
		closeOptionsPop()
		if (clearPickerPrewiew) {
			setImageSrc(logoPicker1x1)
			setImage(null)
			setUploadVisible(true)
		}
		picker.current.value = null
	}

	const openOptionsPop = (event) => {
		event.stopPropagation()
		setOptionsPop(event.currentTarget)
	}
	const closeOptionsPop = () => {
		setOptionsPop(null)
	}
	const optionsPopOpen = Boolean(optionsPop)

	return (
		<>
			<input
				ref={picker}
				// refs='file'
				accept={props?.accept || 'image/*'}
				style={{ display: 'none' }}
				id='icon-button-file'
				type='file'
				onChange={(e) => {
					pickImage(e)
				}}
			/>

			<label
				style={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					width: '120px',
					height: '120px',
					textAlign: 'right',
					borderRadius: '8px',
					position: 'relative',
					overflow: 'hidden',
					backgroundImage: `url(${imgSrc})`,
					backgroundSize: uploadVisible ? 'contain' : 'contain',
					backgroundRepeat: 'no-repeat',
					backgroundPosition: 'center',
					cursor: uploadVisible ? 'pointer' : 'default',
					border: uploadVisible ? 'none' : '1px solid #979797'
				}}
				onClick={uploadVisible ? pickerClickHandler : null}
			>
				{uploadVisible && (
					<>
						<Publish
							style={{ width: '20px', height: '20px', marginRight: 5 }}
						/>
						<Typography style={{ fontSize: '14px' }}>
							{t('fileUpload.upload')}
						</Typography>
					</>
				)}

				{!uploadVisible && (
					<div
						ref={editPopover}
						style={{
							width: '100%',
							height: '100%',
							'&:hover': {
								'& .editButton': {
									width: '24px',
									height: '24px'
								},
								backgroundColor: 'rgba(0,0,0,0.4)'
							}
						}}
					>
						<div
							className='editButton'
							style={{
								width: 0,
								height: 0,
								borderRadius: '50%',
								cursor: 'pointer',
								backgroundColor: '#ffffff',
								position: 'absolute',
								right: '7px',
								top: '7px',
								zIndex: 1,
								alignItems: 'center',
								justifyContent: 'center',
								display: 'flex',
								overflow: 'hidden'
							}}
							onClick={openOptionsPop}
						>
							<Create style={{ width: '16px', height: '16px' }} />
						</div>
						<Popover
							id={'field-popover'}
							open={optionsPopOpen}
							anchorEl={optionsPop}
							onClose={closeOptionsPop}
							anchorOrigin={{
								vertical: 'top',
								horizontal: 'right'
							}}
							transformOrigin={{
								vertical: 'top',
								horizontal: 'right'
							}}
						>
							<PopoverItem
								text={t('fileUpload.change')}
								onClick={() => {
									pickerClear()
									pickerClickHandler()
								}}
								color='#000000'
							>
								<styledUI.PopoverSwapIconStyled />
							</PopoverItem>
							{props.download && (
								<PopoverItem
									text={t('fileUpload.download')}
									onClick={() => {
										props.download && downloadImage(imgSrc, imgName)
									}}
									color='#000000'
								>
									<styledUI.PopoverDownloadIconStyled />
								</PopoverItem>
							)}
							<PopoverItem
								text={t('fileUpload.delete')}
								onClick={() => {
									pickerClear(true)
									props.postData && props.postData(null)
								}}
								color='#FF2619'
							>
								<styledUI.PopoverDeleteIconStyled />
							</PopoverItem>
						</Popover>
					</div>
				)}
			</label>
		</>
	)
}

export default MediaPicker
