import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

const style = {
	root: {
		flexGrow: 1
	}
}

const Resources = () => {
	const { t } = useTranslation()

	useEffect(() => {
		document.title = t('docTitles.resources')
	}, [])

	return (
		<div style={style.root}>
			<div>
				<h1>Resources Page</h1>
				<p>These are resources</p>
			</div>
		</div>
	)
}

export default Resources
