import React, { useState, useEffect } from 'react'
import Grid from '@mui/material/Grid2'
import update from 'immutability-helper'
import { useTranslation } from 'react-i18next'

import AppPageBox from '../../../atoms/appPageBox/AppPageBox'
import DoubleStateText from '../../../molecules/doubleStateField/DoubleStateText'
import Accordion from '../../../atoms/accordion/Accordion'
import { default as Label } from '../../../atoms/settingLabel/SettingLabel'
import { default as Checkbox } from '../../../atoms/kozmikCheckBox/KozmikCheckBox'
import VisibilityOptions from '../../../molecules/visibilityOptions/VisibilityOptions'

import validateWithRegExp from '../../../../methods/validateWithRegExp'
import timeoutDelay from '../../../../methods/timeoutDelay'
import getEntityFieldsByDataTypes from '../../../../methods/getEntityFieldsByDataTypes'
import dataTypeRegExes from '../../../../common/regExp/dataType'
import relationTypes from '../../../../common/enums/relationTypes'

import styledUI from '../../../../styledUI'

const Video = (props) => {
	const { t } = useTranslation()

	const videoFields = getEntityFieldsByDataTypes(
		props?.entities,
		props.activeScreen?.data?.source,
		['URL', 'Text'],
		null,
		true,
		relationTypes.one
	)
	const videoOptions = videoFields?.length ? videoFields : []
	const videoOptionsWithEmpty = [{ label: '-', name: '' }, ...videoOptions]

	/****VIDEO_URL***************************/
	const [isVideoUrlTextMode, setIsVideoUrlTextMode] = useState(false)
	const [videoUrlField, setVideoUrlField] = useState('')
	const [videoUrlDefault, setVideoUrlDefault] = useState('')
	const [videoUrlDefaultValid, setVideoUrlDefaultValid] = useState(true)

	const videoUrlChange = (data) => {
		if (isVideoUrlTextMode) {
			const videoUrlRegExp = dataTypeRegExes.URL.re

			validateWithRegExp(data.target.value, videoUrlRegExp).then((isValid) => {
				setVideoUrlDefaultValid(isValid)
				if (isValid) {
					timeoutDelay(submitVideoUrl, data.target.value, 1000)
				}
			})

			setVideoUrlDefault(data.target.value)
		} else {
			submitVideoUrl(data?.value)
			setVideoUrlField(data?.value)
		}
	}
	const submitVideoUrl = (x) => {
		const selectedField = videoFields?.find((y) => y.name == x)
		const modifiedElementData = update(props.elementData, {
			mapping: {
				videoUrl: {
					field: { $set: isVideoUrlTextMode ? null : x },
					default: { $set: isVideoUrlTextMode ? x : null },
					type: { $set: isVideoUrlTextMode ? null : selectedField?.dataType }
				}
			}
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****INITIALIZATION***************************/
	const initStates = (elementData) => {
		/****VIDEO_URL***************************/
		elementData?.mapping?.videoUrl?.field ||
		elementData?.mapping?.videoUrl?.field === ''
			? setIsVideoUrlTextMode(false)
			: setIsVideoUrlTextMode(true)

		setVideoUrlField(elementData?.mapping?.videoUrl?.field || '')
		setVideoUrlDefault(elementData?.mapping?.videoUrl?.default || '')
	}

	useEffect(() => {
		props?.elementData && initStates(props.elementData)
	}, [props.elementData])

	return (
		<>
			<AppPageBox>
				<Accordion
					title={t('screens.elementSettings.common.titles.content')}
					expanded={true}
				>
					<styledUI.StyledGrid container alignItems='center'>
						<Grid size={{ xs: 4 }}>
							<Label>{t('screens.elementSettings.video.video')}</Label>
						</Grid>
						<Grid size={{ xs: 8 }}>
							<DoubleStateText
								fullWidth={true}
								textError={!videoUrlDefaultValid}
								isTextMode={isVideoUrlTextMode}
								setIsTextMode={setIsVideoUrlTextMode}
								valChange={videoUrlChange}
								textVal={videoUrlDefault}
								autoCompVal={videoUrlField}
								autoCompOptions={videoOptionsWithEmpty}
								uppyPicker
								uppyAllowedFileTypes={['video/*']}
								valueKey='name'
								labelKey='label'
							/>
						</Grid>
					</styledUI.StyledGrid>
				</Accordion>
			</AppPageBox>

			<VisibilityOptions
				block={props.elementData}
				activeScreen={props.activeScreen}
				entities={props.entities}
				updateBlock={props.updateSelectedElement}
			/>
		</>
	)
}

export default Video
