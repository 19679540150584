import React from 'react'
import { styled } from '@mui/material/styles'
import { useTheme } from '@mui/material/styles'
import { Close } from '@mui/icons-material'

const TagsContainerStyled = styled('div')(({ theme }) => ({
	backgroundColor: theme.custom.colors.kozmikGray,
	border: '2px solid transparent',
	padding: '5px 14px',
	borderRadius: 4,
	width: '100%',
	display: 'flex',
	alignItems: 'center',
	flexWrap: 'wrap',
	minHeight: 40,
	maxHeight: 150,
	overflowY: 'auto'
}))

const TagItemStyled = styled('div')(({ theme }) => ({
	backgroundColor: theme.custom.colors.kozmikLightBlue,
	padding: '2px 7px',
	borderRadius: 20,
	display: 'flex',
	alignItems: 'center',
	margin: 3
}))

const TagClodIconStyled = styled(Close)(({ theme }) => ({
	height: 16,
	width: 16,
	color: theme.custom.colors.kozmikBlue,
	marginLeft: 5,
	cursor: 'pointer'
}))

const TagsInputStyled = styled('input')(({ theme }) => ({
	backgroundColor: theme.custom.colors.kozmikGray,
	flexGrow: 1,
	padding: '5px 0',
	border: 'none',
	outline: 'none'
}))

const TextStyled = styled('span')(({ theme }) => ({
	fontSize: 14,
	color: '#000000',
	wordBreak: 'break-all'
}))

const TagInput = (props) => {
	const theme = useTheme()

	const handleKeyDown = (e) => {
		if (e.key !== 'Enter') return
		processTag(e)
	}

	const processTag = (e) => {
		const value = e.target.value
		if (!value.trim()) return
		if (!props?.error) {
			props.setTags([...props.tags, value])
			e.target.value = ''
		}
	}

	const removeTag = (index) => {
		props.setTags(props.tags.filter((el, i) => i !== index))
	}

	return (
		<TagsContainerStyled
			style={props?.error ? { borderColor: theme.custom.colors.red } : null}
		>
			{props.tags?.map((tag, index) => (
				<TagItemStyled key={index}>
					<TextStyled>{tag}</TextStyled>
					<TagClodIconStyled onClick={() => removeTag(index)} />
				</TagItemStyled>
			))}
			<TagsInputStyled
				onBlur={processTag}
				onKeyDown={handleKeyDown}
				onChange={props?.onInputChange}
				type='text'
				placeholder={props?.placeholder || ''}
				autoFocus
			/>
		</TagsContainerStyled>
	)
}

export default TagInput
