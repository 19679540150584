import React from 'react'
import { styled } from '@mui/material/styles'
import Slider from '@mui/material/Slider'

const SliderStyled = styled(Slider)(({ theme }) => ({
	color: theme.custom.colors.kozmikBlue
}))

const KozmikSlider = (props) => {
	return <SliderStyled {...props} />
}
export default KozmikSlider
