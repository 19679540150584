import React from 'react'
import { styled } from '@mui/material/styles'
import { useTheme } from '@mui/material/styles'
import { Button } from '@mui/material'
import DoneIcon from '@mui/icons-material/Done'
import { connect } from 'react-redux'

import { default as appConfig } from '../../../config/app.json'

const paymentEndpoint = appConfig.endpoints.payment

const MainContainerStyled = styled('div')(({ theme }) => ({
	backgroundColor: '#ffffff',
	width: '100%',
	height: '100%',
	padding: 20,
	border: `1px solid transparent`,
	borderRadius: 16,
	overflow: 'hidden'
}))

const ButtonStyled = styled(Button)((props) => ({
	...{
		fontSize: 16,
		height: 40,
		textTransform: 'none',
		fontWeight: '600',
		borderRadius: 8,
		boxShadow: 'none !important',
		backgroundColor: props.theme.custom.colors.gray,
		color: props.theme.custom.colors.kozmikDarkBlue,
		'&:hover': {
			backgroundColor: props.theme.custom.colors.gray
		}
	},
	...(props?.highlighted
		? {
				borderColor: `${props.theme.custom.colors.kozmikGreen} !important`,
				backgroundColor: `${props.theme.custom.colors.kozmikGreen} !important`,
				color: `${props.theme.custom.colors.kozmikDarkBlue} !important`
		  }
		: props?.current
		? {
				borderColor: `${props.theme.custom.colors.gray} !important`,
				backgroundColor: props.theme.custom.colors.gray,
				color: `${props.theme.custom.colors.kozmikDarkBlue} !important`
		  }
		: null)
}))

const TitleStyled = styled('h5')(({ theme }) => ({
	fontSize: 21,
	fontWeight: '600',
	color: theme.custom.colors.kozmikDarkBlue,
	margin: 0
}))

const PriceBoxStyled = styled('div')(({ theme }) => ({
	marginTop: 10,
	marginBottom: 20,
	display: 'flex',
	alignItems: 'center'
}))

const PriceStyled = styled('span')(({ theme }) => ({
	fontSize: 28,
	fontWeight: '700',
	color: theme.custom.colors.kozmikDarkBlue,
	marginRight: 10
}))

const PriceTextStyled = styled('span')(({ theme }) => ({
	fontSize: 14,
	color: theme.custom.colors.kozmikDarkBlue,
	opacity: 0.6
}))

const ListStyled = styled('ul')(({ theme }) => ({
	listStyle: 'none',
	padding: 0,
	margin: 0,
	marginTop: 20,
	[theme.breakpoints.down('lg')]: {
		marginTop: 20
	},
	[theme.breakpoints.down('sm')]: {
		marginTop: 50
	}
}))

const ListItemStyled = styled('li')(({ theme }) => ({
	fontSize: 14,
	display: 'flex',
	alignItems: 'center',
	marginBottom: 10
}))

const DoneIconStyled = styled(DoneIcon)(({ theme }) => ({
	width: 20,
	marginRight: 10,
	height: 'auto',
	color: theme.custom.colors.kozmikGreen
}))

const PriceCard = (props) => {
	const theme = useTheme()

	return (
		<MainContainerStyled
			style={{
				border: props?.highlighted
					? `1px solid ${theme.custom.colors.darkGray}`
					: 'none',
				boxShadow: props?.highlighted
					? '0 8px 16px 0 rgba(0, 0, 0, 0.17)'
					: 'none'
			}}
		>
			<div>
				<TitleStyled>{props?.title}</TitleStyled>
				<PriceBoxStyled>
					<PriceStyled>{props?.price}</PriceStyled>
					<PriceTextStyled>{props?.text}</PriceTextStyled>
				</PriceBoxStyled>
				<form action={paymentEndpoint.checkout} method='POST'>
					<input type='hidden' name='orgId' value={props?.currentOrg?.id} />
					<input type='hidden' name='userId' value={props.userInfo?.id} />
					<input type='hidden' name='email' value={props.userInfo?.email} />
					<input type='hidden' name='plan' value={props?.plan} />
					<ButtonStyled
						highlighted={props?.highlighted}
						current={props?.current}
						disabled={props?.current}
						type='submit'
						variant={props?.current ? 'outlined' : 'contained'}
						fullWidth
					>
						{props?.current ? props?.btnCurrentText : props?.btnText}
					</ButtonStyled>
				</form>
			</div>
			<div>
				<ListStyled>
					{props?.features?.map((item, index) => (
						<ListItemStyled key={index}>
							<DoneIconStyled />
							{item}
						</ListItemStyled>
					))}
				</ListStyled>
			</div>
		</MainContainerStyled>
	)
}

const mapStateToProps = (state) => ({
	...state.userInfo
})

export default connect(mapStateToProps, null)(PriceCard)
