import React, { useState, useEffect } from 'react'
import { styled } from '@mui/material/styles'
import { useTheme } from '@mui/material/styles'

import MentionsInput from '../../atoms/mentionsInput/MentionsInput'
import Dropdown from '../dropdown/Dropdown'

import styledUI from '../../../styledUI'

const TagsContainerStyled = styled('div')(({ theme }) => ({
	display: 'flex',
	justifyContent: 'end',
	flexFlow: 'row',
	alignItems: 'flex-start',
	borderRadius: 3,
	overflow: 'hidden'
}))

const TagsInputContainerStyled = styled('div')(({ theme }) => ({
	overflow: 'hidden',
	flexGrow: 1
}))

const DoubleStateMentions = (props) => {
	const {
		textError,
		errorText,
		valChange,
		textVal,
		autoCompOptions,
		autoCompVal,
		autoCompEmptyText,
		isTextMode,
		setIsTextMode,
		valueKey,
		labelKey,
		callback,
		undefinedText,
		bgColor,
		icon,
		textModePlaceholder,
		uppyPicker,
		uppyAllowedFileTypes,
		fieldDataType,
		multiline,
		...restProps
	} = props

	const theme = useTheme()

	const [open, setOpen] = useState(false)
	const closeDropdown = () => setOpen(false)
	const openDropdown = () => setOpen(true)

	const switchButton = (textMode, callbackFunc = null) => {
		setIsTextMode(textMode)
		if (callbackFunc) {
			if (textMode) {
				callbackFunc(textMode, textVal)
			} else {
				callbackFunc(textMode, autoCompVal)
			}
		}
	}

	const tagOptions = autoCompOptions
		?.filter((item) => item.name?.length > 0)
		.map((item) => ({
			id: item.id,
			name: item.name,
			label: item.label,
			dataType: item.dataType
		}))

	return isTextMode ? (
		<>
			<TagsContainerStyled>
				<styledUI.AdornmentActiveStyled
					onClick={() => switchButton(false, callback ? callback : null)}
				>
					{icon ? (
						<styledUI.IconActiveStyled />
					) : (
						<styledUI.InputIconActiveStyled />
					)}
				</styledUI.AdornmentActiveStyled>
				<TagsInputContainerStyled>
					<MentionsInput
						name={props.name}
						inputStyle={{
							backgroundColor: props?.bgColor
								? props.bgColor
								: theme.custom.colors.kozmikGray,
							borderRadius: 3,
							border: 'none',
							color: theme.custom.colors.textPrimary
						}}
						placeholder={textModePlaceholder || ''}
						whitelist={tagOptions}
						onChange={valChange}
						value={textVal}
						multiline={multiline}
						{...restProps}
					></MentionsInput>
				</TagsInputContainerStyled>
			</TagsContainerStyled>
		</>
	) : (
		<Dropdown
			bgColor={bgColor}
			onItemClick={valChange}
			value={autoCompVal}
			data={autoCompOptions}
			textKey={labelKey}
			valueKey={valueKey}
			groupKey={labelKey}
			groupKeySeperator='.'
			startAdornment={
				<styledUI.AdornmentStyled
					onClick={() => switchButton(true, callback ? callback : null)}
				>
					{icon ? <styledUI.IconStyled /> : <styledUI.InputIconStyled />}
				</styledUI.AdornmentStyled>
			}
		/>
	)
}
export default DoubleStateMentions
