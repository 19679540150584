import React from 'react'
import { useTheme } from '@mui/material/styles'
import Grid from '@mui/material/Grid2'

import TemplateImageItem from './TemplateImageItem'

const style = {
	root: {
		flexGrow: 1
	},
	tile: (props, theme) => {
		return {
			padding: theme.spacing(3),
			textAlign: 'center',
			color: theme.palette.text.secondary,
			minHeight: '100px'
		}
	}
}

const TemplateImageList = (props) => {
	const theme = useTheme()

	return (
		<Grid container spacing={1}>
			<Grid style={style.tile(props, theme)} size={{ xs: 4 }}>
				<TemplateImageItem image={props.image1} setImage={props.setImage1} />
			</Grid>
			<Grid style={style.tile(props, theme)} size={{ xs: 4 }}>
				<TemplateImageItem image={props.image2} setImage={props.setImage2} />
			</Grid>
			<Grid style={style.tile(props, theme)} size={{ xs: 4 }}>
				<TemplateImageItem image={props.image3} setImage={props.setImage3} />
			</Grid>
		</Grid>
	)
}

export default TemplateImageList
