import React, { useRef } from 'react'
import { styled } from '@mui/material/styles'
import { Description } from '@mui/icons-material'
import { Box, Typography } from '@mui/material'

import convertBase64 from '../../../methods/convertBase64'

const BoxStyled = styled(Box)(({ theme }) => ({
	backgroundColor: theme.custom.colors.kozmikGray,
	display: 'flex',
	alignItems: 'center',
	borderRadius: 6,
	height: 35,
	padding: '0 8px',
	cursor: 'pointer',
	overflow: 'hidden'
}))

const DescriptionStyled = styled(Description)(({ theme }) => ({
	fontSize: 20,
	color: '#909090'
}))

const TextStyled = styled(Typography)(({ theme }) => ({
	paddingLeft: 5,
	fontSize: 12,
	fontWeight: '600'
}))

const KozmikFilePicker = (props) => {
	const filePicker = useRef(null)

	const pickFile = async (e) => {
		if (e.target.files[0]) {
			await convertBase64(e.target.files[0]).then((result) => {
				props.onChange({
					name: result.name,
					base64File: result.data
				})
			})
		}
	}

	const openFilePicker = () => {
		filePicker.current.click()
	}

	return (
		<>
			<input
				ref={filePicker}
				accept={props.accept}
				style={{ display: 'none' }}
				type='file'
				onChange={(e) => {
					pickFile(e)
				}}
			/>

			<BoxStyled onClick={() => openFilePicker()}>
				<DescriptionStyled />
				<TextStyled>{props.fileName}</TextStyled>
			</BoxStyled>
		</>
	)
}

export default KozmikFilePicker
