import React, { useState, useEffect } from 'react'
import { useTheme } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'
import Grid from '@mui/material/Grid2'

import SelectBox from '../selectBox/SelectBox'
import SquareOptions from '../squareOprions/SquareOptions'

import styledUI from '../../../styledUI'

const Sorting = (props) => {
	const { t } = useTranslation()
	const theme = useTheme()

	/****SORTING_FIELD***************************/
	const [sortingField, setSortingField] = useState('')

	const sortingFieldChange = (e) => {
		if (props.index === undefined) {
			props.sorting.updateField(e.target.value)
		} else {
			props.sorting.updateField(
				e.target.value,
				props.index,
				props.feature === undefined ? null : props.feature
			)
		}
		setSortingField(e.target.value)
	}

	/****SORTING_SORT***************************/
	const [sortingSort, setSortingSort] = useState(null)

	const sortingSortChange = (val) => {
		if (props.index === undefined) {
			props.sorting.updateSort(val)
		} else {
			props.sorting.updateSort(
				val,
				props.index,
				props.feature === undefined ? null : props.feature
			)
		}
		setSortingSort(val)
	}

	/****INITIALIZATION***************************/
	const initStates = (sortingData) => {
		/****SORTING_FIELD***************************/
		setSortingField(sortingData?.field ? sortingData.field : '')
		/****SORTING_SORT***************************/
		setSortingSort(sortingData?.sort ? sortingData.sort : 'asc')
	}

	useEffect(() => {
		if (props?.sortingData) {
			initStates(props.sortingData)
		}
	}, [props.sortingData])

	return (
		<Grid container alignItems='center'>
			<Grid size={{ xs: 7 }}>
				<SelectBox
					emptyText={t('screens.elementSettings.sorting.emptyField')}
					onChange={sortingFieldChange}
					value={sortingField}
					data={props.fieldOptions}
					sx={styledUI.Style.selectBox(
						{ ...{ props }, bgColor: '#ffffff' },
						theme
					)}
					textKey='label'
					valueKey='name'
				/>
			</Grid>
			<Grid size={{ xs: 5 }}>
				<SquareOptions
					compact
					boxColor='#ffffff'
					data={props.sorting.options}
					getChange={sortingSortChange}
					activeValue={sortingSort}
				/>
			</Grid>
		</Grid>
	)
}

export default Sorting
