import React, { useState, useEffect } from 'react'
import { styled } from '@mui/material/styles'
import { useTheme } from '@mui/material/styles'
import { Box, InputAdornment } from '@mui/material'
import { FileUpload } from '@mui/icons-material'

import BorderlessTextField from '../../atoms/borderlessTextField/BorderlessTextField'
import UppyPicker from '../../atoms/uppyPicker/UppyPicker'
import Dropdown from '../dropdown/Dropdown'

import styledUI from '../../../styledUI'

const SecondaryIconStyled = styled(FileUpload)(({ theme }) => ({
	color: 'gray',
	fontSize: 16
}))

const TextFieldStyled = styled(BorderlessTextField)((props) => ({
	backgroundColor: props?.bgColor ? props.bgColor : '#FAFAFC',
	borderRadius: 3,
	border: 'none',
	'& .MuiInputBase-adornedStart': {
		paddingLeft: 0,
		overflow: 'hidden'
	},
	'& .MuiInputBase-adornedEnd': {
		paddingRight: 0,
		overflow: 'hidden'
	}
}))

const EndAdornmentActiveStyled = styled(Box)((props) => ({
	height: 38,
	backgroundColor: '#F0F0F4',
	padding: '6px 10px',
	color: props.theme.custom.colors.kozmikBlue,
	cursor: 'pointer',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center'
}))

const DoubleStateText = (props) => {
	const {
		textError,
		errorText,
		valChange,
		textVal,
		autoCompOptions,
		autoCompVal,
		autoCompEmptyText,
		isTextMode,
		setIsTextMode,
		valueKey,
		labelKey,
		callback,
		undefinedText,
		bgColor,
		icon,
		textModePlaceholder,
		uppyPicker,
		uppyAllowedFileTypes,
		fieldDataType,
		...restProps
	} = props

	const theme = useTheme()

	const [open, setOpen] = useState(false)
	const closeDropdown = () => setOpen(false)
	const openDropdown = () => setOpen(true)

	const switchButton = (textMode, callbackFunc = null) => {
		setIsTextMode(textMode)
		if (callbackFunc) {
			if (textMode) {
				callbackFunc(textMode, textVal)
			} else {
				callbackFunc(textMode, autoCompVal)
			}
		}
	}

	return isTextMode ? (
		<>
			<TextFieldStyled
				size='small'
				variant='outlined'
				placeholder={textModePlaceholder || ''}
				error={textError}
				value={textVal}
				onChange={valChange}
				slotProps={{
					input: {
						style: styledUI.Style.input(props, theme),
						startAdornment: (
							<InputAdornment position='start'>
								<styledUI.AdornmentActiveStyled
									onClick={() =>
										switchButton(false, callback ? callback : null)
									}
								>
									{icon ? (
										<styledUI.IconActiveStyled />
									) : (
										<styledUI.InputIconActiveStyled />
									)}
								</styledUI.AdornmentActiveStyled>
							</InputAdornment>
						),
						endAdornment: uppyPicker ? (
							<InputAdornment position='end'>
								<UppyPicker
									allowedFileTypes={uppyAllowedFileTypes}
									uploadComplete={(result) => {
										result?.successful?.length &&
											valChange({
												target: {
													value: result.successful[0].uploadURL
												}
											})
									}}
								>
									<EndAdornmentActiveStyled>
										<SecondaryIconStyled />
									</EndAdornmentActiveStyled>
								</UppyPicker>
							</InputAdornment>
						) : null
					}
				}}
				{...restProps}
			/>
			{textError && (
				<p
					style={{
						color: '#d32f2f',
						fontSize: 12,
						margin: 0
					}}
				>
					{errorText ? errorText : 'Error' /*TODO:Localization*/}
				</p>
			)}
		</>
	) : (
		<Dropdown
			bgColor={bgColor}
			onItemClick={valChange}
			value={autoCompVal}
			data={autoCompOptions}
			textKey={labelKey}
			valueKey={valueKey}
			groupKey={labelKey}
			groupKeySeperator='.'
			startAdornment={
				<styledUI.AdornmentStyled
					onClick={() => switchButton(true, callback ? callback : null)}
				>
					{icon ? <styledUI.IconStyled /> : <styledUI.InputIconStyled />}
				</styledUI.AdornmentStyled>
			}
		/>
	)
}
export default DoubleStateText
