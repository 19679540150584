import * as React from 'react'
import { styled } from '@mui/material/styles'
import { Typography } from '@mui/material'

const Container = styled('div')(({ theme }) => ({
	display: 'inline-flex',
	alignItems: 'center',
	cursor: 'pointer'
}))

const Title = styled((props) => {
	const { iconSize, ...restProps } = props

	return <Typography {...restProps} />
})((props) => ({
	paddingLeft: props?.icon ? 5 : 0,
	color: props?.color ? props.color : props?.theme.custom.colors.textPrimary,
	fontSize: props?.fontSize ? props.fontSize : 14,
	fontWeight: 'bold'
}))

const Icon = (props) => (
	<props.icon
		sx={{
			fontSize: props?.iconSize ? props.iconSize : 18,
			color: (theme) =>
				props?.color ? props.color : theme.custom.colors.textPrimary
		}}
	/>
)

const TextButton = (props) => {
	return (
		<Container {...props}>
			{!!props.icon && <Icon {...props} />}
			{!!props.text && <Title {...props}>{props.text}</Title>}
		</Container>
	)
}

export default TextButton
