import React, { useEffect } from 'react'
import { styled } from '@mui/material/styles'
import { useTheme } from '@mui/material/styles'

import { Link, useLocation } from 'react-router-dom'
import { Typography } from '@mui/material'

const DetailMenuItemStyled = styled((props) => {
	const { isActive, ...restProps } = props

	return <Link {...restProps} />
})((props) => ({
	...{
		textDecoration: 'none !important',
		width: 45,
		marginBottom: 25,
		[props.theme.breakpoints.down('md')]: {
			margin: '0 12px'
		}
	},
	...(props.isActive && {
		'& .text': {
			fontWeight: '600'
		},
		'& .iconBox': {
			backgroundColor: `${props.theme.custom.colors.kozmikLightBlue} !important`
		},
		'& .icon': {
			color: `${props.theme.custom.colors.kozmikBlue} !important`
		}
	})
}))

const TextStyled = styled(Typography)(({ theme }) => ({
	paddingTop: 3,
	color: theme.custom.colors.textPrimary,
	fontSize: 12,
	textAlign: 'left'
}))

const IconBoxStyled = styled('div')(({ theme }) => ({
	width: '45px',
	height: '45px',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	backgroundColor: '#ffffff',
	borderRadius: 6
}))

const IconStyled = (props) => (
	<props.data.icon
		className={props.className}
		sx={{
			fontSize: '25px',
			color: props.theme.custom.colors.kozmikLightBlue
		}}
	/>
)

const DetailMenuItem = (props) => {
	const theme = useTheme()
	let currentPage = useLocation().pathname

	const activeHrefs = props.data?.activeHrefs || props.data.href
	const isActive = activeHrefs.indexOf(currentPage) >= 0

	useEffect(() => {
		isActive &&
			props.setActiveMenuEvent &&
			props.setActiveMenuEvent({
				id: props.data.id,
				reVisit: false
			})
	}, [])

	return (
		<DetailMenuItemStyled
			isActive={isActive}
			to={props.data.href}
			onClick={() => {
				props.setActiveMenuEvent &&
					props.setActiveMenuEvent({
						id: props.data.id,
						reVisit: props.activeMenuEvent?.id === props.data.id
					})
			}}
		>
			<div
				style={{
					display: 'flex',
					flexWrap: 'wrap',
					justifyContent: 'center'
				}}
			>
				<IconBoxStyled className='iconBox'>
					{props.data?.icon && (
						<IconStyled data={props.data} theme={theme} className='icon' />
					)}
				</IconBoxStyled>
				<div style={{ display: 'flex', justifyContent: 'center' }}>
					<TextStyled className='text'>{props.data.name}</TextStyled>
				</div>
			</div>
		</DetailMenuItemStyled>
	)
}

export default DetailMenuItem
