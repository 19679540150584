import React, { useState, useEffect } from 'react'
import { useTheme } from '@mui/material/styles'
import Grid from '@mui/material/Grid2'
import update from 'immutability-helper'
import { useTranslation } from 'react-i18next'

import AppPageBox from '../../../atoms/appPageBox/AppPageBox'
import DoubleStateText from '../../../molecules/doubleStateField/DoubleStateText'
import DoubleStateNumber from '../../../molecules/doubleStateField/DoubleStateNumber'
import Accordion from '../../../atoms/accordion/Accordion'
import { default as Label } from '../../../atoms/settingLabel/SettingLabel'
import SelectBox from '../../../molecules/selectBox/SelectBox'
import VisibilityOptions from '../../../molecules/visibilityOptions/VisibilityOptions'

import timeoutDelay from '../../../../methods/timeoutDelay'
import getEntityFieldsByDataTypes from '../../../../methods/getEntityFieldsByDataTypes'
import fieldDataTypes from '../../../../common/fieldDataTypes'
import relationTypes from '../../../../common/enums/relationTypes'

import styledUI from '../../../../styledUI'

const NumberEntry = (props) => {
	const { t } = useTranslation()
	const theme = useTheme()

	const numberFields = getEntityFieldsByDataTypes(
		props?.entities,
		props.activeScreen.data?.source,
		['Number']
	)
	const numberOptions = numberFields?.length ? numberFields : []

	const textFieldsWithLookupAndRel = getEntityFieldsByDataTypes(
		props?.entities,
		props.activeScreen.data?.source,
		fieldDataTypes.textBased,
		null,
		true,
		relationTypes.one
	)
	const textOptions = textFieldsWithLookupAndRel?.length
		? textFieldsWithLookupAndRel
		: []
	const textOptionsWithEmpty = [{ label: '-', name: '' }, ...textOptions]

	/****NUMBER***************************/
	const [number, setNumber] = useState('')

	const numberChange = (e) => {
		submitNumber(e.target.value)
		setNumber(e.target.value)
	}

	const submitNumber = (x) => {
		const selectedField = numberFields?.find((y) => y.name == x)

		const modifiedElementData = update(props.elementData, {
			mapping: {
				number: {
					field: { $set: x },
					type: { $set: selectedField?.dataType }
				}
			}
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****TITLE***************************/
	const [isTitleTextMode, setIsTitleTextMode] = useState(false)
	const [titleField, setTitleField] = useState('')
	const [titleDefault, setTitleDefault] = useState('')

	const titleChange = (data) => {
		if (isTitleTextMode) {
			timeoutDelay(submitTitle, data.target.value, 1000)
			setTitleDefault(data.target.value)
		} else {
			submitTitle(data?.value)
			setTitleField(data?.value)
		}
	}
	const submitTitle = (x) => {
		const selectedField = textFieldsWithLookupAndRel?.find((y) => y.name == x)
		const modifiedElementData = update(props.elementData, {
			mapping: {
				title: {
					field: { $set: isTitleTextMode ? null : x },
					default: { $set: isTitleTextMode ? x : null },
					type: { $set: isTitleTextMode ? null : selectedField?.dataType }
				}
			}
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****MIN***************************/
	const [isMinTextMode, setIsMinTextMode] = useState(false)
	const [minField, setMinField] = useState('')
	const [minDefault, setMinDefault] = useState(0)

	const minChange = (data) => {
		if (isMinTextMode) {
			timeoutDelay(submitMin, data.target.value, 1000)
			setMinDefault(data.target.value)
		} else {
			submitMin(data?.value)
			setMinField(data?.value)
		}
	}
	const submitMin = (x) => {
		const selectedField = numberFields?.find((y) => y.name == x)
		const modifiedElementData = update(props.elementData, {
			mapping: {
				min: {
					field: { $set: isMinTextMode ? null : x },
					default: { $set: isMinTextMode ? x : null },
					type: { $set: isMinTextMode ? null : selectedField?.dataType }
				}
			}
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****MAX***************************/
	const [isMaxTextMode, setIsMaxTextMode] = useState(false)
	const [maxField, setMaxField] = useState('')
	const [maxDefault, setMaxDefault] = useState(0)

	const maxChange = (data) => {
		if (isMaxTextMode) {
			timeoutDelay(submitMax, data.target.value, 1000)
			setMaxDefault(data.target.value)
		} else {
			submitMax(data?.value)
			setMaxField(data?.value)
		}
	}
	const submitMax = (x) => {
		const selectedField = numberFields?.find((y) => y.name == x)
		const modifiedElementData = update(props.elementData, {
			mapping: {
				max: {
					field: { $set: isMaxTextMode ? null : x },
					default: { $set: isMaxTextMode ? x : null },
					type: { $set: isMaxTextMode ? null : selectedField?.dataType }
				}
			}
		})
		props.updateSelectedElement(modifiedElementData)
	}

	/****INITIALIZATION***************************/
	const initStates = (elementData) => {
		/****NUMBER***************************/
		setNumber(elementData?.mapping?.number?.field || '')

		/****TITLE***************************/
		elementData?.mapping?.title?.field ||
		elementData?.mapping?.title?.field === ''
			? setIsTitleTextMode(false)
			: setIsTitleTextMode(true)

		setTitleField(elementData?.mapping?.title?.field || '')
		setTitleDefault(elementData?.mapping?.title?.default || '')

		/****MIN***************************/
		elementData?.mapping?.min?.field || elementData?.mapping?.min?.field === ''
			? setIsMinTextMode(false)
			: setIsMinTextMode(true)

		setMinField(elementData?.mapping?.min?.field || '')
		setMinDefault(elementData?.mapping?.min?.default || 0)

		/****MAX***************************/
		elementData?.mapping?.max?.field || elementData?.mapping?.max?.field === ''
			? setIsMaxTextMode(false)
			: setIsMaxTextMode(true)

		setMaxField(elementData?.mapping?.max?.field || '')
		setMaxDefault(elementData?.mapping?.max?.default || 0)
	}

	useEffect(() => {
		props?.elementData && initStates(props.elementData)
	}, [props.elementData])

	return (
		<>
			<AppPageBox>
				<Accordion
					title={t('screens.elementSettings.common.titles.content')}
					expanded={true}
				>
					<styledUI.StyledGrid container alignItems='center'>
						<Grid size={{ xs: 4 }}>
							<Label>{t('screens.elementSettings.numberEntry.column')}</Label>
						</Grid>
						<Grid size={{ xs: 8 }}>
							<SelectBox
								sx={styledUI.Style.selectBox(props, theme)}
								onChange={numberChange}
								value={number}
								data={numberFields}
								valueKey='name'
								textKey='label'
							/>
						</Grid>
					</styledUI.StyledGrid>
					<styledUI.StyledGrid container alignItems='center'>
						<Grid size={{ xs: 4 }}>
							<Label>{t('screens.elementSettings.numberEntry.min')}</Label>
						</Grid>
						<Grid size={{ xs: 8 }}>
							<DoubleStateNumber
								fullWidth={true}
								isTextMode={isMinTextMode}
								setIsTextMode={setIsMinTextMode}
								valChange={minChange}
								textVal={minDefault?.toString()}
								autoCompVal={minField}
								autoCompOptions={numberOptions}
								autoCompEmptyText={t(
									'screens.elementSettings.numberEntry.emptyField'
								)}
								valueKey='name'
								labelKey='label'
							/>
						</Grid>
					</styledUI.StyledGrid>
					<styledUI.StyledGrid container alignItems='center'>
						<Grid size={{ xs: 4 }}>
							<Label>{t('screens.elementSettings.numberEntry.max')}</Label>
						</Grid>
						<Grid size={{ xs: 8 }}>
							<DoubleStateNumber
								fullWidth={true}
								isTextMode={isMaxTextMode}
								setIsTextMode={setIsMaxTextMode}
								valChange={maxChange}
								textVal={maxDefault?.toString()}
								autoCompVal={maxField}
								autoCompOptions={numberOptions}
								autoCompEmptyText={t(
									'screens.elementSettings.numberEntry.emptyField'
								)}
								valueKey='name'
								labelKey='label'
							/>
						</Grid>
					</styledUI.StyledGrid>
				</Accordion>
			</AppPageBox>

			<AppPageBox>
				<Accordion
					title={t('screens.elementSettings.common.titles.design')}
					expanded={true}
				>
					<styledUI.StyledGrid container alignItems='center'>
						<Grid size={{ xs: 4 }}>
							<Label>{t('screens.elementSettings.numberEntry.title')}</Label>
						</Grid>
						<Grid size={{ xs: 8 }}>
							<DoubleStateText
								fullWidth={true}
								isTextMode={isTitleTextMode}
								setIsTextMode={setIsTitleTextMode}
								valChange={titleChange}
								textVal={titleDefault}
								autoCompVal={titleField}
								autoCompOptions={textOptionsWithEmpty}
								autoCompEmptyText={t(
									'screens.elementSettings.numberEntry.emptyField'
								)}
								valueKey='name'
								labelKey='label'
							/>
						</Grid>
					</styledUI.StyledGrid>
				</Accordion>
			</AppPageBox>

			<VisibilityOptions
				block={props.elementData}
				activeScreen={props.activeScreen}
				entities={props.entities}
				updateBlock={props.updateSelectedElement}
			/>
		</>
	)
}

export default NumberEntry
