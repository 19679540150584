import React, { useState, useEffect } from 'react'
import { styled } from '@mui/material/styles'
import { useTheme } from '@mui/material/styles'

import Cookies from 'universal-cookie'
import { useTranslation } from 'react-i18next'

import KozmikProgressBar from '../../atoms/kozmikProgressBar/KozmikProgressBar'

const MainContainerStyled = styled('div')(({ theme }) => ({
	width: '100%',
	padding: '10px 15px',
	backgroundColor: theme.custom.colors.gray,
	[theme.breakpoints.up('md')]: {
		display: 'none'
	}
}))

const HeadContainerStyled = styled('div')(({ theme }) => ({
	padding: '0 10px'
}))

const TitleStyled = styled('span')(({ theme }) => ({
	fontSize: 14,
	fontWeight: 'bold',
	display: 'inline-block'
}))

const SubTitleStyled = styled('span')(({ theme }) => ({
	fontSize: 12,
	display: 'inline-block'
}))

const ProgressContainerStyled = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center'
}))

const ProgressStyled = styled(KozmikProgressBar)(({ theme }) => ({
	margin: '15px 0',
	flexGrow: 1
}))

const ProgressTextStyled = styled('span')(({ theme }) => ({
	fontSize: 10,
	fontWeight: 'bold',
	paddingLeft: 11
}))

const cookieVersion = '0.0.2' //increment version for data changes

const OnboardingMobile = (props) => {
	const { t } = useTranslation()
	const cookies = new Cookies()
	const theme = useTheme()

	const localizedData = t('onboarding.data', { returnObjects: true })

	const [cookieData, setCookieData] = useState(null)

	const setOnboardingCookie = (data) => {
		cookies.set('onboard', data, {
			path: '/',
			sameSite: 'none',
			secure: true,
			domain: process.env.REACT_APP_COOKIE_DOMAIN
			// httpOnly: true
		})
		setCookieData(data)
	}

	const getCookie = () => {
		const cData = cookies.get('onboard')
		if (cData?.v === cookieVersion) {
			setCookieData(cData)
		} else {
			const newcData = {
				v: cookieVersion,
				closed: false,
				progress: Array(localizedData.length).fill(0)
			}

			setOnboardingCookie(newcData)
		}
	}

	const completedCount = cookieData?.progress?.reduce((a, b) => a + b, 0)
	const itemCount = cookieData?.progress?.length

	const renderOnboarding = () => {
		return props.justSayHello ? (
			<MainContainerStyled>
				<HeadContainerStyled>
					<TitleStyled>{t('onboarding.title')}</TitleStyled>
					<SubTitleStyled>{t('onboarding.subTitle')}</SubTitleStyled>
				</HeadContainerStyled>
			</MainContainerStyled>
		) : (
			itemCount != completedCount && (
				<MainContainerStyled
					onClick={() => {
						props.setIsOnboardingVisible(true)
					}}
				>
					<HeadContainerStyled>
						<TitleStyled>{t('onboarding.title')}</TitleStyled>
						<SubTitleStyled>{t('onboarding.subTitle')}</SubTitleStyled>
						<ProgressContainerStyled>
							<ProgressStyled
								barColor={'#ffffff'}
								progressColor={theme.custom.colors.kozmikGreen}
								height={6}
								variant='determinate'
								value={(100 / itemCount) * completedCount}
							/>
							{!!cookieData && (
								<ProgressTextStyled>
									{completedCount}/{itemCount}
								</ProgressTextStyled>
							)}
						</ProgressContainerStyled>
					</HeadContainerStyled>
				</MainContainerStyled>
			)
		)
	}

	useEffect(() => {
		getCookie()
	}, [])

	useEffect(() => {
		getCookie()
	}, [props.isOnboardingVisible])

	return props.builderOnMobile && renderOnboarding()
}

export default OnboardingMobile
