import React, { useEffect, useState } from 'react'
import { useTheme } from '@mui/material/styles'
import { MentionsInput as MentionsInputLegacy, Mention } from 'react-mentions'
import timeoutDelay from '../../../methods/timeoutDelay'

let container

const MentionsInput = (props) => {
	const theme = useTheme()

	const {
		name,
		placeholder,
		valueKey,
		labelKey,
		onChange,
		className,
		inputStyle,
		readonly,
		whitelist,
		multiline,
		delay,
		...restProps
	} = props

	const [value, setValue] = useState('')

	const convertTemplateToMarkup = (template) => {
		template?.replace(/\[(.*?)\]/g, (tagText) => {
			const tagArr = JSON.parse(tagText)
			if (Array.isArray(tagArr) && tagArr.length > 0) {
				const tag = tagArr[0]
				if (tag) {
					const field = tag.name ?? tag.value
					const label = tag.label

					template = template.replace(
						tagText,
						`[{"id": null, "name": "${field}", "label": "${label}", "dataType": null}]`
					)
				}
			}
		})

		return template
	}

	useEffect(() => {
		setValue(convertTemplateToMarkup(props.value))
	}, [props.name])

	return (
		<div
			id='suggestionPortal'
			ref={(el) => {
				container = el
			}}
			style={
				multiline
					? {}
					: {
							maxHeight: 38
					  }
			}
		>
			<MentionsInputLegacy
				singleLine={!multiline}
				// allowSuggestionsAboveCursor={true}
				// forceSuggestionsAboveCursor={true}
				value={value ?? ''}
				className={className}
				onChange={(event, newValue, newPlainTextValue, mentions) => {
					let template = newValue

					mentions?.map((mention, index) => {
						const item = whitelist?.find(
							(x) => x.value === mention.id || x.name === mention.id
						)

						template = template.replace(
							`[{"id": null, "name": "${mention.id}", "label": "${mention.display}", "dataType": null}]`,
							`[{"id": "${item?.id}", "name": "${mention.id}", "label": "${mention.display}", "dataType": "${item?.dataType}"}]`
						)
					})

					setValue(convertTemplateToMarkup(template))
					timeoutDelay(onChange, template, delay ?? 1000)
				}}
				suggestionsPortalHost={container}
				placeholder={placeholder}
				style={{
					control: {
						fontSize: 14,
						fontWeight: 'normal',
						border: 0
					},

					'&multiLine': {
						control: {
							minHeight: 100
						},
						highlighter: {
							backgroundColor: 'transparent',
							padding: '8px 14px',
							border: 0
						},
						input: {
							...inputStyle,
							padding: '8px 14px',
							border: 0
						}
					},

					'&singleLine': {
						display: 'inline-block',
						width: '100%',

						highlighter: {
							backgroundColor: 'transparent',
							padding: '8px 14px',
							border: 0
						},
						input: {
							...inputStyle,
							padding: '8px 14px',
							height: 38,
							border: 0
						}
					},

					suggestions: {
						zIndex: 2,
						marginTop: 0,
						position: 'fixed',

						list: {
							overflow: 'auto',
							position: 'absolute',
							top: 14,
							maxHeight: 500,
							backgroundColor: 'white',
							fontSize: 14,
							borderRadius: 6,
							boxShadow: `0 3px 7px ${theme.custom.colors.middleGray}, 0 0.6px 2px ${theme.custom.colors.middleGray}`
						},
						item: {
							padding: '5px 15px',
							'&focused': {
								backgroundColor: theme.custom.colors.kozmikLightBlue,
								color: theme.custom.colors.kozmikBlue
							}
						}
					}
				}}
			>
				<Mention
					markup='[{"id": null, "name": "__id__", "label": "__display__", "dataType": null}]'
					trigger={/(?:^|\s{0})(([^ {1}]|[^\s{1}]*))$/}
					data={whitelist.map((item, index) => ({
						id: valueKey ? item[valueKey] : item.name,
						display: labelKey ? item[labelKey] : item.label
					}))}
					style={{
						position: 'relative',
						zIndex: 1,
						backgroundColor: theme.custom.colors.gray,
						color: theme.custom.colors.kozmikBlue
					}}
				/>
			</MentionsInputLegacy>
		</div>
	)
}

export default MentionsInput
