import * as React from 'react'
import { IconButton, Box } from '@mui/material'
import { Publish } from '@mui/icons-material'

const MediaUploadButton = (props) => {
	return (
		<Box sx={{ margin: '0 1%' }}>
			<input
				// refs='file'
				accept='image/*'
				style={{ display: 'none' }}
				id='icon-button-file'
				type='file'
				{...props}
			/>

			<label
				htmlFor='icon-button-file'
				style={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					borderRadius: 1
				}}
			>
				<IconButton
					aria-label='upload picture'
					component='span'
					sx={{
						color: '#000000',
						fontSize: '14px',
						fontWeight: 600,
						borderRadius: '8px',
						padding: '8px'
					}}
					size='large'
				>
					<Publish style={{ marginRight: 5 }} />
					{props?.text}
				</IconButton>
			</label>
		</Box>
	)
}

export default MediaUploadButton
