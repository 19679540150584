import React, { useState, useEffect, useRef } from 'react'
import { styled } from '@mui/material/styles'
import { useTheme } from '@mui/material/styles'
import {
	Button,
	Typography,
	Box,
	Autocomplete,
	CircularProgress
} from '@mui/material'
import Grid from '@mui/material/Grid2'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import CheckIcon from '@mui/icons-material/Check'
import { connect } from 'react-redux'

import KozmikTextField from '../../atoms/kozmikTextField/KozmikTextField'
import KozmikCheckBox from '../../atoms/kozmikCheckBox/KozmikCheckBox'
import KozmikModal from '../../molecules/kozmikModal/KozmikModal'

import { ReactComponent as ExcelIcon } from '../../../assets/icons/excel_icon.svg'
import { ReactComponent as MongoIcon } from '../../../assets/icons/mongodb_icon.svg'
import { ReactComponent as PostgreIcon } from '../../../assets/icons/postgresql_icon.svg'
import { ReactComponent as MSSQLIcon } from '../../../assets/icons/mssql_icon.svg'
import { ReactComponent as MySQLIcon } from '../../../assets/icons/mysql_icon.svg'

import appClient from '../../../services/appClient'
import authorizationClient from '../../../services/authorizationClient'
import dataSourceClient from '../../../services/dataSourceClient'
import dataEntityClient from '../../../services/dataEntityClient'
import showToastResponseError from '../../../methods/showToastResponseError'
import convertBase64 from '../../../methods/convertBase64'
import appMethods from '../../../methods/app'

const ButtonTextStyled = styled(Typography)(({ theme }) => ({
	fontWeight: 'bold',
	fontSize: 14
}))

const TextStyled = styled(Typography)(({ theme }) => ({
	fontSize: '14px',
	fontWeight: theme.custom.fontWeight.fw700
}))

const TextMediumStyled = styled(Typography)(({ theme }) => ({
	fontSize: '14px'
}))

const DataSourceOptionsBoxStyled = styled('div')(({ theme }) => ({
	flexWrap: 'wrap',
	display: 'flex',
	justifyContent: 'space-between',
	padding: '0',
	[theme.breakpoints.down('sm')]: {
		padding: 0
	}
}))

const FilesBoxStyled = styled('div')(({ theme }) => ({
	flexWrap: 'wrap',
	display: 'flex',
	justifyContent: 'space-between',
	padding: '0',
	[theme.breakpoints.down('sm')]: {
		padding: 0
	}
}))

const FilesBoxContentStyled = styled('div')(({ theme }) => ({
	width: '100%',
	borderRadius: 16,
	border: '1px dashed #979797',
	overflow: 'hidden',
	backgroundColor: theme.custom.colors.kozmikGray
}))

const DatabasesContainerStyled = styled(Grid)(({ theme }) => ({
	backgroundColor: theme.custom.colors.kozmikDarkGray,
	padding: 20,
	borderRadius: 10
}))

const DatabaseOptionsBoxStyled = styled('div')(({ theme }) => ({
	flexWrap: 'wrap',
	display: 'flex',
	justifyContent: 'space-between',
	padding: '0',
	[theme.breakpoints.down('sm')]: {
		padding: 0
	}
}))

const ContentBtnBoxStyled = styled('div')(({ theme }) => ({
	width: '100%',
	height: '100%',
	cursor: 'pointer',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	borderRadius: '4px',
	padding: 20
}))

const ContentIconBoxStyled = styled('div')(({ theme }) => ({
	height: '60%',
	alignItems: 'center',
	[theme.breakpoints.down('sm')]: {
		height: '40%'
	}
}))

const ContentTxtBoxStyled = styled('div')(({ theme }) => ({
	display: 'flex',
	justifyContent: 'center',
	flexWrap: 'wrap'
}))

const ModalTitleStyled = styled(Grid)(({ theme }) => ({
	display: 'flex',
	paddingTop: 25,
	paddingBottom: 15
}))

const ExcelIconStyled = styled(ExcelIcon)(({ theme }) => ({
	color: theme.custom.colors.textPrimary,
	width: 30,
	height: 30
}))

const CheckIconStyled = styled(CheckIcon)(({ theme }) => ({
	color: theme.custom.colors.kozmikGreen,
	width: 30,
	height: 30
}))

const ErrorTextStyled = styled('div')(({ theme }) => ({
	color: theme.custom.colors.red,
	paddingTop: 10,
	fontSize: 12
}))

const ContentPrimaryTextStyled = styled(Typography)(({ theme }) => ({
	fontSize: 14,
	textAlign: 'center',
	marginBottom: 5,
	fontWeight: theme.custom.fontWeight.fw700
}))

const ContentSecondaryTextStyled = styled(Typography)(({ theme }) => ({
	fontSize: 11,
	textAlign: 'center'
}))

const DataSourceOptionBoxStyled = styled('div')((props) => ({
	...{
		width: '31%',
		borderRadius: 16,
		border: `1px solid ${props.theme.custom.colors.kozmikGray}`,
		overflow: 'hidden',
		backgroundColor: props.theme.custom.colors.kozmikGray
	},
	...(props.isActive && {
		borderColor: props.theme.custom.colors.kozmikBlue,
		borderStyle: 'solid'
	})
}))

const DatabaseOptionBoxStyled = styled('div')((props) => ({
	...{
		width: '30%',
		borderRadius: 16,
		border: `1px solid ${props.theme.custom.colors.kozmikGray}`,
		overflow: 'hidden',
		backgroundColor: props.theme.custom.colors.kozmikGray
	},
	...(props.isActive && {
		borderColor: props.theme.custom.colors.kozmikBlue,
		borderStyle: 'solid'
	})
}))

const BadgeStyled = styled(Typography)(({ theme }) => ({
	marginTop: 10,
	fontSize: 9,
	fontWeight: 'bold',
	borderRadius: 7.5,
	padding: '4px 8px',
	backgroundImage:
		'linear-gradient(90deg, #96f1ce 50%, rgba(27,54,254,0.3) 100%)'
}))

const DatabaseIconStyled = (props) => (
	<props.database.icon
		sx={{
			color: props.theme.custom.colors.textPrimary,
			height: 60
		}}
	/>
)

const NewAppModal = (props) => {
	const { t } = useTranslation()
	const theme = useTheme()
	const history = useHistory()

	const navigate = (url) => {
		history.push(url)
	} //let navigate = useNavigate()

	/****APP_NAME***************************/
	const [appName, setAppName] = useState(
		t('applications.newAppModal.appNameDefaultValue')
	)
	const [nameErrorText, setNameErrorText] = useState('')

	const errorText = t('applications.newAppModal.error')
	const filesErrorText = t('applications.newAppModal.filesError')
	const portErrorText = t('applications.newAppModal.externalDatabase.portError')

	const nameChange = (e) => {
		const valid = e.target.value != ''

		if (valid) {
			setNameErrorText('')
		} else {
			setNameErrorText(errorText)
		}
		setAppName(e.target.value)
	}

	const handleClose = () => {
		props.setOpen(false)
	}

	/****DATASOURCE***************************/
	const databases = [
		{
			name: 'Microsoft SQL',
			type: 'MSSQL',
			description: 'Lorem ipsum dolar amet sit.',
			icon: MSSQLIcon
		},
		{
			name: 'PostgreSQL',
			type: 'PostgreSQL',
			description: 'Lorem ipsum dolar amet sit.',
			icon: PostgreIcon
		},
		{
			name: 'MySQL',
			type: 'MySQL',
			description: 'Lorem ipsum dolar amet sit.',
			icon: MySQLIcon
		}
		// {
		// 	name: 'MongoDB',
		// 	type: 'MongoDB',
		// 	description: 'Lorem ipsum dolar amet sit.',
		// 	icon: MongoIcon
		// }
	]
	const [testing, setTesting] = useState(false)
	const [dataSourceId, setDataSourceId] = useState('')
	const [dataSourceType, setDataSourceType] = useState('')
	const [databaseType, setDatabaseType] = useState('')
	const [existingApps, setExistingApps] = useState([])
	const [tables, setTables] = useState([])

	const [databaseHost, setDatabaseHost] = useState('')
	const [databaseHostErrorText, setDatabaseHostErrorText] = useState('')
	const [databasePort, setDatabasePort] = useState('')
	const [databasePortErrorText, setDatabasePortErrorText] = useState('')
	const [databaseName, setDatabaseName] = useState('')
	const [databaseNameErrorText, setDatabaseNameErrorText] = useState('')
	const [databaseUsername, setDatabaseUsername] = useState('')
	const [databaseUsernameErrorText, setDatabaseUsernameErrorText] = useState('')
	const [databasePassword, setDatabasePassword] = useState('')
	const [databasePasswordErrorText, setDatabasePasswordErrorText] = useState('')

	const [userTableTableName, setUserTableTableName] = useState('')
	const [userTableEmailField, setUserTableEmailField] = useState('')
	const [userTableNameField, setUserTableNameField] = useState('')

	const [isConnectionStringValid, setIsConnectionStringValid] = useState(false)

	const [showTables, setShowTables] = useState(false)
	const [showUserTableSetting, setShowUserTableSetting] = useState(false)

	const clearCustomDatabaseInputs = () => {
		setDatabaseHost('')
		setDatabaseHostErrorText('')
		setDatabasePort('')
		setDatabasePortErrorText('')
		setDatabaseName('')
		setDatabaseNameErrorText('')
		setDatabaseUsername('')
		setDatabaseUsernameErrorText('')
		setDatabasePassword('')
		setDatabasePasswordErrorText('')
		setIsConnectionStringValid(false)
	}

	const existingAppsOnChange = (e, val) => {
		setDataSourceId(val?.DataSourceId ?? '')
	}

	const userTableTableNameOnChange = (e, val) => {
		setUserTableTableName(val ?? '')
		setUserTableEmailField('')
		setUserTableNameField('')

		const copyOfTables = [...tables]
		copyOfTables.forEach((t) => {
			if (t.name === val) {
				t.isSelected = true
			} else {
				t.isSelected = false
			}
		})

		setTables(copyOfTables)
	}

	const userTableEmailFieldOnChange = (e, val) => {
		setUserTableEmailField(val ?? '')
	}

	const userTableNameFieldOnChange = (e, val) => {
		setUserTableNameField(val ?? '')

		const copyOfTables = [...tables]
		copyOfTables.forEach((t) => {
			if (t.name === userTableTableName) {
				t.displayField = val
			}
		})

		setTables(copyOfTables)
	}

	const databaseHostChange = (e) => {
		const valid = e.target.value != ''

		if (valid) {
			setDatabaseHostErrorText('')
			isConnectionStringValid && setIsConnectionStringValid(false)
		} else {
			setDatabaseHostErrorText(errorText)
		}
		setDatabaseHost(e.target.value)
	}

	const databasePortChange = (e) => {
		const valid = e.target.value != ''

		if (valid) {
			const onlyNumberRegex = /^\d+$/
			if (onlyNumberRegex.test(String(e.target.value))) {
				setDatabasePortErrorText('')
				isConnectionStringValid && setIsConnectionStringValid(false)
			} else {
				setDatabasePortErrorText(portErrorText)
			}
		} else {
			setDatabasePortErrorText(errorText)
		}
		setDatabasePort(e.target.value)
	}

	const databaseNameChange = (e) => {
		const valid = e.target.value != ''

		if (valid) {
			setDatabaseNameErrorText('')
			isConnectionStringValid && setIsConnectionStringValid(false)
		} else {
			setDatabaseNameErrorText(errorText)
		}
		setDatabaseName(e.target.value)
	}

	const databaseUsernameChange = (e) => {
		const valid = e.target.value != ''

		if (valid) {
			setDatabaseUsernameErrorText('')
			isConnectionStringValid && setIsConnectionStringValid(false)
		} else {
			setDatabaseUsernameErrorText(errorText)
		}
		setDatabaseUsername(e.target.value)
	}

	const databasePasswordChange = (e) => {
		const valid = e.target.value != ''

		if (valid) {
			setDatabasePasswordErrorText('')
			isConnectionStringValid && setIsConnectionStringValid(false)
		} else {
			setDatabasePasswordErrorText(errorText)
		}
		setDatabasePassword(e.target.value)
	}

	const connectionString =
		databaseHost &&
		databasePort &&
		databaseName &&
		databaseUsername &&
		databasePassword

	const testConnection = () => {
		if (dataSourceType === 'Custom' && databaseType && connectionString) {
			setTesting(true)
			setIsConnectionStringValid(false)

			const postData = {
				DataSourceType: dataSourceType,
				DatabaseType: databaseType,
				DatabaseHost: databaseHost,
				DatabasePort: databasePort,
				DatabaseName: databaseName,
				DatabaseUsername: databaseUsername,
				DatabasePassword: databasePassword
			}

			dataSourceClient
				.testConnection(postData)
				.then(async (response) => {
					if (response?.data?.success) {
						setIsConnectionStringValid(true)
						setTesting(false)
					} else {
						setIsConnectionStringValid(false)
						setTesting(false)
						showToastResponseError(response)
					}
				})
				.catch((error) => {
					setIsConnectionStringValid(false)
					setTesting(false)
					showToastResponseError(error.response)
				})
		}
	}

	const getEntities = () => {
		if (
			dataSourceType === 'Custom' &&
			databaseType &&
			connectionString &&
			isConnectionStringValid
		) {
			if (tables && tables.length > 0) {
				setShowUserTableSetting(true)
				return
			}

			setFetching(true)

			const postData = {
				DataSourceType: dataSourceType,
				DatabaseType: databaseType,
				DatabaseHost: databaseHost,
				DatabasePort: databasePort,
				DatabaseName: databaseName,
				DatabaseUsername: databaseUsername,
				DatabasePassword: databasePassword
			}

			dataSourceClient
				.getEntitySummaries(postData)
				.then(async (response) => {
					if (response?.data?.success) {
						let data = []
						response?.data?.data.forEach((element) => {
							data.push({
								isSelected: false,
								name: element.name,
								label: '',
								displayField: element.suggestedDisplayField,
								displayFields: element.displayFields,
								relatedTables: element.relatedTables
							})
						})

						setTables(data)
						setShowUserTableSetting(true)
					} else {
						setTables([])
						showToastResponseError(response)
					}

					setFetching(false)
				})
				.catch((error) => {
					setFetching(false)
					setTables([])
					showToastResponseError(error.response)
				})
		}
	}

	/****FILES***************************/
	const [files, setFiles] = useState([])
	const [filesError, setFilesError] = useState(false)

	const picker = useRef(null)

	const pickerClickHandler = () => {
		pickerClear()
		picker.current.click()
	}

	const pickerClear = () => {
		setFiles([])
		picker.current.value = null
	}

	const pickFile = async (e) => {
		let modifiedFiles = []
		const pickedFiles = Array.from(e.target.files)
		let totalSize = 0

		if (pickedFiles?.length) {
			await Promise.all(
				pickedFiles?.map(async (file) => {
					await convertBase64(file).then((result) => {
						totalSize += result.info.size
						const fileNameParts = result.info.name.split('.')
						const extension = fileNameParts?.[fileNameParts?.length - 1]
						const fileName = fileNameParts?.[0]
						modifiedFiles.push({
							ContentType: result.info.type,
							FileName: fileName,
							FullName: result.info.name,
							Extension: extension,
							Uri: result.data
						})
					})
				})
			)
		}
		//MAX size 5MB
		if (totalSize <= 5000000) {
			setFiles(modifiedFiles)
		} else {
			setFilesError(true)
		}
	}

	/****IS_INVITED_ONLY***************************/
	// const [isInviteOnly, setIsInviteOnly] = useState(false)

	/****SUBMIT***************************/
	const [fetching, setFetching] = useState(false)

	const navigateToApp = (appId) => {
		const appUrl = `/app/${appId}/screens`
		navigate(appUrl)
	}

	const postAppConfigAndNavigate = (appId, configData) => {
		appClient.config(appId, configData).then((response) => {
			if (response?.data?.success) {
				navigateToApp(appId)
			} else {
				showToastResponseError(response)
			}
		})
	}

	const updateNewAppConfig = async (appId) => {
		let newConfigData = {
			linking: {
				config: {
					screens: {
						notFound: '*'
					}
				}
			},
			screens: {
				tabs: [],
				drawers: [],
				hiddens: []
			}
		}

		// changed by Ahmet 2022-12-11 - business decision
		// if (files?.length) {
		await dataEntityClient
			.entities(appId, {
				limit: 3
			})
			.then((response) => {
				if (response?.data?.success) {
					if (response.data?.data.length > 0) {
						const entities = response.data.data.filter((x) => !x.managed)
						entities.forEach((entity) => {
							const result = appMethods.getAutoGeneratedScreensByTable(
								entity,
								newConfigData.screens,
								newConfigData.linking,
								{
									list: true,
									detail: true
								}
							)

							newConfigData.screens = result.screens
							newConfigData.linking = result.linking
						})
					}
				} else {
					showToastResponseError(response)
				}
			})
		// } else {
		// 	const newScreen = new Screen()

		await postAppConfigAndNavigate(appId, newConfigData)
	}

	const submit = () => {
		const nameValid = appName != ''
		if (nameValid) {
			setFetching(true)

			const postData = {
				Name: appName,
				Files: files,
				Tables: [],
				DataSourceType: dataSourceType === 'Shared' ? null : dataSourceType,
				DataSourceId: dataSourceType === 'Shared' ? dataSourceId : null,
				DatabaseType: dataSourceType === 'Custom' ? databaseType : null,
				DatabaseHost: dataSourceType === 'Custom' ? databaseHost : null,
				DatabasePort: dataSourceType === 'Custom' ? databasePort : null,
				DatabaseName: dataSourceType === 'Custom' ? databaseName : null,
				DatabaseUsername: dataSourceType === 'Custom' ? databaseUsername : null,
				DatabasePassword: dataSourceType === 'Custom' ? databasePassword : null,
				UserTableSetting: {
					TableName: userTableTableName,
					EmailFieldName: userTableEmailField,
					NameFieldName: userTableNameField
				}
			}
			if (tables?.length > 0) {
				tables
					.filter((x) => x.isSelected)
					.forEach((element) => {
						postData.Tables.push({
							IsSelected: true,
							Name: element.name,
							Label: element.label,
							DisplayField: element.displayField
						})
					})
			}

			appClient
				.create(postData)
				.then(async (response) => {
					if (response?.data?.success) {
						const appId = response.data?.unique?.Value
						if (appId) {
							await authorizationClient.refreshTokenAndUserInfo()
							await updateNewAppConfig(appId)
							// handleClose()
						}
						// else {
						// 	handleClose()
						// }
					} else {
						showToastResponseError(response)
					}
					// setFetching(false)
				})
				.catch((error) => {
					setFetching(false)
				})
		} else {
			setNameErrorText(errorText)
		}
	}

	/****INITIALIZATION***************************/
	const clearModalStates = () => {
		setAppName(t('applications.newAppModal.appNameDefaultValue'))
		setNameErrorText('')
		setFiles([])
		setFilesError(false)
		setFetching(false)

		setDataSourceId('')
		setDataSourceType('Tables')
		setDatabaseType('')
		clearCustomDatabaseInputs()
		setIsConnectionStringValid(false)
		setTesting(false)
		setTables([])
		setShowTables(false)
		setShowUserTableSetting(false)

		setUserTableTableName('')
		setUserTableEmailField('')
		setUserTableNameField('')
	}

	useEffect(() => {
		props.open && clearModalStates()
	}, [props.open])

	useEffect(() => {
		setExistingApps(props.apps ?? [])
	}, [props.apps])

	return (
		<KozmikModal
			visible={props.open}
			hideModal={handleClose}
			flexHeight
			maxHeight={700}
		>
			<Grid
				container
				justifyContent='center'
				style={{ marginBottom: '40px !important' }}
			>
				{showUserTableSetting && (
					<Grid container size={{ xs: 10, sm: 10 }}>
						<ModalTitleStyled container size={{ xs: 12 }} alignItems='center'>
							<Grid size={{ xs: 12, sm: 12 }}>
								<TextStyled style={{ fontSize: 18, padding: 0 }}>
									{t('applications.newAppModal.userTableSetting.title')}
								</TextStyled>
							</Grid>
						</ModalTitleStyled>

						<Grid
							container
							size={{ xs: 12, sm: 12 }}
							style={{ marginTop: '10px !important' }}
						>
							<Grid
								container
								size={{ xs: 12, sm: 12 }}
								spacing={1}
								style={{ marginTop: '10px !important' }}
							>
								<Grid
									size={{ xs: 4, sm: 4 }}
									style={{
										display: 'flex',
										alignItems: 'center'
									}}
								>
									<TextMediumStyled>
										{t('applications.newAppModal.userTableSetting.table')}
									</TextMediumStyled>
								</Grid>
								<Grid size={{ xs: 8, sm: 8 }}>
									<Autocomplete
										onChange={userTableTableNameOnChange}
										fullWidth={true}
										size='small'
										multiple={false}
										value={userTableTableName}
										options={tables.map((x) => x.name)}
										sx={{
											'& .MuiOutlinedInput-root': {
												borderRadius: '4px',
												backgroundColor: theme.custom.colors.kozmikGray,
												fontSize: '14px',
												color: theme.custom.colors.textPrimary
											},
											'& label': {
												fontSize: '14px'
											}
										}}
										getOptionLabel={(option) => {
											return option ?? ''
										}}
										renderOption={(props, option) => {
											return (
												<div {...props} style={{ fontSize: 14 }}>
													{option ?? ''}
												</div>
											)
										}}
										renderInput={(params) => (
											<KozmikTextField
												{...params}
												placeholder={t(
													'applications.newAppModal.userTableSetting.tablePlaceholder'
												)}
											/>
										)}
									/>
								</Grid>
							</Grid>
						</Grid>
						{userTableTableName && (
							<Grid
								container
								size={{ xs: 12, sm: 12 }}
								style={{ marginTop: '30px !important' }}
							>
								<Grid
									size={{ xs: 12, sm: 12 }}
									style={{ marginTop: '10px !important' }}
								>
									<TextStyled gutterBottom>
										{t('applications.newAppModal.userTableSetting.mapping')}
									</TextStyled>
								</Grid>
								<Grid
									container
									size={{ xs: 12, sm: 12 }}
									style={{ marginTop: '20px !important' }}
								>
									<Grid container size={{ xs: 12, sm: 12 }} spacing={1}>
										<Grid
											size={{ xs: 4, sm: 4 }}
											style={{
												display: 'flex',
												alignItems: 'center'
											}}
										>
											<TextMediumStyled>
												{t('applications.newAppModal.userTableSetting.email')}
											</TextMediumStyled>
										</Grid>
										<Grid size={{ xs: 8, sm: 8 }}>
											<Autocomplete
												onChange={userTableEmailFieldOnChange}
												fullWidth={true}
												size='small'
												multiple={false}
												value={userTableEmailField}
												options={
													tables
														.find((x) => x.name === userTableTableName)
														?.displayFields?.filter(
															(x) =>
																x !== userTableNameField || !userTableNameField
														) ?? []
												}
												sx={{
													'& .MuiOutlinedInput-root': {
														borderRadius: '4px',
														backgroundColor: theme.custom.colors.kozmikGray,
														fontSize: '14px',
														color: theme.custom.colors.textPrimary
													},
													'& label': {
														fontSize: '14px'
													}
												}}
												getOptionLabel={(option) => {
													return option
												}}
												renderOption={(props, option) => {
													return (
														<div {...props} style={{ fontSize: 14 }}>
															{option}
														</div>
													)
												}}
												renderInput={(params) => (
													<KozmikTextField
														{...params}
														placeholder={t(
															'applications.newAppModal.userTableSetting.emailPlaceholder'
														)}
													/>
												)}
											/>
										</Grid>
									</Grid>
									<Grid
										container
										size={{ xs: 12, sm: 12 }}
										spacing={1}
										style={{ marginTop: '10px !important' }}
									>
										<Grid
											size={{ xs: 4, sm: 4 }}
											style={{
												display: 'flex',
												alignItems: 'center'
											}}
										>
											<TextMediumStyled>
												{t('applications.newAppModal.userTableSetting.name')}
											</TextMediumStyled>
										</Grid>
										<Grid size={{ xs: 8, sm: 8 }}>
											<Autocomplete
												onChange={userTableNameFieldOnChange}
												fullWidth={true}
												size='small'
												multiple={false}
												value={userTableNameField}
												options={
													tables
														.find((x) => x.name === userTableTableName)
														?.displayFields?.filter(
															(x) =>
																x !== userTableEmailField ||
																!userTableEmailField
														) ?? []
												}
												sx={{
													'& .MuiOutlinedInput-root': {
														borderRadius: '4px',
														backgroundColor: theme.custom.colors.kozmikGray,
														fontSize: '14px',
														color: theme.custom.colors.textPrimary
													},
													'& label': {
														fontSize: '14px'
													}
												}}
												getOptionLabel={(option) => {
													return option
												}}
												renderOption={(props, option) => {
													return (
														<div {...props} style={{ fontSize: 14 }}>
															{option}
														</div>
													)
												}}
												renderInput={(params) => (
													<KozmikTextField
														{...params}
														placeholder={t(
															'applications.newAppModal.userTableSetting.namePlaceholder'
														)}
													/>
												)}
											/>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						)}
						<Grid
							container
							size={{ xs: 12, sm: 12 }}
							spacing={2}
							style={{ marginTop: '10px !important' }}
						>
							<Grid size={{ xs: 6, sm: 6 }}>
								<Button
									onClick={() => {
										setShowUserTableSetting(false)
									}}
									fullWidth
									size='small'
									variant='outlined'
									sx={{
										textTransform: 'none',
										borderColor: `${theme.custom.colors.kozmikBlue} !important`,
										color: `${theme.custom.colors.kozmikBlue} !important`,
										boxShadow: 'none',
										height: '40px',
										fontSize: 16,
										borderRadius: '6px'
									}}
								>
									<Box
										style={{
											display: 'flex',
											alignItems: 'center'
										}}
									>
										<ButtonTextStyled>
											{t('applications.newAppModal.back')}
										</ButtonTextStyled>
									</Box>
								</Button>
							</Grid>
							<Grid size={{ xs: 6, sm: 6 }}>
								<Button
									disabled={
										!(
											appName &&
											isConnectionStringValid &&
											userTableTableName &&
											userTableEmailField &&
											userTableNameField
										)
									}
									onClick={() => {
										setShowUserTableSetting(false)
										setShowTables(true)
									}}
									fullWidth
									size='large'
									variant='outlined'
									sx={{
										textTransform: 'none',
										backgroundColor: `${theme.custom.colors.kozmikBlue} !important`,
										color: '#ffffff !important',
										boxShadow: 'none',
										height: '40px',
										fontSize: 16,
										borderRadius: '6px',
										opacity:
											appName &&
											isConnectionStringValid &&
											userTableTableName &&
											userTableEmailField &&
											userTableNameField
												? 1
												: 0.4
									}}
								>
									<Box
										style={{
											display: 'flex',
											alignItems: 'center'
										}}
									>
										<ButtonTextStyled>
											{t('applications.newAppModal.continue')}
										</ButtonTextStyled>
									</Box>
								</Button>
							</Grid>
						</Grid>
					</Grid>
				)}

				{showTables && (
					<Grid container size={{ xs: 10, sm: 10 }}>
						<ModalTitleStyled container size={{ xs: 12 }} alignItems='center'>
							<Grid size={{ xs: 12, sm: 12 }}>
								<TextStyled style={{ fontSize: 18, padding: 0 }}>
									{t('applications.newAppModal.tables.title')}
								</TextStyled>
							</Grid>
						</ModalTitleStyled>

						<Grid
							container
							size={{ xs: 12, sm: 12 }}
							style={{ marginTop: '10px !important' }}
						>
							<Grid container size={{ xs: 12, sm: 12 }} spacing={1}>
								<Grid size={{ xs: 1, sm: 1 }}>
									<TextStyled gutterBottom>
										{t('applications.newAppModal.tables.choose')}
									</TextStyled>
								</Grid>
								<Grid size={{ xs: 4, sm: 4 }}>
									<TextStyled gutterBottom>
										{t('applications.newAppModal.tables.name')}
									</TextStyled>
								</Grid>
								<Grid size={{ xs: 4, sm: 4 }}>
									<TextStyled gutterBottom>
										{t('applications.newAppModal.tables.label')}
									</TextStyled>
								</Grid>
								<Grid size={{ xs: 3, sm: 3 }}>
									<TextStyled gutterBottom>
										{t('applications.newAppModal.tables.displayField')}
									</TextStyled>
								</Grid>
							</Grid>
							<Grid
								container
								size={{ xs: 12, sm: 12 }}
								spacing={1}
								sx={{
									overflowY: 'scroll',
									maxHeight: 400,
									marginTop: 10
								}}
							>
								{tables.map((table, index) => (
									<Grid
										container
										size={{ xs: 12, sm: 12 }}
										key={index}
										spacing={1}
									>
										<Grid size={{ xs: 1, sm: 1 }}>
											<KozmikCheckBox
												disabled={table.name === userTableTableName}
												checked={table.isSelected}
												onChange={(e) => {
													let copyOfTables = [...tables]
													copyOfTables[index].isSelected = e.target.checked

													if (e.target.checked) {
														if (table.relatedTables?.length > 0) {
															copyOfTables.forEach((element) => {
																if (
																	table.relatedTables.includes(element.name)
																) {
																	element.isSelected = true
																}
															})
														}
													}

													setTables(copyOfTables)
												}}
											/>
										</Grid>
										<Grid
											size={{ xs: 4, sm: 4 }}
											style={{ display: 'inline-flex', alignItems: 'center' }}
										>
											<TextMediumStyled>{table.name}</TextMediumStyled>
										</Grid>
										<Grid size={{ xs: 4, sm: 4 }}>
											<KozmikTextField
												fullWidth
												value={table.label}
												onChange={(e) => {
													let copyOfTables = [...tables]
													copyOfTables[index].label = e.target.value

													setTables(copyOfTables)
												}}
											/>
										</Grid>
										<Grid size={{ xs: 3, sm: 3 }}>
											<Autocomplete
												disabled={table.name === userTableTableName}
												onChange={(e, val) => {
													let copyOfTables = [...tables]
													copyOfTables[index].displayField = val

													setTables(copyOfTables)
												}}
												value={table.displayField}
												fullWidth={true}
												size='small'
												multiple={false}
												options={table.displayFields}
												sx={{
													'& .MuiOutlinedInput-root': {
														borderRadius: '4px',
														backgroundColor: theme.custom.colors.kozmikGray,
														fontSize: '14px',
														color: theme.custom.colors.textPrimary
													},
													'& .MuiInputBase-root.Mui-disabled': {
														'& > fieldset': {
															border: '0px !important'
														}
													},
													'& label': {
														fontSize: '14px'
													}
												}}
												getOptionLabel={(option) => {
													return option || table?.displayField
												}}
												renderOption={(props, option) => {
													return (
														<div {...props} style={{ fontSize: 14 }}>
															{option || table?.displayField}
														</div>
													)
												}}
												renderInput={(params) => (
													<KozmikTextField
														{...params}
														placeholder={table?.displayField}
													/>
												)}
											/>
										</Grid>
									</Grid>
								))}
							</Grid>
						</Grid>
						<Grid
							container
							size={{ xs: 12, sm: 12 }}
							spacing={2}
							style={{ marginTop: '10px !important' }}
						>
							<Grid size={{ xs: 6, sm: 6 }}>
								<Button
									onClick={() => {
										setShowTables(false)
										setShowUserTableSetting(true)
									}}
									fullWidth
									size='small'
									variant='outlined'
									sx={{
										textTransform: 'none',
										borderColor: `${theme.custom.colors.kozmikBlue} !important`,
										color: `${theme.custom.colors.kozmikBlue} !important`,
										boxShadow: 'none',
										height: '40px',
										fontSize: 16,
										borderRadius: '6px'
									}}
								>
									<Box
										style={{
											display: 'flex',
											alignItems: 'center'
										}}
									>
										<ButtonTextStyled>
											{t('applications.newAppModal.back')}
										</ButtonTextStyled>
									</Box>
								</Button>
							</Grid>
							<Grid size={{ xs: 6, sm: 6 }}>
								<Button
									disabled={
										!(
											appName &&
											isConnectionStringValid &&
											userTableTableName &&
											userTableEmailField &&
											userTableNameField
										)
									}
									onClick={submit}
									fullWidth
									size='large'
									variant='contained'
									sx={{
										textTransform: 'none',
										backgroundColor: `${theme.custom.colors.kozmikBlue} !important`,
										color: '#ffffff !important',
										boxShadow: 'none',
										height: '40px',
										fontSize: 16,
										borderRadius: '6px',
										opacity:
											appName &&
											isConnectionStringValid &&
											userTableTableName &&
											userTableEmailField &&
											userTableNameField
												? 1
												: 0.4
									}}
								>
									<Box
										style={{
											display: 'flex',
											alignItems: 'center'
										}}
									>
										<ButtonTextStyled>
											{t('applications.newAppModal.create')}
										</ButtonTextStyled>
										{fetching && (
											<CircularProgress
												size={20}
												sx={{
													margin: '10px',
													color: '#FFFFFF'
												}}
											/>
										)}
									</Box>
								</Button>
							</Grid>
						</Grid>
					</Grid>
				)}

				{!showTables && !showUserTableSetting && (
					<Grid container size={{ xs: 10, sm: 10 }}>
						<ModalTitleStyled container size={{ xs: 12 }} alignItems='center'>
							<TextStyled style={{ fontSize: 18, padding: 0 }}>
								{t('applications.newAppModal.newApp')}
							</TextStyled>
						</ModalTitleStyled>
						<Grid size={{ xs: 12, sm: 12 }}>
							<TextStyled gutterBottom>
								{t('applications.newAppModal.appName')}
							</TextStyled>
							<KozmikTextField
								error={!!nameErrorText}
								helperText={nameErrorText}
								fullWidth
								placeholder={t('applications.newAppModal.appNamePlaceholder')}
								value={appName}
								onChange={nameChange}
								onFocus={(e) => {
									e.target.select()
								}}
								autoFocus
							/>
						</Grid>

						<Grid
							size={{ xs: 12, sm: 12 }}
							style={{ marginTop: '20px !important' }}
						>
							<TextStyled gutterBottom>
								{t('applications.newAppModal.dataSourceSelection')}
							</TextStyled>
							<DataSourceOptionsBoxStyled>
								<DataSourceOptionBoxStyled
									isActive={dataSourceType === 'Tables'}
								>
									<ContentBtnBoxStyled
										onClick={() => {
											setDataSourceType('Tables')
										}}
									>
										<ContentIconBoxStyled>
											<ContentPrimaryTextStyled>
												{t('applications.newAppModal.kozmikTablesTitle')}
											</ContentPrimaryTextStyled>
										</ContentIconBoxStyled>
										<ContentTxtBoxStyled>
											<ContentSecondaryTextStyled>
												{t('applications.newAppModal.kozmikTables')}
											</ContentSecondaryTextStyled>
										</ContentTxtBoxStyled>
									</ContentBtnBoxStyled>
								</DataSourceOptionBoxStyled>

								<DataSourceOptionBoxStyled
									isActive={dataSourceType === 'Shared'}
								>
									<ContentBtnBoxStyled
										onClick={() => {
											setDataSourceType('Shared')
										}}
									>
										<ContentIconBoxStyled>
											<ContentPrimaryTextStyled>
												{t('applications.newAppModal.sharedTitle')}
											</ContentPrimaryTextStyled>
										</ContentIconBoxStyled>
										<ContentTxtBoxStyled>
											<ContentSecondaryTextStyled>
												{t('applications.newAppModal.shared')}
											</ContentSecondaryTextStyled>
										</ContentTxtBoxStyled>
									</ContentBtnBoxStyled>
								</DataSourceOptionBoxStyled>

								<DataSourceOptionBoxStyled
									isActive={dataSourceType === 'Custom'}
								>
									<ContentBtnBoxStyled
										onClick={() => {
											if (props?.currentOrg?.plan?.includes('business')) {
												setDataSourceType('Custom')
												setDatabaseType('MSSQL')
											} else {
												window.location.href = '/subscription?modal=true'
											}
										}}
									>
										<ContentIconBoxStyled>
											<ContentPrimaryTextStyled>
												{t('applications.newAppModal.externalDatabaseTitle')}
											</ContentPrimaryTextStyled>
										</ContentIconBoxStyled>
										<ContentTxtBoxStyled>
											<ContentSecondaryTextStyled>
												{t(
													'applications.newAppModal.externalDatabase.description'
												)}
											</ContentSecondaryTextStyled>
										</ContentTxtBoxStyled>
										{!props?.currentOrg?.plan?.includes('business') && (
											<BadgeStyled>{t('publishModule.upgrade')}</BadgeStyled>
										)}
									</ContentBtnBoxStyled>
								</DataSourceOptionBoxStyled>
							</DataSourceOptionsBoxStyled>
						</Grid>

						{dataSourceType === 'Shared' && (
							<Grid
								size={{ xs: 12, sm: 12 }}
								style={{ marginTop: '20px !important' }}
							>
								<Grid size={{ xs: 12, sm: 12 }}>
									<TextStyled gutterBottom>
										{t('applications.newAppModal.existingApps')}
									</TextStyled>
								</Grid>
								<Grid size={{ xs: 12, sm: 12 }}>
									<Autocomplete
										onChange={existingAppsOnChange}
										fullWidth={true}
										size='small'
										multiple={false}
										options={existingApps}
										sx={{
											'& .MuiOutlinedInput-root': {
												borderRadius: '4px',
												backgroundColor: theme.custom.colors.kozmikGray,
												fontSize: '14px',
												color: theme.custom.colors.textPrimary
											},
											'& label': {
												fontSize: '14px'
											}
										}}
										getOptionLabel={(option) => {
											return (
												option.Name ||
												t('applications.newAppModal.existingAppsEmpty')
											)
										}}
										renderOption={(props, option) => {
											return (
												<div {...props} style={{ fontSize: 14 }}>
													{option.Name ||
														t('applications.newAppModal.existingAppsEmpty')}
												</div>
											)
										}}
										renderInput={(params) => (
											<KozmikTextField
												{...params}
												placeholder={t(
													'applications.newAppModal.existingAppsLabel'
												)}
											/>
										)}
									/>
								</Grid>
							</Grid>
						)}

						{(dataSourceType === 'Tables' || dataSourceType === 'Shared') && (
							<Grid
								container
								size={{ xs: 12, sm: 12 }}
								style={{ marginTop: '20px !important' }}
							>
								<Grid size={{ xs: 12, sm: 12 }}>
									<FilesBoxStyled>
										<FilesBoxContentStyled
											style={
												filesError
													? {
															borderColor: 'red',
															borderStyle: 'solid'
													  }
													: null
											}
										>
											<input
												ref={picker}
												accept='.csv,.xlsx,.xls'
												style={{ display: 'none' }}
												type='file'
												onChange={(e) => {
													pickFile(e)
												}}
												multiple
											/>
											<div onClick={pickerClickHandler}>
												<ContentBtnBoxStyled onClick={props?.onClick}>
													<ContentIconBoxStyled>
														<ExcelIconStyled />
													</ContentIconBoxStyled>
													<ContentTxtBoxStyled>
														{files?.length > 0 ? (
															<ContentSecondaryTextStyled>
																{files[0].FullName}
															</ContentSecondaryTextStyled>
														) : (
															<ContentSecondaryTextStyled>
																{t('applications.newAppModal.importFile')}
															</ContentSecondaryTextStyled>
														)}
													</ContentTxtBoxStyled>
												</ContentBtnBoxStyled>
											</div>
										</FilesBoxContentStyled>
										{filesError && (
											<ErrorTextStyled>{filesErrorText}</ErrorTextStyled>
										)}
									</FilesBoxStyled>
								</Grid>
								<Grid
									size={{ xs: 12, sm: 12 }}
									style={{ marginTop: '20px !important' }}
								>
									<Button
										disabled={
											filesError ||
											!appName ||
											fetching ||
											(dataSourceType === 'Shared' && !dataSourceId)
										}
										onClick={submit}
										fullWidth
										size='large'
										variant='contained'
										sx={{
											textTransform: 'none',
											backgroundColor: `${theme.custom.colors.kozmikBlue} !important`,
											color: '#ffffff !important',
											boxShadow: 'none',
											height: '40px',
											fontSize: 16,
											borderRadius: '6px',
											opacity:
												!filesError &&
												appName &&
												!fetching &&
												(dataSourceType === 'Tables' ||
													(dataSourceType === 'Shared' && dataSourceId))
													? 1
													: 0.4
										}}
									>
										<Box
											style={{
												display: 'flex',
												alignItems: 'center'
											}}
										>
											<ButtonTextStyled>
												{t('applications.newAppModal.create')}
											</ButtonTextStyled>
											{fetching && (
												<CircularProgress
													size={20}
													sx={{
														margin: '10px',
														color: '#ffffff'
													}}
												/>
											)}
										</Box>
									</Button>
								</Grid>
							</Grid>
						)}

						{dataSourceType === 'Custom' && (
							<Grid
								container
								size={{ xs: 12, sm: 12 }}
								style={{ marginTop: '20px !important' }}
							>
								<DatabasesContainerStyled size={{ xs: 12, sm: 12 }}>
									<Grid size={{ xs: 12, sm: 12 }}>
										<DatabaseOptionsBoxStyled>
											{databases.map((database, index) => (
												<DatabaseOptionBoxStyled
													isActive={databaseType === database.type}
													key={index}
												>
													<ContentBtnBoxStyled
														onClick={() => {
															setDatabaseType(database.type)
															clearCustomDatabaseInputs()
														}}
													>
														<ContentIconBoxStyled>
															<DatabaseIconStyled icon={database} />
														</ContentIconBoxStyled>
													</ContentBtnBoxStyled>
												</DatabaseOptionBoxStyled>
											))}
										</DatabaseOptionsBoxStyled>
									</Grid>

									<Grid container style={{ marginTop: '10px !important' }}>
										<Grid size={{ xs: 12, sm: 12 }}>
											<TextStyled gutterBottom>
												{t('applications.newAppModal.externalDatabase.server')}
											</TextStyled>
										</Grid>
										<Grid container size={{ xs: 12, sm: 12 }} spacing={2}>
											<Grid size={{ xs: 6, sm: 6 }}>
												<KozmikTextField
													error={!!databaseHostErrorText}
													helperText={databaseHostErrorText}
													fullWidth
													placeholder={t(
														'applications.newAppModal.externalDatabase.hostPlaceHolder'
													)}
													value={databaseHost}
													onChange={databaseHostChange}
													bgColor='#fff'
												/>
											</Grid>
											<Grid size={{ xs: 2, sm: 2 }}>
												<KozmikTextField
													error={!!databasePortErrorText}
													helperText={databasePortErrorText}
													fullWidth
													placeholder={t(
														'applications.newAppModal.externalDatabase.portPlaceHolder'
													)}
													value={databasePort}
													onChange={databasePortChange}
													bgColor='#fff'
												/>
											</Grid>
											<Grid size={{ xs: 4, sm: 4 }}>
												<KozmikTextField
													error={!!databaseNameErrorText}
													helperText={databaseNameErrorText}
													fullWidth
													placeholder={t(
														'applications.newAppModal.externalDatabase.databaseNamePlaceHolder'
													)}
													value={databaseName}
													onChange={databaseNameChange}
													bgColor='#fff'
												/>
											</Grid>
										</Grid>
										<Grid
											size={{ xs: 12, sm: 12 }}
											style={{ marginTop: '10px !important' }}
										>
											<TextStyled gutterBottom>
												{t(
													'applications.newAppModal.externalDatabase.authentication'
												)}
											</TextStyled>
										</Grid>
										<Grid container size={{ xs: 12, sm: 12 }} spacing={2}>
											<Grid size={{ xs: 6, sm: 6 }}>
												<KozmikTextField
													error={!!databaseUsernameErrorText}
													helperText={databaseUsernameErrorText}
													fullWidth
													placeholder={t(
														'applications.newAppModal.externalDatabase.usernameHolder'
													)}
													value={databaseUsername}
													onChange={databaseUsernameChange}
													bgColor='#fff'
												/>
											</Grid>
											<Grid size={{ xs: 6, sm: 6 }}>
												<KozmikTextField
													error={!!databasePasswordErrorText}
													helperText={databasePasswordErrorText}
													fullWidth
													placeholder={t(
														'applications.newAppModal.externalDatabase.passwordHolder'
													)}
													value={databasePassword}
													onChange={databasePasswordChange}
													bgColor='#fff'
													type='password'
												/>
											</Grid>
										</Grid>
									</Grid>
								</DatabasesContainerStyled>
								<Grid
									container
									size={{ xs: 12, sm: 12 }}
									spacing={2}
									style={{ marginTop: '10px !important' }}
								>
									<Grid size={{ xs: 6, sm: 6 }}>
										<Button
											disabled={!databaseType || !connectionString || testing}
											onClick={testConnection}
											fullWidth
											size='small'
											variant='outlined'
											sx={{
												textTransform: 'none',
												borderColor: `${theme.custom.colors.kozmikBlue} !important`,
												color: `${theme.custom.colors.kozmikBlue} !important`,
												boxShadow: 'none',
												height: '40px',
												fontSize: 16,
												borderRadius: '6px',
												opacity:
													databaseType && connectionString && !testing ? 1 : 0.4
											}}
										>
											<Box
												style={{
													display: 'flex',
													alignItems: 'center'
												}}
											>
												<ButtonTextStyled>
													{t(
														'applications.newAppModal.externalDatabase.testConnection'
													)}
												</ButtonTextStyled>
												{isConnectionStringValid && <CheckIconStyled />}
												{testing && (
													<CircularProgress
														size={20}
														sx={{
															margin: '10px',
															color: theme.custom.colors.kozmikBlue
														}}
													/>
												)}
											</Box>
										</Button>
									</Grid>
									<Grid size={{ xs: 6, sm: 6 }}>
										<Button
											disabled={
												!appName || fetching || !isConnectionStringValid
											}
											onClick={() => {
												getEntities()
											}}
											fullWidth
											size='large'
											variant='outlined'
											sx={{
												textTransform: 'none',
												backgroundColor: `${theme.custom.colors.kozmikBlue} !important`,
												color: '#ffffff !important',
												boxShadow: 'none',
												height: '40px',
												fontSize: 16,
												borderRadius: '6px',
												opacity:
													appName && !fetching && isConnectionStringValid
														? 1
														: 0.4
											}}
										>
											<Box
												style={{
													display: 'flex',
													alignItems: 'center'
												}}
											>
												<ButtonTextStyled>
													{fetching
														? t('applications.newAppModal.fetchingMetadata')
														: t('applications.newAppModal.continue')}
												</ButtonTextStyled>
												{fetching && (
													<CircularProgress
														size={20}
														sx={{
															margin: '10px',
															color: '#ffffff'
														}}
													/>
												)}
											</Box>
										</Button>
									</Grid>
								</Grid>
							</Grid>
						)}
					</Grid>
				)}
			</Grid>
		</KozmikModal>
	)
}

const mapStateToProps = (state) => ({
	...state.userInfo,
	...state.currentOrg
})

export default connect(mapStateToProps, null)(NewAppModal)
