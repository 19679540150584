import React, { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import { Typography } from '@mui/material'
import Grid from '@mui/material/Grid2'
import { useTranslation } from 'react-i18next'
import { ArrowBackIos, Visibility } from '@mui/icons-material'

import { connect } from 'react-redux'

import ViewAs from '../viewAs/ViewAs'

const previewClass = (selectedIndex, isFullPagePreview) => {
	if (isFullPagePreview) {
		return {
			'& .iframe': {
				paddingTop: 40
			}
		}
	}

	switch (selectedIndex) {
		case 0: // mobile
			return { width: 340, height: 700, borderRadius: 44 }
		case 1: // tablet
			return { width: 517, height: 700, borderRadius: 32 }
		case 2: // desktop
			return {
				border: '1px solid #D1D1D1',
				paddingTop: 44,
				width: 900,
				height: 660,
				borderRadius: 6,

				'& .previewBrowserBar': {
					display: 'flex'
				},
				'& .iframe': {
					transform: 'scale(0.55)',
					transformOrigin: '0 0',
					width: '182%',
					height: '182%'
				}
			}
	}
}

const PreviewStyled = styled('div')((props) => ({
	...{
		border: '6px solid #000000',
		borderRadius: 47,
		overflow: 'hidden',
		zIndex: 1
	},
	...previewClass(props.previewButtonIndex, props.isFullPagePreview)
}))

const PreviewMobileBarStyled = styled('div')(({ theme }) => ({
	display: 'none',
	backgroundColor: theme.custom.colors.kozmikLightBlue,
	flexDirection: 'row',
	justifyContent: 'space-between',
	alignItems: 'center',
	width: '100%',
	height: 40,
	padding: '0px 10px',
	position: 'fixed',
	top: 0,
	left: 0,
	cursor: 'pointer',
	zIndex: 101,
	[theme.breakpoints.down('md')]: {
		display: 'flex',
		visibility: 'visible'
	}
}))

const PreviewBrowserBarStyled = styled('div')(({ theme }) => ({
	display: 'none',
	alignItems: 'center',
	justifyContent: 'center',
	position: 'absolute',
	backgroundColor: '#F0F0F4',
	width: '100%',
	height: 40,
	top: 0,
	left: 0,
	right: 0,
	zIndex: 1
}))

const ViewAsBoxStyled = styled('div')(({ theme }) => ({
	position: 'absolute',
	display: 'flex',
	left: 15
}))

const BrowserUrlBoxStyled = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	padding: '0 10px',
	width: 300,
	height: 24,
	overflow: 'hidden',
	borderRadius: 6,
	backgroundColor: '#FFFFFF'
}))

const BrowserUrlStyled = styled('span')(({ theme }) => ({ fontSize: 12 }))

const PreviewMobileButtonStyled = styled('div')(({ theme }) => ({
	display: 'none',
	justifyContent: 'center',
	alignItems: 'center',
	textAlign: 'center',
	width: 60,
	height: 80,
	backgroundColor: '#6BEFC1',
	position: 'fixed',
	right: 0,
	bottom: 0,
	zIndex: 101,
	visibility: 'visible',
	cursor: 'pointer',
	padding: 5,
	[theme.breakpoints.down('md')]: {
		display: 'flex'
	}
}))

const VisibilityIconStyled = styled(Visibility)(({ theme }) => ({
	fontSize: 22,
	color: theme.custom.colors.kozmikDarkBlue
}))

const PreviewMobileButtonTextStyled = styled(Typography)(({ theme }) => ({
	fontSize: 10,
	color: theme.custom.colors.kozmikDarkBlue,
	fontWeight: theme.custom.fontWeight.fw700
}))

const IframeStyled = styled('iframe')((props) => ({
	...{ width: '100%', height: '100%', border: 0 },
	...(props.isFullPagePreview
		? {
				[props.theme.breakpoints.down('md')]: {
					position: 'fixed',
					bottom: 0,
					left: 0,
					zIndex: 100,
					display: 'block !important',
					visibility: 'visible !important'
				}
		  }
		: null)
}))

const PreviewMobileBackButtonContainerStyled = styled('div')(({ theme }) => ({
	display: 'none',
	alignItems: 'center',
	cursor: 'pointer',
	[theme.breakpoints.down('md')]: {
		display: 'flex'
	}
}))

const PreviewMobileBackButtonStyled = styled('div')(({ theme }) => ({
	display: 'flex',
	flexWrap: 'wrap',
	alignItems: 'center',
	justifyContent: 'center',
	textAlign: 'center'
}))

const PreviewMobileBackButtonIconStyled = styled(ArrowBackIos)(({ theme }) => ({
	fontSize: 14,
	color: theme.custom.colors.textPrimary
}))

const PreviewMobileBackButtonTextStyled = styled(Typography)(({ theme }) => ({
	fontSize: 12,
	color: theme.custom.colors.textPrimary,
	fontWeight: theme.custom.fontWeight.fw600
}))

const Preview = (props) => {
	const { t } = useTranslation()

	const mobileButtonClick = () => {
		if (props.isFullPagePreview) {
			props.setIsFullPagePreview(false)
		} else {
			props.setIsFullPagePreview(true)
		}
	}

	return props.previewButtonIndex != null ? (
		<>
			<Grid container justifyContent='center' alignItems='center'>
				<PreviewStyled
					previewButtonIndex={props.previewButtonIndex}
					isFullPagePreview={props.isFullPagePreview}
					style={{
						position: 'relative',
						marginTop: 21,
						zIndex: 11,
						backgroundColor:
							props.previewButtonIndex != 2 &&
							(props.appDetail?.Theme == 'Dark' ? '#24272a' : '#FFFFFF')
					}}
				>
					{props?.splash && props.previewButtonIndex != 2 && (
						<div
							style={{
								position: 'absolute',
								top: 0,
								bottom: 0,
								left: 0,
								right: 0,
								backgroundColor: props.appDetail?.SplashUseAccentColor
									? props.appDetail?.AccentColor
										? props.appDetail.AccentColor
										: props.appDetail?.Theme == 'Light'
										? '#24272a'
										: '#FFFFFF'
									: props.appDetail?.Theme == 'Light'
									? '#FFFFFF'
									: '#24272a',
								zIndex: 1,
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center'
							}}
						>
							{props.appDetail?.SplashIcon && (
								<img
									src={props.appDetail?.SplashIcon}
									alt='splash screen icon'
									style={{
										width: '100%',
										height: '100%',
										objectFit: props.appDetail?.SplashResizeMode || 'contain'
									}}
								></img>
							)}
						</div>
					)}

					<PreviewBrowserBarStyled className='previewBrowserBar'>
						<ViewAsBoxStyled>
							<ViewAs inline={true} users={props.users} />
						</ViewAsBoxStyled>
						<BrowserUrlBoxStyled>
							<BrowserUrlStyled>{`https://${
								props?.shortName ? props.shortName : t('preview.yourapp')
							}.kozmik.io`}</BrowserUrlStyled>
						</BrowserUrlBoxStyled>
					</PreviewBrowserBarStyled>

					<IframeStyled
						isFullPagePreview={props.isFullPagePreview}
						className='iframe'
						id='kozmikPreviewFrame'
						// loading='lazy'
						allow='autoplay; camera; microphone; web-share; fullscreen'
						// src='http://localhost:8081'
						src='https://play.kozmik.io'
					></IframeStyled>

					{props.isFullPagePreview && (
						<PreviewMobileBarStyled>
							<PreviewMobileBackButtonContainerStyled
								onClick={mobileButtonClick}
							>
								<PreviewMobileBackButtonStyled>
									<PreviewMobileBackButtonIconStyled />
									<PreviewMobileBackButtonTextStyled>
										{t('preview.backToBuilder')}
									</PreviewMobileBackButtonTextStyled>
								</PreviewMobileBackButtonStyled>
							</PreviewMobileBackButtonContainerStyled>
							<ViewAs mobile={true} users={props.users} />
						</PreviewMobileBarStyled>
					)}
				</PreviewStyled>
			</Grid>

			{!props.isFullPagePreview && (
				<PreviewMobileButtonStyled onClick={mobileButtonClick}>
					<div
						style={{
							display: 'flex',
							flexWrap: 'wrap',
							justifyContent: 'center'
						}}
					>
						<VisibilityIconStyled />
						<PreviewMobileButtonTextStyled>
							{t('preview.goApp')}
						</PreviewMobileButtonTextStyled>
					</div>
				</PreviewMobileButtonStyled>
			)}
		</>
	) : null
}

const mapStateToProps = (state) => ({
	...state.appData
})

export default connect(mapStateToProps, null)(Preview)
