import React from 'react'
import { styled } from '@mui/material/styles'
import { useTheme } from '@mui/material/styles'
import { Button, Typography } from '@mui/material'
import Grid from '@mui/material/Grid2'

import KozmikModal from '../../kozmikModal/KozmikModal'

const ModalButtonStyled = styled(Button)(({ theme }) => ({
	width: '48%',
	height: 40,
	textTransform: 'none',
	borderRadius: 8
}))

const ModalButtonCloseStyled = styled(ModalButtonStyled)(({ theme }) => ({
	backgroundColor: '#FFFFFF',
	border: `1px solid ${theme.custom.colors.red}`,
	'&:hover': {
		border: `1px solid ${theme.custom.colors.red}`
	}
}))

const ModalButtonPublishStyled = styled(ModalButtonStyled)(({ theme }) => ({
	backgroundColor: theme.custom.colors.red,
	float: 'right',
	'&:hover': {
		backgroundColor: theme.custom.colors.darkRed
	}
}))

const ModalButtonTextStyled = styled(Typography)(({ theme }) => ({
	fontWeight: 'bold',
	fontSize: 14
}))

const CancelButtonTextStyled = styled(ModalButtonTextStyled)(({ theme }) => ({
	color: theme.custom.colors.red,
	textAlign: 'left'
}))

const TextBoldStyled = styled(Typography)(({ theme }) => ({
	fontWeight: theme.custom.fontWeight.fw700
}))

const WarningDialog = (props) => {
	const theme = useTheme()

	return (
		<KozmikModal
			accentColor={theme.custom.colors.red}
			visible={props.dialogVisible}
			hideModal={props.dialogClose}
			flexHeight
			sm
		>
			<Grid container justifyContent='center' style={{ padding: 20 }}>
				{props?.dialogTitle && (
					<Grid container size={{ xs: 12 }}>
						<Grid
							container
							size={{ xs: 12 }}
							alignItems='center'
							style={{ display: 'flex', padding: '10px 0' }}
						>
							<TextBoldStyled style={{ fontSize: 18 }}>
								{props.dialogTitle}
							</TextBoldStyled>
						</Grid>
					</Grid>
				)}
				<Grid size={{ xs: 12 }}>
					{props?.title && (
						<TextBoldStyled gutterBottom style={{ fontSize: 14 }}>
							{props.title}
						</TextBoldStyled>
					)}
					{props?.text && <p style={{ fontSize: 14 }}>{props.text}</p>}
				</Grid>
				<Grid size={{ xs: 12 }} style={{ marginTop: 30 }}>
					{props?.dialogCancelText && (
						<ModalButtonCloseStyled
							onClick={props.dialogClose}
							variant='outlined'
							color='primary'
						>
							<CancelButtonTextStyled>
								{props.dialogCancelText}
							</CancelButtonTextStyled>
						</ModalButtonCloseStyled>
					)}
					{props?.dialogAcceptText && (
						<ModalButtonPublishStyled
							onClick={props.dialogAccept}
							variant='contained'
							color='primary'
						>
							<ModalButtonTextStyled>
								{props?.dialogAcceptText}
							</ModalButtonTextStyled>
						</ModalButtonPublishStyled>
					)}
				</Grid>
			</Grid>
		</KozmikModal>
	)
}

export default WarningDialog
