import React from 'react'
import { styled } from '@mui/material/styles'
import { useTheme } from '@mui/material/styles'
import { Typography, Button } from '@mui/material'
import Grid from '@mui/material/Grid2'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import PageBox from '../../atoms/pageBox/PageBox'
import Header from '../../molecules/header/Header'
import UsageTable from '../../molecules/usageTable/UsageTable'

import { default as appConfig } from '../../../config/app.json'

const paymentEndpoint = appConfig.endpoints.payment

const ButtonStyled = styled(Button)(({ theme }) => ({
	textTransform: 'none',
	boxShadow: 'none !important',
	borderRadius: '4px',
	margin: '5px',
	flexGrow: 1,
	padding: 0,
	height: 40
}))

const HighlightedButtonStyled = styled(ButtonStyled)(({ theme }) => ({
	backgroundColor: `${theme.custom.colors.kozmikGreen} !important`
}))

const BorderedButtonStyled = styled(ButtonStyled)(({ theme }) => ({
	backgroundColor: `#ffffff !important`,
	border: `1px solid ${theme.custom.colors.kozmikDarkBlue}`
}))

const FormButtonStyled = styled(Button)(({ theme }) => ({
	fontSize: 14,
	color: theme.custom.colors.kozmikBlue,
	textTransform: 'none',
	backgroundColor: `transparent !important`,
	boxShadow: 'none !important',
	borderRadius: '4px',
	padding: '0px !important'
}))

const FormLinkButtonStyled = styled(FormButtonStyled)(({ theme }) => ({
	fontSize: 14,
	color: theme.custom.colors.kozmikBlue
}))

const ButtonTextStyled = styled(Typography)(({ theme }) => ({
	fontSize: 14,
	fontWeight: 600,
	color: theme.custom.colors.kozmikDarkBlue
}))

const TitleStyled = styled(Typography)(({ theme }) => ({
	display: 'block',
	fontSize: '16px',
	fontWeight: 600,
	width: '100%',
	marginBottom: 15
}))

const TitleHideMobileStyled = styled(TitleStyled)(({ theme }) => ({
	[theme.breakpoints.down('md')]: {
		display: 'none'
	}
}))

const PlanTitleStyled = styled('span')(({ theme }) => ({
	fontSize: 16,
	fontWeight: 600
}))

const PlanTextStyled = styled('span')(({ theme }) => ({
	fontSize: 14
}))

const getPriceTextWithCurrency = (price, currency) => {
	return currency == 'usd'
		? ` $${price}`
		: currency == 'try'
		? ` ${price}₺`
		: ''
}

const SubscriptionTemplate = (props) => {
	const { t } = useTranslation()
	const theme = useTheme()

	const permission = props.currentOrg?.roleType == 'Owner'

	return (
		<Grid container spacing={2}>
			<Grid size={{ xs: 12 }}>
				<Header text={t('subscriptionTemp.headerTitle')} />
			</Grid>

			<Grid container size={{ xs: 12, lg: 9 }} spacing={2}>
				{!!props?.usage && (
					<Grid size={{ xs: 12 }}>
						<PageBox
							sx={{
								width: '100%',
								padding: '20px 25px 45px 25px',
								flexWrap: 'wrap'
							}}
						>
							<TitleHideMobileStyled>
								{t('subscriptionTemp.yourPlan')}
							</TitleHideMobileStyled>

							<Grid
								container
								justifyContent='space-between'
								alignItems='center'
								size={{ xs: 12 }}
							>
								<Grid
									container
									size={{ xs: 12, md: 6 }}
									display={{ xs: 'none', sm: 'none', md: 'none', lg: 'block' }}
								>
									<Grid container size={{ xs: 12 }}>
										<Grid size={{ xs: 5 }}>
											<PlanTitleStyled>{props.usage?.Plan}</PlanTitleStyled>
										</Grid>
										<Grid size={{ xs: 7 }}>
											<PlanTitleStyled>
												{getPriceTextWithCurrency(
													props.usage?.PlanPrice,
													props.usage?.PlanCurrency
												)}
											</PlanTitleStyled>
										</Grid>
									</Grid>
								</Grid>

								<Grid
									container
									size={{ xs: 12, md: 6 }}
									display={{
										xs: 'block',
										sm: 'block',
										md: 'block',
										lg: 'none'
									}}
								>
									<Grid container size={{ xs: 12 }}>
										<Grid size={{ xs: 6 }}>
											<TitleStyled>
												{t('subscriptionTemp.yourPlan')}
											</TitleStyled>
										</Grid>
										<Grid size={{ xs: 6 }} style={{ textAlign: 'right' }}>
											<PlanTitleStyled>{props.usage?.Plan}</PlanTitleStyled>
											<PlanTitleStyled>
												{getPriceTextWithCurrency(
													props.usage?.PlanPrice,
													props.usage?.PlanCurrency
												)}
											</PlanTitleStyled>
										</Grid>
									</Grid>
								</Grid>

								<Grid
									container
									flexWrap={'nowrap'}
									alignItems='center'
									size={{ xs: 12, md: 4 }}
								>
									<HighlightedButtonStyled
										size='large'
										variant='contained'
										onClick={() => props.setModalVisible(true)}
									>
										<ButtonTextStyled>
											{t('subscriptionTemp.upgrade')}
										</ButtonTextStyled>
									</HighlightedButtonStyled>
									<BorderedButtonStyled
										size='large'
										variant='contained'
										onClick={() => props.setModalVisible(true)}
									>
										<ButtonTextStyled>
											{t('subscriptionTemp.viewPlans')}
										</ButtonTextStyled>
									</BorderedButtonStyled>
								</Grid>
							</Grid>
						</PageBox>
					</Grid>
				)}

				<Grid size={{ xs: 12 }}>
					<PageBox
						sx={{
							width: '100%',
							padding: '20px 25px 45px 25px',
							flexWrap: 'wrap'
						}}
					>
						<TitleStyled>{t('subscriptionTemp.usage')}</TitleStyled>

						<UsageTable data={props.usage} />
					</PageBox>
				</Grid>
			</Grid>

			<Grid size={{ xs: 12, lg: 3 }}>
				<PageBox
					sx={{
						width: '100%',
						padding: '20px 25px 45px 25px',
						flexWrap: 'wrap'
					}}
				>
					<TitleStyled>{t('subscriptionTemp.billing')}</TitleStyled>
					<Grid container spacing={2}>
						<Grid container alignItems='center' size={{ xs: 12 }}>
							<Grid size={{ xs: 6 }}>
								<PlanTextStyled>
									{t('subscriptionTemp.paymentMethod')}
								</PlanTextStyled>
							</Grid>
							<Grid container justifyContent='flex-end' size={{ xs: 6 }}>
								<form action={paymentEndpoint.portal} method='POST'>
									<input
										type='hidden'
										name='orgId'
										value={props?.currentOrg?.id ?? ''}
									/>
									<FormLinkButtonStyled type='submit'>
										{t('subscriptionTemp.addCard')}
									</FormLinkButtonStyled>
								</form>
							</Grid>
						</Grid>

						<Grid container alignItems='center' size={{ xs: 12 }}>
							<Grid size={{ xs: 6 }}>
								<PlanTextStyled>
									{t('subscriptionTemp.billInfo')}
								</PlanTextStyled>
							</Grid>
							<Grid container justifyContent='flex-end' size={{ xs: 6 }}>
								<form action={paymentEndpoint.portal} method='POST'>
									<input
										type='hidden'
										name='orgId'
										value={props?.currentOrg?.id ?? ''}
									/>
									<FormButtonStyled type='submit'>
										{t('subscriptionTemp.updateInfo')}
									</FormButtonStyled>
								</form>
							</Grid>
						</Grid>

						<Grid container alignItems='center' size={{ xs: 12 }}>
							<Grid size={{ xs: 6 }}>
								<PlanTextStyled>
									{t('subscriptionTemp.invoiceHistory')}
								</PlanTextStyled>
							</Grid>
							<Grid container justifyContent='flex-end' size={{ xs: 6 }}>
								<form action={paymentEndpoint.portal} method='POST'>
									<input
										type='hidden'
										name='orgId'
										value={props?.currentOrg?.id ?? ''}
									/>
									<FormButtonStyled type='submit'>
										{t('subscriptionTemp.history')}
									</FormButtonStyled>
								</form>
							</Grid>
						</Grid>
					</Grid>
				</PageBox>
			</Grid>
		</Grid>
	)
}

const mapStateToProps = (state) => ({
	...state.userInfo,
	...state.usage
})

export default connect(mapStateToProps, null)(SubscriptionTemplate)
